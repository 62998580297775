import { makeStyles } from '@material-ui/core/styles';
import { GlobalTheme } from 'features/app/app.theme';

const useProfProspectStyles = makeStyles((theme: GlobalTheme) => ({
  labelRenseing: {
    fontSize: '0.9375rem',
    textAlign: 'left',
    fontFamily: theme.fonts.medium,
    color: theme.secondary.title,
    marginBottom: '17px',
  },
  labelRenseingTooltip: {
    fontSize: '0.9375rem',
    textAlign: 'left',
    fontFamily: theme.fonts.medium,
    color: theme.secondary.title,
  },
  groupFormBody: {
    padding: '30px',
  },
  textFieldRib: {
    width: '110px',
    marginRight: '20px',
    '& .MuiOutlinedInput-marginDense': {
      direction: 'ltr',
      '& .MuiInputAdornment-positionEnd': {
        marginRight: '5px',
        marginLeft: '5px',
      },
    },
    '& .MuiInputBase-input': {
      direction: 'initial',
      textAlign: 'center',
      fontFamily: theme.fonts.bold,
      fontSize: '0.875rem',
    },
    '@media (max-width: 414px)': {
      width: '45%',
      float: 'left',
    },
  },
  textFieldRibAr: {
    width: '110px',
    marginRight: '20px',
    '& .MuiOutlinedInput-marginDense': {
      direction: 'ltr',
      padding: '0px',
      '& .MuiInputAdornment-positionEnd': {
        marginRight: '5px',
        marginLeft: '5px',
        '& p': {
          fontWeight: 'bold',
        },
      },
    },
    '& .MuiInputBase-input': {
      direction: 'initial',
      textAlign: 'center',
      fontFamily: theme.fonts.bold,
      fontSize: '0.875rem',
    },
    '@media (max-width: 414px)': {
      width: '45%',
      float: 'left',
    },
  },
  thirdTextFieldRib: {
    width: '240px',
    '@media (max-width: 414px)': {
      width: '100%',
    },
  },
  lastTextFieldRib: {
    width: '90px',
    '@media (max-width: 414px)': {
      width: '40%',
    },
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center',
  },
  rootTextField: {
    color: theme.secondary.title,
    border: '0.063em solid',
    borderRadius: '20px',
  },
  globalTooltip: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  radioGroup: {
    justifyContent: 'start',
  },
  displayDiv: {
    '@media (max-width: 540px)': {
      display: 'none',
    },
  },
}));

export default useProfProspectStyles;
