import { GlobalTheme } from 'features/app/app.theme';
import { makeStyles } from '@material-ui/core/styles';

const useProfNotConvStyles = makeStyles((theme: GlobalTheme) => ({
  labelRenseing: {
    fontSize: '0.9375rem',
    textAlign: 'left',
    fontFamily: theme.fonts.medium,
    color: theme.secondary.title,
    marginBottom: '17px',
  },
  labelRenseingTooltip: {
    fontSize: '0.9375rem',
    textAlign: 'left',
    fontFamily: theme.fonts.medium,
    color: theme.secondary.title,
  },
  groupFormBody: {
    padding: '30px',
  },
  textFieldRib: {
    width: '110px',
    marginRight: '20px',
    '& .MuiOutlinedInput-marginDense': {
      direction: 'ltr',
      '& .MuiInputAdornment-positionEnd': {
        marginRight: '5px',
        marginLeft: '5px',
      },
    },
    '& .MuiInputBase-input': {
      direction: 'initial',
      textAlign: 'center',
      fontFamily: theme.fonts.bold,
      fontSize: '0.875rem',
    },
    '@media (max-width: 414px)': {
      width: '45%',
      float: 'left',
    },
  },
  textFieldRibAr: {
    width: '110px',
    marginRight: '20px',
    '& .MuiOutlinedInput-marginDense': {
      direction: 'ltr',
      padding: '0px',
      '& .MuiInputAdornment-positionEnd': {
        marginRight: '5px',
        marginLeft: '5px',
        '& p': {
          fontWeight: 'bold',
        },
      },
    },
    '& .MuiInputBase-input': {
      direction: 'initial',
      textAlign: 'center',
      fontFamily: theme.fonts.bold,
      fontSize: '0.875rem',
    },
    '@media (max-width: 414px)': {
      width: '45%',
      float: 'left',
    },
  },
  thirdTextFieldRib: {
    width: '240px',
    '@media (max-width: 414px)': {
      width: '100%',
    },
  },
  lastTextFieldRib: {
    width: '90px',
    '@media (max-width: 414px)': {
      width: '40%',
    },
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center',
  },
  rootTextField: {
    color: theme.secondary.title,
    border: '0.063em solid',
    borderRadius: '20px',
  },
  globalTooltip: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  radioGroup: {
    justifyContent: 'start',
  },
  elementsRib: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    '@media (max-width: 768px)': {
      flexDirection: 'column',
    },
  },
  elementsRibAr: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'flex-start',
    '@media (max-width: 768px)': {
      flexDirection: 'column',
    },
  },
  autoComplBorder: {
    width: '100%',
    border: 'solid 1px',
    borderRadius: '40px',
    borderColor: theme.secondary.title,
    '& .MuiInputBase-root': {
      padding: '0px 15px',
    },
  },
  noBorder: {
    border: 0,
    '& .MuiInputBase-root': {
      padding: '0px 15px',
    },
  },
  error: {
    fontFamily: theme.fonts.regular,
    color: theme.secondary.main,
    fontSize: '0.75rem',
    fontWeight: 700,
  },
  whithoutMsgError: {
    '& .MuiFormHelperText-root.Mui-error': {
      display: 'none',
    },
  },
  displayDiv: {
    '@media (max-width: 540px)': {
      display: 'none',
    },
  },
  errorBorder: {
    '& .MuiInputBase-root': {
      borderColor: theme.secondary.main,
    },
  },
}));

export default useProfNotConvStyles;
