import { AppStore } from 'core/root.model';
import { createSelector } from 'reselect';
import {
  WordingModel,
  WordingStore,
  WORDINGS_REDUCER,
  Wording,
} from './reducer.wording';
import { toJS } from 'common/utils/app.util';

function getWordingObj(state: AppStore): WordingStore {
  return state.get(WORDINGS_REDUCER);
}

export function selectByKey<T>(key: keyof WordingModel | any) {
  return createSelector(getWordingObj, (wordingObj) => {
    return toJS(wordingObj.get(key)) as T;
  });
}

export function selectByArray<T>(array: any) {
  return createSelector(getWordingObj, (wordingObj) => {
    return toJS(wordingObj.getIn(array)) as T;
  });
}

export const selectWording = selectByKey<Wording>('wording');

export const selectWordingFR = selectByKey<Wording>('wordingfr');

export const selectWordingAR = selectByKey<Wording>('wordingar');

export const selectWordingLoading = selectByKey<boolean>('loading');

export const selectCompWording = (comp: string) =>
  selectByArray<Wording>(['wording', comp]);
