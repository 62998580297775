import Box from '@material-ui/core/Box';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Wording } from 'common/wording/reducer.wording';
import { selectCompWording } from 'common/wording/selectors.wording';
import { GlobalTheme } from 'features/app/app.theme';
import { LANG_STATE } from 'features/home/constants.simulation';
import { getSimulationInfoByArraySelector } from 'features/home/selector.simulation';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import QontoConnector from './QontoConnector';
import QontoStepIcon from './QontoStepIcon';
import useStepperMapper from './useStepperMapper';

const useStyles = makeStyles((theme: GlobalTheme) => ({
  stepLabel: {
    '& .MuiStepLabel-label': {
      textTransform: 'uppercase',
      fontSize: '1rem',
      fontFamily: theme.fonts.bold,
    },
    '& .MuiStepLabel-active': {
      color: theme.secondary.main,
    },
    '& .MuiStepConnector-root.Mui-active .MuiStepConnector-line': {
      borderColor: 'red',
    },
    '& .MuiStepConnector-root.Mui-completed .MuiStepConnector-line': {
      borderColor: 'green',
    },
  },
  actionsContainer: {
    marginBottom: 10,
  },
  stepper: {
    '@media (max-width: 980px)': {
      flexDirection: 'row',
      padding: '0 0 10px',
      '& .MuiStepLabel-labelContainer': {
        display: 'none',
      },
      '& .MuiStepConnector-root': {
        display: 'none',
      },
    },
  },
  stepMobileLabel: {
    display: 'none',
    '& .MuiTypography-root': {
      fontSize: '1rem',
      fontFamily: theme.fonts.medium,
      color: theme.primary.title,
      textAlign: 'left',
      fontWeight: 500,
    },

    '@media (max-width: 980px)': {
      display: 'block',
    },
  },
}));

function getSteps() {
  return ['step_1', 'step_2', 'step_3', 'step_4', 'step_5'];
}

function CustomStepper() {
  const classes = useStyles();
  const userCurentStep = useStepperMapper();
  const [activeStep, setActiveStep] = useState(userCurentStep);

  const langSelected: any =
    useSelector(getSimulationInfoByArraySelector(['lang'])) || LANG_STATE.FR;

  const steps = getSteps();

  const handleStep = (step: number) => {
    setActiveStep(step);
  };

  useEffect(() => {
    handleStep(userCurentStep);
  }, [userCurentStep]);

  const wording: Wording = useSelector(selectCompWording('stipper'));

  return (
    <Box
      style={
        langSelected === LANG_STATE.FR
          ? {
              direction: 'ltr',
            }
          : {
              direction: 'rtl',
            }
      }
    >
      <Box mb={1} className={classes.stepMobileLabel}>
        <Typography>{wording?.[steps[activeStep]]}</Typography>
      </Box>
      <Stepper
        className={classes.stepper}
        activeStep={activeStep}
        orientation='vertical'
        connector={<QontoConnector />}
      >
        {steps.map((step) => {
          return (
            <Step key={step}>
              <StepLabel
                className={classes.stepLabel}
                StepIconComponent={QontoStepIcon}
              >
                {wording?.[step]}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}

export default CustomStepper;
