import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { selectCompWording } from 'common/wording/selectors.wording';
import { GlobalTheme } from 'features/app/app.theme';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import money from 'assets/svg/money.svg';
import leftFlesh from 'assets/svg/leftFlesh.svg';
import { RecapSimulationElement } from 'features/home/components/RecapSimulationElement';
import Link from '@material-ui/core/Link';
import { updateFieldByArrayAction, SimulationAT } from '../actions.simulation';
import { push } from 'connected-react-router';
import {
  SIMULATION_STATE,
  SLIDER_PREATTRIBUTION,
} from '../constants.simulation';
import Spinner from 'common/components/Spinner';
import Slider from './Slider';
import { GlobalError } from 'common/components/customFields/GlobalError';
import {
  E3,
  E34,
  E35,
  E36,
  E37,
  E38,
  E39,
} from 'common/utils/validateJs/contants';
import { isEmpty } from 'lodash';
import { ProductTypes } from 'features/auth/constants';
import { getSimulationInfoByArraySelector } from 'features/home/selector.simulation';
import { LANG_STATE } from 'features/home/constants.simulation';
import { getValidationInfoByArraySelector } from 'features/validationBmceDirect/selector.validation';
import { getSessionStorage } from 'common/utils/sessionStorage.util';
import { scrollToElement } from 'common/utils/app.util';

const useStyles = makeStyles((theme: GlobalTheme) => ({
  root: {
    width: '900px',
    '@media (max-width: 1440px)': {
      width: '900px',
    },
    '@media (max-width: 1024px)': {
      width: '700px',
    },
    '@media (max-width: 425px)': {
      width: '100%',
    },
    '@media (max-width: 320px)': {
      width: '282px',
    },
  },
  recapDetailBody: {
    maxWidth: '70%',
    margin: 'auto',
    padding: '50px 90px 90px 90px',
    '@media (max-width: 450px)': {
      padding: '36px 50px 10px',
    },
  },
  recapDetailHeader: {
    padding: '40px 90px 0 90px',
    display: 'flex',
    alignItems: 'center',
    margin: 'auto',
    width: '70%',
    justifyContent: 'left',
    '@media (max-width: 1024px)': {
      padding: '20px 0 0',
      justifyContent: 'center',
      flexDirection: 'column',
    },
  },
  header: {
    paddingLeft: '40px',
    '@media (max-width: 1024px)': {
      paddingLeft: 0,
    },
  },
  headerTitle: {
    textTransform: 'uppercase',
    color: theme.secondary.main,
    fontFamily: theme.fonts.bold,
    fontSize: '1.625rem',
    textAlign: 'left',
    '@media (max-width: 1024px)': {
      fontSize: '1.25rem',
    },
    '@media (max-width: 414px)': {
      fontSize: '0.8125rem',
    },
  },
  leftTitleIcon: {
    width: '65px',
  },
  separator: {
    backgroundColor: '#cbd1dc',
    height: '0.1px',
    margin: '45px auto',
  },
  separatorAr: {
    backgroundColor: '#cbd1dc',
    height: '1px',
    margin: '55px auto',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    '@media (max-width: 450px)': {
      flexDirection: 'column',
    },
  },
  buttonSubmit: {
    borderRadius: '14px 0',
    fontSize: '0.875rem',
    width: '200px',
    padding: '14px 29px',
    fontFamily: theme.fonts.bold,
    '@media (max-width: 450px)': {
      width: '100%',
    },
  },
  simulationElementColor: {
    color: theme.primary.title,
  },
  simulationSizeVal: {
    fontSize: '1.625rem',
  },
  link: {
    padding: '0 26px',
    color: theme.secondary.title,
    fontSize: '0.875rem',
    display: 'flex',
    fontFamily: theme.fonts.bold,
    alignItems: 'center',
    '&:hover': {
      textDecoration: 'none',
    },
    '@media (max-width: 450px)': {
      margin: 'auto',
      padding: '15px 26px',
    },
  },
  imgLink: {
    marginRight: '12px',
  },
  imgLinkAr: {
    transform: 'scaleX(-1)',
    marginRight: '12px',
  },
  borderTitle: {
    border: '3px solid #F93276',
    width: '55px',
    borderRadius: '5px',
    marginTop: '3px',
  },
  groupFormFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    '@media (max-width: 450px)': {
      flexDirection: 'column',
    },
  },
  saveButton: {
    borderRadius: '0 14px',
    backgroundColor: theme.primary.title,
    '&:hover': {
      backgroundColor: theme.primary.title,
    },
    '@media (max-width: 450px)': {
      width: '100%',
    },
  },
  bold: {
    fontFamily: `${theme.fonts.bold} !important`,
  },
  iconInfoUp: {
    width: '43px',
  },
  textInfo: {
    padding: '5px',
    textAlign: 'center',
    '@media (max-width: 450px)': {
      textAlign: 'left',
    },
  },
  message: {
    fontSize: '0.9375rem',
    color: '#556584',
    fontFamily: theme.fonts.regular,
  },
  info: {
    backgroundColor: '#DBE2ED',
    width: '100%',
    display: 'flex',
    marginTop: '40px',
  },
  contentSimulation: {
    padding: '0 20px !important',
    position: 'relative',
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '30px 0 !important',
  },
  circuleProgress: {
    position: 'absolute',
    zIndex: 1,
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  spinner: {
    width: '80px',
  },
  titleSize: {
    '@media (max-width: 414px)': {
      flexDirection: 'column',
    },
  },
}));

interface Convention {
  codeConvention: string;
  companyName: string;
  minAmount: number;
  maxAmount: number;
  minDuration: number;
  maxDuration: number;
  minMonthlyPayment: number;
  maxMonthlyPayment: number;
}

const RemakeSimulation = () => {
  const classes = useStyles();
  const wordingSimulation = useSelector(selectCompWording('simulationRecap'));
  const errorsWording = useSelector(selectCompWording('errors'));
  const langSelected: any =
    useSelector(getSimulationInfoByArraySelector(['lang'])) || LANG_STATE.FR;
  const dispatch = useDispatch();

  const simulation: any = useSelector(
    getSimulationInfoByArraySelector(['simulation', 'fields']),
  );

  const simulationResponse: any = useSelector(
    getSimulationInfoByArraySelector(['simulation', 'response']),
  );
  const errors: any = useSelector(
    getSimulationInfoByArraySelector(['simulation', 'errors']),
  );
  const selectedConvention: any | Convention = useSelector(
    getSimulationInfoByArraySelector(['simulation', 'selectedConvention']),
  );

  // FROM BMCE DIRECT REDIRECT
  const fromBMCEDirect: any = useSelector(
    getValidationInfoByArraySelector(['data']),
  );
  const preAttributionBmceDirect: any = useSelector(
    getValidationInfoByArraySelector(['preAttribution']),
  );
  const MontantCREDIT = !isEmpty(getSessionStorage('MontantCREDIT'))
    ? parseInt(getSessionStorage('MontantCREDIT'))
    : SLIDER_PREATTRIBUTION.minAmount;
  const duree = !isEmpty(getSessionStorage('duree'))
    ? parseInt(getSessionStorage('duree'))
    : SLIDER_PREATTRIBUTION.maxDuration;
  const preAttributionToField = () => {
    dispatch(
      updateFieldByArrayAction(
        ['simulation', 'selectedConvention', 'maxDuration'],
        duree,
      ),
    );
    dispatch(
      updateFieldByArrayAction(
        ['simulation', 'selectedConvention', 'minDuration'],
        SLIDER_PREATTRIBUTION.minDuration,
      ),
    );
    dispatch(
      updateFieldByArrayAction(
        ['simulation', 'selectedConvention', 'maxAmount'],
        MontantCREDIT,
      ),
    );
    dispatch(
      updateFieldByArrayAction(
        ['simulation', 'fields', 'creditDuration'],
        selectedConvention?.maxDuration,
      ),
    );
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updateFieldByArrayAction(
        ['simulation', 'fields', event.target.name],
        event.target.value,
      ),
    );
    if (preAttributionBmceDirect) preAttributionToField();
    setTimeout(() => dispatch({ type: SimulationAT.refaireSimulation }), 3000);
  };

  const goBackToEditSimulation = () => {
    dispatch({
      type: SimulationAT.updateState,
      value: SIMULATION_STATE.simulation,
    });
  };

  const goToSubscribtion = () => {
    dispatch({
      type: SimulationAT.submitSimulationCreaEspace,
      simulationResponse,
    });
    // if (preAttributionBmceDirect)
    //   dispatch({ type: SimulationAT.preAttributionRoute });
    // else
    if (
      simulationResponse.product === ProductTypes.cp_slf ||
      !simulation.clientBOA
    ) {
      dispatch(push('/sign-up'));
    } else {
      if (isEmpty(fromBMCEDirect)) {
        dispatch(push('/create-profile'));
      } else {
        dispatch(push('/sign-up'));
      }
    }
  };

  /*const debouncedCreditAmount = useDebounce(500, simulation?.creditAmount);
  const debouncedCreditDuration = useDebounce(500, simulation?.creditDuration);
  const debouncedMonthlyPayment = useDebounce(500, simulation.monthlyPayment);
  const prevDebouncedCreditAmount = usePrevious(simulation?.creditAmount);
  useEffect(() => {
    if (prevDebouncedCreditAmount) {
      dispatch({
        type: SimulationAT.simulateWithLoader,
        paramToInit: 'monthlyPayment',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps,
  }, [dispatch, debouncedCreditAmount]);

  useEffect(() => {
    if (prevDebouncedCreditAmount) {
      dispatch({
        type: SimulationAT.simulateWithLoader,
        paramToInit: 'monthlyPayment',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps,
  }, [dispatch, debouncedCreditDuration]);

  useEffect(() => {
    if (prevDebouncedCreditAmount) {
      dispatch({
        type: SimulationAT.simulateWithLoader,
        paramToInit: 'creditDuration',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps,
  }, [dispatch, debouncedMonthlyPayment]);*/

  useEffect(() => {
    scrollToElement(undefined, 400);
  }, []);
  const hasError =
    errors &&
    errors.global &&
    (errors.global.includes(E3) ||
      errors.global.includes(E34) ||
      errors.global.includes(E35) ||
      errors.global.includes(E36) ||
      errors.global.includes(E37) ||
      errors.global.includes(E38) ||
      errors.global.includes(E39));
  return (
    <div className={classes.root}>
      <Spinner loader={simulationResponse?.loader} />
      <div className={classes.recapDetailHeader}>
        <img src={money} alt='' className={classes.leftTitleIcon} />
        <div className={classes.header}>
          <Typography variant='h4' className={classes.headerTitle}>
            {wordingSimulation?.title}
          </Typography>
          <div className={classes.borderTitle} />
        </div>
      </div>
      {hasError && (
        <GlobalError globalError={errors.global} errorWording={errorsWording} />
      )}
      <div className={classes.recapDetailBody}>
        <RecapSimulationElement
          label={wordingSimulation?.monthlyTax}
          value={isEmpty(errors) ? simulationResponse.monthlyPayment : ''}
          unit={wordingSimulation?.dhsByMonth}
          asterisk={true}
          InputClasses={{
            simulationSizeVal: classes.simulationSizeVal,
            simulationElementColor: classes.simulationElementColor,
            titleSize: classes.titleSize,
          }}
        />
        <div
          className={
            langSelected === LANG_STATE.FR
              ? classes.separator
              : classes.separatorAr
          }
        />
        <RecapSimulationElement
          label={wordingSimulation?.amountRequested}
          value={simulationResponse.creditAmount || simulation?.creditAmount}
          unit={wordingSimulation?.dhs}
        />
        <RecapSimulationElement
          label={wordingSimulation?.creditTerm}
          value={
            simulationResponse.creditDuration || simulation?.creditDuration
          }
          unit={wordingSimulation?.month}
        />
        {simulation.rachat === 'O' && (
          <RecapSimulationElement
            label={wordingSimulation?.mntDebloqueSurCompte}
            value={
              simulationResponse.debloquedAmount || simulation.debloquedAmount
            }
            unit={wordingSimulation?.dhs}
          />
        )}
        <RecapSimulationElement
          label={wordingSimulation?.insurance}
          value={isEmpty(errors) ? simulationResponse.amountFeesAssurance : ''}
          unit={wordingSimulation?.dhs}
        />
        <RecapSimulationElement
          label={wordingSimulation?.applicationFees}
          value={
            isEmpty(errors)
              ? simulationResponse.amountFeesDemande?.toString() === '0'
                ? wordingSimulation?.free
                : simulationResponse.amountFeesDemande
              : ''
          }
          unit={
            isEmpty(errors)
              ? simulationResponse.amountFeesDemande?.toString() === '0'
                ? ' '
                : wordingSimulation?.dhs
              : wordingSimulation?.dhs
          }
        />
        <RecapSimulationElement
          label={wordingSimulation?.totalInterest}
          value={isEmpty(errors) ? simulationResponse.totalInterest : ''}
          unit={wordingSimulation?.dhs}
        />
        <RecapSimulationElement
          label={wordingSimulation?.totalCostOfCredit}
          value={isEmpty(errors) ? simulationResponse.totalCostOfCredit : ''}
          unit={wordingSimulation?.dhs}
        />
        <div className={classes.separator} />
        <Slider
          classes={classes}
          wordingSimulation={wordingSimulation}
          errors={errors}
          errorsWording={errorsWording}
          selectedConvention={selectedConvention}
          simulation={simulation}
          simulationResponse={simulationResponse}
          handleChange={handleChange}
          hasOTP={false}
        />
      </div>
      <div className={classes.groupFormFooter}>
        <div className={classes.buttons}>
          <Link
            onClick={goBackToEditSimulation}
            component='button'
            variant='body2'
            className={classes.link}
          >
            <img
              src={leftFlesh}
              alt=''
              className={
                langSelected === LANG_STATE.FR
                  ? classes.imgLink
                  : classes.imgLinkAr
              }
            />
            {wordingSimulation?.modifyInfos}
          </Link>
          <Button
            type='submit'
            className={classes.buttonSubmit}
            variant='contained'
            color='secondary'
            onClick={goToSubscribtion}
            disabled={hasError}
          >
            {wordingSimulation?.iSubscribe}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RemakeSimulation;
