import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { GlobalTheme } from 'features/app/app.theme';

const useStyles = makeStyles((theme: GlobalTheme) => ({
  blocStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: '#FFF',
    borderRadius: '30px',
    padding: '20px',
    boxShadow: '0px 0px 2px 0px #ececec',
    marginBottom: '15px',
  },
  text: {
    fontFamily: theme.fonts.bold,
    color: theme.primary.title,
    fontSize: '1.1rem',
    '@media (max-width: 480px)': {
      fontSize: '0.9rem',
    },
  },
  icon: {
    width: '70px',
    marginRight: '20px',
  },
  description: {
    color: theme.primary.title,
  },
}));

interface IAdvantageBloc {
  text: string;
  description?: string;
  icon: string;
}
const AdvantageBloc = ({ text, icon, description }: IAdvantageBloc) => {
  const classes = useStyles();

  return (
    <div className={classes.blocStyle}>
      <img src={icon} alt={'question'} className={classes.icon} />
      <div>
        <Typography variant='body1' align='left' className={classes.text}>
          {text}
        </Typography>
        {description && (
          <Typography
            variant='h5'
            component='p'
            align='left'
            className={classes.description}
          >
            {description}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default AdvantageBloc;
