import { toJS } from 'common/utils/app.util';
import { createSelector } from 'reselect';
import { AppStore } from '../../core/root.model';
import { AUTH_REDUCER } from './reducer.auth';

const getAuth = (state: AppStore) => state.get(AUTH_REDUCER);

export const getSignUpErrorsSelector = createSelector(getAuth, (auth) =>
  toJS(auth.getIn(['signUp', 'errors'])),
);

export const getAuthInfoByArraySelector = (selector: string[]) =>
  createSelector(getAuth, (auth) => toJS(auth.getIn(selector)));

export const isAuthenticatedSelector = createSelector(getAuth, (auth) =>
  auth.get('isAuthenticated'),
);

export const userProfileSelector = createSelector(getAuth, (auth) => ({
  typeClient: auth.getIn(['userProfile', 'typeClient']),
  product: auth.getIn(['userProfile', 'product']),
  clientBOA: auth.getIn(['userProfile', 'clientBOA']),
  stepClient: auth.getIn(['userProfile', 'stepClient']),
  appreciation: auth.getIn(['userProfile', 'appreciation']),
}));
