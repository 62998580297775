import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { CustomInput } from 'common/components/customFields/CustomInput';
import CustomizedSwitches from 'common/components/customFields/CustomizedSwitches';
import { CustomRadioGroup } from 'common/components/customFields/CustomRadioGroup';
import InputNumber from 'common/components/customFields/InputNumber';
import SelectDown from 'common/components/customFields/SelectDown';
import Seo from 'common/components/seo/Seo';
import { CheckAuthorization } from 'common/components/PrivateRoute';

import {
  selectCompWording,
  selectWording,
} from 'common/wording/selectors.wording';
import { GlobalTheme } from 'features/app/app.theme';
import {
  AccountTypes,
  ProductTypes,
  signUpFields,
} from 'features/auth/constants';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getConfigAction, updateFieldAction } from '../../actions.situation';
import {
  salarieSituationConfig,
  situationSalarieFields,
  listDayOfMonth,
} from '../../constants.situation';
import { getSituationInfoByArraySelector } from '../../selector.situation';
import { getSessionStorage } from 'common/utils/sessionStorage.util';
import { getSimulationInfoByArraySelector } from 'features/home/selector.simulation';
import SelectGrid from 'common/components/customFields/SelectGrid';
import { isEmpty } from 'lodash';
import { SituationAT } from 'features/situation/actions.situation';
import { getAuthInfoByArraySelector } from 'features/auth/selector.auth';
import { updateFieldSignUpAction } from './../../../auth/actions.auth';
import FieldError from '../../../auth/components/CustomFieldError';
import { CustomCheckBox } from '../../../../common/components/customFields/CustomCheckBox';
import GeneralCondition from '../../../auth/components/GeneralCondition';
import { FormControl, FormHelperText, Typography } from '@material-ui/core';
const useStyles = makeStyles((theme: GlobalTheme) => ({
  labelRenseing: {
    fontSize: '0.9375rem',
    textAlign: 'left',
    fontFamily: theme.fonts.medium,
    color: theme.secondary.title,
    marginBottom: '17px',
  },
  labelRenseingTooltip: {
    fontSize: '0.9375rem',
    textAlign: 'left',
    fontFamily: theme.fonts.medium,
    color: theme.secondary.title,
  },
  groupFormBody: {
    padding: '45px',
    '@media (max-width: 414px)': {
      padding: '30px',
    },
  },
  textFieldRib: {
    width: '110px',
    marginRight: '20px',
    '& .MuiOutlinedInput-marginDense': {
      direction: 'ltr',
      '& .MuiInputAdornment-positionEnd': {
        marginRight: '5px',
        marginLeft: '5px',
      },
    },
    '& .MuiInputBase-input': {
      direction: 'initial',
      textAlign: 'center',
      fontFamily: theme.fonts.bold,
      fontSize: '0.875rem',
    },
    '@media (max-width: 414px)': {
      width: '45%',
      float: 'left',
    },
  },
  textFieldRibAr: {
    width: '110px',
    marginRight: '20px',
    '& .MuiOutlinedInput-marginDense': {
      direction: 'ltr',
      padding: '0px',
      '& .MuiInputAdornment-positionEnd': {
        marginRight: '5px',
        marginLeft: '5px',
        '& p': {
          fontWeight: 'bold',
        },
      },
    },
    '& .MuiInputBase-input': {
      direction: 'initial',
      textAlign: 'center',
      fontFamily: theme.fonts.bold,
      fontSize: '0.875rem',
    },
    '@media (max-width: 414px)': {
      width: '45%',
      float: 'left',
    },
  },
  thirdTextFieldRib: {
    width: '240px',
    '@media (max-width: 414px)': {
      width: '100%',
    },
  },
  lastTextFieldRib: {
    width: '90px',
    '@media (max-width: 414px)': {
      width: '40%',
    },
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center',
  },
  globalTooltip: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  labelTooltip: {
    padding: '10px',
  },
  radioGroup: {
    justifyContent: 'start',
  },
  elementsRib: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    '@media (max-width: 768px)': {
      flexDirection: 'column',
    },
  },
  elementsRibAr: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'flex-start',
    '@media (max-width: 768px)': {
      flexDirection: 'column',
    },
  },
  error: {
    fontFamily: theme.fonts.regular,
    color: theme.secondary.main,
    fontSize: '0.75rem',
    fontWeight: 700,
  },
  whithoutMsgError: {
    '& .MuiFormHelperText-root.Mui-error': {
      display: 'none',
    },
  },
  displayDiv: {
    '@media (max-width: 540px)': {
      display: 'none',
    },
  },
  errorBorder: {
    '& .MuiInputBase-root': {
      borderColor: theme.secondary.main,
    },
  },
  civility: {
    marginRight: '10px',
  },
  civilityRadioGroup: {
    marginLeft: '-40px',
  },
  checkedStyle: {
    marginLeft: '5px',
  },
  checkboxLabel: {
    fontSize: '0.75rem',
    textAlign: 'left',
  },
  conditionOfUseLink: {
    textDecoration: 'underline',
    textAlign: 'left',
    color: theme.secondary.main,
  },
}));

const PreattRetraiteConv = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const wordingSalarie = useSelector(selectCompWording('detailedForm'));
  const wording = useSelector(selectWording);

  const additionalIncomeNatureList = [
    {
      label: wordingSalarie?.foncierLoyer,
      value: '1',
    },
    {
      label: wordingSalarie?.placementsFinanciers,
      value: '2',
    },
    {
      label: wordingSalarie?.rente,
      value: '3',
    },
    {
      label: wordingSalarie?.pension,
      value: '4',
    },
    {
      label: wordingSalarie?.autresNature,
      value: '5',
    },
  ];
  const listDiffere = [
    { label: wordingSalarie?.mois1, value: 1 },
    { label: wordingSalarie?.mois2, value: 2 },
    { label: wordingSalarie?.mois3, value: 3 },
  ];
  const civility = [
    {
      label: wording?.signUp?.civ_dam,
      value: 'F',
    },
    {
      label: wording?.signUp?.civ_mon,
      value: 'M',
    },
  ];
  const errorsWording = useSelector(selectCompWording('errors'));
  const situationSalarie: any = useSelector(
    getSituationInfoByArraySelector(['situationSalarie', 'fields']),
  );
  const simulation: any = useSelector(
    getSimulationInfoByArraySelector(['simulation', 'fields']),
  );

  const errors: any = useSelector(
    getSituationInfoByArraySelector(['situationSalarie', 'errors']),
  );

  const signUp: any = useSelector(getAuthInfoByArraySelector(['signUp']));
  const signUpWording = wording?.signUp;
  const [openConditionOfUse, setOpenConditionOfUse] = React.useState(false);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updateFieldAction(
        'situationSalarie',
        event.target.name,
        event.target.value,
      ),
    );
  };

  const displayGridsRachatFromBMCE =
    simulation.rachat === 'O' && !isEmpty(situationSalarie.listRachats);

  const displayGridsRachatNotFromBMCE =
    simulation.rachat === 'O' &&
    isEmpty(situationSalarie.listRachats) &&
    situationSalarie.isBMCE === 'client';
  // handle signup changes
  const handleChangeSignUp = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'firstName' || e.target.name === 'lastName') {
      const value = e.target.value.replace(/[^\D]/gi, '');
      dispatch(updateFieldSignUpAction('signUp', e.target.name, value));
    } else if (
      e.target.name === 'email' ||
      e.target.name === 'emailConfirmation'
    ) {
      const value = e.target.value.replace(/\s/g, '');
      dispatch(updateFieldSignUpAction('signUp', e.target.name, value));
    } else {
      dispatch(
        updateFieldSignUpAction('signUp', e.target.name, e.target.value),
      );
    }
  };
  const conditionChanged = () => {
    if (signUp.conditionOfUse) {
      dispatch(
        updateFieldSignUpAction('signUp', signUpFields?.conditionOfUse, false),
      );
    } else {
      setOpenConditionOfUse(true);
    }
  };
  const handleClose = () => {
    setOpenConditionOfUse(false);
  };

  const handleValidate = () => {
    dispatch(
      updateFieldSignUpAction('signUp', signUpFields?.conditionOfUse, true),
    );
    setOpenConditionOfUse(false);
  };
  const onPaste = (e: React.ClipboardEvent) => {
    e.preventDefault();
  };
  //   const errorsCompWording = useSelector(selectCompWording('errors'));

  const errorsSignUp: any = useSelector(
    getAuthInfoByArraySelector(['signUp', 'errors']),
  );

  useEffect(() => {
    dispatch({
      type: SituationAT.loanRepurchase,
      value: getSessionStorage('simulationId'),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(situationSalarie.email)) {
      dispatch(
        updateFieldSignUpAction(
          'signUp',
          signUpFields?.emailConfirmation,
          situationSalarie.email,
        ),
      );
      dispatch(
        updateFieldSignUpAction(
          'signUp',
          signUpFields?.email,
          situationSalarie.email,
        ),
      );
    }
  }, [dispatch, situationSalarie.email]);
  useEffect(() => {
    dispatch(getConfigAction(salarieSituationConfig.familialSituation));
    dispatch(getConfigAction(salarieSituationConfig.activityArea));
    // dispatch(getConfigAction(salarieSituationConfig.profession));
    dispatch(getConfigAction(salarieSituationConfig.nationalities));
    dispatch(
      updateFieldAction('situationSalarie', 'type', AccountTypes.retraite),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <CheckAuthorization
      roles={{
        typeClients: [AccountTypes.retraite],
        products: [ProductTypes.bconvcp],
      }}
    >
      <div className={classes.groupFormBody}>
        <Seo titleKey='title_14' keywordsKey='keywords_14' />
        <GeneralCondition
          open={openConditionOfUse}
          onClose={handleClose}
          onValidate={handleValidate}
        />
        <Grid container spacing={5}>
          {/*<Grid item xs={12} sm={6}>
            <CustomizedSwitches
              formLabel={wordingSalarie?.retraiteRetirment}
              tooltip={wordingSalarie?.retraiteRetirmentInfo}
              labelPrefix={wordingSalarie?.no}
              labelSufix={wordingSalarie?.yes}
              checked={situationSalarie.retirment}
              onChange={handleChange}
              name={situationSalarieFields.retirment}
            />
          </Grid>*/}
          <Grid item xs={12} sm={6} className={classes.civility}>
            <CustomRadioGroup
              label={signUpWording?.civility}
              name={signUpFields?.civility}
              onChange={handleChangeSignUp}
              value={signUp.civility}
              listOfElements={civility}
              error={errorsWording?.[errorsSignUp?.[signUpFields?.civility]]}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={classes.labelTooltip}>
              <FormLabel
                className={classes.labelRenseingTooltip}
                component='legend'
              >
                {signUpWording?.firstName}
              </FormLabel>
            </div>
            <CustomInput
              value={signUp.firstName}
              name={signUpFields?.firstName}
              onChange={handleChangeSignUp}
              placeholder={signUpWording?.firstName}
              error={FieldError(errorsSignUp?.[signUpFields?.firstName])}
              hasDefaultBorder={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={classes.labelTooltip}>
              <FormLabel
                className={classes.labelRenseingTooltip}
                component='legend'
              >
                {signUpWording?.lastName}
              </FormLabel>
            </div>
            <CustomInput
              name={signUpFields?.lastName}
              value={signUp.lastName}
              onChange={handleChangeSignUp}
              placeholder={signUpWording?.lastName}
              hasDefaultBorder={true}
              error={FieldError(errorsSignUp?.[signUpFields?.lastName])}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={classes.labelTooltip}>
              <FormLabel
                className={classes.labelRenseingTooltip}
                component='legend'
              >
                {signUpWording?.email}
              </FormLabel>
            </div>

            <CustomInput
              value={signUp.email}
              name={signUpFields?.email}
              onChange={handleChangeSignUp}
              placeholder={signUpWording?.email}
              hasDefaultBorder={true}
              error={FieldError(errorsSignUp?.[signUpFields?.email])}
              disabled={!isEmpty(situationSalarie.email)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={classes.labelTooltip}>
              <FormLabel
                className={classes.labelRenseingTooltip}
                component='legend'
              >
                {signUpWording?.emailConfirmation}
              </FormLabel>
            </div>

            <CustomInput
              value={signUp.emailConfirmation}
              name={signUpFields?.emailConfirmation}
              onChange={handleChangeSignUp}
              placeholder={signUpWording?.emailConfirmation}
              onPaste={onPaste}
              hasDefaultBorder={true}
              error={FieldError(
                errorsSignUp?.[signUpFields?.emailConfirmation],
              )}
              disabled={!isEmpty(situationSalarie.email)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <CustomizedSwitches
              formLabel={wordingSalarie?.additionalIncome}
              tooltip={wordingSalarie?.infoAdditionalIncome}
              labelPrefix={wordingSalarie?.no}
              labelSufix={wordingSalarie?.yes}
              checked={situationSalarie.additionalIncome}
              onChange={handleChange}
              name={situationSalarieFields.additionalIncome}
            />
          </Grid>
          {situationSalarie.additionalIncome === true && (
            <>
              <Grid item xs={12} sm={6}>
                <SelectDown
                  formLabel={wordingSalarie?.additionalIncomeNature}
                  list={additionalIncomeNatureList}
                  label={wordingSalarie?.additionalIncomeNaturePlaceHolder}
                  name={situationSalarieFields.additionalIncomeNature}
                  value={situationSalarie.additionalIncomeNature}
                  onChange={handleChange}
                  error={
                    errors[situationSalarieFields.additionalIncomeNature]
                      ? errorsWording[
                          errors[situationSalarieFields.additionalIncomeNature]
                        ]
                      : null
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputNumber
                  formLabel={wordingSalarie?.amountadditionalIncome}
                  onChange={handleChange}
                  name={situationSalarieFields.amountadditionalIncome}
                  value={situationSalarie.amountadditionalIncome}
                  placeholder={
                    wordingSalarie?.placeHolderAmountadditionalIncome
                  }
                  suffix={''}
                  endElement={wordingSalarie?.dhs}
                  decimalSeparator=','
                  hasDefaultBorder={true}
                  error={
                    errors[situationSalarieFields.amountadditionalIncome]
                      ? errorsWording[
                          errors[situationSalarieFields.amountadditionalIncome]
                        ]
                      : null
                  }
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={6}>
            <InputNumber
              formLabel={wordingSalarie?.retraiteBankDebit}
              onChange={handleChange}
              name={situationSalarieFields.bankDebit}
              value={situationSalarie.bankDebit}
              placeholder={wordingSalarie?.retraiteBankDebitPlaceHolder}
              tooltip={wordingSalarie?.retraiteBankDebitInfo}
              suffix={''}
              endElement={wordingSalarie?.dhs}
              decimalSeparator=','
              hasDefaultBorder={true}
              error={
                errors[situationSalarieFields.bankDebit]
                  ? errorsWording[errors[situationSalarieFields.bankDebit]]
                  : null
              }
            />
          </Grid>
          {displayGridsRachatFromBMCE && (
            <Grid item xs={12}>
              <SelectGrid
                name={situationSalarieFields.listRachats}
                onChange={handleChange}
                error={
                  errors[situationSalarieFields.listRachats]
                    ? errorsWording[errors[situationSalarieFields.listRachats]]
                    : null
                }
              />
            </Grid>
          )}
          {displayGridsRachatNotFromBMCE && (
            <Grid item xs={12} sm={6}>
              <InputNumber
                formLabel={wordingSalarie?.rachatMntEch}
                tooltip={wordingSalarie?.infoRachatMntEch}
                onChange={handleChange}
                name={situationSalarieFields.rachatMntEch}
                value={situationSalarie.rachatMntEch}
                placeholder={wordingSalarie?.placeHolderRachatMntEch}
                suffix={''}
                endElement={wordingSalarie?.dhs}
                decimalSeparator=','
                hasDefaultBorder={true}
              />
            </Grid>
          )}

          <Grid item xs={12} sm={6}>
            <SelectDown
              formLabel={wordingSalarie?.monthlyDateDebit}
              list={listDayOfMonth}
              label={wordingSalarie?.monthlyDateDebitPlaceHolder}
              tooltip={wordingSalarie?.monthlyDateDebitInfo}
              name={situationSalarieFields.monthlyDateDebit}
              value={situationSalarie.monthlyDateDebit}
              onChange={handleChange}
              error={
                errors[situationSalarieFields.monthlyDateDebit]
                  ? errorsWording[
                      errors[situationSalarieFields.monthlyDateDebit]
                    ]
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedSwitches
              formLabel={wordingSalarie?.isDiffere}
              tooltip={wordingSalarie?.infoDiffere}
              labelPrefix={wordingSalarie?.no}
              labelSufix={wordingSalarie?.yes}
              checked={situationSalarie.isDiffere}
              onChange={handleChange}
              name={situationSalarieFields.isDiffere}
            />
          </Grid>
          {situationSalarie.isDiffere && (
            <Grid item xs={12} sm={6}>
              <SelectDown
                formLabel={wordingSalarie?.differe}
                list={listDiffere}
                label={wordingSalarie?.differePlaceHolder}
                name={situationSalarieFields.differe}
                value={situationSalarie.differe}
                onChange={handleChange}
                error={
                  errors[situationSalarieFields.differe]
                    ? errorsWording[errors[situationSalarieFields.differe]]
                    : null
                }
              />
            </Grid>
          )}
          <FormControl
            className={classes.checkedStyle}
            error={!!errorsSignUp?.[signUpFields?.conditionOfUse]}
          >
            <CustomCheckBox
              onChange={conditionChanged}
              name={signUpFields?.conditionOfUse}
              label={
                <Typography
                  variant='subtitle1'
                  className={classes.checkboxLabel}
                >
                  {signUpWording?.conditionOfUse}
                  <span className={classes.conditionOfUseLink}>
                    {signUpWording?.conditionOfUseLink}
                  </span>
                </Typography>
              }
              checked={signUp.conditionOfUse}
            />
            <CustomCheckBox
              onChange={handleChangeSignUp}
              name={signUpFields?.promotionalOffers}
              label={
                <Typography
                  variant='subtitle1'
                  className={classes.checkboxLabel}
                >
                  <span>{signUpWording?.promotionalOffers}</span>{' '}
                  {signUpWording?.promotionalOffersSecondContent}
                </Typography>
              }
              checked={signUp.promotionalOffers}
            />

            <FormHelperText>
              {errorsSignUp?.[signUpFields?.conditionOfUse]
                ? errorsWording[errorsSignUp?.[signUpFields?.conditionOfUse][0]]
                : null}
            </FormHelperText>
          </FormControl>
        </Grid>
      </div>
    </CheckAuthorization>
  );
};

export default PreattRetraiteConv;
