import { getSessionStorage } from 'common/utils/sessionStorage.util';
import { push } from 'connected-react-router';
import { saveUserProfileAndRedirect as saveUserProfile } from 'features/validationBmceDirect/sagas.validation';
import { put, takeLatest } from 'redux-saga/effects';
import { RecapAT, SubmitRecapAction } from './actions.recap';

export function* handleNavigateToUpload(action: SubmitRecapAction) {
  const userProfile = getSessionStorage('userProfile');
  yield saveUserProfile({ ...userProfile, stepClient: '5' });
  yield put(push('/upload-documents'));
}

export function* handleNavigateToUploadSaga() {
  yield takeLatest(RecapAT.submitRecap, handleNavigateToUpload);
}

export const recapSagas = [handleNavigateToUploadSaga];
