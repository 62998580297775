export const getSessionStorage = (
  key: string,
  defaultValue: any = false,
): any => {
  if (
    sessionStorage.getItem(key) &&
    sessionStorage.getItem(key) !== 'undefined'
  ) {
    return JSON.parse(sessionStorage.getItem(key) as string);
  }

  return defaultValue ? defaultValue : sessionStorage.getItem(key);
};

export const setSessionStorage = (key: string, value: any): any => {
  return sessionStorage.setItem(key, JSON.stringify(value));
};

export const removeSessionStorage = (key: string): any => {
  return sessionStorage.removeItem(key);
};
