import { AccountTypes } from 'features/auth/constants';

export const TIMER_GENERATE_CODE_ACCESS = 60;

export const typeClientConst = [
  {
    value: AccountTypes.salarie,
  },
  {
    value: AccountTypes.fonctionnaire,
  },
  {
    value: AccountTypes.professionnel,
  },
  {
    value: AccountTypes.retraite,
  },
];

export const clientBOAConst = [
  {
    value: true,
  },
  {
    value: false,
  },
];

export const rachatConst = [
  {
    value: 'N',
  },
  {
    value: 'O',
  },
];

export enum CODE_CONVENTION {
  DEFAULT = '0', // Default Convention
  X9 = 'X9', // Autre X9 Prospect
  X8 = 'X8', // Autre X8 Non Conv
}

export enum SLIDER_NCEP {
  step = 6,
}

export enum C_SLIDER_SALARIE {
  minAmount = 5000,
  maxAmount = 500000,
  minDuration = 6,
  maxDuration = 120,
}

export enum C_SLIDER_PROFESSIONEL {
  minAmount = 5000,
  maxAmount = 200000,
  minDuration = 12,
  maxDuration = 84,
}

export enum P_SLIDER_PROFESSIONEL {
  minAmount = 10000,
  maxAmount = 200000,
  minDuration = 12,
  maxDuration = 84,
}

export enum C_SLIDER_FONCTIONNAIRE {
  minAmount = 10000,
  maxAmount = 500000,
  minDuration = 6,
  maxDuration = 120,
}

export enum C_SLIDER_RETRAITE {
  minAmount = 5000,
  maxAmount = 400000,
  minDuration = 6,
  maxDuration = 83,
}
export enum PROSPECT_SLIDER {
  minAmount = 10000,
  maxAmount = 200000,
  minDuration = 12,
  maxDuration = 84,
}

// A SPECIFIER
export enum SLIDER_PREATTRIBUTION {
  minAmount = 5000,
  maxAmount = 500000,
  minDuration = 6,
  maxDuration = 120,
}

// Variable qui controle la duration de simulation pour les Non conv et Prospect non BOA
export const CLOSEST: number[] = [
  12,
  18,
  24,
  30,
  36,
  42,
  48,
  54,
  60,
  66,
  72,
  78,
  84,
];

export enum TEL_VALID {
  TEL_A = '06',
  TEL_B = '07',
}

export enum SIMULATION_STATE {
  simulation = 'simulation',
  waiting = 'waiting',
  recapSimulation = 'recapSimulation',
  confirmationOtp = 'confirmationOtp',
  remakeSimulation = 'remakeSimulation',
}

export enum LANG_STATE {
  FR = 'FR',
  AR = 'ع',
  AR_LABEL = 'AR',
}

export enum COMPANY_NAME {
  other = 'Autre',
  otherAr = 'اخرى',
}

export enum simulationFields {
  typeClient = 'typeClient',
  clientBOA = 'clientBOA',
  openAccountBOA = 'openAccountBOA',
  //birthday = 'birthday',
  // companyName = 'companyName',
  // otherEmploye = 'otherEmploye',
  // nameOtherEmploye = 'nameOtherEmploye',
  creditAmount = 'creditAmount',
  creditDuration = 'creditDuration',
  monthlyPayment = 'monthlyPayment',
  //codeConvention = 'codeConvention',
  phone = 'phone',
  otp = 'otp',
  rachat = 'rachat',
  rachatMntReste = 'rachatMntReste',
  rachatMntEch = 'rachatMntEch',
}

export interface SimulationFields {
  [simulationFields.typeClient]: string;
  [simulationFields.clientBOA]: boolean;
  [simulationFields.openAccountBOA]: boolean;
  //[simulationFields.birthday]: Date;
  // [simulationFields.companyName]: string;
  //[simulationFields.codeConvention]: string;
  [simulationFields.phone]: string;
  //[simulationFields.otherEmploye]: boolean;
  [simulationFields?.creditAmount]: number;
  [simulationFields.creditDuration]: number;
  [simulationFields.monthlyPayment]: number;
  [simulationFields.otp]: string;
  [simulationFields.rachat]: string;
  [simulationFields.rachatMntReste]: number;
  [simulationFields.rachatMntEch]: number;
}
