import { apiGet } from 'common/utils/request.util';
import { put, delay, takeLatest, select } from 'redux-saga/effects';
import {
  LOAD_WORDING,
  loadWordingAction,
  setLoadingAction,
  loadWordingSuccessAction,
  loadWordingARSuccessAction,
  loadWordingFRSuccessAction,
  loadWordingErrorAction,
} from './actions.wording';
import { SimulationAT } from 'features/home/actions.simulation';
import { Wording } from './reducer.wording';
import { LANG_STATE } from 'features/home/constants.simulation';
import { selectWordingFR, selectWordingAR } from './selectors.wording';

const getWordingByLang = (wordApi: any, lang: any) =>
  wordApi.data.reduce((acc: any, val: any) => {
    acc[val.parent] = acc[val.parent] || {};
    acc[val.parent][val.value] =
      lang === LANG_STATE.FR ? val.label : val.labelar;
    return acc;
  }, {});

function* loadWording(action: any): Generator {
  try {
    const lang = action.value || LANG_STATE.FR;
    const wordApi: any = yield apiGet('/form-config/get-wordings');
    const wordingAR: any = getWordingByLang(wordApi, LANG_STATE.AR);
    const wordingFR: any = getWordingByLang(wordApi, LANG_STATE.FR);
    yield put(loadWordingARSuccessAction(wordingAR));
    yield put(loadWordingFRSuccessAction(wordingFR));
    // Select default navigator language
    const wording: Wording = lang === LANG_STATE.FR ? wordingFR : wordingAR;
    yield put(loadWordingSuccessAction(wording));
    yield put({
      type: SimulationAT.isOnline,
      value: { check: true, status: 200, error: null },
    });
  } catch (error) {
    yield put(loadWordingErrorAction(error));
    yield delay(6000);
    yield put(loadWordingAction());
  }
}

function* loadWordingSaga() {
  yield takeLatest(LOAD_WORDING, loadWording);
}

function* setLangWording(action: any): Generator {
  try {
    const lang = action.value || LANG_STATE.FR;
    const wordingAR = yield select(selectWordingAR);
    const wordingFR = yield select(selectWordingFR);
    yield put(
      loadWordingSuccessAction(lang === LANG_STATE.FR ? wordingFR : wordingAR),
    );
    yield put(setLoadingAction(true));
    yield delay(1000);
    yield put(setLoadingAction(false));
  } catch (error) {
    yield put(setLoadingAction(false));
    yield put(loadWordingErrorAction(error));
  }
}

function* setLangWordingSaga() {
  yield takeLatest(SimulationAT.setLang, setLangWording);
}

export const wordingSagas = [loadWordingSaga, setLangWordingSaga];
