import { Wording } from 'common/wording/reducer.wording';

const SeoDefaultLocalWording: {
  FR: Wording;
  AR: Wording;
} = {
  FR: {
    defaultKeywords: 'BANK OF AFRICA, crédit daba, Maroc',
    defaultTitle: 'Souscrivez à votre crédit conso en quelques clics!',
    defaultDescription:
      'Découvrez nos solutions de crédit conso et financez vos projets en toute simplicité',
    title_1: 'Crédit conso',
    keywords_1: 'Crédit conso - BANK OF AFRICA',
    title_2: 'Crédit daba',
    keywords_2: 'Crédit daba - BANK OF AFRICA',
    title_3: 'Simulation crédit consommation',
    keywords_3: 'Simulation crédit consommation - BANK OF AFRICA',
    title_4: 'Mensualite credit conso',
    keywords_4:
      'Mensualite credit conso - Simulation crédit consommation - BANK OF AFRICA',
    title_5: 'Ouvrir un compte bancaire en ligne',
    keywords_5: 'Ouvrir un compte bancaire en ligne - BANK OF AFRICA',
    title_6: 'Ouvrir un compte bancaire en ligne',
    keywords_6: 'Ouvrir un compte bancaire en ligne - BANK OF AFRICA',
    title_7: 'bmce direct identifiant',
    keywords_7: 'bmce direct identifiant - BANK OF AFRICA',
    title_8: 'Accord de principe banque',
    keywords_8: 'Accord de principe banque - Crédit conso - BANK OF AFRICA',
    title_9: 'étude dossier crédit',
    keywords_9: 'étude dossier crédit- Crédit conso - BANK OF AFRICA',
    title_10: 'Pret salarié non conventionné',
    keywords_10: 'Pret salarié - Crédit conso - BANK OF AFRICA',
    title_11: 'bmce direct compte salarié',
    keywords_11: 'Pret salarié - Crédit conso - BANK OF AFRICA',
    title_12: 'Pret salarié conventionné',
    keywords_12: 'Pret salarié - Crédit conso - BANK OF AFRICA',
    title_13: 'Pret aux salariés',
    keywords_13: 'Pret aux salariés - Crédit conso - BANK OF AFRICA',
    title_14: 'Credit retraité conventionné',
    keywords_14: 'Credit retraité - Crédit conso - BANK OF AFRICA',
    title_15: 'Credit retraité conventionné bmce direct',
    keywords_15: 'Credit retraité - Crédit conso - BANK OF AFRICA',
    title_16: 'Credit retraité non conventionné',
    keywords_16: 'Credit retraité - Crédit conso - BANK OF AFRICA',
    title_17: 'Credit retraité non conventionné bmce direct',
    keywords_17: 'Credit retraité - Crédit conso - BANK OF AFRICA',
    title_18: 'Credit retraite',
    keywords_18: 'Credit retraite - Crédit conso - BANK OF AFRICA',
    title_19: 'crédit fonctionnaire',
    keywords_19: 'Crédit fonctionnaire - Crédit conso - BANK OF AFRICA',
    title_20: 'crédit fonctionnaire bmce direct',
    keywords_20: 'Crédit fonctionnaire - Crédit conso - BANK OF AFRICA',
    title_21: 'Crédit fonctionnaire non conventionné',
    keywords_21: 'Crédit fonctionnaire - Crédit conso - BANK OF AFRICA',
    title_22: 'simulation crédit fonctionnaire',
    keywords_22:
      'Simulation crédit fonctionnaire - Crédit conso - BANK OF AFRICA',
    title_23: 'Récapitulatif demande de crédit consommation',
    keywords_23: 'Demande de crédit consommation - BANK OF AFRICA',
    title_24: 'Crédit consommation en ligne',
    keywords_24: 'Crédit consommation en ligne - BANK OF AFRICA',
    title_25: 'Simulation credit de consommation',
    keywords_25: 'Simulation credit de consommation - BANK OF AFRICA',
    title_26: 'Suivi de dossier crédit',
    keywords_26: 'Suivi de dossier crédit - Crédit conso - BANK OF AFRICA',
    title_27: 'Simulation crédit consommation',
    keywords_27:
      'Simulation credit  consommation - BANK OF AFRICA - crédit daba',
    title_28: 'Crédit conso | BANK OF AFRICA',
    keywords_28:
      'Simulez votre crédit conso en quelques clics à travers la nouvelle plateforme Crédit Daba de BANK OF AFRICA',
    title_29: 'Crédit conso | BANK OF AFRICA | PAGE INTROUVABLE',
    keywords_29: 'CETTE PAGE EST INTROUVABLE - Crédit Daba de BANK OF AFRICA',
    title_30: 'Crédit conso | Crédit Daba de BANK OF AFRICA',
    keywords_30:
      'Crédit conso - Simulateur crédit consommation - Crédit conso - BANK OF AFRICA',
    description_30:
      'Simulez votre crédit conso en quelques clics à travers la toute nouvelle plateforme Crédit Daba, le simulateur crédit consommation de BANK OF AFRICA',
    title_31: 'Connectez-vous en ligne | Crédit Daba de BANK OF AFRICA',
    keywords_31: 'Crédit daba - Crédit conso, simulateur crédit consommation',
    description_31:
      'Connectez-vous à Crédit Daba, la nouvelle plateforme de crédit conso 100% en ligne de BANK OF AFRICA',

    title_32: 'Crédit à la consommation, tout ce que vous devez savoir',
    keywords_32:
      'Crédit consomation, Simulateur crédit consommation, Crédit daba',
    description_32:
      'Bienvenue sur CREDIT DABA, votre plateforme de crédit à la consommation en ligne qui vous permet de disposer de votre crédit conso en quelques étapes. Quel que soit votre besoin de financement, nous vous proposons des solutions adaptées à vos projets et à votre budget.',
    title_33: 'Rachat de crédit - Crédit daba',
    keywords_33:
      'Rachat de crédit, Crédit consomation, Simulateur crédit consommation, Crédit daba',
    description_33:
      'Bienvenue sur notre page dédiée au rachat de crédits à la consommation, une solution de financement permettant de regrouper vos crédits Conso en cours en un seul prêt pour simplifier la gestion de votre budget. Grâce au rachat, vous pouvez étaler le remboursement de vos différents crédits en les transformant en un seul crédit avec un taux unique, et la possibilité de diminuer le montant de vos mensualités et allonger la durée de votre remboursement.',
    title_34: 'Notre offre de crédit à la consommation s’adapte à vous !',
    keywords_34:
      'Crédit consomation, Simulateur crédit consommation, Crédit daba',
    description_34:
      "Parce que chaque client a des besoins uniques selon ses projets et sa situation professionnelle, nous vous proposons nos offres spécialement conçues pour s'adapter à chaque profil : salariés, fonctionnaires, professionnels libéraux et retraités.",
  },
  AR: {
    defaultKeywords: 'بنك أفريقيا -  crédit daba  - المغرب',
    defaultTitle: 'قوموا بمحاكاة سلفكم في بعض نقرات واكتشفوا عرضنا.',
    defaultDescription: 'سلف الاستهلاك',
    title_1: 'سلف الاستهلاك',
    keywords_1: 'سلف الاستهلاك -  بنك أفريقيا',
    title_2: 'Crédit daba',
    keywords_2: ' سلف DABA - بنك أفريقيا',
    title_3: 'محاكاة سلف الاستهلاك',
    keywords_3: 'محاكاة سلف الاستهلاك -  بنك أفريقيا',
    title_4: 'الأقساط الشهرية لسلف الاستهلاك',
    keywords_4:
      'الأقساط الشهرية لسلف الاستهلاك - محاكاة سلف الاستهلاك -  بنك أفريقيا',
    title_5: 'فتح حساب بنكي عبر الأنترنت',
    keywords_5: 'فتح حساب بنكي عبر الأنترنت - بنك أفريقيا',
    title_6: 'فتح حساب بنكي عبر الأنترنت - بنك أفريقيا',
    keywords_6: 'فتح حساب بنكي عبر الأنترنت - بنك أفريقيا',
    title_7: 'BMCE Direct إسم المستخدم',
    keywords_7: 'BMCE Direct إسم المستخدم - بنك أفريقيا\n',
    title_8: 'الموافقة المبدئية للبنك',
    keywords_8: 'الموافقة المبدئية للبنك - سلف الاستهلاك - بنك أفريقيا',
    title_9: 'دراسة ملف السلف ',
    keywords_9: 'دراسة ملف السلف - سلف الاستهلاك - بنك أفريقيا',
    title_10: 'سلف الأجير غير التعاقدي',
    keywords_10: 'سلف الأجير - سلف الاستهلاك - بنك أفريقيا',
    title_11: 'BMCE Direct حساب الأجير ',
    keywords_11: 'سلف الأجير التعاقدي ',
    title_12: 'سلف الأجير التعاقدي ',
    keywords_12: 'سلف الأجير - سلف الاستهلاك - بنك أفريقيا',
    title_13: 'قرض للموظفين',
    keywords_13: 'سلف الأجراء - سلف الاستهلاك - بنك أفريقيا',
    title_14: 'سلف المتقاعد التعاقدي',
    keywords_14: 'رصيد متقاعد conventionné bmce direct',
    title_15: 'سلف المتقاعد - سلف الاستهلاك - بنك أفريقيا',
    keywords_15: 'سلف المتقاعد - سلف الاستهلاك - بنك أفريقيا',
    title_16: 'سلف المتقاعد غير التعاقدي',
    keywords_16: 'سلف المتقاعد - سلف الاستهلاك - بنك أفريقيا',
    title_17: 'سلف المتقاعد غير التعاقدي BMCE Direct',
    keywords_17: 'سلف المتقاعد - سلف الاستهلاك - بنك أفريقيا',
    title_18: 'سلف التقاعد ',
    keywords_18: 'سلف التقاعد - سلف الاستهلاك - بنك أفريقيا',
    title_19: 'سلف الموظف',
    keywords_19: 'سلف الموظف - سلف الاستهلاك - بنك أفريقيا',
    title_20: 'سلف الموظف BMCE Direct',
    keywords_20: 'محاكاة سلف الموظف - سلف الاستهلاك - بنك أفريقيا',
    title_21: 'سلف الموظف غير التعاقدي',
    keywords_21: 'سلف الموظف - سلف الاستهلاك - بنك أفريقيا',
    title_22: 'محاكاة سلف الموظف ',
    keywords_22: 'محاكاة سلف الموظف - سلف الاستهلاك - بنك أفريقيا',
    title_23: 'ملخص طلب سلف الاستهلاك',
    keywords_23: 'طلب سلف الاستهلاك - بنك أفريقيا',
    title_24: 'سلف الاستهلاك عبر الأنترنت',
    keywords_24: 'سلف الاستهلاك عبر الأنترنت - بنك أفريقيا',
    title_25: 'محاكاة سلف الاستهلاك',
    keywords_25: 'محاكاة سلف الاستهلاك - بنك أفريقيا',
    title_26: 'تتبع ملف السلف',
    keywords_26: 'تتبع ملف السلف - سلف الاستهلاك - بنك أفريقيا',
    title_27: 'محاكاة سلف الاستهلاك',
    keywords_27: 'محاكاة سلف الإستهلاك - بنك أفريقيا - crédit daba',
    title_28: ' سلف الاستهلاك| بنك أفريقيا',
    keywords_28:
      'قوموا بمحاكاة سلف الإستهلاك الخاص بكم في بعض نقرات عبر المنصة الجديدة crédit daba لبنك أفريقيا',
    title_29: ' سلف الاستهلاك| بنك أفريقيا | الصفحة غير متاحة',
    keywords_29: ' سلف الاستهلاك| بنك أفريقيا | الصفحة غير متاحة',
    title_30: 'Crédit Daba - BANK OF AFRICA | محاكاة قرض',
    keywords_30:
      'قوموا بمحاكاة سلف الاستهلاك الخاص بكم ببعض النقرات عبر المنصة الجديدة سلف DABA لبنك أفريقيا',
    description_30:
      'قوموا بمحاكاة سلف الإستهلاك الخاص بكم في بعض نقرات عبر المنصة الجديدة crédit daba لبنك أفريقيا',
    title_31: 'Crédit Daba - BANK OF AFRICA | تسجيل الدخول',
    keywords_31: 'محاكاة سلف الاستهلاك - بنك أفريقيا',
    description_31:
      'المنصة الجديدة لمحاكاة و طلب قرض شخصي  عبر الإنترنت من بنك إفريقيا، Crédit Daba سجل دخولك إلى',
    title_32: 'سلف الاستهلاك كل ما تحتاج معرفته',
    keywords_32: 'سلف الاستهلاك - بنك أفريقيا',
    description_32:
      'مرحبًا بكم في منصتكم لطلب سلف الاستهلاك عبر الإنترنت والتي تمكنكم من الحصول على سلفكم للاستهلاك في بضع خطوات. أيا كانت حاجتكم للتمويل، نقدم لكم حلولًا مناسبة لمشاريعكم وميزانيتكم.',
    title_33: 'إعادة تمويل القروض - سلف الاستهلاك - بنك أفريقيا',
    keywords_33: 'إعادة تمويل القروض, سلف الاستهلاك',
    description_33:
      'مرحبًا بكم في صفحتنا المخصصة لإعادة تمويل قروض الاستهلاك، وهي حل تمويلي يمكنكم من تجميع قروضكم للاستهلاك في قرض واحد لتحسين إدارة ميزانيتكم',
    title_34: 'عروضنا لسلف الاستهلاك لتلبية احتياجاتكم',
    keywords_34: 'سلف الاستهلاك, بنك أفريقيا, عروضنا لسلف الاستهلاك, ',
    description_34:
      'لأن كل عميل لديه احتياجات خاصة وفقًا لمشاريعه ووضعه المهني، نقدم لكم عروضنا المصممة خصيصًا لتناسب كل فئة',
  },
};

export default SeoDefaultLocalWording;
