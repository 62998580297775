import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BackgroundRightContainer from 'assets/images/BackgroundConfirmationMail.png';
import { Wording } from 'common/wording/reducer.wording';
import { useSelector, useDispatch } from 'react-redux';
import Footer from 'common/components/Footer';
import Simulation from './components/Simulation';
import { selectWording } from 'common/wording/selectors.wording';
import RecapSimulation from './components/RecapSimulation';
import { getSimulationInfoByArraySelector } from './selector.simulation';
import { CircularProgress, Typography } from '@material-ui/core';
import { SIMULATION_STATE } from './constants.simulation';
import Advantages from './components/Advantages';
import LoginHomePage from './components/LoginHomePage';
import HeaderHomePage from './components/HeaderHomePage';
import NeedsHomePage from './components/NeedsHomePage';
import StepsHomePage from './components/StepsHomePage';
import FAQ from './components/FAQ';
import ConfirmationOTP from './components/ConfirmationOTP';
import RemakeSimulation from './components/RemakeSimulation';
import Seo from 'common/components/seo/Seo';
import { useLocation } from 'react-router-dom';
import { includes, isEmpty, toUpper } from 'lodash';
import { LANG_STATE } from 'features/home/constants.simulation';
import { setLangAction } from 'features/home/actions.simulation';
import { getSessionStorage } from 'common/utils/sessionStorage.util';
import { GlobalTheme } from 'features/app/app.theme';
import { pushPageView } from 'features/auth/constants';

const useStyles = makeStyles((theme: GlobalTheme) => ({
  rootHome: {
    display: 'flex',
    flexDirection: 'column',
    backgroundImage: `url(${BackgroundRightContainer})`,
    backgroundRepeat: 'repeat',
    minHeight: '34.72%',
  },
  globalContainer: {
    margin: 'auto',
    // width: '50%',
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 1024px)': {
      textAlign: 'center',
    },
    '@media (max-width: 768px)': {
      marginTop: '30px',
    },
  },
  footer: {
    width: '100%',
    '@media (max-width: 1024px)': {
      textAlign: 'center',
    },
  },
  simulatioLoaderWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  simulatioLoader: {
    width: '4.16% !important',
    height: '4.16% !important',
    marginBottom: 10,
  },
  simulationContainer: {
    backgroundColor: '#FFF',
    borderRadius: '1.6rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '@media (max-width: 1440px)': {
      minWidth: '71.26‬rem',
    },
    '@media (max-width: 1024px)': {
      width: '100vw',
      borderRadius: '0',
    },
    '@media (max-width: 768px)': {
      minWidth: '70%',
    },
  },
}));

const Home = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const wording: Wording = useSelector(selectWording);
  const simulationState: any = useSelector(
    getSimulationInfoByArraySelector(['simulation', 'response', 'state']),
  );
  const location = useLocation();
  const lang = getSessionStorage('lang');
  isEmpty(lang) && dispatch(setLangAction(LANG_STATE.FR));

  useEffect(() => {
    includes(toUpper(location.pathname), toUpper(LANG_STATE.AR_LABEL)) &&
      dispatch(setLangAction(LANG_STATE.AR));
    includes(toUpper(location.pathname), toUpper(LANG_STATE.FR)) &&
      dispatch(setLangAction(LANG_STATE.FR));
  }, [dispatch, location.pathname]);

  useEffect(() => {
    pushPageView('homepage', 'showcase');
  }, [location]);

  return (
    <div className={classes.rootHome}>
      <Seo titleKey='title_30' descriptionKey='description_30'>
        <link rel='canonical' href='https://www.creditdaba.ma/' />
      </Seo>
      <LoginHomePage />
      <HeaderHomePage />
      <div className={classes.globalContainer} id={'simulationCont'}>
        <div className={classes.simulationContainer}>
          {simulationState === SIMULATION_STATE.waiting && (
            <div className={classes.simulatioLoaderWrapper}>
              <CircularProgress className={classes.simulatioLoader} />
              <Typography variant='h5'>
                {wording?.simulation.loadingMessage}
              </Typography>
            </div>
          )}

          {simulationState === SIMULATION_STATE.simulation && <Simulation />}
          {simulationState === SIMULATION_STATE.recapSimulation && (
            <RecapSimulation />
          )}
          {simulationState === SIMULATION_STATE.confirmationOtp && (
            <ConfirmationOTP />
          )}
          {simulationState === SIMULATION_STATE.remakeSimulation && (
            <RemakeSimulation />
          )}
        </div>
      </div>
      <StepsHomePage />

      <Advantages />
      <NeedsHomePage />
      <FAQ />
      <div className={classes.footer}>
        <Footer />
      </div>
    </div>
  );
};

export default Home;
