import { makeStyles } from '@material-ui/core/styles';
import { toLower, isEmpty } from 'lodash';
import { SimulationDetailLine } from 'common/components/SimulationDetailLine';
import { Wording } from 'common/wording/reducer.wording';
import { selectCompWording } from 'common/wording/selectors.wording';
import { GlobalTheme } from 'features/app/app.theme';
import React from 'react';
import { useSelector } from 'react-redux';
import { getRecapInfoByArraySelector } from 'features/recap/selector.recap';
import { CheckAuthorization } from 'common/components/PrivateRoute';
import { AccountTypes, ProductTypes } from 'features/auth/constants';
import { OUI } from 'features/app/app.constants';

const useStyles = makeStyles((theme: GlobalTheme) => ({
  recapDetailBody: {
    maxWidth: '80%',
    margin: 'auto',
    padding: '10px 0 10px 0',
  },
}));

const ProfessionnelProspectRecap = () => {
  const wording: Wording = useSelector(selectCompWording('detailedForm'));
  const classes = useStyles();

  const propositionResponse: any = useSelector(
    getRecapInfoByArraySelector(['recap', 'fields']),
  );

  return (
    <CheckAuthorization
      roles={{
        typeClients: [AccountTypes.professionnel],
        products: [ProductTypes.cp_slf],
      }}
    >
      <div className={classes.recapDetailBody}>
        {!isEmpty(propositionResponse.formattedCivility) && (
          <SimulationDetailLine
            label={wording?.civility}
            value={propositionResponse.formattedCivility}
            unit={''}
          />
        )}
        {!isEmpty(propositionResponse.lastName) && (
          <SimulationDetailLine
            label={wording?.lastName}
            value={propositionResponse.lastName}
            unit={''}
          />
        )}
        {!isEmpty(propositionResponse.firstName) && (
          <SimulationDetailLine
            label={wording?.firstName}
            value={propositionResponse.firstName}
            unit={''}
          />
        )}
        {!isEmpty(propositionResponse.birthday) && (
          <SimulationDetailLine
            label={wording?.birthDay}
            value={propositionResponse.birthday}
            unit={''}
          />
        )}
        {!isEmpty(propositionResponse.phoneNumber) && (
          <SimulationDetailLine
            label={wording?.phoneNumber}
            value={propositionResponse.phoneNumber}
            unit={''}
          />
        )}
        {!isEmpty(propositionResponse.email) && (
          <SimulationDetailLine
            label={wording?.email}
            value={propositionResponse.email}
            unit={''}
          />
        )}
        {!isEmpty(propositionResponse.formattedFamilialSituation) && (
          <SimulationDetailLine
            label={wording?.familialSituation}
            value={propositionResponse.formattedFamilialSituation}
            unit={''}
          />
        )}
        {!isEmpty(propositionResponse.formattedactiveSpouse) &&
          propositionResponse.familialSituation === '1' && (
            <SimulationDetailLine
              label={wording?.activeSpouse}
              value={
                toLower(propositionResponse.formattedactiveSpouse) ===
                toLower(OUI)
                  ? wording?.yes
                  : wording?.no
              }
              unit={''}
            />
          )}
        {!isEmpty(propositionResponse.formattedCity) && (
          <SimulationDetailLine
            label={wording?.city}
            value={propositionResponse.formattedCity}
            unit={''}
          />
        )}
        {!isEmpty(propositionResponse.formattedNationality) && (
          <SimulationDetailLine
            label={wording?.nationality}
            value={propositionResponse.formattedNationality}
            unit={''}
          />
        )}
        {!isEmpty(propositionResponse.numberPersonWhitoutChilds) && (
          <SimulationDetailLine
            label={wording?.numberPersonWhitoutChilds}
            value={propositionResponse.numberPersonWhitoutChilds}
            unit={''}
          />
        )}
        {!isEmpty(propositionResponse.numberChilds) && (
          <SimulationDetailLine
            label={wording?.numberChilds}
            value={propositionResponse.numberChilds}
            unit={''}
          />
        )}
        {!isEmpty(propositionResponse.formattedActivityArea) && (
          <SimulationDetailLine
            label={wording?.activityArea}
            value={propositionResponse.formattedActivityArea}
            unit={''}
          />
        )}
        {!isEmpty(propositionResponse.formattedProfession) && (
          <SimulationDetailLine
            label={wording?.profession}
            value={propositionResponse.formattedProfession}
            unit={''}
          />
        )}

        {!isEmpty(propositionResponse.formattedDateCurrentEmployer) && (
          <SimulationDetailLine
            label={wording?.activityStartDate}
            value={propositionResponse.formattedDateCurrentEmployer}
            unit={''}
          />
        )}
        {!isEmpty(propositionResponse.formattedretirment) && (
          <SimulationDetailLine
            label={wording?.retirment}
            value={
              toLower(propositionResponse.formattedretirment) === toLower(OUI)
                ? wording?.yes
                : wording?.no
            }
            unit={''}
          />
        )}
        {!isEmpty(propositionResponse.monthlyIncome) && (
          <SimulationDetailLine
            label={wording?.monthlyIncome}
            value={propositionResponse.monthlyIncome}
            unit={wording?.dhs}
          />
        )}
        {!isEmpty(propositionResponse.amountadditionalIncome) &&
          propositionResponse.additionalIncome !== false && (
            <SimulationDetailLine
              label={wording?.additionalIncome}
              value={propositionResponse.amountadditionalIncome}
              unit={wording?.dhs}
            />
          )}
        {!isEmpty(propositionResponse.formattedCodeOtherBank) && (
          <SimulationDetailLine
            label={wording?.codeOtherBank}
            value={propositionResponse.formattedCodeOtherBank}
            unit={''}
          />
        )}
        {!isEmpty(
          propositionResponse.formattedDateCreationOtherBankAccount,
        ) && (
          <SimulationDetailLine
            label={wording?.dateCreationOtherBankAccount}
            value={propositionResponse.formattedDateCreationOtherBankAccount}
            unit={''}
          />
        )}
        {!isEmpty(propositionResponse.bankDebit) && (
          <SimulationDetailLine
            label={wording?.bankDebit}
            value={propositionResponse.bankDebit}
            unit={wording?.dhs}
          />
        )}
        {!isEmpty(propositionResponse.monthlyCreditImmovable) && (
          <SimulationDetailLine
            label={wording?.monthlyCreditImmovable}
            value={propositionResponse.monthlyCreditImmovable}
            unit={wording?.dhs}
          />
        )}
        {!isEmpty(propositionResponse.mouvementCredit) && (
          <SimulationDetailLine
            label={wording?.mouvementCredit}
            value={propositionResponse.mouvementCredit}
            unit={wording?.dhs}
          />
        )}
        {!isEmpty(propositionResponse.mouvementDebit) && (
          <SimulationDetailLine
            label={wording?.mouvementDebit}
            value={propositionResponse.mouvementDebit}
            unit={wording?.dhs}
          />
        )}
        {!isEmpty(propositionResponse.balanceEndOfMonth) && (
          <SimulationDetailLine
            label={wording?.balanceEndOfMonth}
            value={propositionResponse.balanceEndOfMonth}
            unit={wording?.dhs}
          />
        )}
        {!isEmpty(propositionResponse.cin) && (
          <SimulationDetailLine
            label={wording?.cin}
            value={propositionResponse.cin}
            unit={''}
          />
        )}
      </div>
    </CheckAuthorization>
  );
};

export default ProfessionnelProspectRecap;
