// @ts-nocheck
import { routerMiddleware } from 'connected-react-router/immutable';
import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import createRootReducer from './core/root.reducers';
import { rootSagas } from './core/root.sagas';

const composeEnhancer: typeof compose =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

declare global {
  interface Window {
    dataLayer: any[];
  }
}

const sagaMiddleware = createSagaMiddleware();

export const history = createBrowserHistory();

function configureStore(preloadedState?: any) {
  const store = createStore(
    createRootReducer(history),
    preloadedState,
    composeEnhancer(applyMiddleware(sagaMiddleware, routerMiddleware(history))),
  );

  if (Array.isArray(rootSagas)) {
    rootSagas.forEach((saga) => sagaMiddleware.run(saga));
  }
  return store;
}
export default configureStore();
