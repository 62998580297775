import { apiPost } from 'common/utils/request.util';
import { push } from 'connected-react-router';
import { put, select, takeLatest } from 'redux-saga/effects';
import {
  UpdateInformationAT,
  SubmiInformationAction,
} from './actions.updateInformation';
import { SituationAT } from 'features/situation/actions.situation';
import { getUpdateInfoByArraySelector } from './selector.updateInformation';
import {
  getSessionStorage,
  setSessionStorage,
} from 'common/utils/sessionStorage.util';
import { costumValidate } from 'common/utils/validateJs/validateJs';
import { updateUserConstraints } from 'common/utils/validateJs/constraints';
import { AccountTypes, ProductTypes } from 'features/auth/constants';
import { updateFieldSignUpAction } from 'features/auth/actions.auth';

export function* submitInformation(action: SubmiInformationAction) {
  try {
    yield put({ type: SituationAT.updateLoader, value: true });
    const userData = yield select(
      getUpdateInfoByArraySelector(['updateInformation', 'fields']),
    );
    const errors = costumValidate(userData, updateUserConstraints());
    if (errors) {
      yield put({ type: UpdateInformationAT.submitSignUpError, errors });
      yield put({ type: SituationAT.updateLoader, value: false });
      return;
    }
    const simulationId = yield select(
      getUpdateInfoByArraySelector([
        'updateInformation',
        'fields',
        'idSimulation',
      ]),
    );
    yield put(
      updateFieldSignUpAction('signUp', 'firstName', userData.firstName),
    );
    setSessionStorage('firstName', userData.firstName);

    yield put(updateFieldSignUpAction('signUp', 'lastName', userData.lastName));
    setSessionStorage('lastName', userData.lastName);

    yield apiPost('/auth/update-user', { ...userData, simulationId });
    const typeClient = getSessionStorage('typeclient');
    const product = userData.product; // getSessionStorage('product');
    const clientBOA = getSessionStorage('clientBOA');

    if (typeClient === AccountTypes.salarie) {
      if (product === ProductTypes.bconvcp && clientBOA) {
        yield put(push('/salarie/conv'));
      } else if (product === ProductTypes.cp && clientBOA) {
        yield put(push('/salarie/noConv'));
      } else {
        yield put(push('/salarie/prospect'));
      }
    } else if (typeClient === AccountTypes.fonctionnaire) {
      if (product === ProductTypes.bconvppr && clientBOA) {
        yield put(push('/fonctionnaire/conv'));
      } else if (product === ProductTypes.ppr && clientBOA) {
        yield put(push('/fonctionnaire/noConv'));
      } else {
        yield put(push('/fonctionnaire/prospect'));
      }
    } else if (typeClient === AccountTypes.retraite) {
      if (product === ProductTypes.bconvcp && clientBOA) {
        yield put(push('/retraite/conv'));
      } else if (product === ProductTypes.cp && clientBOA) {
        yield put(push('/retraite/noConv'));
      } else {
        yield put(push('/retraite/prospect'));
      }
    } else if (typeClient === AccountTypes.professionnel) {
      if (product === ProductTypes.cp) {
        yield put(push('/professionnel/client'));
      } else if (product === ProductTypes.cp_slf) {
        yield put(push('/professionnel/prospect'));
      }
    }
    yield put({ type: SituationAT.updateLoader, value: false });
  } catch (e) {
    yield put({ type: SituationAT.updateLoader, value: false });
    console.error(e);
  }
}

export function* submitInformationSaga() {
  yield takeLatest(UpdateInformationAT.submitInformation, submitInformation);
}

export const updateInformationSagas = [submitInformationSaga];
