import { Button, createStyles, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Wording } from 'common/wording/reducer.wording';
import { selectCompWording } from 'common/wording/selectors.wording';
import { GlobalTheme } from 'features/app/app.theme';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import logout from 'assets/images/logout.png';
import useIsAuthenticated from 'common/hooks/useIsAuthenticate';
import logo from 'assets/images/logo.png';
import { AuthAT } from 'features/auth/actions.auth';
import { SimulationAT } from 'features/home/actions.simulation';
import { SituationAT } from 'features/situation/actions.situation';
import { CongratulationAT } from 'features/congratulation/actions.congratulation';
import { PropositionAT } from 'features/proposition/actions.proposition';
import { RecapAT } from 'features/recap/actions.recap';
import { ValidationAT } from 'features/validationBmceDirect/actions.validation';
import { CaseTrackingAT } from 'features/suivieDossier/actions.caseTracking';
import { UpdateInformationAT } from 'features/updateInformation/actions.updateInformation';
import { UploadDocumentAT } from 'features/upload/actions.upload';
import { getAuthInfoByArraySelector } from 'features/auth/selector.auth';
import { getSimulationInfoByArraySelector } from 'features/home/selector.simulation';
import { LANG_STATE } from 'features/home/constants.simulation';

const useStyles = makeStyles((theme: GlobalTheme) =>
  createStyles({
    root: {
      width: 'auto',
      '@media (max-width: 1024px)': {
        width: '100%',
      },
    },
    bodyContent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '1.31rem 1.25rem 0.625rem',
      '@media (max-width: 768px)': {
        padding: '0.25rem',
      },
    },
    bodyContentStart: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    bodyContentEnd: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    logo: {
      width: '2.1rem',
    },
    logoBmce: {
      display: 'none',
      '@media (max-width: 1024px)': {
        display: 'block',
        width: '8.9rem',
      },
      '@media (max-width: 768px)': {
        paddingLeft: '0.25rem',
      },
      '@media (max-width: 320px)': {
        width: '6.75rem',
      },
    },
    authName: {
      textTransform: 'uppercase',
      fontFamily: theme.fonts.bold,
      width: 'max-content',
    },
    welcome: {
      marginRight: '0.3rem',
    },
    button: {
      '&:hover': {
        backgroundColor: 'unset',
      },
    },
    elements: {
      display: 'flex',
      flexWrap: 'wrap',
      marginLeft: '0.3rem',
    },
    elementsAr: {
      display: 'flex',
      flexWrap: 'wrap',
      marginLeft: '0.3rem',
      flexDirection: 'row-reverse',
    },
  }),
);

function Logout({ showIcon = false }: { showIcon?: boolean }) {
  const classes = useStyles();
  const wording: Wording = useSelector(selectCompWording('logout'));
  const firstName: any = useSelector(
    getAuthInfoByArraySelector(['signUp', 'firstName']),
  );
  const langSelected: any =
    useSelector(getSimulationInfoByArraySelector(['lang'])) || LANG_STATE.FR;
  const dispatch = useDispatch();
  const isAuthenticated = useIsAuthenticated();
  const logoutUser = () => {
    dispatch({ type: SimulationAT.initSimulationAT });
    dispatch({ type: SituationAT.initSituationAT });
    dispatch({ type: CongratulationAT.initCongratulationAT });
    dispatch({ type: PropositionAT.initPropositionAT });
    dispatch({ type: RecapAT.initRecapAT });
    dispatch({ type: ValidationAT.initValidationnAT });
    dispatch({ type: CaseTrackingAT.initCaseTrackingAT });
    dispatch({ type: UpdateInformationAT.initUpdateInformationAT });
    dispatch({ type: UploadDocumentAT.initUploadDocumentAT });
    dispatch({ type: RecapAT.initRecapAT });
    dispatch({ type: AuthAT.loGout });
  };

  return (
    <div className={classes.root}>
      {isAuthenticated && (
        <div className={`${classes.bodyContent}`}>
          {showIcon && (
            <div className={`${classes.bodyContentStart}`}>
              <img className={classes.logoBmce} alt='logo' src={logo} />
            </div>
          )}
          <div className={`${classes.bodyContentEnd}`}>
            <div
              className={`${
                langSelected === LANG_STATE.FR
                  ? classes.elements
                  : classes.elementsAr
              }`}
            >
              <Typography variant='h5' className={classes.welcome}>
                {wording?.welcome}
              </Typography>
              <Typography variant='h5' className={classes.authName}>
                {firstName}
              </Typography>
            </div>
            <Button onClick={logoutUser} className={classes.button}>
              <img
                src={logout}
                alt={wording?.logOut}
                className={classes.logo}
                title={wording?.logOut}
              />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Logout;
