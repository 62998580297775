export enum stepsName {
  quickSimulation = 'quickSimulation',
  auth = 'auth',
  mySituation = 'mySituation',
  proposition = 'proposition',
  derogationProposition = 'derogationProposition',
  rejectProposition = 'rejectProposition',
  recap = 'recap',
  congra = 'congra',
  caseTracking = 'caseTracking',
  uploadDocuments = 'uploadDocuments',
  creationProfileBMCE = 'creationProfileBMCE',
  createProfile = 'createProfile',
  forgetPassword = 'forgetPassword',
  modifySimulation = 'modifySimulation',
  updateInformation = 'updateInformation',
  preattributionSalarie = 'preattributionSalarie',
  preattributionFonctionnaire = 'preattributionFonctionnaire',
  preattributionRetraite = 'preattributionRetraite',
}
