import React from 'react';
import { Wording } from 'common/wording/reducer.wording';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { selectWording } from 'common/wording/selectors.wording';
import { makeStyles } from '@material-ui/core/styles';
import { GlobalTheme } from 'features/app/app.theme';
import logoWhatsapp from 'assets/images/logoWhatsapp.png';
import { getSimulationInfoByArraySelector } from 'features/home/selector.simulation';
import { LANG_STATE } from 'features/home/constants.simulation';
import { pushEvent } from 'features/auth/constants';
import { FR, AR } from 'features/app/app.constants';

const useStyles = makeStyles((theme: GlobalTheme) => ({
  stickyContainer: {
    padding: 0,
    margin: 0,
    position: 'fixed',
    right: '-80px',
    top: 350,
    width: '145px',
    '@media (max-width: 768px)': {
      display: 'none',
    },
  },
  stickyContainerAr: {
    padding: 0,
    margin: 0,
    position: 'fixed',
    left: '-80px',
    top: 350,
    width: '145px',
    transform: 'scaleX(-1)',
    '@media (max-width: 768px)': {
      display: 'none',
    },
  },
  stickyContainerNone: {
    display: 'none',
  },
  sticky: {
    borderTopLeftRadius: 50,
    borderBottomLeftRadius: 50,
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'flex-start',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: '#00b2bb',
    color: '#efefef',
    height: '55px',
    padding: '0px',
    transition: 'all 0.25s ease-in-out',
    cursor: 'pointer',
    filter: 'gray',
    '&:hover': {
      marginLeft: '-135px',
    },
  },
  stickyImg: {
    width: 35,
    height: 35,
    margin: 20,
  },
  stickyImgAr: {
    width: 35,
    height: 35,
    margin: 20,
    transform: 'scaleX(-1)',
  },
  stickyWord: {
    display: 'flex',
    flexDirection: 'column',
  },
  stickyWordAr: {
    display: 'flex',
    flexDirection: 'column',
    transform: 'scaleX(-1)',
  },
  stickyTitle: {
    fontFamily: theme.fonts.regular,
    fontSize: '0.75rem',
    marginBottom: 3,
  },
  stickyTitleAr: {
    fontFamily: theme.fonts.regular,
    fontSize: '0.75rem',
    marginBottom: 3,
    textAlign: 'right',
    fontWeight: 600,
  },
  stickyPhone: {
    fontWeight: 'bold',
    fontFamily: theme.fonts.regular,
    fontSize: '0.75rem',
  },
  stickyPhoneAr: {
    fontWeight: 'bold',
    fontFamily: theme.fonts.regular,
    fontSize: '0.75rem',
    direction: 'rtl',
  },
}));

const Social = () => {
  const classes = useStyles();
  const wording: Wording = useSelector(selectWording);
  const langSelected: any =
    useSelector(getSimulationInfoByArraySelector(['lang'])) || LANG_STATE.FR;
  const handleWhatsapp = () => {
    window.open(
      `https://api.whatsapp.com/send?phone=${wording?.social?.phoneWhatsappValue}&text&app_absent=0`,
      '_blank',
    );
    let eventObj = {
      event: 'click_icon',
      click_label: 'whatsapp',
      element_category: 'body',
    };
    pushEvent(eventObj);
  };
  const location = useLocation();
  return (
    <div
      className={
        location.pathname === '/home' ||
        location.pathname === '/' ||
        location.pathname === AR ||
        location.pathname === FR ||
        location.pathname === '/credit-conso'
          ? langSelected === LANG_STATE.FR
            ? classes.stickyContainer
            : classes.stickyContainerAr
          : classes.stickyContainerNone
      }
      onClick={handleWhatsapp}
    >
      <div className={classes.sticky}>
        <img
          className={
            langSelected === LANG_STATE.FR
              ? classes.stickyImg
              : classes.stickyImgAr
          }
          title={wording?.social?.whatsApp}
          alt={wording?.alt?.logo_wapp_home}
          src={logoWhatsapp}
        />
        <div
          className={
            langSelected === LANG_STATE.FR
              ? classes.stickyWord
              : classes.stickyWordAr
          }
        >
          <div
            className={
              langSelected === LANG_STATE.FR
                ? classes.stickyTitle
                : classes.stickyTitleAr
            }
          >
            {wording?.social?.whatsApp}
          </div>
          <div
            className={
              langSelected === LANG_STATE.FR
                ? classes.stickyPhone
                : classes.stickyPhoneAr
            }
          >
            {wording?.social?.phoneWhatsapp}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Social;
