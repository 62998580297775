import {
  Button,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  Typography,
} from '@material-ui/core';
import { isEmpty, toUpper } from 'lodash';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { CustomCheckBox } from 'common/components/customFields/CustomCheckBox';
import { CustomInput } from 'common/components/customFields/CustomInput';
import { CustomRadioGroup } from 'common/components/customFields/CustomRadioGroup';
import Seo from 'common/components/seo/Seo';
import { Wording } from 'common/wording/reducer.wording';
import { GlobalTheme } from 'features/app/app.theme';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthAT, updateFieldSignUpAction } from '../actions.auth';
import { AccountTypes, pushPageView, signUpFields } from '../constants';
import { getAuthInfoByArraySelector } from '../selector.auth';
import FieldError from './CustomFieldError';
import GeneralCondition from './GeneralCondition';
import { GlobalError } from 'common/components/customFields/GlobalError';
import { selectCompWording } from 'common/wording/selectors.wording';
import { getSimulationInfoByArraySelector } from 'features/home/selector.simulation';
import {
  CODE_CONVENTION,
  LANG_STATE,
} from 'features/home/constants.simulation';
import { CustomDatePicker } from 'common/components/customFields/CustomDatePicker';
import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  SimulationAT,
  updateConventionAction,
  updateFieldByArrayAction,
} from 'features/home/actions.simulation';
import { COMPANY_NAME } from 'features/home/constants.simulation';
import { defaultSelectedConvention } from 'features/home/reducer.simulation';
import useDebounce from 'common/hooks/useDebounce';
import usePrevious from 'common/hooks/usePrevious';
import { INVALID_DATE_FORMAT } from 'common/utils/validateJs/contants';
import SelectDown from 'common/components/customFields/SelectDown';
import { getFiltredProfessionListByCriteria } from 'features/situation/selector.situation';
import { salarieSituationConfig } from 'features/situation/constants.situation';
import { getConfigAction } from 'features/situation/actions.situation';

const useStyles = makeStyles((theme: GlobalTheme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      maxWidth: '1173px',
      paddingLeft: '0px',
      paddingRight: '0px',
    },
    form: {
      maxWidth: '396px',
      '& > *': {
        width: '100%',
        display: 'flex',
        maxWidth: '396px',
      },
    },
    title: {
      textTransform: 'uppercase',
      '@media (max-width: 320px)': {
        marginTop: '80px',
      },
    },
    titleAr: {
      textTransform: 'uppercase',
      fontWeight: 'bold',
      '@media (max-width: 320px)': {
        marginTop: '80px',
      },
    },
    subtitle: {
      marginTop: 10,
      marginBottom: 30,
    },
    valider: {
      width: '100%',
      backgroundColor: '#00B2BB',
      fontFamily: theme.fonts.bold,
      borderRadius: '40px',
      fontSize: '0.875rem',
      color: '#FFFFFF',
      marginTop: '20px',
      '&:hover': {
        boxShadow:
          '0px 3px 0.063em -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 0.063em 5px 0px rgba(0,0,0,0.12)',
        backgroundColor: '#00b2bbe6',
      },
    },
    checkedStyle: {
      marginLeft: '5px',
    },
    checkboxLabel: {
      fontSize: '0.75rem',
      textAlign: 'left',
    },
    conditionOfUseLink: {
      textDecoration: 'underline',
      textAlign: 'left',
      color: theme.secondary.main,
    },
    civilityContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    civility: {
      margin: '10px',
      '@media (max-width: 450px)': {
        marginBottom: '48px',
      },
    },
    radioButton: {
      color: theme.secondary.title,
    },
    civilityError: {
      padding: '0 0 10px 10px',
    },
    hiddenElement: {
      display: 'none',
    },
    gridItem: {
      minHeight: 60,
    },
    employer: {
      width: '100%',
      '& .MuiInputBase-root': {
        padding: '2px 15px',
        border: 'solid 1px',
        borderRadius: '40px',
        borderColor: theme.primary.white,
        backgroundColor: theme.primary.white,
      },
    },
    otherEmploye: {
      borderRadius: '40px',
      borderColor: theme.primary.white,
    },
  }),
);

interface Props {
  wording: Wording;
}
interface Convention {
  codeConvention: string;
  companyName: string;
  minAmount: number;
  maxAmount: number;
  minDuration: number;
  maxDuration: number;
  minMonthlyPayment: number;
  maxMonthlyPayment: number;
}

const regExpArab = /[\u0600-\u06FF]/;

const SignUpForm: React.FC<Props> = ({ wording }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const signUp: any = useSelector(getAuthInfoByArraySelector(['signUp']));
  const langSelected: any =
    useSelector(getSimulationInfoByArraySelector(['lang'])) || LANG_STATE.FR;

  const civility = [
    {
      label: wording?.signUp?.civ_dam,
      value: 'F',
    },
    {
      label: wording?.signUp?.civ_mon,
      value: 'M',
    },
  ];

  const errorsCompWording = useSelector(selectCompWording('errors'));

  const errors: any = useSelector(
    getAuthInfoByArraySelector(['signUp', 'errors']),
  );
  const wordingSimulation = useSelector(selectCompWording('simulation'));
  const [comptDate, setComptDate] = React.useState(0);

  const signUpWording = wording?.signUp;
  let errorWording = wording?.errors;
  const [openConditionOfUse, setOpenConditionOfUse] = React.useState(false);

  /* ******************&&&&&&&&&&&&&*************Convention********&&&&&&&&&&************* */

  const [open, setOpen] = React.useState(false);
  const [isArab, setIsArab] = React.useState(false);
  const [comptOtherComp, setComptOtherComp] = React.useState(0);
  const [listOfConventionVerou, setListOfConventionVerou] = React.useState<
    boolean
  >(false);
  const simulation: any = useSelector(
    getSimulationInfoByArraySelector(['simulation', 'fields']),
  );
  const listOfConvention: any | Convention[] = useSelector(
    getSimulationInfoByArraySelector(['simulation', 'convention']),
  );
  const [queryCompanyName, setQueryCompanyName] = React.useState<string>('');
  const [isOtherEmployer, setIsOtherEmployer] = React.useState(
    signUp?.companyName === COMPANY_NAME.other,
  ); // first appear must be true if company name is other (needed when user submit form with an existing mail)
  const selectedConvention: any | Convention = useSelector(
    getSimulationInfoByArraySelector(['simulation', 'selectedConvention']),
  );
  console.log(
    'isOtherEmployer',
    isOtherEmployer,
    'openAccountBOA',
    simulation.openAccountBOA,
    'clientBOA',
    simulation.clientBOA,
    'signUp.companyName',
    signUp.companyName,
    'errors',
    errors,
  );

  const isUserRetired = simulation.typeClient === AccountTypes.retraite;
  const isUserProfessionnel =
    simulation.typeClient === AccountTypes.professionnel;
  const errorsWording = useSelector(selectCompWording('errors'));
  const [comptCompany, setComptCompany] = React.useState(0);
  const [disabled, setDisabled] = React.useState(false);

  /* ##### PROFESSIONS LIST ###### */
  const professionList: any = useSelector(
    getFiltredProfessionListByCriteria(isUserProfessionnel, isOtherEmployer),
  );

  useEffect(() => {
    if (queryCompanyName !== '') {
      setIsArab(regExpArab.test(queryCompanyName));
    }
  }, [queryCompanyName, isArab]);

  useEffect(() => {
    if (signUp.birthday && signUp?.birthday !== '') {
      setDisabled(true);
    }
    // get profession list
    dispatch(getConfigAction(salarieSituationConfig.profession));

    pushPageView('step_1_creation_profil');
    // eslint-disable-next-line
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'firstName' || e.target.name === 'lastName') {
      const value = e.target.value.replace(/[^\D]/gi, '');
      dispatch(updateFieldSignUpAction('signUp', e.target.name, value));
    } else if (
      e.target.name === 'email' ||
      e.target.name === 'emailConfirmation'
    ) {
      const value = e.target.value.replace(/\s/g, '');
      dispatch(updateFieldSignUpAction('signUp', e.target.name, value));
    } else {
      dispatch(
        updateFieldSignUpAction('signUp', e.target.name, e.target.value),
      );
    }
    if (e.target.name === 'nameOtherEmploye' && e.target.value) {
      dispatch(
        updateFieldSignUpAction('signUp', 'nameOtherEmploye', e.target.value),
      );

      //reset only if is not isOtherEmployer => 'companyName' was not displayed
      if (!isOtherEmployer)
        dispatch(
          updateFieldSignUpAction('signUp', 'companyName', e.target.value),
        );
    }

    // hide Autre input
    // setIsOtherEmployer(false);
  };
  const handleChangeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (moment(e.target.value, 'dd/MM/yyyy').isValid()) {
      setComptDate(comptDate + 1);
      dispatch(
        updateFieldSignUpAction('signUp', 'comptDate', signUp.comptDate + 1),
      );
      dispatch(
        updateFieldSignUpAction('signUp', e.target.name, e.target.value),
      );
    } else {
      dispatch(
        updateFieldSignUpAction('signUp', e.target.name, e.target.value),
      );
    }
  };

  const handleBlurDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!moment(event.target.value, 'DD/MM/yyyy', true).isValid()) {
      dispatch(
        updateFieldSignUpAction('signUp', 'errors', {
          birthday: INVALID_DATE_FORMAT,
        }),
      );
    }
  };

  /* ***********************************Handle Change Convention******************************** */
  const handleChangeOtherCampanyName = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setComptOtherComp(comptOtherComp + 1);
    dispatch(
      updateFieldSignUpAction(
        'signUp',
        'comptOtherComp',
        signUp.comptOtherComp + 1,
      ),
    );
    handleChange(event);
    dispatch(updateFieldByArrayAction(['simulation', 'convention'], []));
    if (signUp.companyName !== COMPANY_NAME.other) {
      setListOfConventionVerou(false);
    }
    setQueryCompanyName('');
  };

  const handleChangeCompanyName = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.value.length < 3 && listOfConventionVerou) {
      setListOfConventionVerou(false);
      setIsOtherEmployer(false);
    }

    setQueryCompanyName(event.target.value);
    // clean other company field
    dispatch(updateFieldSignUpAction('signUp', 'nameOtherEmploye', ''));
  };

  const debouncedQueryCompanyName = useDebounce(500, queryCompanyName);
  useEffect(
    () => {
      if (
        debouncedQueryCompanyName &&
        debouncedQueryCompanyName.length > 2 &&
        queryCompanyName &&
        debouncedQueryCompanyName.length > 0
      ) {
        dispatch({
          type: SimulationAT.getConvention,
          value: queryCompanyName,
          typeClient: simulation.typeClient,
          signUp,
        });
        setListOfConventionVerou(true);
      } else if (
        signUp?.nameOtherEmploye?.length > 0
        // !listOfConventionVerou
      ) {
        dispatch({
          type: SimulationAT.getConvention,
          value: COMPANY_NAME.other,
          signUp,
        });
        setListOfConventionVerou(true);
      } else if (isEmpty(debouncedQueryCompanyName)) {
        dispatch(updateConventionAction([]));
      }
      // if (preAttributionBmceDirect) {
      //   preAttributionToField();
      // }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps,
    [dispatch, debouncedQueryCompanyName, signUp?.nameOtherEmploye],
  );

  const handleChangeCompanyValue = (
    event: React.ChangeEvent<{}>,
    value: Convention | null,
    reason: any,
  ) => {
    setIsOtherEmployer(false);
    setQueryCompanyName('');
    if (value) {
      setComptCompany(comptCompany + 1);
      dispatch(
        updateFieldSignUpAction(
          'signUp',
          'comptCompany',
          simulation.comptCompany + 1,
        ),
      );
      if (value.codeConvention === CODE_CONVENTION.X8) {
        setIsOtherEmployer(true);
      }
      dispatch(
        updateFieldByArrayAction(['simulation', 'selectedConvention'], value),
      );
      //if (preAttributionBmceDirect) preAttributionToField();
      // dispatch(
      //   updateFieldSignUpAction('signUp', 'errors', signUpFields.companyName),
      // );
      dispatch(updateFieldSignUpAction('signUp', 'nameOtherEmploye', ''));
    } else {
      dispatch(
        updateFieldByArrayAction(
          ['simulation', 'selectedConvention'],
          defaultSelectedConvention,
        ),
      );
    }
  };

  const prevSelectedConvention = usePrevious(selectedConvention.codeConvention);
  useEffect(() => {
    if (prevSelectedConvention) {
      dispatch(
        updateFieldSignUpAction(
          'signUp',
          'codeConvention',
          selectedConvention.codeConvention,
        ),
      );
      dispatch(
        updateFieldSignUpAction(
          'signUp',
          'companyName',
          selectedConvention.companyName,
        ),
      );
      //if (preAttributionBmceDirect) preAttributionToField();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps,
  }, [dispatch, JSON.stringify(selectedConvention)]);
  /* ENNNNND***********************************Handle Change Convention******************************** */

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log('submit form');
    dispatch({
      type: AuthAT.submitSignUp,
    });
  };

  const handleClose = () => {
    setOpenConditionOfUse(false);
  };

  const handleValidate = () => {
    dispatch(
      updateFieldSignUpAction('signUp', signUpFields?.conditionOfUse, true),
    );
    setOpenConditionOfUse(false);
  };

  const conditionChanged = () => {
    if (signUp.conditionOfUse) {
      dispatch(
        updateFieldSignUpAction('signUp', signUpFields?.conditionOfUse, false),
      );
    } else {
      setOpenConditionOfUse(true);
    }
  };
  const onPaste = (e: React.ClipboardEvent) => {
    e.preventDefault();
  };
  const hasError =
    errors &&
    errors?.email &&
    errors?.email.includes(toUpper('duplicate_email'));

  return (
    <Container maxWidth='xs' className={classes.root}>
      <Seo titleKey='title_5' keywordsKey='keywords_5' />
      <GeneralCondition
        open={openConditionOfUse}
        onClose={handleClose}
        onValidate={handleValidate}
      />
      <Typography
        variant='h3'
        className={
          langSelected === LANG_STATE.FR ? classes.title : classes.titleAr
        }
      >
        {signUp?.bmceDirect
          ? signUpWording?.title_bmce_direct
          : signUpWording?.title}
      </Typography>
      <Typography variant='h5' className={classes.subtitle}>
        {signUpWording?.subtitle}
      </Typography>
      {hasError && (
        <GlobalError
          globalError={errors?.email}
          errorWording={errorsCompWording}
          linkContact={errorsCompWording.SIGNIN_LABEL_LINK}
        />
      )}
      <form
        className={classes.form}
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit}
        data-test-id='submit-form'
        style={
          langSelected === LANG_STATE.FR
            ? {
                direction: 'ltr',
              }
            : {
                direction: 'rtl',
              }
        }
      >
        <Grid container>
          <div className={classes.civility}>
            <CustomRadioGroup
              label={signUpWording?.civility}
              name={signUpFields?.civility}
              onChange={handleChange}
              value={signUp.civility}
              listOfElements={civility}
              error={errorWording?.[errors?.[signUpFields?.civility]]}
            />
          </div>
          <CustomInput
            value={signUp.firstName}
            name={signUpFields?.firstName}
            onChange={handleChange}
            formLabel={signUpWording?.firstName}
            placeholder={signUpWording?.firstName}
            error={FieldError(errors?.[signUpFields?.firstName])}
          />
          <CustomInput
            value={signUp.lastName}
            name={signUpFields?.lastName}
            onChange={handleChange}
            formLabel={signUpWording?.lastName}
            placeholder={signUpWording?.lastName}
            error={FieldError(errors?.[signUpFields?.lastName])}
          />
          {/* <CustomInput
            value={signUp.login}
            name={signUpFields?.login}
            className={classes.hiddenElement}
          />
          <CustomInput
            value={signUp.password}
            type={'password'}
            name={signUpFields?.password}
            className={classes.hiddenElement}
          /> */}
          <CustomInput
            value={signUp.email}
            name={signUpFields?.email}
            onChange={handleChange}
            formLabel={signUpWording?.email}
            placeholder={signUpWording?.email}
            error={FieldError(errors?.[signUpFields?.email])}
          />
          <CustomInput
            value={signUp.emailConfirmation}
            name={signUpFields?.emailConfirmation}
            onChange={handleChange}
            formLabel={signUpWording?.emailConfirmation}
            placeholder={signUpWording?.emailConfirmation}
            onPaste={onPaste}
            error={FieldError(errors?.[signUpFields?.emailConfirmation])}
          />

          <CustomDatePicker
            isMajor={true}
            name={signUpFields?.birthday}
            value={signUp.birthday}
            onChange={handleChangeDate}
            format='dd/MM/yyyy'
            disabled={disabled}
            onBlur={handleBlurDate}
            formLabel={wordingSimulation?.datePlaceholder}
            placeholder={wordingSimulation?.datePlaceholder}
            hasDefaultBorder={disabled ? true : false}
            isSignUp={disabled ? false : true}
            error={
              errors[signUpFields.birthday]
                ? errorWording[errors[signUpFields.birthday]]
                : null
            }
          />

          <Grid item xs={12} sm={12} className={classes.gridItem}>
            {simulation.openAccountBOA || simulation.clientBOA === true ? (
              <Autocomplete
                freeSolo={false}
                className={classes.employer}
                onChange={handleChangeCompanyValue}
                open={open}
                onOpen={() => {
                  setOpen(true);
                }}
                onClose={() => {
                  setOpen(false);
                }}
                getOptionLabel={(option: any) => {
                  return isArab ? option.companyNameAR : option.companyName;
                }}
                options={listOfConvention}
                loading={open}
                loadingText={
                  queryCompanyName ? (
                    !listOfConvention.length && queryCompanyName.length < 3 ? (
                      wordingSimulation?.enterCompanyName
                    ) : (
                      <div
                        style={{
                          cursor: 'pointer',
                        }}
                        onMouseDown={(e) => {
                          e.preventDefault();
                          setIsOtherEmployer(true);
                        }}
                      >
                        {wordingSimulation?.other}
                      </div>
                    )
                  ) : (
                    wordingSimulation?.enterCompanyName
                  )
                }
                value={
                  {
                    [isArab ? 'companyNameAR' : 'companyName']:
                      queryCompanyName !== ''
                        ? queryCompanyName
                        : isArab
                        ? selectedConvention.companyNameAR
                        : selectedConvention.companyName,
                  } as any
                }
                renderInput={(params: any) => (
                  <CustomInput
                    {...params}
                    onChange={handleChangeCompanyName}
                    name={signUpFields.companyName}
                    value={signUp.companyName}
                    placeholder={
                      !isUserRetired
                        ? wordingSimulation?.companyNamePlaceholder
                        : wordingSimulation?.retiredNamePlaceholder
                    }
                    formLabel={
                      !isUserRetired
                        ? wordingSimulation?.companyName
                        : wordingSimulation?.retiredNameLabel
                    }
                    error={
                      errors[signUpFields.companyName]
                        ? errorsWording[errors[signUpFields.companyName]]
                        : null
                    }
                  />
                )}
              />
            ) : (
              <CustomInput
                hasDefaultBorder={true}
                className={classes.otherEmploye}
                onChange={handleChangeOtherCampanyName}
                name={signUpFields.nameOtherEmploye}
                value={signUp.nameOtherEmploye}
                formLabel={wordingSimulation?.prospectNameOtherEmployeHolder}
                placeholder={wordingSimulation?.prospectNameOtherEmployeHolder}
                error={
                  errors[signUpFields.nameOtherEmploye] ||
                  errors[signUpFields.companyName]
                    ? errorsWording[
                        errors[signUpFields.nameOtherEmploye] ||
                          errors[signUpFields.companyName]
                      ]
                    : null
                }
              />
            )}
          </Grid>
          {(simulation.openAccountBOA || simulation.clientBOA === true) &&
            isOtherEmployer && (
              <Grid item xs={12} sm={12} className={classes.gridItem}>
                <CustomInput
                  onChange={handleChangeOtherCampanyName}
                  name={signUpFields.nameOtherEmploye}
                  value={signUp.nameOtherEmploye}
                  placeholder={
                    isUserRetired
                      ? wordingSimulation?.retiredNameOtherEmployeHolder
                      : wordingSimulation?.nameOtherEmployeHolder
                  }
                  formLabel={
                    isUserRetired
                      ? wordingSimulation?.retiredNameOtherEmployeHolder
                      : wordingSimulation?.nameOtherEmployeHolder
                  }
                  error={
                    errors[signUpFields.nameOtherEmploye] ||
                    errors[signUpFields.companyName]
                      ? errorsWording[
                          errors[signUpFields.nameOtherEmploye] ||
                            errors[signUpFields.companyName]
                        ]
                      : null
                  }
                />
              </Grid>
            )}

          {/* ###############  PROFESSION (HIDE IF clientType is retraite or if openAccountBOA or clientBOA is TRUE and is conventionné  ) ################# */}
          {/* commentaire PO ==> Salarié (OUI) ou (Non / oui) et conventionné : supprimer le champs profession */}
          {simulation.typeClient !== AccountTypes.retraite &&
            (!simulation.openAccountBOA ||
              ((simulation.clientBOA || simulation.openAccountBOA) &&
                isOtherEmployer)) && (
              <Grid item xs={12} sm={12} className={classes.gridItem}>
                <SelectDown
                  // formLabel={wordingSimulation?.profession}
                  //  list={config[salarieSituationConfig.profession]}
                  list={professionList}
                  formLabel={wordingSimulation?.professionPlaceHolder}
                  label={wordingSimulation?.professionPlaceHolder}
                  name={signUpFields.profession}
                  value={signUp.profession}
                  tooltip={wordingSimulation?.infoProfession}
                  onChange={handleChange}
                  error={
                    errors[signUpFields.profession]
                      ? errorsWording[errors[signUpFields.profession]]
                      : null
                  }
                />
              </Grid>
            )}
          <FormControl
            className={classes.checkedStyle}
            error={!!errors?.[signUpFields?.conditionOfUse]}
          >
            <CustomCheckBox
              onChange={conditionChanged}
              name={signUpFields?.conditionOfUse}
              label={
                <Typography
                  variant='subtitle1'
                  className={classes.checkboxLabel}
                >
                  {signUpWording?.conditionOfUse}
                  <span className={classes.conditionOfUseLink}>
                    {signUpWording?.conditionOfUseLink}
                  </span>
                </Typography>
              }
              checked={signUp.conditionOfUse}
            />
            <CustomCheckBox
              onChange={handleChange}
              name={signUpFields?.promotionalOffers}
              label={
                <Typography
                  variant='subtitle1'
                  className={classes.checkboxLabel}
                >
                  <span>{signUpWording?.promotionalOffers}</span>{' '}
                  {signUpWording?.promotionalOffersSecondContent}
                </Typography>
              }
              checked={signUp.promotionalOffers}
            />

            <FormHelperText>
              {errors?.[signUpFields?.conditionOfUse]
                ? errorWording[errors?.[signUpFields?.conditionOfUse][0]]
                : null}
            </FormHelperText>
          </FormControl>
          <Button type='submit' className={classes.valider}>
            {signUpWording?.validate}
          </Button>
        </Grid>
      </form>
    </Container>
  );
};

export default SignUpForm;
