import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { selectCompWording } from 'common/wording/selectors.wording';
import { GlobalTheme } from 'features/app/app.theme';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import money from 'assets/svg/money.svg';
import leftFlesh from 'assets/svg/leftFlesh.svg';
import { RecapSimulationElement } from 'features/home/components/RecapSimulationElement';
import Link from '@material-ui/core/Link';
import { SIMULATION_STATE } from '../constants.simulation';
import { SimulationAT } from '../actions.simulation';
import { push } from 'connected-react-router';
import Seo from 'common/components/seo/Seo';
import { GlobalError } from 'common/components/customFields/GlobalError';
import {
  E3,
  E34,
  E35,
  E36,
  E37,
  E38,
  E39,
} from 'common/utils/validateJs/contants';
import { isEmpty } from 'lodash';
import { ProductTypes, pushEvent, pushPageView } from 'features/auth/constants';
import { getSimulationInfoByArraySelector } from 'features/home/selector.simulation';
import { LANG_STATE } from 'features/home/constants.simulation';
import { getValidationInfoByArraySelector } from 'features/validationBmceDirect/selector.validation';
import { scrollToElement } from 'common/utils/app.util';

const useStyles = makeStyles((theme: GlobalTheme) => ({
  root: {
    width: '900px',
    '@media (max-width: 1024px)': {
      width: '100%',
    },
  },
  recapDetailBody: {
    maxWidth: '70%',
    margin: 'auto',
    padding: '90px 0 50px',
    '@media (max-width: 600px)': {
      padding: '30px 25px 10px',
      maxWidth: '100%',
    },
  },
  recapDetailHeader: {
    padding: '40px 90px 0 90px',
    display: 'flex',
    alignItems: 'center',
    margin: 'auto',
    width: '50%',
    justifyContent: 'left',
    '@media (max-width: 1024px)': {
      padding: '20px 0 0',
      justifyContent: 'center',
      flexDirection: 'column',
    },
  },
  header: {
    paddingLeft: '40px',
    '@media (max-width: 1024px)': {
      paddingLeft: 0,
    },
  },
  headerTitle: {
    textTransform: 'uppercase',
    color: theme.secondary.main,
    fontFamily: theme.fonts.bold,
    fontSize: '1.625rem',
    textAlign: 'left',
    '@media (max-width: 1024px)': {
      fontSize: '1rem',
    },
    '@media (max-width: 414px)': {
      fontSize: '0.8125rem',
    },
  },
  leftTitleIcon: {
    width: '65px',
  },
  separator: {
    backgroundColor: '#cbd1dc',
    height: '0.1px',
    margin: '55px auto',
  },
  separatorAr: {
    backgroundColor: '#cbd1dc',
    height: '1px',
    margin: '55px auto',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    '@media (max-width: 600px)': {
      flexDirection: 'column',
    },
  },
  buttonSubmit: {
    borderRadius: '14px 0',
    fontSize: '0.875rem',
    width: '200px',
    padding: '14px 29px',
    fontFamily: theme.fonts.bold,
    '@media (max-width: 600px)': {
      width: '100%',
    },
  },

  flottant: {
    '@media (max-width: 600px)': {
      position: 'fixed',
      bottom: '2%',
      width: '94%',
      left: '3%',
      justifyContent: 'center',
      zIndex: 1,
      borderRadius: '15px',
    },
  },
  simulationElementColor: {
    color: theme.primary.title,
  },
  simulationSizeVal: {
    fontSize: '1.625rem',
    '@media (max-width: 600px)': {
      fontSize: '1.25rem',
    },
  },
  link: {
    padding: '0 26px',
    color: theme.secondary.title,
    fontSize: '0.875rem',
    display: 'flex',
    fontFamily: theme.fonts.bold,
    alignItems: 'center',
    '&:hover': {
      textDecoration: 'none',
    },
    '@media (max-width: 600px)': {
      margin: 'auto',
      padding: '15px 26px',
    },
  },
  imgLink: {
    marginRight: '12px',
  },
  imgLinkAr: {
    transform: 'scaleX(-1)',
    marginRight: '12px',
  },
  borderTitle: {
    border: '3px solid #F93276',
    width: '55px',
    borderRadius: '5px',
    marginTop: '3px',
  },
  groupFormFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width: 600px)': {
      flexDirection: 'column',
    },
  },
  saveButton: {
    borderRadius: '0 14px',
    backgroundColor: theme.primary.title,
    '&:hover': {
      backgroundColor: theme.primary.title,
    },
    '@media (max-width: 600px)': {
      width: '100%',
    },
  },
  bold: {
    fontFamily: `${theme.fonts.bold} !important`,
  },
  iconInfoUp: {
    width: '43px',
  },
  textInfo: {
    padding: '5px',
    textAlign: 'center',
    '@media (max-width: 600px)': {
      textAlign: 'left',
    },
  },
  message: {
    fontSize: '0.9375rem',
    color: '#556584',
    fontFamily: theme.fonts.regular,
  },
  info: {
    backgroundColor: '#DBE2ED',
    width: '100%',
    display: 'flex',
    marginTop: '40px',
  },
  description: {
    width: '80%',
    textAlign: 'left',
    padding: '0 90px 0 0',
    marginBottom: '10px',
    fontStyle: 'italic',
    fontSize: '0.75rem',
    paddingBottom: '40px',
  },
  titleSize: {
    '@media (max-width: 414px)': {
      flexDirection: 'column',
    },
  },
}));

const RecapSimulation = () => {
  const classes = useStyles();
  const wordingSimulation = useSelector(selectCompWording('simulationRecap'));
  const errorsWording = useSelector(selectCompWording('errors'));
  const dispatch = useDispatch();
  const langSelected: any =
    useSelector(getSimulationInfoByArraySelector(['lang'])) || LANG_STATE.FR;
  const simulation: any = useSelector(
    getSimulationInfoByArraySelector(['simulation', 'fields']),
  );
  const simulationResponse: any = useSelector(
    getSimulationInfoByArraySelector(['simulation', 'response']),
  );
  const errors: any = useSelector(
    getSimulationInfoByArraySelector(['simulation', 'errors']),
  );
  // const nameOtherEmploye = useSelector(
  //   getSimulationInfoByArraySelector([
  //     'simulation',
  //     'fields',
  //     'nameOtherEmploye',
  //   ]),
  // );

  // const companyName = useSelector(
  //   getSimulationInfoByArraySelector(['simulation', 'fields', 'companyName']),
  // );
  // FROM BMCE DIRECT REDIRECT
  const fromBMCEDirect: any = useSelector(
    getValidationInfoByArraySelector(['data']),
  );
  // const preAttributionBmceDirect: any = useSelector(
  //   getValidationInfoByArraySelector(['preAttribution']),
  // );

  const goBackToEditSimulation = () => {
    dispatch({
      type: SimulationAT.updateState,
      value: SIMULATION_STATE.simulation,
    });
    let eventObj = {
      event: 'click_simulation',
      click_label: 'modifier_mes_informations',
      audience: simulation.typeClient,
      is_client: simulation.clientBOA,
      debt_type:
        simulation.rachat === 'O'
          ? 'un_credit_conso_avec_rachat_dencours'
          : 'un_credit_conso_simple',
      element_category: 'body',
    };
    pushEvent(eventObj);
  };

  const goBackToSimulation = () => {
    dispatch({ type: SimulationAT.getSimulationResponse, simulationResponse });
    dispatch({
      type: SimulationAT.updateState,
      value: SIMULATION_STATE.remakeSimulation,
    });
    let eventObj = {
      event: 'click_simulation',
      click_label: 'refaire_ma_simulation',
      audience: simulation.typeClient,
      is_client: simulation.clientBOA,
      debt_type:
        simulation.rachat === 'O'
          ? 'un_credit_conso_avec_rachat_dencours'
          : 'un_credit_conso_simple',
      element_category: 'body',
    };
    pushEvent(eventObj);
  };

  const goToSubscribtion = () => {
    dispatch({
      type: SimulationAT.submitSimulationCreaEspace,
      simulationResponse,
    });
    // if (preAttributionBmceDirect) {
    //   dispatch({ type: SimulationAT.preAttributionRoute });
    // } else
    if (
      simulationResponse.product === ProductTypes.cp_slf ||
      !simulation.clientBOA
    ) {
      dispatch(push('/sign-up'));
    } else {
      if (isEmpty(fromBMCEDirect)) {
        dispatch(push('/create-profile'));
      } else {
        dispatch(push('/sign-up'));
      }
    }
    let eventObj = {
      event: 'click_onboarding',
      click_label: 'je_souscris',
      audience: simulation.typeClient,
      is_client: simulation.clientBOA,
      debt_type:
        simulation.rachat === 'O'
          ? 'un_credit_conso_avec_rachat_dencours'
          : 'un_credit_conso_simple',
      element_category: 'body',
    };
    pushEvent(eventObj);
  };

  useEffect(() => {
    scrollToElement(undefined, 400);
    pushPageView('resultat_de_simulation');
  }, []);

  const hasError =
    errors &&
    errors.global &&
    (errors.global.includes(E3) ||
      errors.global.includes(E34) ||
      errors.global.includes(E35) ||
      errors.global.includes(E36) ||
      errors.global.includes(E37) ||
      errors.global.includes(E38) ||
      errors.global.includes(E39));

  return (
    <div className={classes.root}>
      <Seo titleKey='title_3' keywordsKey='keywords_3' />
      <div className={classes.recapDetailHeader}>
        <img src={money} alt='' className={classes.leftTitleIcon} />
        <div className={classes.header}>
          <Typography variant='h4' className={classes.headerTitle}>
            {wordingSimulation?.title}
          </Typography>
          <div className={classes.borderTitle} />
        </div>
      </div>
      {/*simulationResponse.companyName !== '' && (
        <div className={classes.info}>
          <img src={infoMsg} alt='' className={classes.iconInfoUp} />
          <Typography variant='h3' className={classes.textInfo}>
            <span className={classes.message}>
              {wordingSimulation?.infoFirstCont}
            </span>{' '}
            <span className={`${classes.message} ${classes.bold}`}>
              {wordingSimulation?.infoSecondCont}
            </span>{' '}
          </Typography>
        </div>
      )*/}
      {hasError && (
        <GlobalError globalError={errors.global} errorWording={errorsWording} />
      )}
      <div className={classes.recapDetailBody}>
        <RecapSimulationElement
          label={wordingSimulation?.monthlyTax}
          value={simulationResponse.monthlyPayment}
          unit={wordingSimulation?.dhsByMonth}
          asterisk={true}
          InputClasses={{
            simulationSizeVal: classes.simulationSizeVal,
            simulationElementColor: classes.simulationElementColor,
            titleSize: classes.titleSize,
          }}
        />
        <div
          className={
            langSelected === LANG_STATE.FR
              ? classes.separator
              : classes.separatorAr
          }
        />
        <RecapSimulationElement
          label={wordingSimulation?.amountRequested}
          value={simulationResponse.creditAmount}
          unit={wordingSimulation?.dhs}
        />
        <RecapSimulationElement
          label={wordingSimulation?.creditTerm}
          value={simulationResponse.creditDuration}
          unit={wordingSimulation?.month}
        />
        {simulation.rachat === 'O' && (
          <RecapSimulationElement
            label={wordingSimulation?.mntDebloqueSurCompte}
            value={simulationResponse.debloquedAmount}
            unit={wordingSimulation?.dhs}
          />
        )}
        {simulation.rachat === 'O' &&
          !isEmpty(simulationResponse.rachatMntReste) && (
            <RecapSimulationElement
              label={wordingSimulation?.totalRachatEncours}
              value={simulationResponse.rachatMntReste}
              unit={wordingSimulation?.dhs}
            />
          )}
        <RecapSimulationElement
          label={wordingSimulation?.insurance}
          value={simulationResponse.amountFeesAssurance}
          unit={wordingSimulation?.dhs}
        />
        <RecapSimulationElement
          label={wordingSimulation?.applicationFees}
          value={
            simulationResponse.amountFeesDemande &&
            simulationResponse.amountFeesDemande?.toString() === '0'
              ? wordingSimulation?.free
              : simulationResponse.amountFeesDemande
          }
          unit={
            simulationResponse.amountFeesDemande &&
            simulationResponse.amountFeesDemande?.toString() === '0'
              ? ' '
              : wordingSimulation?.dhs
          }
        />
        <RecapSimulationElement
          label={wordingSimulation?.totalInterest}
          value={simulationResponse.totalInterest}
          unit={wordingSimulation?.dhs}
        />
        <RecapSimulationElement
          label={wordingSimulation?.totalCostOfCredit}
          value={simulationResponse.totalCostOfCredit}
          unit={wordingSimulation?.dhs}
        />
        {/* <RecapSimulationElement
          label={wordingSimulation?.employeur}
          value={nameOtherEmploye ? nameOtherEmploye : companyName}
        /> */}
        <Typography variant='h5' className={classes.description}>
          {wordingSimulation?.description}
        </Typography>
      </div>

      <div className={classes.groupFormFooter}>
        <Button
          className={`${classes.buttonSubmit} ${classes.saveButton}`}
          variant='contained'
          color='secondary'
          onClick={goBackToSimulation}
        >
          {wordingSimulation?.remakeSimulation}
        </Button>
        <div className={classes.buttons}>
          <Link
            onClick={goBackToEditSimulation}
            component='button'
            variant='body2'
            className={classes.link}
          >
            <img
              src={leftFlesh}
              alt=''
              className={
                langSelected === LANG_STATE.FR
                  ? classes.imgLink
                  : classes.imgLinkAr
              }
            />
            {wordingSimulation?.modifyInfos}
          </Link>
          <Button
            type='submit'
            className={`${classes.buttonSubmit} ${classes.flottant}`}
            variant='contained'
            color='secondary'
            onClick={goToSubscribtion}
            disabled={hasError}
          >
            {wordingSimulation?.iSubscribe}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RecapSimulation;
