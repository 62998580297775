import { fromJS } from 'immutable';
import { StoreOf } from '../../core/root.model';
import {
  SubmitRecapAction,
  RecapAT,
  UpdateRecapAction,
  InitRecapATAction,
  UpdateRecapElement,
} from './actions.recap';
import { getSessionStorage } from 'common/utils/sessionStorage.util';

const recap = getSessionStorage('recap');

export const initState: RecapState = fromJS({
  recap: {
    fields: recap
      ? recap
      : {
          lastName: '',
          firstName: '',
          activityPro: 'commercant',
          bankingSeniority: 'lessThanYear',
          additionalIncomeType: false,
          additionalIncome: false,
          activitySeniority: 'lessThanYear',
          seniorityBankOfAfrica: 'lessThanSixMonth',
          isBMCE: '',
          debloquedAmount: '80',
        },
    errors: {},
  },
});

export const resetState: RecapState = fromJS({
  recap: {
    fields: {
      lastName: '',
      firstName: '',
      activityPro: 'commercant',
      bankingSeniority: 'lessThanYear',
      additionalIncomeType: false,
      additionalIncome: false,
      activitySeniority: 'lessThanYear',
      seniorityBankOfAfrica: 'lessThanSixMonth',
      isBMCE: '',
      debloquedAmount: '80',
    },
    errors: {},
  },
});

export const RECAP_REDUCER = 'RECAP_REDUCER';

export interface RecapStateModel {
  recap: {};
}

export type RecapState = StoreOf<RecapStateModel>;

export type RecapActions =
  | SubmitRecapAction
  | UpdateRecapAction
  | InitRecapATAction
  | UpdateRecapElement;

export function RecapReducer(
  state = initState,
  action: RecapActions,
): RecapState {
  switch (action.type) {
    case RecapAT.updateRecap:
      return state.setIn(['recap', 'fields'], fromJS(action.data));
    case RecapAT.updateRecapElement:
      return state.setIn(
        [action.step, 'fields', action.name],
        fromJS(action.value),
      );
    case RecapAT.initRecapAT:
      return resetState;
    default:
      return state;
  }
}
