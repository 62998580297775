import {
  ValidationBmceDirectAction,
  ValidationBmceDirectSuccessAction,
  ValidationBmceDirectErrorAction,
  initValidationnATAction,
  UpdateFieldAction,
  ValidationAT,
  ValidationLoaderAction,
  PreattributionAction
} from './actions.validation';
import { fromJS } from 'immutable';
import { isEmpty } from 'lodash';
import { StoreOf } from '../../core/root.model';
import { getSessionStorage } from 'common/utils/sessionStorage.util';

export const VALIDATION_REDUCER = 'VALIDATION_REDUCER';
export type HomeState = StoreOf<ValidationStateModel>;
export const initState: HomeState = fromJS({
  errors: {},
  token: {},
  data: {},
  checkedClose: true,
  loader: true,
  preAttribution:false
});

const validation: any = getSessionStorage('validation');
export interface ValidationStateModel {
  errors: string;
  token: string;
  data: any;
  checkedClose: boolean;
  loader: boolean;
  preAttribution:boolean;
}

const initialState: HomeState = fromJS(
  isEmpty(validation) ? initState : (validation as ValidationStateModel),
);
export function validationReducer(
  state = initialState,
  action:
    | ValidationBmceDirectAction
    | ValidationBmceDirectSuccessAction
    | ValidationBmceDirectErrorAction
    | initValidationnATAction
    | UpdateFieldAction
    | ValidationLoaderAction
    | PreattributionAction
): HomeState {
  switch (action.type) {
    case ValidationAT.VALIDATION_BMCEDIRECT:
      return state.set('token', action.token);
    case ValidationAT.VALIDATION_BMCEDIRECT_SUCCESS:
      return state.set('data', action.data);
    case ValidationAT.VALIDATION_BMCEDIRECT_ERROR:
      return state.set('errors', action.errors);
    case ValidationAT.updateField:
      return state.set('checkedClose', action.checkedClose);
    case ValidationAT.VALIDATION_LOADER:
      return state.set('loader', action.loader);
    case ValidationAT.VALIDATION_PREATTRIBUTION:
      return state.set('preAttribution', action.preAttribution);
    case ValidationAT.initValidationnAT:
      return initState;
    default:
      return state;
  }
}
