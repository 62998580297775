import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { CustomSlider } from 'common/components/customFields/CustomSlider';
import {
  simulationFields,
  //COMPANY_NAME,
  SLIDER_NCEP,
  C_SLIDER_SALARIE,
  C_SLIDER_FONCTIONNAIRE,
  C_SLIDER_RETRAITE,
  COMPANY_NAME,
  PROSPECT_SLIDER,
  C_SLIDER_PROFESSIONEL,
  P_SLIDER_PROFESSIONEL,
} from '../constants.simulation';
import info from 'assets/svg/info.svg';
import FormLabel from '@material-ui/core/FormLabel';
import Link from '@material-ui/core/Link';
import { isUndefined } from 'lodash';
import { AccountTypes } from 'features/auth/constants';
import { useSelector } from 'react-redux';
import { getValidationInfoByArraySelector } from 'features/validationBmceDirect/selector.validation';
import { getAuthInfoByArraySelector } from 'features/auth/selector.auth';

const Slider = ({
  classes,
  wordingSimulation,
  errors,
  errorsWording,
  selectedConvention,
  simulation,
  simulationResponse,
  handleChange,
  handleSubmitToProfil,
  hasOTP,
}: any) => {
  const [client, setClient] = useState(true);
  const [NOCONV, setNOCONV] = useState(false);
  const SELECTED_X8_X9 = { ...selectedConvention };
  const company: any = useSelector(
    getAuthInfoByArraySelector(['signUp', 'companyName']),
  );

  useEffect(() => {
    if (company === COMPANY_NAME.other) {
      setNOCONV(true);
    } else if (!simulation?.clientBOA && !simulation?.openAccountBOA) {
      setNOCONV(false);
      setClient(false);
    } else {
      setClient(true);
    }
  }, [NOCONV, client, simulation, company]);

  const preAttributionBmceDirect: any = useSelector(
    getValidationInfoByArraySelector(['preAttribution']),
  );

  const isProfessionnel = simulation?.typeClient === AccountTypes.professionnel;
  return (
    <Grid className={classes?.contentSimulation} container>
      <Grid item xs={12} sm={12} className={classes?.gridItem}>
        <CustomSlider
          disabled={simulationResponse?.loader}
          value={simulation?.creditAmount}
          handleChange={handleChange}
          error={
            errors?.[simulationFields?.creditAmount]
              ? errorsWording?.[errors?.[simulationFields?.creditAmount]]
              : null
          }
          label={wordingSimulation?.creditAmount}
          min={
            NOCONV
              ? SELECTED_X8_X9.minAmount
              : simulation?.typeClient === AccountTypes.salarie
              ? hasOTP
                ? selectedConvention.minAmount
                : client
                ? C_SLIDER_SALARIE.minAmount
                : PROSPECT_SLIDER.minAmount
              : simulation?.typeClient === AccountTypes.professionnel
              ? hasOTP
                ? selectedConvention.minAmount
                : client
                ? C_SLIDER_PROFESSIONEL.minAmount
                : P_SLIDER_PROFESSIONEL.minAmount
              : simulation?.typeClient === AccountTypes.fonctionnaire
              ? hasOTP
                ? selectedConvention.minAmount
                : client
                ? C_SLIDER_FONCTIONNAIRE.minAmount
                : PROSPECT_SLIDER.minAmount
              : hasOTP
              ? selectedConvention.minAmount
              : client
              ? C_SLIDER_RETRAITE.minAmount
              : PROSPECT_SLIDER.minAmount
          }
          max={
            preAttributionBmceDirect
              ? selectedConvention.maxAmount
              : NOCONV
              ? SELECTED_X8_X9.maxAmount
              : simulation?.typeClient === AccountTypes.salarie
              ? hasOTP
                ? selectedConvention.maxAmount
                : client
                ? C_SLIDER_SALARIE.maxAmount
                : PROSPECT_SLIDER.maxAmount
              : simulation?.typeClient === AccountTypes.professionnel
              ? hasOTP
                ? selectedConvention.maxAmount
                : client
                ? C_SLIDER_PROFESSIONEL.maxAmount
                : P_SLIDER_PROFESSIONEL.maxAmount
              : simulation?.typeClient === AccountTypes.fonctionnaire
              ? hasOTP
                ? selectedConvention.maxAmount
                : client
                ? C_SLIDER_FONCTIONNAIRE.maxAmount
                : PROSPECT_SLIDER.maxAmount
              : hasOTP
              ? selectedConvention.maxAmount
              : client
              ? C_SLIDER_RETRAITE.maxAmount
              : PROSPECT_SLIDER.maxAmount
          }
          step={1000}
          name={simulationFields?.creditAmount}
          endElement={wordingSimulation?.dhs}
          blue={true}
          numFloat={0}
        />
      </Grid>
      <Grid item xs={12} sm={12} className={classes?.gridItem}>
        <CustomSlider
          disabled={simulationResponse?.loader || preAttributionBmceDirect}
          value={simulation?.creditDuration}
          handleChange={handleChange}
          error={
            errors?.[simulationFields?.creditDuration]
              ? errorsWording?.[errors?.[simulationFields.creditDuration]]
              : null
          }
          label={wordingSimulation?.creditDuration}
          min={
            preAttributionBmceDirect
              ? selectedConvention.minDuration
              : NOCONV
              ? SELECTED_X8_X9.minDuration
              : simulation?.typeClient === AccountTypes.salarie
              ? hasOTP
                ? selectedConvention.minDuration
                : client
                ? C_SLIDER_SALARIE.minDuration
                : PROSPECT_SLIDER.minDuration
              : simulation?.typeClient === AccountTypes.professionnel
              ? hasOTP
                ? selectedConvention.minDuration
                : client
                ? C_SLIDER_PROFESSIONEL.minDuration
                : P_SLIDER_PROFESSIONEL.minDuration
              : simulation?.typeClient === AccountTypes.fonctionnaire
              ? hasOTP
                ? selectedConvention.minDuration
                : client
                ? C_SLIDER_FONCTIONNAIRE.minDuration
                : PROSPECT_SLIDER.minDuration
              : hasOTP
              ? selectedConvention.minDuration
              : client
              ? C_SLIDER_RETRAITE.minDuration
              : PROSPECT_SLIDER.minDuration
          }
          max={
            preAttributionBmceDirect
              ? selectedConvention.maxDuration
              : NOCONV
              ? SELECTED_X8_X9.maxDuration
              : simulation?.typeClient === AccountTypes.salarie
              ? hasOTP
                ? selectedConvention.maxDuration
                : client
                ? C_SLIDER_SALARIE.maxDuration
                : PROSPECT_SLIDER.maxDuration
              : simulation?.typeClient === AccountTypes.professionnel
              ? hasOTP
                ? selectedConvention.maxDuration
                : client
                ? C_SLIDER_PROFESSIONEL.maxDuration
                : P_SLIDER_PROFESSIONEL.maxDuration
              : simulation?.typeClient === AccountTypes.fonctionnaire
              ? hasOTP
                ? selectedConvention.maxDuration
                : client
                ? C_SLIDER_FONCTIONNAIRE.maxDuration
                : PROSPECT_SLIDER.maxDuration
              : hasOTP
              ? selectedConvention.maxDuration
              : client
              ? C_SLIDER_RETRAITE.maxDuration
              : PROSPECT_SLIDER.maxDuration
          }
          step={NOCONV || !client || isProfessionnel ? SLIDER_NCEP.step : 1}
          name={simulationFields.creditDuration}
          endElement={wordingSimulation?.month}
          numFloat={0}
          ncep={NOCONV || !client}
        />
      </Grid>
      {!isUndefined(handleSubmitToProfil) && (
        <Grid
          item
          xs={12}
          sm={12}
          className={`${classes?.gridItem} ${classes?.info}`}
        >
          <img src={info} alt='' />
          <FormLabel className={classes?.labelInformation} component='legend'>
            {wordingSimulation?.infoModifySimulation}
            <Link onClick={handleSubmitToProfil} className={classes?.link}>
              {wordingSimulation?.clicLinkInfo}
            </Link>
          </FormLabel>
        </Grid>
      )}
    </Grid>
  );
};

export default Slider;
