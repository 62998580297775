import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useIsAuthenticated from 'common/hooks/useIsAuthenticate';
import { selectCompWording } from 'common/wording/selectors.wording';
import { GlobalTheme } from 'features/app/app.theme';
import { LANG_STATE } from 'features/home/constants.simulation';
import {
  getSimulationInfoByArraySelector,
  getSimulationInfoByArraySelector as simulSelector,
} from 'features/home/selector.simulation';
import { getPropositionInfoByArraySelector } from 'features/proposition/selector.proposition';
import React from 'react';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme: GlobalTheme) => ({
  container: {
    border: `1px solid ${theme.secondary.main}`,
    borderRadius: 20,
    padding: '16px 20px',
    margin: '30px auto',
  },
  boxAr: {
    flexDirection: 'row-reverse',
  },
  box: {
    display: 'flex',
    margin: '5px 0',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  smallSize: {
    fontSize: '0.875rem',
    fontFamily: theme.fonts.medium,
  },
  normaSize: {
    fontSize: '1rem',
    fontFamily: theme.fonts.medium,
  },
  bigSize: {
    fontSize: '1.465rem',
    fontFamily: theme.fonts.bold,
  },
  primaryColor: {
    color: theme.primary.title,
  },
  secondaryColor: {
    color: theme.secondary.title,
  },
}));

function CreditReminder() {
  const classes = useStyles();
  const isAuthenticated = useIsAuthenticated();

  const reminderWording = useSelector(selectCompWording('reminder'));
  const simulation: any = useSelector(
    simulSelector(['simulation', 'response']),
  );

  const proposition: any = useSelector(
    getPropositionInfoByArraySelector(['proposition', 'fields']),
  );

  const langSelected: any =
    useSelector(getSimulationInfoByArraySelector(['lang'])) || LANG_STATE.FR;

  // monthly payment => check if if authenticated and has totalCost value
  const monthlyPayment =
    isAuthenticated && proposition?.totalCostOfCredit
      ? proposition?.monthlyPayment
      : simulation?.monthlyPayment ?? 0;

  // credit Amount
  const creditAmount =
    isAuthenticated && proposition?.totalCostOfCredit
      ? proposition?.creditAmount
      : simulation?.creditAmount ?? 0;

  return (
    <Box className={classes.container}>
      <Box
        className={`${classes.box} ${
          langSelected !== LANG_STATE.FR ? classes.boxAr : ''
        }`}
      >
        <Typography className={`${classes.normaSize} ${classes.primaryColor}`}>
          {reminderWording?.monthly}
        </Typography>
        <Typography className={`${classes.bigSize} ${classes.primaryColor}`}>
          <NumberFormat
            value={monthlyPayment}
            displayType={'text'}
            thousandSeparator={' '}
            //  suffix={reminderWording?.dhs}
          />
          <span> {reminderWording?.dhs}</span>
        </Typography>
      </Box>
      <Box
        className={`${classes.box} ${
          langSelected !== LANG_STATE.FR ? classes.boxAr : ''
        }`}
      >
        <Typography
          className={`${classes.smallSize} ${classes.secondaryColor}`}
        >
          {reminderWording?.amountRequested}
        </Typography>
        <Typography
          className={`${classes.normaSize} ${classes.secondaryColor}`}
        >
          <NumberFormat
            value={creditAmount}
            displayType={'text'}
            thousandSeparator={' '}
            // suffix={reminderWording?.dhs}
          />
          <span> {reminderWording?.dhs}</span>
        </Typography>
      </Box>
    </Box>
  );
}

export default CreditReminder;
