import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { selectCompWording } from 'common/wording/selectors.wording';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GlobalTheme } from 'features/app/app.theme';
import { SimulationAT, updateFieldByArrayAction } from '../actions.simulation';
import { getSimulationInfoByArraySelector } from '../selector.simulation';
import FormLabel from '@material-ui/core/FormLabel';
import Spinner from 'common/components/Spinner';
import Slider from './Slider';
import { GlobalError } from 'common/components/customFields/GlobalError';
import { getSituationInfoByArraySelector } from 'features/situation/selector.situation';
import {
  E3,
  E34,
  E35,
  E36,
  E37,
  E38,
  E39,
} from 'common/utils/validateJs/contants';
import { getValidationInfoByArraySelector } from './../../validationBmceDirect/selector.validation';
import { getSessionStorage } from 'common/utils/sessionStorage.util';
import { isEmpty } from 'lodash';
import { SLIDER_PREATTRIBUTION } from '../constants.simulation';
const useStyles = makeStyles((theme: GlobalTheme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // padding: '80px 88px 80px 110px',
    direction: 'ltr',
  },
  labelInformation: {
    fontSize: '1rem',
    textAlign: 'left',
    fontFamily: theme.fonts.medium,
    color: theme.primary.title,
    opacity: 1,
    marginLeft: '10px',
    '@media (max-width: 425px)': {
      fontSize: '0.6875rem',
    },
  },
  labelSimulation: {
    fontSize: '1.5rem',
    textAlign: 'center',
    fontFamily: theme.fonts.medium,
    color: theme.primary.title,
    paddingTop: '45px',
    '@media (max-width: 425px)': {
      fontSize: '1.25rem',
    },
  },
  info: {
    display: 'flex',
    alignItems: 'center',
  },
  simulationDetail: {
    border: '0.063em solid #FFF',
    borderRadius: '22px',
    backgroundColor: '#FFF',
    width: '95%',
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 425px)': {
      padding: '15px !important',
    },
    '@media (max-width: 320px)': {
      padding: '20px !important',
    },
  },
  subscriptionButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    // marginTop: '30px',
  },
  subscriptionButton: {
    color: '#fff',
    backgroundColor: '#F93276',
    textTransform: 'capitalize',
    border: '0.063em solid #F93276',
    borderRadius: '20px 0',
    padding: '16px 86px',
    fontSize: '0.875rem',
    '&:hover': {
      backgroundColor: '#F93276',
    },
  },
  contentSimulation: {
    padding: '40px',
    position: 'relative',
  },
  link: {
    color: theme.secondary.main,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  circuleProgress: {
    position: 'absolute',
    zIndex: 1,
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  spinner: {
    width: '80px',
  },
}));
interface Convention {
  codeConvention: string;
  companyName: string;
  minAmount: number;
  maxAmount: number;
  minDuration: number;
  maxDuration: number;
  minMonthlyPayment: number;
  maxMonthlyPayment: number;
}

const ModifySimulation = () => {
  const wordingSimulation = useSelector(selectCompWording('simulation'));
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updateFieldByArrayAction(
        ['simulation', 'fields', event.target.name],
        event.target.value,
      ),
    );
  };
  const errorsWording = useSelector(selectCompWording('errors'));

  const selectedConvention: any | Convention = useSelector(
    getSimulationInfoByArraySelector(['simulation', 'selectedConvention']),
  );

  const simulation: any = useSelector(
    getSimulationInfoByArraySelector(['simulation', 'fields']),
  );
  const simulationResponse: any = useSelector(
    getSimulationInfoByArraySelector(['simulation', 'response']),
  );

  const errors: any = useSelector(
    getSituationInfoByArraySelector(['situationSalarie', 'errors']),
  );
  const preAttributionBmceDirect: any = useSelector(
    getValidationInfoByArraySelector(['preAttribution']),
  );
  const MontantCREDIT = !isEmpty(getSessionStorage('MontantCREDIT'))
    ? parseInt(getSessionStorage('MontantCREDIT'), 10)
    : SLIDER_PREATTRIBUTION.maxAmount;
  const duree = !isEmpty(getSessionStorage('duree'))
    ? parseInt(getSessionStorage('duree'), 10)
    : SLIDER_PREATTRIBUTION.maxDuration;

  useEffect(() => {
    if (preAttributionBmceDirect) {
      dispatch(
        updateFieldByArrayAction(
          ['simulation', 'selectedConvention', 'maxDuration'],
          duree,
        ),
      );
      dispatch(
        updateFieldByArrayAction(
          ['simulation', 'selectedConvention', 'minDuration'],
          SLIDER_PREATTRIBUTION.minDuration,
        ),
      );
      dispatch(
        updateFieldByArrayAction(
          ['simulation', 'selectedConvention', 'maxAmount'],
          MontantCREDIT,
        ),
      );
      dispatch(
        updateFieldByArrayAction(
          ['simulation', 'fields', 'creditDuration'],
          selectedConvention?.maxDuration,
        ),
      );
    }
  }, [
    MontantCREDIT,
    dispatch,
    duree,
    preAttributionBmceDirect,
    selectedConvention,
  ]);
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch({ type: SimulationAT.modifySimulation });
  };

  const userProfile = getSessionStorage('userProfile');
  if (userProfile && isEmpty(userProfile.typeClient)) {
    userProfile.typeClient = getSessionStorage('typeclient');
  }

  const handleSubmitToProfil = () => {
    // handling modify Simulation  preAttribution
    if (preAttributionBmceDirect) {
      //   const _url = getRoute(
      //     userProfile.typeClient,
      //     userProfile.product,
      //     userProfile.clientBOA,
      //     '2',
      //     userProfile.appreciation,
      //     preAttributionBmceDirect,
      //   );
      dispatch(updateFieldByArrayAction(['simulation', 'isEditInfo'], true));
      //   dispatch(push(_url));
    }
    dispatch({ type: SimulationAT.submitToProfil });
  };
  const hasError =
    errors.global &&
    (errors.global.includes(E3) ||
      errors.global.includes(E34) ||
      errors.global.includes(E35) ||
      errors.global.includes(E36) ||
      errors.global.includes(E37) ||
      errors.global.includes(E38) ||
      errors.global.includes(E39));
  return (
    <form onSubmit={handleSubmit} className={classes.root}>
      <Spinner loader={simulationResponse?.loader} />
      <div className={classes.simulationDetail}>
        <FormLabel className={classes.labelSimulation} component='legend'>
          {wordingSimulation?.modifySimulation}
        </FormLabel>
        {hasError && (
          <GlobalError
            globalError={errors.global}
            errorWording={errorsWording}
          />
        )}
        <Slider
          classes={classes}
          wordingSimulation={wordingSimulation}
          errors={errors}
          errorsWording={errorsWording}
          selectedConvention={selectedConvention}
          simulation={simulation}
          simulationResponse={simulationResponse}
          handleChange={handleChange}
          handleSubmitToProfil={handleSubmitToProfil}
          hasOTP={true}
        />
        <div className={classes.subscriptionButtonContainer}>
          <Button
            variant='contained'
            color='primary'
            className={classes.subscriptionButton}
            type='submit'
          >
            {wordingSimulation?.validate}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default ModifySimulation;
