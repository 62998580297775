import React, { useEffect } from 'react';
import ContextualLayout from './components/ContextualLayout';
import MainView from './components/MainView';
import EligibilityImage from 'assets/svg/eligibility.svg';
import Profiles from './components/Profiles';
import { Wording } from 'common/wording/reducer.wording';
import { useSelector } from 'react-redux';
import { selectCompWording } from 'common/wording/selectors.wording';
import Seo from 'common/components/seo/Seo';
import { pushPageView } from 'features/auth/constants';

function Eligibility() {
  const wording: Wording = useSelector(selectCompWording('contextual'));

  useEffect(() => {
    pushPageView('profils_eligibiles', 'showcase');
  }, []);

  return (
    <ContextualLayout>
      <>
        <Seo
          keywordsKey='keywords_34'
          titleKey='title_34'
          descriptionKey='description_34'
        />
        <MainView
          title={wording?.title_profil_eligible}
          description={wording?.description_profil_eligible}
          image={EligibilityImage}
        />
        <Profiles />
      </>
    </ContextualLayout>
  );
}

export default Eligibility;
