import { getValidationInfoByArraySelector } from './../validationBmceDirect/selector.validation';
import {
  INVALID_BMCE_DIRECT_LOGIN_OR_PASSWORD,
  INVALID_EMAIL_OR_PASSWORD,
  REQUIRED,
} from 'common/utils/validateJs/contants';
import { apiGet, apiPost } from 'common/utils/request.util';
import { has, isEmpty, assign } from 'lodash';
import {
  passwordOublieConstraints,
  signInConstraints,
  signUpBMCEDirectConstraints,
  signUpConstraints,
} from 'common/utils/validateJs/constraints';
import { costumValidate } from 'common/utils/validateJs/validateJs';
import { INVALID_DATE_FORMAT } from 'common/utils/validateJs/contants';
import { extractError } from 'common/utils/auth.util';
import { push } from 'connected-react-router';
import { delay, put, select, takeLatest } from 'redux-saga/effects';
import { AuthStateModel, initState } from './reducer.auth';
import {
  AuthAT,
  isAuthenticatedAction,
  LoGout,
  saveUserProfileAction,
  signInErrorResponseAction,
  SubmitPasswordOublieAction,
  SubmitSignInAction,
  SubmitGenerateAction,
  SubmitSignUpAction,
  updateFieldAuthAction,
  updateFieldSignInAction,
  updateFieldSignUpAction,
  updateStepAction,
} from './actions.auth';
import {
  updateFieldAction,
  updateStepAct,
} from '../situation/actions.situation';
import { getAuthInfoByArraySelector } from './selector.auth';
import { getRoute, toJS } from 'common/utils/app.util';
import {
  getSessionStorage,
  removeSessionStorage,
  setSessionStorage,
} from 'common/utils/sessionStorage.util';
import { getSimulationInfoByArraySelector } from 'features/home/selector.simulation';
import { getSituationInfoByArraySelector } from 'features/situation/selector.situation';
import {
  AccountTypes,
  ProductTypes,
  professionTypes,
  pushEvent,
  SegClientTypes,
} from './constants';
import {
  PropositionAT,
  updatePropositionAction,
} from '../proposition/actions.proposition';
import { updateFieldUpAction } from '../upload/actions.upload';
import { updateFieldCaseAction } from 'features/suivieDossier/actions.caseTracking';
import {
  CODE_CONVENTION,
  COMPANY_NAME,
  LANG_STATE,
  TIMER_GENERATE_CODE_ACCESS,
} from 'features/home/constants.simulation';
import { SimulationAT } from 'features/home/actions.simulation';
import { ValidationAT } from 'features/validationBmceDirect/actions.validation';
import { updateFieldByArrayAction } from './../home/actions.simulation';
import moment from 'moment';

export function* saveUserProfileAndRedirect(userProfile: any) {
  // Get URL
  const preAttributionBmceDirect: any = yield select(
    getValidationInfoByArraySelector(['preAttribution']),
  );
  const _url = getRoute(
    userProfile.typeClient,
    userProfile.product,
    userProfile.clientBOA,
    userProfile.stepClient,
    userProfile.appreciation,
    preAttributionBmceDirect,
  );
  setSessionStorage('userProfile', userProfile);
  yield put(saveUserProfileAction(userProfile));
  yield put(isAuthenticatedAction(true));
  yield put(push(_url));
}

export function* submitSignUp(action: SubmitSignUpAction) {
  try {
    const preAttributionBmceDirect = yield select(
      getValidationInfoByArraySelector(['preAttribution']),
    );
    const simulation = yield select(
      getSimulationInfoByArraySelector(['simulation', 'fields']),
    );

    const langSelected = yield select(
      getSimulationInfoByArraySelector(['lang']),
    ) || LANG_STATE.FR;
    if (langSelected === LANG_STATE.FR) {
      yield put(updateFieldSignUpAction('signUp', 'lang', LANG_STATE.FR));
    } else {
      yield put(updateFieldSignUpAction('signUp', 'lang', LANG_STATE.AR));
    }
    const userData = yield select(getAuthInfoByArraySelector(['signUp']));
    const errors = costumValidate(userData, signUpConstraints())
      ? costumValidate(userData, signUpConstraints())
      : {};

    if (!userData.birthday) {
      assign(errors, { birthday: [INVALID_DATE_FORMAT] });
    }
    const birthday = new Date(userData.birthday);
    userData.birthday = birthday;

    /* format date to frensh  */
    if (userData.birthday !== '') {
      userData.birthday = userData?.birthday?.toLocaleDateString('fr-FR');
    }

    if (!moment(userData.birthday, 'dd/MM/yyyy').isValid()) {
      assign(errors, { birthday: [INVALID_DATE_FORMAT] });
    }

    if (
      userData.codeConvention === CODE_CONVENTION.X8 ||
      userData.codeConvention === CODE_CONVENTION.X9
    ) {
      userData.companyName = COMPANY_NAME.other;
    }

    if (isEmpty(userData.companyName)) {
      assign(errors, { companyName: [REQUIRED] });
    }

    if (
      isEmpty(userData.nameOtherEmploye) &&
      userData.companyName === COMPANY_NAME.other
    ) {
      assign(errors, { nameOtherEmploye: [REQUIRED] });
    }

    /* #### validate profession input if exist #### */
    const isOtherEmployer = userData.companyName === COMPANY_NAME.other;
    console.log('isOtherEmployer', isOtherEmployer, userData.companyName);
    console.log('typeClient', simulation.typeClient);
    if (
      simulation.typeClient !== AccountTypes.retraite &&
      !userData.profession &&
      (!simulation.openAccountBOA ||
        ((simulation.clientBOA || simulation.openAccountBOA) &&
          (isOtherEmployer || !userData.companyName)))
    ) {
      assign(errors, { profession: [REQUIRED] });
    }

    /* *******Gestion segClient**** */
    if (userData.companyName !== COMPANY_NAME.other) {
      userData.segClient = SegClientTypes.C;
    } else {
      userData.segClient = SegClientTypes.NC;
    }

    yield put(
      updateFieldAction('situationSalarie', 'segClient', userData.segClient),
    );

    /* *******END Gestion segClient**** */

    /* ***** Gestion Product ********** */
    if (simulation.clientBOA && userData.companyName !== COMPANY_NAME.other) {
      if (
        simulation.typeClient === AccountTypes.salarie ||
        simulation.typeClient === AccountTypes.retraite
      ) {
        userData.product = ProductTypes.bconvcp;
      } else {
        userData.product = ProductTypes.bconvppr;
      }
    }
    if (simulation.clientBOA && userData.companyName === COMPANY_NAME.other) {
      if (
        simulation.typeClient === AccountTypes.salarie ||
        simulation.typeClient === AccountTypes.retraite
      ) {
        userData.product = ProductTypes.cp;
      } else {
        userData.product = ProductTypes.ppr;
      }
    }
    if (!simulation.clientBOA) {
      userData.product = ProductTypes.cp_slf;
    }

    /* ###### set product for professionnel  ##### */
    if (simulation.typeClient === AccountTypes.professionnel) {
      // is client or want to be client
      if (simulation.clientBOA || simulation.openAccountBOA) {
        userData.product = ProductTypes.cp;
      } else {
        userData.product = ProductTypes.cp_slf;
      }
    }
    yield put(
      updateFieldByArrayAction(
        ['simulation', 'fields', 'product'],
        userData.product,
      ),
    );

    yield put(
      updateFieldByArrayAction(
        ['simulation', 'response', 'product'],
        userData.product,
      ),
    );
    // END GESTION PRODUCT

    const simulationId = getSessionStorage('simulationId');
    const cin = getSessionStorage('cin');
    setSessionStorage('userData', userData);
    console.log('errors ', errors);
    if (!isEmpty(errors)) {
      yield put({ type: AuthAT.submitSignUpError, errors });
      return;
    }
    yield put({ type: AuthAT.submitSignUpError, errors: {} });
    yield put(
      updateFieldSignUpAction('confirmationMail', 'sendingEmail', true),
    );
    const fromBMCEDirect = yield select(
      getValidationInfoByArraySelector(['data']),
    );

    if (isEmpty(fromBMCEDirect)) {
      userData.redirectViaBmceDirect = false;
    } else {
      userData.redirectViaBmceDirect = true;
    }

    // set profession to "11"(retraite) from clienttype equal "retraite"
    if (simulation.typeClient === AccountTypes.retraite) {
      userData.profession = professionTypes.retraite;
    }

    // submit form to server
    const { data } = yield apiPost('/auth/sign-up', {
      ...userData,
      cin,
      simulationId,
    });

    // google tag manager
    pushEvent({
      event: 'create_profile',
      creation_type: userData.bmceDirect ? 'profil_bmce' : 'standard',
    });

    yield put(
      updateFieldSignUpAction('signUp', 'firstName', userData.firstName),
    );
    yield put(updateFieldSignUpAction('signUp', 'lastName', userData.lastName));

    yield delay(5000);
    setSessionStorage('firstName', userData.firstName);
    setSessionStorage('lastName', userData.lastName);
    // TODO move all to one reducer
    // updateStepAct('situationSalarie') set data to AUTH.reducer and SITUATION.reducer
    let cinOrRib;
    let isBMCE: any = null;
    const list = data.payload.infosClient;
    if (has(list, 'listInfosClient') && !isEmpty(list.listInfosClient)) {
      const datas = list.listInfosClient[0];
      datas.natureContrat = 'cdi';
      datas.cddEndDate = '';
      datas.dateCurrentEmployer = '';
      datas.dateLastTransfer = '';
      datas.dateCreationOtherBankAccount = '';
      if (datas.cin !== '') {
        cinOrRib = 'cin';
      } else {
        cinOrRib = 'rib';
      }
      datas.cinBmce = datas.cin;
      const listLength = datas.cptClient.length;
      if (listLength > 0) {
        datas.rib = datas.cptClient[0].rib;
        const listRibBmce = [] as any;
        datas.cptClient.forEach((element: any) => {
          listRibBmce.push({ label: element.rib, value: element.rib });
        });
        datas.listRibBmce = listRibBmce;
      }

      // SignUp service in case preattribution (to verify)
      // yield put(updateStepAct('situationSalarie', { ...datas, cinOrRib }));
      if (!preAttributionBmceDirect) {
        yield put(updateStepAct('situationSalarie', { ...datas, cinOrRib }));
      }

      isBMCE = 'bmce';
      yield put(updateFieldAction('situationSalarie', 'isBMCE', isBMCE));
    } else {
      isBMCE = 'client';
      yield put(updateFieldAction('situationSalarie', 'isBMCE', isBMCE));
    }

    const typeClient = yield select(
      getSimulationInfoByArraySelector(['simulation', 'fields', 'typeClient']),
    );

    const clientBOA = yield select(
      getSimulationInfoByArraySelector(['simulation', 'fields', 'clientBOA']),
    );
    const product = yield select(
      getSimulationInfoByArraySelector(['simulation', 'response', 'product']),
    );

    yield put(updateFieldAction('situationSalarie', 'product', product));

    yield put(updateFieldAction('situationSalarie', 'clientBOA', clientBOA));

    const situationData = yield select(
      getSituationInfoByArraySelector(['situationSalarie', 'fields']),
    );

    setSessionStorage('lastName', data.payload.lastName);
    setSessionStorage('firstName', data.payload.firstName);
    setSessionStorage('codeConvention', userData.codeConvention);
    setSessionStorage('situationSalarie', {
      ...situationData,
      clientBOA,
      product,
    });
    setSessionStorage(
      'typeclient',
      typeClient === AccountTypes.salarie ? AccountTypes.salarie : typeClient,
    );
    yield put(updateFieldAction('situationSalarie', 'type', typeClient));
    /* save user and redirect to matched profile */
    const userProfile = {
      clientBOA,
      typeClient:
        typeClient === AccountTypes.salarie ? AccountTypes.salarie : typeClient,
      product,
      stepClient: '2',
    };
    //if (!preAttributionBmceDirect) {
    // TODO : verify compatibility
    yield saveUserProfileAndRedirect(userProfile);
    //}
    // handling submitSignUp preattribution

    if (preAttributionBmceDirect) {
      yield put(updateFieldByArrayAction(['simulation', 'isEditInfo'], true));
    }
  } catch (error) {
    yield put(
      updateFieldSignUpAction('confirmationMail', 'sendingEmail', false),
    );
    if (error.response && error.response.status === 400) {
      yield put({
        type: AuthAT.submitSignUpError,
        errors: extractError(error),
      });
    }
  }
}

export function* logout(action: LoGout) {
  try {
    yield put(updateFieldAuthAction('signIn', 'loader', true));
    yield apiGet('/auth/logout');
    yield put(isAuthenticatedAction(false));
    removeSessionStorage('email');
    removeSessionStorage('lastName');
    removeSessionStorage('rib');
    removeSessionStorage('bmceDirect');
    removeSessionStorage('cin');
    removeSessionStorage('civility');
    removeSessionStorage('validation');
    removeSessionStorage('isAuthenticated');
    removeSessionStorage('typeAuthentication');
    removeSessionStorage('simulationId');
    removeSessionStorage('usernameAuth');
    removeSessionStorage('typeclient');
    removeSessionStorage('identifiant_otp');
    removeSessionStorage('statutDossier');
    removeSessionStorage('updateInformation');
    removeSessionStorage('recap');
    removeSessionStorage('situationSalarie');
    removeSessionStorage('type');
    removeSessionStorage('product');
    removeSessionStorage('clientBOA');
    removeSessionStorage('proposition');
    removeSessionStorage('firstName');
    removeSessionStorage('lastName');
    removeSessionStorage('userProfile');
    removeSessionStorage('birthday');
    removeSessionStorage('comptDate');
    removeSessionStorage('rachat');
    removeSessionStorage('emailExist');
    removeSessionStorage('MontantCREDIT');
    removeSessionStorage('duree');
    removeSessionStorage('statut');
    removeSessionStorage('echeance');
    removeSessionStorage('userData');
    removeSessionStorage('employerName');
    yield put({
      type: ValidationAT.VALIDATION_PREATTRIBUTION,
      preAttribution: false,
    });
    yield put(updateFieldAuthAction('signIn', 'loader', false));
    yield put(push('/sign-in'));
  } catch (error) {
    yield put(updateFieldAuthAction('signIn', 'loader', false));
    console.error(error);
  }
}

export function* submitGenerate(action: SubmitGenerateAction) {
  const userDataSignIn = yield select(getAuthInfoByArraySelector(['signIn']));
  const bmceDirect = userDataSignIn.bmceDirect === 'signInByBmceDirect';
  setSessionStorage('email', userDataSignIn.email);

  try {
    userDataSignIn.bmceDirect = bmceDirect;
    userDataSignIn.reCAPTCHA = !action.reCAPTCHA ? '' : action.reCAPTCHA;
    const errors = costumValidate(
      userDataSignIn,
      signInConstraints(bmceDirect),
    );
    if (!bmceDirect) {
      userDataSignIn.login = userDataSignIn.email;
    }
    yield put(updateFieldAuthAction('signIn', 'loader', true));
    if (errors) {
      yield put({ type: AuthAT.submitSignInError, errors });
      yield put(updateFieldAuthAction('signIn', 'loader', false));
      return;
    }
    yield put({ type: AuthAT.submitSignInError, errors: {} });
    const response = yield apiPost('/auth/generate-password', userDataSignIn);
    if (response.status === 200) {
      setSessionStorage('emailExist', true);
      yield put(push('/generate-access-code'));
    }
    yield put(updateFieldAuthAction('signIn', 'loader', false));
  } catch (errors) {
    yield put(
      signInErrorResponseAction({
        global: [INVALID_EMAIL_OR_PASSWORD],
      }),
    );
    setSessionStorage('emailExist', false);
    yield put(updateFieldAuthAction('signIn', 'loader', false));
  }
}

export function* submitSignIn(action: SubmitSignInAction) {
  const userDataSignIn = yield select(getAuthInfoByArraySelector(['signIn']));
  const bmceDirect = userDataSignIn.bmceDirect === 'signInByBmceDirect';
  try {
    let isBMCE: any = null;
    const listRibBmce = [] as any;
    let rib = null;
    userDataSignIn.bmceDirect = bmceDirect;
    userDataSignIn.reCAPTCHA = !action.reCAPTCHA ? '' : action.reCAPTCHA;
    const errors = costumValidate(
      userDataSignIn,
      signInConstraints(bmceDirect),
    );
    if (!bmceDirect) {
      userDataSignIn.login = userDataSignIn.email;
      userDataSignIn.password = userDataSignIn.accessCode;
    }
    yield put(updateFieldAuthAction('signIn', 'loader', true));
    if (errors) {
      yield put({ type: AuthAT.submitSignInError, errors });
      yield put(updateFieldAuthAction('signIn', 'loader', false));
      return;
    }
    yield put({ type: AuthAT.submitSignInError, errors: {} });
    const { data } = yield apiPost('/auth/sign-in', userDataSignIn);

    yield put({
      type: SimulationAT.setLang,
      value: data.lang || LANG_STATE.FR,
    });

    data.cinOrRib = 'cin';
    if (
      has(data, 'infosClient') &&
      !isEmpty(data.infosClient.listInfosClient)
    ) {
      const datas = data.infosClient.listInfosClient[0];
      data.cinOrRib = '';
      data.cinBmce = datas.cin;
      const listLength = datas.cptClient.length;
      if (listLength > 0) {
        rib = datas.cptClient[0].rib;
        datas.cptClient.forEach((element: any) => {
          listRibBmce.push({ label: element.rib, value: element.rib });
        });
        datas.listRibBmce = listRibBmce;
      }
      isBMCE = 'bmce';
      yield put(
        updateStepAct('situationSalarie', {
          ...data,
          listRibBmce,
          rib,
          isBMCE,
        }),
      );
      setSessionStorage('situationSalarie', {
        ...data,
        listRibBmce,
        rib,
        isBMCE,
      });
    } else {
      isBMCE = 'client';
      yield put(updateStepAct('situationSalarie', { ...data, isBMCE }));
      setSessionStorage('situationSalarie', { ...data, isBMCE });
    }
    yield put(updateFieldSignUpAction('signUp', 'firstName', data.firstName));
    yield put(updateFieldSignUpAction('signUp', 'lastName', data.lastName));
    setSessionStorage('lastName', data.lastName);
    setSessionStorage('firstName', data.firstName);
    setSessionStorage('typeclient', data.typeClient);
    setSessionStorage('employerName', data.employerName);
    setSessionStorage('codeConvention', data.codeConv);
    yield put({ type: PropositionAT.updateProposition, data });
    setSessionStorage('proposition', data);
    yield put(updatePropositionAction('isBMCE', isBMCE));
    yield put(updateFieldUpAction('upload', 'type', data.typeClient));
    yield put(updateFieldUpAction('upload', 'product', data.product));
    yield put(updateFieldUpAction('upload', 'clientBOA', data.clientBOA));
    if (data.stepClient === '1') {
      yield put(push('/sign-up'));
    }
    if (data.stepClient === '6') {
      const datas = yield apiPost('/situation/suivi-dossier', '');
      setSessionStorage('statutDossier', datas.data.statusDemande);
      yield put(
        updateFieldCaseAction('caseTracking', 'step', datas.data.statusDemande),
      );
    }

    /* save user and redirect to matched profile */
    const userProfile = {
      clientBOA: data.clientBOA,
      typeClient: data.typeClient,
      product: data.product,
      stepClient: data.stepClient,
      appreciation: data.appreciation,
    };
    yield saveUserProfileAndRedirect(userProfile);
    yield put(updateFieldAuthAction('signIn', 'loader', false));
    if (bmceDirect) {
      pushEvent({
        event: 'login',
        login_type: 'bmce_direct',
        user_id:
          getSessionStorage('simulationId') ??
          getSessionStorage('situationSalarie')?.idSimulation,
        audience:
          getSessionStorage('typeclient') ??
          getSessionStorage('userProfile')?.typeclient,
      });
    } else {
      pushEvent({
        event: 'login',
        login_type: 'email',
        user_id:
          getSessionStorage('simulationId') ??
          getSessionStorage('situationSalarie')?.idSimulation,
        audience:
          getSessionStorage('typeclient') ??
          getSessionStorage('userProfile')?.typeclient,
      });
    }
  } catch (errors) {
    if (errors.response && errors.response.status === 401) {
      yield put(
        signInErrorResponseAction({
          global: [
            bmceDirect
              ? INVALID_BMCE_DIRECT_LOGIN_OR_PASSWORD
              : INVALID_EMAIL_OR_PASSWORD,
          ],
        }),
      );
      if (!bmceDirect) {
        setSessionStorage('timer', TIMER_GENERATE_CODE_ACCESS);
      }
    }
    yield put(updateFieldAuthAction('signIn', 'loader', false));
  }
}

export function* submitPassworOublie(action: SubmitPasswordOublieAction) {
  try {
    const data = yield select(getAuthInfoByArraySelector(['passwordOublie']));
    const errors = costumValidate(data, passwordOublieConstraints());
    if (errors) {
      yield put({ type: AuthAT.submitPasswordOublieError, errors });
      return;
    }
    yield put({ type: AuthAT.submitPasswordOublieError, errors: {} });
    yield apiPost('/auth/forget-password', { email: data?.email });
    yield put(push('/sign-in?origine=pass'));
    yield put(updateFieldSignInAction('passwordOublie', 'email', ''));
  } catch (error) {
    if (error.response && error.response.status === 400) {
      yield put(signInErrorResponseAction(extractError(error)));
    }
  }
}

export function* submitSignOut() {
  try {
    yield apiGet('/auth/signOut');
    yield put(push('/'));
  } catch (error) {
    console.error('sign out error');
  }
}

export function* submitSignUpBmceDirect() {
  try {
    const langSelected = yield select(
      getSimulationInfoByArraySelector(['lang']),
    ) || LANG_STATE.FR;
    if (langSelected === LANG_STATE.FR) {
      yield put(
        updateFieldAuthAction('signUpBMCEDirect', 'lang', LANG_STATE.FR),
      );
    } else {
      yield put(
        updateFieldAuthAction('signUpBMCEDirect', 'lang', LANG_STATE.AR),
      );
    }

    const typeClient = yield select(
      getSimulationInfoByArraySelector(['simulation', 'fields', 'typeClient']),
    );
    const product = yield select(
      getSimulationInfoByArraySelector(['simulation', 'response', 'product']),
    );

    const clientBOA = yield select(
      getSimulationInfoByArraySelector(['simulation', 'fields', 'clientBOA']),
    );

    const userData = yield select(
      getAuthInfoByArraySelector(['signUpBMCEDirect']),
    );
    const errors = costumValidate(userData, signUpBMCEDirectConstraints());
    const simulationId = getSessionStorage('simulationId');
    const userBmceSignin = { ...userData, bmceDirect: true };
    if (errors) {
      yield put({ type: AuthAT.submitSignUpBMCEDirectError, errors });
      return;
    }
    yield put({ type: AuthAT.submitSignUpBMCEDirectError, errors: {} });

    yield put(updateFieldAuthAction('signUpBMCEDirect', 'loader', true));
    userBmceSignin.stepClient = '1';
    userBmceSignin.redirectViaBmceDirect = true;
    const { data } = yield apiPost('/auth/sign-up-bmce-direct', {
      ...userBmceSignin,
      simulationId,
    });
    yield put(updateFieldAuthAction('signUpBMCEDirect', 'sendingEmail', true));
    const password = userBmceSignin.password;
    yield put(updateStepAction('signUp', { ...data.payload, password }));
    yield put({ type: AuthAT.submitSignUpError, errors: {} });
    yield put(updateFieldAction('signUpBMCEDirect', 'loader', false));

    yield put(
      updateStepAction(
        'signUpBMCEDirect',
        (toJS(initState) as AuthStateModel).signUpBMCEDirect,
      ),
    );
    yield put(
      updateFieldSignUpAction('signUp', 'firstName', data.payload.firstName),
    );
    yield put(
      updateFieldSignUpAction('signUp', 'lastName', data.payload.lastName),
    );

    setSessionStorage('simulationId', simulationId);

    setSessionStorage('civility', data.payload.civility);
    setSessionStorage('email', data.payload.email);
    setSessionStorage('lastName', data.payload.lastName);
    setSessionStorage('firstName', data.payload.firstName);

    setSessionStorage('cin', data.payload.cin);
    setSessionStorage('rib', data.payload.rib);
    setSessionStorage('bmceDirect', data.payload.bmceDirect);
    setSessionStorage('codeConvention', userBmceSignin.codeConvention);

    /* save user and redirect to matched profile */
    const userProfile = {
      clientBOA,
      typeClient:
        typeClient === AccountTypes.salarie ? AccountTypes.salarie : typeClient,
      product,
      stepClient: '1',
    };
    yield saveUserProfileAndRedirect(userProfile);
  } catch (error) {
    yield put(updateFieldAuthAction('signUpBMCEDirect', 'sendingEmail', false));
    yield put(updateFieldAuthAction('signUpBMCEDirect', 'loader', false));
    if (error.response && error.response.status === 400) {
      yield put({
        type: AuthAT.submitSignUpBMCEDirectError,
        errors: extractError(error),
      });
    }
    yield put(
      updateFieldAuthAction('signUpBMCEDirect', 'checkValidateClient', true),
    );
  }
}

export function* loGoutSaga() {
  yield takeLatest(AuthAT.loGout, logout);
}

export function* submitSignUpSaga() {
  yield takeLatest(AuthAT.submitSignUp, submitSignUp);
}

export function* submitSignOutSaga() {
  yield takeLatest(AuthAT.submitSignOut, submitSignOut);
}

export function* submitGenerateSaga() {
  yield takeLatest(AuthAT.submitGenerate, submitGenerate);
}

export function* submitSignInSaga() {
  yield takeLatest(AuthAT.submitSignIn, submitSignIn);
}

export function* submitSignUpBMCEDirectSaga() {
  yield takeLatest(AuthAT.submitSignUpBMCEDirect, submitSignUpBmceDirect);
}

export function* submitPassworOublieSaga() {
  yield takeLatest(AuthAT.submitPasswordOublie, submitPassworOublie);
}

export const authSagas = [
  submitSignUpSaga,
  submitSignOutSaga,
  submitSignInSaga,
  submitGenerateSaga,
  submitSignUpBMCEDirectSaga,
  submitPassworOublieSaga,
  loGoutSaga,
];
