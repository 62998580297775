/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { GlobalTheme } from 'features/app/app.theme';
import { useDispatch, useSelector } from 'react-redux';
import { selectCompWording } from 'common/wording/selectors.wording';
import SalarieNotConv from './SalarieNotConv';
import SalarieConv from './SalarieConv';
import {
  SituationAT,
  updateFieldAction,
} from 'features/situation/actions.situation';
import { getLocation } from 'core/root.selector';
import SalarieProspect from './SalarieProspect';
import { getSituationInfoByArraySelector } from 'features/situation/selector.situation';
import Spinner from 'common/components/Spinner';
import { isEmpty } from 'lodash';
import {
  COMPTE_MULTI_TITULAIRE,
  CIN_PLUSIEURS_TIERS,
  CIN_RIB_NULLS,
  DATA_IN_NOT_VALIDE,
} from 'common/utils/validateJs/contants';
import PopUpInfo from '../../../home/components/PopUpInfo';
import { getSimulationInfoByArraySelector } from 'features/home/selector.simulation';
import { LANG_STATE } from 'features/home/constants.simulation';
import { pushPageView } from 'features/auth/constants';

const useStyles = makeStyles((theme: GlobalTheme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  parag: {
    width: '88%',
    padding: '10px',
    marginBottom: '20px',
    textAlign: 'left',
  },
  paragTitle: {
    fontFamily: theme.fonts.medium,
    color: theme.primary.title,
    textTransform: 'uppercase',
    fontSize: '1.75rem',
    '@media (max-width: 450px)': {
      fontSize: '1.3125rem',
    },
  },
  paragTitleAr: {
    fontFamily: theme.fonts.medium,
    color: theme.primary.title,
    textTransform: 'uppercase',
    fontSize: '1.75rem',
    fontWeight: 'bold',
    '@media (max-width: 450px)': {
      fontSize: '1.3125rem',
    },
  },
  paragSubTitle: {
    width: '591px',
    '@media (max-width: 768px)': {
      width: '400px',
    },
    '@media (max-width: 560px)': {
      width: '300px',
    },
    '@media (max-width: 414px)': {
      width: '255px',
    },
  },
  groupForm: {
    width: '100%',
    backgroundColor: '#FFF',
    borderRadius: '23px',
    direction: 'ltr',
  },

  groupFormFooter: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonSubmit: {
    padding: '18px 91px',
    borderRadius: '14px 0',
    '@media (max-width: 768px)': {
      padding: '16px 44px',
    },
    '@media (max-width: 414px)': {
      padding: '16px 44px',
    },
  },
  saveButton: {
    borderRadius: '0 14px',
    backgroundColor: theme.primary.title,
    '&:hover': {
      backgroundColor: theme.primary.title,
    },
  },
}));

function Salarie() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const langSelected: any =
    useSelector(getSimulationInfoByArraySelector(['lang'])) || LANG_STATE.FR;
  const wordingSalarie = useSelector(selectCompWording('detailedForm'));

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch({ type: SituationAT.submitSituationSalarie });
  };
  const handleSave = () => {
    dispatch({ type: SituationAT.saveOrConfirmeSituation, name: 'save' });
  };
  const location = useSelector(getLocation);

  const loader: any = useSelector(
    getSituationInfoByArraySelector(['situationSalarie', 'config', 'loader']),
  );

  // Detection des errors pour les Conventionnées, Non Conventionnées et Prospects.
  const errors: any = useSelector(
    getSituationInfoByArraySelector(['situationSalarie', 'errors']),
  );
  const situationSalarie: any = useSelector(
    getSituationInfoByArraySelector(['situationSalarie', 'fields']),
  );
  const formHeader: any = useRef();

  const scrollToTop = () => {
    formHeader.current.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start',
    });
    const element: any = document.getElementById('formHeader');
    element.scrollIntoView();
    element.scrollTop -= 10;
  };

  useEffect(() => {
    if (!isEmpty(errors)) {
      scrollToTop();
    }
  }, [errors]);

  const handleClose = () => {
    dispatch(
      updateFieldAction('situationSalarie', 'checkValidateClient', false),
    );
  };

  useEffect(() => {
    if (
      errors &&
      errors.global &&
      (errors.global[0] === CIN_PLUSIEURS_TIERS ||
        errors.global[0] === CIN_RIB_NULLS ||
        errors.global[0] === DATA_IN_NOT_VALIDE)
    ) {
      dispatch(updateFieldAction('situationSalarie', 'cinOrRib', 'rib'));
    }
  }, [errors]);

  useEffect(() => {
    pushPageView('step_2_formulaire');
  }, []);

  return (
    <div className={classes.root}>
      <Spinner loader={loader} />
      <div className={classes.parag}>
        <Typography
          variant='h4'
          className={
            langSelected === LANG_STATE.FR
              ? classes.paragTitle
              : classes.paragTitleAr
          }
        >
          {wordingSalarie?.title}
        </Typography>
      </div>
      <form
        id='formHeader'
        onSubmit={handleSubmit}
        className={classes.groupForm}
        ref={formHeader}
      >
        {errors.global && errors.global[0] === COMPTE_MULTI_TITULAIRE && (
          <PopUpInfo
            open={situationSalarie.checkValidateClient}
            onClose={handleClose}
            error={errors.global[0]}
          />
        )}

        {location === '/salarie/conv' ? (
          <SalarieConv />
        ) : location === '/salarie/noConv' ? (
          <SalarieNotConv />
        ) : (
          location === '/salarie/prospect' && <SalarieProspect />
        )}

        <div className={classes.groupFormFooter}>
          <Button
            onClick={handleSave}
            className={`${classes.buttonSubmit} ${classes.saveButton}`}
            variant='contained'
            color='secondary'
            // disabled={loader}
          >
            {wordingSalarie?.save}
          </Button>
          <Button
            type='submit'
            className={classes.buttonSubmit}
            variant='contained'
            color='secondary'
            // disabled={loader}
          >
            {wordingSalarie?.submit}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default Salarie;
