import { makeStyles } from '@material-ui/core/styles';
import { Wording } from 'common/wording/reducer.wording';
import React from 'react';
import { selectWording } from 'common/wording/selectors.wording';
import { useSelector } from 'react-redux';
import { GlobalTheme } from 'features/app/app.theme';
import CustomTitle from 'common/components/customFields/CustomTitle';
import traceWithout from 'assets/svg/traceWithout.svg';
import traceWithFlesh from 'assets/svg/traceWithFlesh.svg';
import traceCercle from 'assets/svg/traceCercle.svg';
import StepElementHomePage from './StepElementHomePage';
import { getSimulationInfoByArraySelector } from 'features/home/selector.simulation';
import { LANG_STATE } from 'features/home/constants.simulation';

const useStyles = makeStyles((theme: GlobalTheme) => ({
  root: {
    width: '100%',
    margin: '40px auto 20px',
  },
  bodyContent: {
    width: '90%',
    height: 'auto',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'center',
    '@media (max-width: 768px)': {
      padding: '0px',
    },
  },
  button: {
    width: '100%',
    maxWidth: 'max-content',
    textTransform: 'inherit',
    padding: '17px 39px',
    fontFamily: theme.fonts.regular,
    fontSize: '0.875rem',
    height: 'max-content',
    borderRadius: '72px 39px',
  },
  rightIcon: {
    marginLeft: '19px',
  },
  imgTrace: {
    marginLeft: '-38px',
    marginRight: '-38px',
    width: '165px',
    '@media (max-width: 1024px)': {
      display: 'none',
    },
  },
  imgTraceWithFlesh: {
    marginLeft: '0px',
  },
  imgTraceWithFleshAr: {
    marginLeft: '0px',
    transform: 'scaleX(-1)',
  },

  imgTraceCercle: {
    marginTop: '-255px',
    marginLeft: '-45px',
    width: '183px',
    '@media (max-width: 1024px)': {
      display: 'none',
    },
  },
  reverse5: {
    '@media (max-width: 1024px)': {
      order: 3,
    },
  },
}));

const StepsHomePage = () => {
  const classes = useStyles();
  const wording: Wording = useSelector(selectWording);
  const langSelected: any =
    useSelector(getSimulationInfoByArraySelector(['lang'])) || LANG_STATE.FR;
  return (
    <div className={classes.root}>
      <CustomTitle
        title={wording?.stepsHomePage.title}
        colorBorder={'blue'}
        positionBorder={''}
        positionTitle={''}
      />
      <div className={classes.bodyContent}>
        <StepElementHomePage
          number={'01'}
          content={wording?.stepsHomePage.stepOne}
          icon={'calculator'}
        />
        <img
          src={traceWithout}
          alt={wording?.alt.line_home}
          className={classes.imgTrace}
        />
        <StepElementHomePage
          number={'02'}
          content={wording?.stepsHomePage.stepTwo}
          icon={'personalInformation'}
        />
        <img
          src={traceWithout}
          alt={wording?.alt.line_home}
          className={classes.imgTrace}
        />
        <StepElementHomePage
          number={'03'}
          content={wording?.stepsHomePage.stepThree}
          icon={'sale'}
          firstSubContentOne={
            wording?.stepsHomePage.firstsubContentOneStepThree
          }
          firstSubContentTwo={
            wording?.stepsHomePage.firstsubContentTwoStepThree
          }
          secondSubContent={wording?.stepsHomePage.subContentTwoStepThree}
          starRequered={true}
        />
        <StepElementHomePage
          number={'05'}
          content={wording?.stepsHomePage.stepFive}
          icon={'contract'}
          className={classes.reverse5}
        />
        <img
          src={traceWithFlesh}
          alt={wording?.alt.arrow_home}
          className={`${classes.imgTrace} ${
            langSelected === LANG_STATE.FR
              ? classes.imgTraceWithFlesh
              : classes.imgTraceWithFleshAr
          }`}
        />
        <StepElementHomePage
          number={'04'}
          content={wording?.stepsHomePage.stepFour}
          icon={'files'}
        />
        <img
          src={traceCercle}
          alt=''
          className={`${classes.imgTrace} ${classes.imgTraceCercle}`}
          style={
            langSelected === LANG_STATE.AR
              ? {
                  transform: 'rotate(-237deg)',
                  marginTop: '-177px',
                }
              : {}
          }
        />
      </div>
    </div>
  );
};

export default StepsHomePage;
