import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Timer from 'assets/svg/Sablier.svg';
import { useSelector } from 'react-redux';
import { selectCompWording } from 'common/wording/selectors.wording';
import React from 'react';
import { GlobalTheme } from 'features/app/app.theme';

const useStyles = makeStyles((theme: GlobalTheme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: 'auto',
    textAlign: 'center',
    margin: 'auto',
  },
  title: {
    textTransform: 'uppercase',
    margin: 10,
  },
  description: {
    textAlign: 'center',
    padding: '60px 120px',
  },
  iconeMessage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconeMessagePng: {
    width: '38px',
    height: '51px',
  },
  waitMessage: {
    textAlign: 'center',
    fontFamily: theme.fonts.bold,
    marginLeft: '20px',
  },
}));

function ConfirmationMailBMCE() {
  const confirmationMailBmce = useSelector(
    selectCompWording('confirmationMailBmce'),
  );
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant='h3' className={classes.title}>
        {confirmationMailBmce?.title}
      </Typography>

      <Typography variant='h4' className={classes.description}>
        {confirmationMailBmce?.description}
      </Typography>
      <div className={classes.iconeMessage}>
        <img
          className={classes.iconeMessagePng}
          src={`${Timer}`}
          alt={confirmationMailBmce?.altImage}
        />
        <Typography variant='subtitle2' className={classes.waitMessage}>
          {confirmationMailBmce?.waitMessage}
        </Typography>
      </div>
    </div>
  );
}

export default ConfirmationMailBMCE;
