import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import iconServiceDown from 'assets/svg/iconServiceDown.svg';
import React from 'react';
import { GlobalTheme } from 'features/app/app.theme';
import { Wording } from 'common/wording/reducer.wording';
import { useSelector } from 'react-redux';
import { selectCompWording } from 'common/wording/selectors.wording';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: GlobalTheme) => ({
  header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'left',
    margin: '70px 90px 10px 90px',
    '@media (max-width: 768px)': {
      margin: '70px 30px 10px 30px',
    },
  },
  popup: {
    '& .MuiDialog-paperFullWidth': {
      width: '40%',
      margin: 'auto',
      '@media (max-width: 1024px)': {
        width: '60%',
      },
      '@media (max-width: 450px)': {
        width: '85%',
      },
    },
  },
  textInfo: {
    padding: '5px',
    textAlign: 'center',
    margin: '25px auto',
    color: theme.primary.title,
    '@media (max-width: 450px)': {
      textAlign: 'left',
    },
  },
  message: {
    fontSize: '0.9375rem',
    color: '#556584',
    fontFamily: theme.fonts.regular,
  },
  iconServiceDown: {
    width: '43px',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
  },
}));

interface Props {
  open: any;
}

function PopUpInfo({ open }: Props) {
  const wording: Wording = useSelector(selectCompWording('simulation'));
  const classes = useStyles();
  return (
    <Dialog
      fullWidth={true}
      maxWidth='sm'
      open={open}
      className={classes.popup}
    >
      <div className={classes.header}>
        <img className={classes.iconServiceDown} src={iconServiceDown} alt='' />
        <Typography variant='h3' className={classes.textInfo}>
          <span className={classes.message}>{wording?.serviceDown}</span>{' '}
        </Typography>
      </div>
    </Dialog>
  );
}

export default PopUpInfo;
