import { makeStyles } from '@material-ui/core/styles';
import { GlobalTheme } from 'features/app/app.theme';
import React from 'react';
import { Typography } from '@material-ui/core';
import { Wording } from 'common/wording/reducer.wording';
import { selectWording } from 'common/wording/selectors.wording';
import { useSelector } from 'react-redux';
import CheckElement from './CheckElement';
import Button from '@material-ui/core/Button';
import rightFlesh from 'assets/svg/rightFlesh.svg';
import { getSimulationInfoByArraySelector } from 'features/home/selector.simulation';
import { LANG_STATE } from 'features/home/constants.simulation';
import { pushEvent } from 'features/auth/constants';

const useStyles = makeStyles((theme: GlobalTheme) => ({
  listStyle: {
    backgroundSize: 'cover',
    flex: '0 0 auto',
    width: '304px',
    height: '360px',
    marginRight: '25px',
    borderRadius: '52px 32px',
  },
  titleStyle: {
    textAlign: 'left',
    fontSize: '0.9375rem',
    fontFamily: theme.fonts.regular,
    textTransform: 'uppercase',
    marginTop: '10px',
  },
  description: {
    textAlign: 'left',
    width: '80%',
    fontFamily: theme.fonts.regular,
    fontSize: '0.9375rem',
    marginBottom: '42px',
  },
  img: {
    width: '67px',
  },
  bodyContent: {
    width: '62%',
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 600px)': {
      width: '100%',
    },
  },
  button: {
    width: 'max-content',
    textTransform: 'none',
    padding: '12px 30px',
    fontFamily: theme.fonts.regular,
    fontSize: '1rem',
    borderRadius: '22px',
    marginBottom: '10px',
    '@media (max-width: 768px)': {
      padding: '12px 35px',
      float: 'right',
      margin: 'auto',
    },
  },
  rightIcon: {
    marginLeft: '15px',
    height: '15px',
  },
  rightIconAr: {
    marginLeft: '15px',
    height: '15px',
    transform: 'scaleX(-1)',
  },
  checkList: {
    marginTop: '20px',
    marginBottom: '10px',
    '@media (max-width: 450px)': {
      width: '273px',
    },
  },
}));

const NeedLeftElement = () => {
  const classes = useStyles();
  const wording: Wording = useSelector(selectWording);
  const executeScroll = () => {
    const element: any = document.getElementById('simulateur');
    element.scrollIntoView({ block: 'start', behavior: 'smooth' });
    let eventObj = {
      event: 'click_cta',
      click_label: 'simulez_votre_credit',
      element_category: 'body',
    };
    pushEvent(eventObj);
  };
  const langSelected: any =
    useSelector(getSimulationInfoByArraySelector(['lang'])) || LANG_STATE.FR;
  return (
    <div className={classes.bodyContent}>
      <Typography variant='h5' className={`${classes.titleStyle}`}>
        {wording?.needsBlockHome.subTitle}
      </Typography>
      <div className={classes.checkList}>
        <CheckElement title={wording?.needsBlockHome.checkFirstElement} />
        <CheckElement title={wording?.needsBlockHome.checkSecondElement} />
        <CheckElement title={wording?.needsBlockHome.checkThirdElement} />
        <CheckElement title={wording?.needsBlockHome.checkLastElement} />
      </div>
      <Typography variant='h5' className={`${classes.description}`}>
        {wording?.needsBlockHome.description}
      </Typography>
      <Button
        type='button'
        className={classes.button}
        variant='contained'
        color='primary'
        onClick={executeScroll}
      >
        {wording?.needsBlockHome.simulation}
        <img
          src={rightFlesh}
          alt=''
          className={
            langSelected === LANG_STATE.FR
              ? classes.rightIcon
              : classes.rightIconAr
          }
        />
      </Button>
    </div>
  );
};

export default NeedLeftElement;
