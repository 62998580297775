import { makeStyles } from '@material-ui/core/styles';
import contextbg1 from 'assets/svg/contextbg.svg';
import contextbg2 from 'assets/svg/contextbg2.svg';
import Footer from 'common/components/Footer';
import { scrollToElement } from 'common/utils/app.util';
import LoginHomePage from 'features/home/components/LoginHomePage';
import { LANG_STATE } from 'features/home/constants.simulation';
import { getSimulationInfoByArraySelector } from 'features/home/selector.simulation';
import React, { PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  rootHome: {
    backgroundImage: `url(${contextbg1}),url(${contextbg2})`,
    backgroundPosition: ' 0 0, right 25%',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#fbfbfb',
  },
  rootHomeAr: {
    backgroundPosition: ' 0 0, left 25%',
  },
  container: {
    maxWidth: 1200,
    margin: '0 auto',
    padding: '60px 30px',
  },
  footer: {
    width: '100%',
    '@media (max-width: 1024px)': {
      textAlign: 'center',
    },
  },
}));

const ContextualLayout = ({ children }: PropsWithChildren<{}>) => {
  const location = useLocation();
  /* ######## scrol to top on location change ########  */
  useEffect(() => {
    scrollToElement(undefined, 1);
  }, [location.pathname]);
  /* ######## END scrol change ######## */

  const classes = useStyles();

  const langSelected: any =
    useSelector(getSimulationInfoByArraySelector(['lang'])) || LANG_STATE.FR;

  const isFr = langSelected === LANG_STATE.FR;
  return (
    <div
      className={`${classes.rootHome} ${!isFr ? classes.rootHomeAr : ''}`}
      style={
        isFr
          ? {
              direction: 'ltr',
            }
          : {
              direction: 'rtl',
            }
      }
    >
      {/* header */}
      <LoginHomePage />
      <div className={classes.container}>{children}</div>

      <div className={classes.footer}>
        <Footer />
      </div>
    </div>
  );
};

export default ContextualLayout;
