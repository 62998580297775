import {
  Button,
  FormControl,
  FormHelperText,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { CustomCheckBox } from 'common/components/customFields/CustomCheckBox';
import { CustomDatePicker } from 'common/components/customFields/CustomDatePicker';
import { CustomInput } from 'common/components/customFields/CustomInput';
import { CustomRadioGroup } from 'common/components/customFields/CustomRadioGroup';
import Spinner from 'common/components/Spinner';
import { Wording } from 'common/wording/reducer.wording';
import {
  selectCompWording,
  selectWording,
} from 'common/wording/selectors.wording';
import { GlobalTheme } from 'features/app/app.theme';
import GeneralCondition from 'features/auth/components/GeneralCondition';
import { signUpFields } from 'features/auth/constants';
import { LANG_STATE } from 'features/home/constants.simulation';
import { getSimulationInfoByArraySelector } from 'features/home/selector.simulation';
import { getSituationInfoByArraySelector } from 'features/situation/selector.situation';
import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateFieldInformationAction,
  UpdateInformationAT,
} from './actions.updateInformation';
import { updateInformationFields } from './constants';
import { getUpdateInfoByArraySelector } from './selector.updateInformation';

const useStyles = makeStyles((theme: GlobalTheme) =>
  createStyles({
    root: {
      width: '100%',
      direction: 'ltr',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20px 0px',
      '@media (max-width: 1024px)': {
        paddingTop: '50px',
        textAlign: 'center',
      },
    },
    form: {
      maxWidth: '396px',

      '& > *': {
        width: '100%',
        display: 'flex',
        maxWidth: '396px',
      },
    },
    title: {
      textTransform: 'uppercase',
    },
    titleAr: {
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    subtitle: {
      marginTop: 10,
      marginBottom: 30,
    },
    valider: {
      backgroundColor: '#00B2BB',
      fontFamily: theme.fonts.bold,
      borderRadius: '40px',
      fontSize: '0.875rem',
      color: '#FFFFFF',
      marginTop: '20px',
      '&:hover': {
        boxShadow:
          '0px 3px 0.063em -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 0.063em 5px 0px rgba(0,0,0,0.12)',
        backgroundColor: '#00b2bbe6',
      },
    },
    checkboxLabel: {
      fontSize: '0.75rem',
    },
    conditionOfUseLink: {
      textDecoration: 'underline',
      textAlign: 'left',
      color: theme.secondary.main,
    },
  }),
);

function UpdateInformation() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const wording = useSelector(selectWording);
  const langSelected: any =
    useSelector(getSimulationInfoByArraySelector(['lang'])) || LANG_STATE.FR;

  const loader: any = useSelector(
    getSituationInfoByArraySelector(['situationSalarie', 'config', 'loader']),
  );

  const signUp: any = useSelector(
    getUpdateInfoByArraySelector(['updateInformation', 'fields']),
  );

  const errors: any = useSelector(
    getUpdateInfoByArraySelector(['updateInformation', 'errors']),
  );

  const dateFormat = signUp.birthday
    ? moment(
        signUp.birthday,
        signUp.birthday.length <= 10 ? 'DD/MM/yyyy' : undefined,
      ).format()
    : '';

  const civility = [
    {
      label: wording?.signUp?.civ_dam,
      value: 'F',
    },
    {
      label: wording?.signUp?.civ_mon,
      value: 'M',
    },
  ];

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updateFieldInformationAction(
        'updateInformation',
        e.target.name,
        e.target.value,
      ),
    );
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch({ type: UpdateInformationAT.submitInformation });
  };

  const signUpWording: Wording = useSelector(selectCompWording('signUp')) || {};
  const errorsWording: Wording = useSelector(selectCompWording('errors')) || {};
  const wordingSimulation = useSelector(selectCompWording('simulation'));

  const [openConditionOfUse, setOpenConditionOfUse] = React.useState(false);

  const handleClose = () => {
    setOpenConditionOfUse(false);
  };

  const handleValidate = () => {
    dispatch(
      updateFieldInformationAction(
        'updateInformation',
        updateInformationFields.conditionOfUse,
        true,
      ),
    );
    setOpenConditionOfUse(false);
  };

  const conditionChanged = () => {
    if (signUp.conditionOfUse) {
      dispatch(
        updateFieldInformationAction(
          'updateInformation',
          updateInformationFields.conditionOfUse,
          false,
        ),
      );
    } else {
      setOpenConditionOfUse(true);
    }
  };

  return (
    <div className={classes.root}>
      <Spinner loader={loader} />
      <GeneralCondition
        open={openConditionOfUse}
        onClose={handleClose}
        onValidate={handleValidate}
      />
      <Typography
        variant='h3'
        className={
          langSelected === LANG_STATE.FR ? classes.title : classes.titleAr
        }
      >
        {signUpWording.title}
      </Typography>
      <Typography variant='h5' className={classes.subtitle}>
        {signUpWording.subtitle}
      </Typography>
      <form
        className={classes.form}
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit}
        data-test-id='submit-form'
      >
        <CustomRadioGroup
          label={signUpWording.civility}
          name={updateInformationFields.civility}
          onChange={handleChange}
          value={signUp.civility}
          listOfElements={civility}
          error={errorsWording[errors[updateInformationFields.civility]]}
        />
        <CustomInput
          value={signUp.firstName}
          name={updateInformationFields.firstName}
          onChange={handleChange}
          placeholder={signUpWording.firstName}
          error={
            errors[updateInformationFields.firstName]
              ? errorsWording[errors[updateInformationFields.firstName][0]]
              : null
          }
        />
        <CustomInput
          value={signUp.lastName}
          name={updateInformationFields.lastName}
          onChange={handleChange}
          placeholder={signUpWording.lastName}
          error={
            errors[updateInformationFields.lastName]
              ? errorsWording[errors[updateInformationFields.lastName][0]]
              : null
          }
        />
        <CustomInput
          value={signUp.email}
          name={updateInformationFields.email}
          onChange={handleChange}
          disabled={true}
          placeholder={signUpWording.email}
          error={
            errors[updateInformationFields.email]
              ? errorsWording[errors[updateInformationFields.email][0]]
              : null
          }
        />
        <CustomDatePicker
          isMajor={true}
          disabled={true}
          name={signUpFields?.birthday}
          value={dateFormat}
          onChange={handleChange}
          format='dd/MM/yyyy'
          placeholder={wordingSimulation?.datePlaceholder}
          hasDefaultBorder={true}
          isSignUp={false}
        />
        <CustomInput
          value={signUp.employerName}
          name={wordingSimulation?.employeur}
          onChange={handleChange}
          disabled={true}
          placeholder={wordingSimulation?.nameOtherEmployeHolder}
        />
        {/* show profession if exist */}
        {signUp?.formattedProfession && (
          <CustomInput
            value={signUp.formattedProfession}
            name={wordingSimulation?.formattedProfession}
            disabled={true}
            placeholder={wordingSimulation?.profession}
          />
        )}

        <FormControl error={!!errors[updateInformationFields.conditionOfUse]}>
          <CustomCheckBox
            onChange={conditionChanged}
            name={updateInformationFields.conditionOfUse}
            label={
              <Typography variant='subtitle1' className={classes.checkboxLabel}>
                {signUpWording.conditionOfUse}
                <span className={classes.conditionOfUseLink}>
                  {signUpWording.conditionOfUseLink}
                </span>
              </Typography>
            }
            checked={signUp.conditionOfUse}
          />
          <CustomCheckBox
            onChange={handleChange}
            name={updateInformationFields.promotionalOffers}
            label={
              <Typography variant='subtitle1' className={classes.checkboxLabel}>
                {signUpWording.promotionalOffers}
              </Typography>
            }
            checked={signUp.promotionalOffers}
          />
          <FormHelperText>
            {errors[updateInformationFields.conditionOfUse]
              ? errorsWording[errors[updateInformationFields.conditionOfUse][0]]
              : null}
          </FormHelperText>
        </FormControl>

        <Button type='submit' className={classes.valider}>
          {signUpWording.validate}
        </Button>
      </form>
    </div>
  );
}

export default UpdateInformation;
