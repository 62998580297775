import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { GlobalTheme } from 'features/app/app.theme';

const useStyles = makeStyles((theme: GlobalTheme) => ({
  title: {
    color: theme.secondary.main,
    fontFamily: theme.fonts.bold,
    fontSize: '2rem',
    marginTop: '50px',
  },
}));

interface IBlocTitle {
  title: string;
}
const BlocTitle = ({ title }: IBlocTitle) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={12}>
      <Typography variant='h2' component='h2' className={classes.title}>
        {title}
      </Typography>
    </Grid>
  );
};

export default BlocTitle;
