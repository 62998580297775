import { createStyles } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import { GlobalTheme } from 'features/app/app.theme';
import React from 'react';
import { CustomInput } from './CustomInput';
import FormLabel from '@material-ui/core/FormLabel';
import Tooltip from 'common/components/customFields/CustomTooltip';

const useStyles = makeStyles((theme: GlobalTheme) =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    FieldStyle: {
      width: '100%',
      margin: '0',

      '& .MuiFormControl-root': {
        padding: '5px',
      },
      '& .MuiSelect-select': {
        borderRadius: '25px',
        color: theme.secondary.title,
        fontSize: '0.9375rem',
        '&:focus': {
          backgroundColor: '#FFF',
        },
        padding: '12px 26px',
        textAlign: 'left',
        display: 'flex',
        alignItems: 'center',
      },
      '& .MuiFormLabel-root': {
        borderColor: theme.secondary.title,
        color: theme.secondary.title,
        fontSize: '0.9375rem',
        textAlign: 'left',
        paddingLeft: '10px',
        width: '85%',
        '@media (max-width: 768px)': {
          fontSize: '0.875rem',
        },
        '@media (max-width: 540px)': {
          fontSize: '0.75rem',
        },
        paddingTop: '3px',
        display: 'flex',
        alignItems: 'center',
      },
      '& .MuiInputBase-root': {
        color: theme.secondary.title,
        fontSize: '0.75rem',
        width: '100% !important',
      },
    },
    label: {
      fontSize: '0.75rem',
      transform: 'unset',
      padding: '13px 13px 13px 25px',
    },
    globalTooltip: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    tooltip: {
      padding: '0',
      margin: 'auto',
      marginLeft: '10px',
    },
    formLabel: {
      fontSize: '0.865rem',
      textAlign: 'left',
      fontFamily: theme.fonts.medium,
      color: theme.secondary.title,
      marginBottom: '7px',
    },
    formLabelTooltip: {
      fontSize: '0.865rem',
      textAlign: 'left',
      fontFamily: theme.fonts.medium,
      color: theme.secondary.title,
    },
    selectElement: {
      fontSize: '0.875rem',
      color: theme.secondary.title,
    },
  }),
);

export interface ListDown {
  value?: any;
  label?: string;
}

interface PropsSelectDown {
  label?: string;
  name?: string;
  list?: ListDown[];
  value?: string;
  onChange?: (e: any) => void;
  error?: string | null;
  formLabel?: string;
  tooltip?: string;
}
export default function SelectDown({
  label = '',
  name = '',
  list = [],
  value = '',
  onChange = () => ({}),
  error = null,
  formLabel = '',
  tooltip = '',
}: PropsSelectDown) {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.globalTooltip}>
        {formLabel && (
          <FormLabel
            className={tooltip ? classes.formLabelTooltip : classes.formLabel}
            component='legend'
          >
            {formLabel}
            {tooltip && <Tooltip content={tooltip} />}
          </FormLabel>
        )}
      </div>
      <div className={classes.container}>
        <CustomInput
          name={name}
          select
          label={value === '' ? label : ''}
          className={classes.FieldStyle}
          value={value}
          onChange={onChange}
          error={error}
          placeholder={label}
          hasDefaultBorder={true}
        >
          {list.map((element?: any) => (
            <MenuItem
              key={element.value}
              value={element.value}
              className={classes.selectElement}
            >
              {element.label}
            </MenuItem>
          ))}
        </CustomInput>
      </div>
    </div>
  );
}
