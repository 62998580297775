// @ts-nocheck
import React from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';
import { makeStyles, Theme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import info from 'assets/svg/info.svg';
import { GlobalTheme } from 'features/app/app.theme';

const useStyles = makeStyles((theme: GlobalTheme & Theme) => ({
  tooltip: {
    fontSize: '0.75rem',
    borderRadius: '4px',
    padding: '4px 8px',
    maxWidth: '300px',
    wordWrap: 'break-word',
    backgroundColor: '#556584',
    fontFamily: theme.fonts.regular,
    textAlign: (props) => props.textAlign,
    color: '#FFF',
    lineHeight: '1.4em',
    margin: 'auto',
    '& b': {
      fontWeight: 600,
    },
  },
}));

function Tooltip(props: {
  content: string | JSX.Element;
  children?: JSX.Element;
  textAlign?: string;
}) {
  const classes = useStyles({ textAlign: props.textAlign || 'center' });
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    placement: 'top',
  });

  return (
    <>
      <IconButton aria-label='info' ref={setTriggerRef}>
        {!props.children ? <img src={info} alt='Tooltip' /> : props.children}
      </IconButton>

      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className: `tooltip-container ${classes.tooltip}`,
          })}
        >
          {props.content}
          <div
            {...getArrowProps({
              className: 'tooltip-arrow',
            })}
          />
        </div>
      )}
    </>
  );
}

export default Tooltip;
