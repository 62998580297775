import {
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { GlobalTheme } from 'features/app/app.theme';
import React from 'react';
import { AccountTypes } from 'features/auth/constants';
import salarie from 'assets/svg/salarie.svg';
import fonctionnaire from 'assets/svg/fonctionnaire.svg';
import pro from 'assets/svg/pro.svg';
import retraite from 'assets/svg/retraite.svg';
import salarieSelected from 'assets/svg/salarieSelected.svg';
import fonctionnaireSelected from 'assets/svg/fonctionnaireSelected.svg';
import proSelected from 'assets/svg/proSelected.svg';
import retraiteSelected from 'assets/svg/retraiteSelected.svg';

const useStyles = makeStyles((theme: GlobalTheme) => ({
  root: {
    padding: '0',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '15px',
    flexWrap: 'nowrap',
    '@media (max-width: 768px)': {
      flexWrap: 'wrap',
      padding: '0',
      margin: '10px 0',
    },
  },
  label: {
    fontSize: '0.75rem',
    fontFamily: theme.fonts.medium,
    margin: '8px',
    color: '#606784',
    '@media (max-width: 768px)': {
      margin: '10px',
    },
  },
  rootBloc: {
    width: '18.8%',
    height: '100px',
    padding: '15px 10px',
    border: '1px solid #d6d6d6',
    borderRadius: '15px',
    margin: '5px',
    display: 'flex',
    flexDirection: 'column',
    '& .MuiRadio-root': {
      padding: '0',
    },
    '@media (max-width: 918px)': {
      width: '17%',
    },
    '@media (max-width: 768px)': {
      width: '42%',
      margin: '5px 0',
      height: '110px',
    },
    '@media (max-width: 600px)': {
      width: '100%',
      margin: '5px 0',
      height: '40px',
      padding: '5px',
      flexDirection: 'row',
    },
  },
  icon: {
    width: '45px',
    '@media (max-width: 768px)': {
      width: '65px',
    },
    '@media (max-width: 600px)': {
      width: '40px',
    },
  },
  iconContainer: {
    borderRadius: '12px',
    border: `1px solid ${theme.primary.gray}`,
    '@media (max-width: 768px)': {
      border: 0,
    },
  },
}));

interface PropsCustomRadioGroup {
  label?: string;
  value?: string;
  name: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string | null;
  type?: string;
  placeholder?: string;
  row?: boolean;
  listOfElements: { value: any }[];
  wording?: any;
  className?: any;
}

export const ClientTypeSelect = ({
  value = '',
  onChange = () => ({}),
  error = null,
  label = '',
  name = '',
  listOfElements = [],
  wording,
  row = true,
  className,
  ...rest
}: PropsCustomRadioGroup) => {
  const classes = useStyles();
  const getWordingLabel = (element: any) => {
    return isEmpty(wording)
      ? element.label
      : element.value === AccountTypes.salarie
      ? wording?.salarie
      : element.value === AccountTypes.fonctionnaire
      ? wording?.fonctionnaire
      : element.value === AccountTypes.retraite
      ? wording?.retraite
      : wording?.professionnel;
  };
  const getIcon = (element: any) => {
    return element.value === AccountTypes.salarie
      ? salarie
      : element.value === AccountTypes.fonctionnaire
      ? fonctionnaire
      : element.value === AccountTypes.retraite
      ? retraite
      : pro;
  };
  const getIconSelected = (element: any) => {
    return element.value === AccountTypes.salarie
      ? salarieSelected
      : element.value === AccountTypes.fonctionnaire
      ? fonctionnaireSelected
      : element.value === AccountTypes.retraite
      ? retraiteSelected
      : proSelected;
  };

  return (
    <div>
      <RadioGroup
        row
        aria-label='position'
        name={name}
        onChange={onChange}
        value={value}
        {...rest}
        classes={{ root: classes.root }}
      >
        {listOfElements.map((element: any) => (
          <FormControlLabel
            key={element.value}
            value={element.value}
            classes={{ root: classes.rootBloc, label: classes.label }}
            style={{
              border:
                element.value === value
                  ? '2px solid #095098'
                  : '1px solid #cfcfcf',
            }}
            control={
              <Radio
                size={'small'}
                className={classes.iconContainer}
                icon={
                  <img
                    className={classes.icon}
                    src={getIcon(element)}
                    alt={'select'}
                  />
                }
                checkedIcon={
                  <img
                    className={classes.icon}
                    src={getIconSelected(element)}
                    alt={'select'}
                  />
                }
              />
            }
            label={getWordingLabel(element)}
          />
        ))}
      </RadioGroup>

      {error && <FormHelperText>{error}</FormHelperText>}
    </div>
  );
};
