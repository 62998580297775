import React, { useEffect } from 'react';
import { createStyles, Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Wording } from 'common/wording/reducer.wording';
import { selectCompWording } from 'common/wording/selectors.wording';
import { GlobalTheme } from 'features/app/app.theme';
import { useSelector, useDispatch } from 'react-redux';
import { CongratulationAT } from './actions.congratulation';
import { getSessionStorage } from 'common/utils/sessionStorage.util';
import { pushEvent, pushPageView } from 'features/auth/constants';

const useStyles = makeStyles((theme: GlobalTheme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      justifyContent: 'flex-start',
      margin: 'auto',
      '@media (max-width: 1024px)': {
        margin: 'auto',
      },
    },
    title: {
      textTransform: 'uppercase',
      margin: '36px 10px',
      color: theme.primary.main,
    },
    message: {
      textAlign: 'center',
      lineHeight: 1.2,
      '@media (max-width: 450px)': {
        fontSize: '1.125rem',
      },
    },
    lastMessage: {
      textAlign: 'center',
      marginTop: '10px',
    },
    buttonContinueClic: {
      margin: '11px auto',
      padding: '8px 103px',
      fontFamily: theme.fonts.bold,
      '@media (max-width: 450px)': {
        padding: '8px 60px',
      },
    },
    buttonClic: {
      margin: '11px auto',
      padding: '8px 47px',
      color: theme.primary.title,
      backgroundColor: 'unset',
      fontFamily: theme.fonts.bold,
      border: `0.063em solid ${theme.primary.title}`,
      '&:hover': {
        backgroundColor: 'unset',
      },
      '@media (max-width: 450px)': {
        padding: '8px 26px',
      },
      '@media (max-width: 320px)': {
        padding: '8px 10px',
      },
    },
    contentBody: {
      margin: 'auto',
      marginBottom: '230px',
      '@media (max-width: 450px)': {
        width: '80%',
      },
    },
    buttonStyle: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '35px',
    },
  }),
);

const Congratulation = () => {
  const classes = useStyles();
  const wording: Wording = useSelector(selectCompWording('congratulation'));
  const situationSalarie: any = getSessionStorage('situationSalarie');

  const dispatch = useDispatch();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let eventObj = {
      event: 'click_cta',
      click_label: 'suivre_ma_demande',
      element_category: 'body',
    };
    pushEvent(eventObj);
    dispatch({ type: CongratulationAT.submitCongratulation });
  };

  const trackClickGTM = () => {
    let eventObj = {
      event: 'click_cta',
      click_label: 'ouvrir_mon_compte_bancaire_en_ligne',
      element_category: 'body',
    };
    pushEvent(eventObj);
  };

  useEffect(() => {
    pushPageView('step_6_soumission_de_la_demande');
  }, []);

  return (
    <div className={classes.root}>
      <form onSubmit={handleSubmit} className={classes.contentBody}>
        <Typography variant='h1' className={classes.title}>
          {wording?.congratulation}
        </Typography>
        <Typography variant='body2' className={classes.message}>
          {wording?.FirstContent}
        </Typography>
        <Typography variant='body2' className={classes.message}>
          {wording?.secondContent}
        </Typography>
        <Typography variant='body2' className={classes.lastMessage}>
          {wording?.lastContent}
        </Typography>
        <Typography variant='body2' className={classes.lastMessage}>
          {wording?.followFileOnline}
        </Typography>
        <div className={classes.buttonStyle}>
          {!isEmpty(situationSalarie) &&
          situationSalarie.openAccountBOA &&
          !situationSalarie.clientBOA ? (
            <Button
              variant='contained'
              color='primary'
              className={classes.buttonClic}
              type='button'
              target='_blank'
              rel='noopener noreferrer'
              href={wording?.href}
              onClick={trackClickGTM}
            >
              {wording?.createAcountOnline}
            </Button>
          ) : (
            ''
          )}
          <Button
            variant='contained'
            color='primary'
            className={classes.buttonContinueClic}
            type='submit'
          >
            {wording?.buttoncontinue}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Congratulation;
