import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from 'common/components/customFields/CustomTooltip';
import Typography from '@material-ui/core/Typography';
import { CustomInput } from 'common/components/customFields/CustomInput';
import CustomizedSwitches from 'common/components/customFields/CustomizedSwitches';
import InputNumber from 'common/components/customFields/InputNumber';
import SelectDown from 'common/components/customFields/SelectDown';
import { Wording } from 'common/wording/reducer.wording';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SituationAT, updateFieldAction } from '../actions.situation';
import {
  listActivityPro,
  listIncoms,
  listOfJobNature,
  seniorityByMonth,
  seniorityByYear,
  situationProFields,
} from '../constants.situation';
import { getSituationInfoByArraySelector } from '../selector.situation';
import { GlobalTheme } from 'features/app/app.theme';

const useStyles = makeStyles((theme: GlobalTheme) => ({
  groupForm: {
    width: '100%',
    backgroundColor: '#FFF',
    borderRadius: '23px',
  },
  labelRenseing: {
    fontSize: '0.875rem',
    textAlign: 'left',
    fontFamily: theme.fonts.medium,
    color: theme.secondary.title,
    opacity: 1,
    marginBottom: '17px',
  },
  labelRenseingTooltip: {
    fontSize: '0.875rem',
    textAlign: 'left',
    fontFamily: theme.fonts.medium,
    color: theme.secondary.title,
    opacity: 1,
  },
  groupFormHeader: {
    padding: '45px',
  },
  groupFormFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonSubmitRenseing: {
    borderRadius: '14px 0',
    textTransform: 'inherit',
    padding: '14px 50px',
    fontFamily: theme.fonts.regular,
    fontSize: '0.6875rem',
    '&:hover': {
      backgroundColor: '#F93276',
    },
  },
  label: {
    fontSize: '0.875rem',
    letterSpacing: '0.03333em',
    lineHeight: 1.66,
    color: theme.secondary.title,
  },

  textfieldRenseing: {
    width: '100%',
    marginTop: '0',
    color: theme.secondary.title,
    cursor: 'text',
    display: 'inline-flex',
    position: 'relative',
    fontSize: '1rem',
    boxSizing: 'border-box',
    fontFamily: theme.fonts.medium,
    lineHeight: '1.1875em',
    '&::placeholder ': {
      fontSize: '0.625rem',
    },
  },
  rootRadio: {
    color: theme.secondary.title,
  },
  rootTextField: {
    color: theme.secondary.title,
    border: '0.063em solid',
    borderRadius: '20px',
    width: '100%',
    padding: '4px 4px 4px 24px',
  },
  placeholder: {
    fontSize: '0.75rem',
  },
  globalTooltip: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  value: {
    width: '50%',
  },
  radioButton: {
    color: theme.secondary.title,
  },
}));

interface Props {
  wording: Wording;
}

function Pro({ wording }: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const wordingPro = wording?.Pro;
  const errorsWording = wording?.errors;

  const situationPro: any = useSelector(
    getSituationInfoByArraySelector(['situationPro']),
  );
  const errors: any = useSelector(
    getSituationInfoByArraySelector(['situationPro', 'errors']),
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updateFieldAction('situationPro', event.target.name, event.target.value),
    );
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch({ type: SituationAT.submitSituation });
  };

  return (
    <form onSubmit={handleSubmit} className={classes.groupForm}>
      <div className={classes.groupFormHeader}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <div className={classes.globalTooltip}>
              <FormLabel
                className={classes.labelRenseingTooltip}
                component='legend'
              >
                {wordingPro?.activityPro}
              </FormLabel>
              <Tooltip content={wordingPro?.infoActivityPro} />
            </div>

            <SelectDown
              list={listActivityPro}
              label={listActivityPro[0].label}
              name={situationProFields.activityPro}
              value={situationPro.activityPro}
              onChange={handleChange}
            />
          </Grid>
          {situationPro.activityPro === 'liberale' && (
            <Grid item xs={12} sm={6}>
              <div className={classes.globalTooltip}>
                <FormLabel
                  className={classes.labelRenseingTooltip}
                  component='legend'
                >
                  {wordingPro?.jobNature}
                </FormLabel>
                <Tooltip content={wordingPro?.infoJobNature} />
              </div>
              <SelectDown
                list={listOfJobNature}
                label={wordingPro?.jobNaturePlaceHolder}
                name={situationProFields.jobNature}
                value={situationPro.jobNature}
                onChange={handleChange}
                error={
                  errors[situationProFields.jobNature]
                    ? errorsWording[errors[situationProFields.jobNature]]
                    : null
                }
              />
            </Grid>
          )}

          <Grid item sm={12}>
            <FormLabel className={classes.labelRenseing} component='legend'>
              {wordingPro?.activitySeniority}
            </FormLabel>
            <RadioGroup
              row
              aria-label='position'
              name={situationProFields.activitySeniority}
              onChange={handleChange}
              value={situationPro.activitySeniority}
            >
              {seniorityByYear.map((element) => (
                <FormControlLabel
                  key={element.value}
                  value={element.value}
                  control={<Radio classes={{ root: classes.rootRadio }} />}
                  label={
                    <Typography
                      variant='caption'
                      className={classes.radioButton}
                    >
                      {element.label}
                    </Typography>
                  }
                />
              ))}
            </RadioGroup>
          </Grid>

          <Grid item xs={12} sm={6}>
            <div className={classes.globalTooltip}>
              <FormLabel
                className={classes.labelRenseingTooltip}
                component='legend'
              >
                {wordingPro?.bankingSeniority}
              </FormLabel>
              <Tooltip content={wordingPro?.infoBankingSeniority} />
            </div>
            <RadioGroup
              row
              aria-label='position'
              name={situationProFields.bankingSeniority}
              onChange={handleChange}
              value={situationPro.bankingSeniority}
            >
              {seniorityByYear.map((element) => (
                <FormControlLabel
                  key={element.value}
                  value={element.value}
                  control={<Radio classes={{ root: classes.rootRadio }} />}
                  label={
                    <Typography
                      variant='caption'
                      className={classes.radioButton}
                    >
                      {element.label}
                    </Typography>
                  }
                />
              ))}
            </RadioGroup>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormLabel className={classes.labelRenseing} component='legend'>
              {wordingPro?.seniorityBankOfAfrica}
            </FormLabel>
            <RadioGroup
              row
              aria-label='position'
              name={situationProFields.seniorityBankOfAfrica}
              onChange={handleChange}
              value={situationPro.seniorityBankOfAfrica}
            >
              {seniorityByMonth.map((element) => (
                <FormControlLabel
                  key={element.value}
                  value={element.value}
                  control={<Radio classes={{ root: classes.rootRadio }} />}
                  label={
                    <Typography
                      variant='caption'
                      className={classes.radioButton}
                    >
                      {element.label}
                    </Typography>
                  }
                />
              ))}
            </RadioGroup>
          </Grid>

          <Grid item xs={12} sm={6}>
            <InputNumber
              formLabel={wordingPro?.monthlyIncome}
              tooltip={wordingPro?.infoMonthlyIncome}
              onChange={handleChange}
              name={situationProFields.monthlyIncome}
              value={situationPro.monthlyIncome}
              placeholder={wordingPro?.placeHolderMonthlyIncome}
              suffix={''}
              endElement={wordingPro?.dhs}
              error={
                errors[situationProFields.monthlyIncome]
                  ? errorsWording[errors[situationProFields.monthlyIncome]]
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={classes.globalTooltip}>
              <FormLabel
                className={classes.labelRenseingTooltip}
                component='legend'
              >
                {wordingPro?.additionalIncome}
              </FormLabel>
              <Tooltip content={wordingPro?.infoAdditionalIncome} />
            </div>
            <CustomizedSwitches
              labelPrefix={wordingPro?.no}
              labelSufix={wordingPro?.yes}
              checked={situationPro.additionalIncome}
              onChange={handleChange}
              name={situationProFields.additionalIncome}
            />
          </Grid>
          {situationPro.additionalIncome && (
            <>
              <Grid item xs={12} sm={6}>
                <FormLabel className={classes.labelRenseing} component='legend'>
                  {wordingPro?.additionalIncomeType}
                </FormLabel>

                <SelectDown
                  list={listIncoms}
                  label={wordingPro?.additionalIncomeTypePlaceholder}
                  name={situationProFields.additionalIncomeType}
                  value={situationPro.additionalIncomeType}
                  onChange={handleChange}
                  error={
                    errors[situationProFields.additionalIncomeType]
                      ? errorsWording[
                          errors[situationProFields.additionalIncomeType]
                        ]
                      : null
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputNumber
                  formLabel={wordingPro?.amountAdditionalIncome}
                  onChange={handleChange}
                  placeholder={wordingPro?.placeHolderAmountAdditionalIncome}
                  value={situationPro.amountAdditionalIncome}
                  name={situationProFields.amountAdditionalIncome}
                  suffix={''}
                  endElement={wordingPro?.dhs}
                  error={
                    errors[situationProFields.amountAdditionalIncome]
                      ? errorsWording[
                          errors[situationProFields.amountAdditionalIncome]
                        ]
                      : null
                  }
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={6}>
            <FormLabel className={classes.labelRenseing} component='legend'>
              {wordingPro?.totalCreditAmount}
            </FormLabel>
            <CustomInput
              value={situationPro.totalCreditAmount}
              name={situationProFields.totalCreditAmount}
              onChange={handleChange}
              placeholder={wordingPro?.totalCreditAmountPlaceHolder}
              className={classes.textfieldRenseing}
              error={
                errors[situationProFields.totalCreditAmount]
                  ? errorsWording[errors[situationProFields.totalCreditAmount]]
                  : null
              }
              InputProps={{
                classes: {
                  input: classes.placeholder,
                  root: classes.rootTextField,
                },
              }}
            />
          </Grid>
        </Grid>
      </div>
      <div className={classes.groupFormFooter}>
        <Button
          type='submit'
          className={classes.buttonSubmitRenseing}
          variant='contained'
          color='secondary'
        >
          {wordingPro?.submitRenseign}
        </Button>
      </div>
    </form>
  );
}

export default Pro;
