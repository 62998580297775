import { Button, Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import checklist from 'assets/svg/checklist.svg';
import previous from 'assets/svg/previous.svg';
import { Wording } from 'common/wording/reducer.wording';
import { selectCompWording } from 'common/wording/selectors.wording';
import { GlobalTheme } from 'features/app/app.theme';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CaseTrackingElement from './component/CaseTrackingElement';
import { caseStatus } from './constant';
import { getCaseTrackingInfoByArraySelector } from './selector.caseTracking';
import { useHistory } from 'react-router-dom';
import { updateFieldUpAction } from '../upload/actions.upload';
import { getPropositionInfoByArraySelector } from 'features/proposition/selector.proposition';
import { getSessionStorage } from 'common/utils/sessionStorage.util';
import { getSimulationInfoByArraySelector } from 'features/home/selector.simulation';
import { LANG_STATE } from 'features/home/constants.simulation';
import { pushPageView } from 'features/auth/constants';

const useStyles = makeStyles((theme: GlobalTheme) => ({
  root: {
    width: '80%',
    margin: '0 auto',
    direction: 'ltr',
    '@media (max-width: 980px)': {
      width: '100%',
    },
  },
  header: {
    width: '86%',
    display: 'flex',
    marginBottom: 27,
    flexDirection: 'column',
  },
  descriptionHeader: {
    width: '80%',
    display: 'flex',
    textAlign: 'left',
  },
  title: {
    textTransform: 'uppercase',
    margin: 'auto 15px',
    color: theme.primary.title,
    '@media (max-width: 540px)': {
      fontSize: '1.25rem',
    },
  },
  description: {
    textAlign: 'left',
    fontSize: '0.9375rem',
  },
  checklistImg: {
    display: 'block',
    marginBottom: '15px',
  },
  caseDetail: {
    width: '100%',
    border: '0.063em solid #FFF',
    borderRadius: '22px',
    backgroundColor: '#FFF',
  },
  returnButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0 35px 25px 45px',
  },
  returnButtonSubmit: {
    color: theme.secondary.title,
    textTransform: 'none',
    fontSize: '1rem',
    fontFamily: theme.fonts.medium,
  },
  rightIconButton: {
    marginRight: '19px',
  },
  groupFormHeader: {
    padding: '20px 45px',
    '& .MuiListItem-root': {
      '&:last-child': {
        borderBottom: 'none',
      },
    },
    '@media (max-width: 450px)': {
      padding: '5px',
    },
  },
  stepValid: {
    color: '#00B2BB',
  },
  stepNotValid: {
    color: '#F93276',
  },
  grey: {
    color: theme.secondary.title,
  },
  rotate: {
    transform: 'rotate(-180deg)',
  },
}));

function CaseTracking() {
  const wording: Wording =
    useSelector(selectCompWording('suivieDossier')) || {};
  const classes = useStyles();
  const dispatch = useDispatch();
  const caseTracking: any = useSelector(
    getCaseTrackingInfoByArraySelector(['caseTracking']),
  );
  const proposition: any = useSelector(
    getPropositionInfoByArraySelector(['proposition', 'fields']),
  );
  const statutDossier = getSessionStorage('statutDossier');

  const langSelected: any =
    useSelector(getSimulationInfoByArraySelector(['lang'])) || LANG_STATE.FR;

  const step = Number(statutDossier)
    ? Number(statutDossier)
    : Number(caseTracking.step);
  const history = useHistory();
  const returnClick = () => {
    if (
      proposition.typeClient &&
      proposition.product &&
      proposition.clientBOA
    ) {
      dispatch(updateFieldUpAction('upload', 'type', proposition.typeClient));
      dispatch(updateFieldUpAction('upload', 'product', proposition.product));
      dispatch(
        updateFieldUpAction('upload', 'clientBOA', proposition.clientBOA),
      );
    }
    history.push('/upload-documents');
  };

  useEffect(() => {
    pushPageView('suivie_de_la_demande');
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.descriptionHeader}>
          <img className={classes.checklistImg} alt={''} src={`${checklist}`} />
          <Typography variant='h1' className={classes.title}>
            {wording?.title}
          </Typography>
        </div>
      </div>
      <div className={classes.caseDetail}>
        <List className={classes.groupFormHeader}>
          {
            // eslint-disable-next-line
            Array.from(Array(14), (e, item: any) => {
              /*
                  Suivi de la demande en se basant sur le numéro de step fourni 
  
              step=1,2,3 : Demande envoyée 
              step = 4,5 : Verification dossier (Incomplet/Encours)
              tep = 7,8 : Demande En cours d'etude
              step = 6  : Etude du dossier en cours 
              step = 9  : Accordé apres etude de dossier
              step  = 10  rejetée après etude du dossier(decision du credit)
              step  = 11  Contrat envoyé
              step  = 12,13  Dosssier Cloturé  
               */
              if (item === step) {
                return (
                  <>
                    <CaseTrackingElement
                      name='CaseTrackingElement_1'
                      key={item}
                      step={item}
                      title={wording[caseStatus[item].title]}
                      icon={caseStatus[item].icon}
                      statusFolder={wording[caseStatus[item].status]}
                      className={
                        item === 9
                          ? classes.stepValid
                          : item === 10
                          ? classes.stepNotValid
                          : classes.grey
                      }
                    />
                    {step === 6 && ( // Etude du dossier en cours
                      <CaseTrackingElement
                        name='CaseTrackingElement_2'
                        step={item}
                        title={wording?.demandeStudyFile}
                        icon={'defaultIcon'}
                        statusFolder={wording?.demandeCheckingComment}
                      />
                    )}
                    {step === 10 && ( // Demande rejetée après etude du dossier
                      <CaseTrackingElement
                        name='CaseTrackingElement_3'
                        step={item}
                        title={wording?.demandeDecision}
                        icon={'defaultIcon'}
                        statusFolder={wording[caseStatus[item].status]}
                        className={classes.stepNotValid}
                      />
                    )}
                  </>
                );
              } else if (
                item <= step &&
                (step === 4 ||
                  step === 6 ||
                  step === 9 ||
                  step === 11 ||
                  step === 12 ||
                  step === 13) &&
                (item === 2 || item === 6 || item === 9)
              ) {
                return (
                  <>
                    <CaseTrackingElement
                      name='CaseTrackingElement_4'
                      key={item}
                      step={item}
                      title={wording[caseStatus[item].title]}
                      statusFolder={wording[caseStatus[item].status]}
                      icon={'valideIcon'}
                      className={
                        item === 9
                          ? classes.stepValid
                          : item === 10
                          ? classes.stepNotValid
                          : classes.grey
                      }
                    />
                    {(step === 11 || step === 12 || step === 13) && //  Demande acceptée et contrat envoyé
                      item === 9 && (
                        <CaseTrackingElement
                          name='CaseTrackingElement_5'
                          step={item}
                          title={wording?.demandeDecision}
                          icon={'valideIcon'}
                          statusFolder={wording?.demandeStudyFileAccepted}
                        />
                      )}
                  </>
                );
              } else if (
                item <= step &&
                (step === 5 || step === 7 || step === 8) &&
                item === 2
              ) {
                return (
                  <CaseTrackingElement
                    name='CaseTrackingElement_6'
                    key={item}
                    step={item}
                    title={wording[caseStatus[item].title]}
                    statusFolder={wording[caseStatus[item].status]}
                    icon={'valideIcon'}
                    className={
                      item === 9
                        ? classes.stepValid
                        : item === 10
                        ? classes.stepNotValid
                        : classes.grey
                    }
                  />
                );
              } else if (
                item <= step &&
                step === 10 &&
                (item === 2 || item === 6)
              ) {
                return (
                  <CaseTrackingElement
                    name='CaseTrackingElement_7'
                    key={item}
                    step={item}
                    title={wording[caseStatus[item].title]}
                    statusFolder={wording[caseStatus[item].status]}
                    icon={'valideIcon'}
                    className={
                      item === 9
                        ? classes.stepValid
                        : item === 10
                        ? classes.stepNotValid
                        : classes.grey
                    }
                  />
                );
              } else if (
                item > step &&
                (item === 4 ||
                  item === 9 ||
                  item === 10 ||
                  item === 11 ||
                  item === 12 ||
                  item === 13)
              ) {
                return (
                  <>
                    {step === 6 && item === 11 && (
                      <CaseTrackingElement
                        name='CaseTrackingElement_8'
                        key={item}
                        step={item}
                        title={wording[caseStatus[item].title]}
                        statusFolder={''}
                        icon={'defaultIcon'}
                        className={
                          item === 9
                            ? classes.stepValid
                            : item === 10
                            ? classes.stepNotValid
                            : classes.grey
                        }
                      />
                    )}
                    {step !== 6 &&
                      (item === 4 || item === 9 || item === 11) && (
                        <CaseTrackingElement
                          name='CaseTrackingElement_9'
                          key={item}
                          step={item}
                          title={wording[caseStatus[item].title]}
                          statusFolder={''}
                          icon={'defaultIcon'}
                          className={
                            item === 9
                              ? classes.stepValid
                              : item === 10
                              ? classes.stepNotValid
                              : classes.grey
                          }
                        />
                      )}
                    {item === 10 && (
                      <CaseTrackingElement
                        name='CaseTrackingElement_10'
                        key={item}
                        step={item}
                        title={wording?.demandeDecision}
                        statusFolder={''}
                        icon={'defaultIcon'}
                        className={classes.stepValid}
                      />
                    )}
                  </>
                );
              }
            })
          }
        </List>
        {(step === 1 || step === 2 || step === 3 || step === 4) && ( // To upload documents
          <div className={classes.returnButton}>
            <Button
              className={classes.returnButtonSubmit}
              onClick={returnClick}
            >
              <img
                src={previous}
                alt=''
                className={`${classes.rightIconButton} ${
                  langSelected === LANG_STATE.AR ? classes.rotate : ''
                }`}
              />
              {wording?.btnRetour}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default CaseTracking;
