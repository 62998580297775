import { ThemeOptions } from '@material-ui/core';
import './fonts.css';

export const fonts = {
  regular: 'tajawal-medium',
  bold: 'tajawal-bold',
  medium: 'tajawal-medium',
};

interface Colors {
  [key: string]: string;
}

export const primary: Colors = {
  main: '#00B2BB',
  title: '#003D7C',
  gray: '#E7E9F1',
  background: '#e0e0e0',
  white: '#FFF',
};

export const secondary: Colors = {
  main: '#F93276',
  title: '#556584',
};

export type GlobalTheme = ThemeOptions & {
  fonts: { [key: string]: string };
} & { primary: Colors } & { secondary: Colors };

const theme: GlobalTheme = {
  primary,
  secondary,
  fonts,
  typography: {
    fontFamily: fonts.regular,
    h1: {
      fontSize: '1.75rem',
      color: primary.main,
      fontFamily: fonts.bold,
    },
    h2: {
      fontSize: '1.75rem',
      color: primary.title,
    },
    h3: {
      fontSize: '1.5rem',
      color: primary.title,
    },
    h4: {
      fontSize: '1.25rem',
      color: secondary.title,
    },
    h5: {
      fontSize: '0.9375rem',
      color: secondary.title,
    },
    h6: {
      fontSize: '0.75rem',
      color: secondary.title,
    },
    body2: {
      fontSize: '1.5rem',
      color: secondary.title,
      '@media (max-width: 1440px)': {
        fontSize: '1.25rem',
      },
    },
    subtitle1: {
      color: secondary.title,
    },
    subtitle2: {
      color: secondary.title,
      fontSize: '0.9375rem',
    },
  },
  palette: {
    primary: {
      main: primary.main,
    },
    secondary: {
      main: secondary.main,
    },
  },
  overrides: {
    MuiInputBase: {
      input: {
        color: secondary.title,
        borderRadius: '40px',
        backgroundColor: 'white',
        '&::placeholder': {
          color: secondary.title,
          fontSize: '0.875rem',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        padding: '0px',
        '& $notchedOutline': {
          border: 'none',
        },
        '&$error $notchedOutline': {
          border: 'solid',
          borderRadius: '40px',
          borderWidth: 1,
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: secondary.title,
        fontSize: '0.875rem',
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '0.75rem',
        color: '#FFF',
        backgroundColor: secondary.title,
      },
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: secondary.title,
        },
        '&$error': {
          color: secondary.title,
        },
      },
    },

    MuiButton: {
      contained: {
        backgroundColor: primary.gray,
        color: secondary.title,
        boxShadow: 'none',
        padding: '10px 16px',
        borderRadius: '22px',
        textTransform: 'inherit',
        '&:hover': {
          backgroundColor: primary.gray,
        },
      },
      containedPrimary: {
        color: '#FFF',
        '&:hover': {
          backgroundColor: primary.main,
        },
      },
      containedSecondary: {
        color: '#FFF',
        padding: '14px 50px',
        '&:hover': {
          backgroundColor: secondary.main,
        },
      },
    },
    MuiDialog: {
      paperFullWidth: {
        width: '50%',
      },
      scrollPaper: {
        alignItems: 'flex-Start',
      },
      paperWidthSm: {
        maxHeight: '75%',
        maxWidth: '1030px',
        margin: '4% 30px',
        borderRadius: '10px',
      },
    },
    MuiSlider: {
      markLabel: {
        fontSize: '0.875rem',
        top: 30,
        color: secondary.title,
        '@media (pointer: coarse)': {
          fontSize: '0.875rem',
          top: 30,
        },
      },
      markLabelActive: {
        color: secondary.title,
      },
      root: {
        color: secondary.main,
        '@media (pointer: coarse)': {
          padding: '13px 0',
        },
      },
      rail: {
        backgroundColor: secondary.title,
      },
      thumb: {
        top: '5px',
        width: '28px',
        height: '28px',
        '&.Mui-disabled': {
          top: '5px',
          width: '28px',
          height: '28px',
        },
      },
    },
    MuiInput: {
      root: {
        color: secondary.title,
        border: 'solid',
        borderRadius: '40px',
        borderWidth: 1,
      },
      input: {
        height: '20px',
        marginLeft: '4%',
      },
      underline: {
        '&:before': {
          borderBottom: 'none',
        },
        '&:hover': {
          borderBottom: 'none',
        },
      },
    },
    MuiFormControl: {
      root: {
        width: '100%',
      },
    },
  },
};

export default theme;
