import { isEmpty } from 'lodash';
import {
  SimulationAT,
  ValidateJsError,
  UpdateSectionAction,
} from 'features/home/actions.simulation';
import { fromJS } from 'immutable';
import { StoreOf } from '../../core/root.model';
import {
  IsOnlineAction,
  SubmitSimulationErrorsAction,
  UpdateConventionAction,
  UpdateFieldByArrayAction,
  UpdateSimulationAction,
  UpdateSimulationLoaderAction,
  UpdateSimulationResponseAction,
  SubmitSimulationCreaEspaceAction,
  InitSimulationATAction,
  SetLangAction,
} from './actions.simulation';
import {
  setSessionStorage,
  getSessionStorage,
} from 'common/utils/sessionStorage.util';
import { SIMULATION_STATE } from 'features/home/constants.simulation';
import { AccountTypes, ProductTypes } from 'features/auth/constants';

export const defaultSelectedConvention = {
  codeConvention: '0',
  companyName: '',
  minAmount: 5000,
  maxAmount: 150000,
  minDuration: 12,
  maxDuration: 48,
  minMonthlyPayment: 500,
  maxMonthlyPayment: 2000,
};
//const date = getSessionStorage('birthday');
const rachat = getSessionStorage('rachat');
//const compteurDate = getSessionStorage('comptDate');
export const initState: HomeState = fromJS({
  isOnline: {
    check: true,
    status: 0,
    error: '',
  },
  simulation: {
    modifySimulationPreAtt: false,
    isEditInfo: false,
    convention: [],
    selectedConvention: defaultSelectedConvention,
    fields: {
      rachat: !isEmpty(rachat) ? rachat : 'N',
      rachatMntReste: '',
      rachatMntEch: '',
      typeClient: AccountTypes.salarie,
      product: ProductTypes.bconvcp,
      clientBOA: true,
      openAccountBOA: true,
      //birthday: date,
      // companyName: '',
      // otherEmploye: false,
      // nameOtherEmploye: '',
      creditAmount: 105000,
      creditDuration: 48,
      monthlyPayment: 1400,
      //codeConvention: '0',
      phone: '',
      codeOtp: '',
      checkValidateSimulation: false,
      //comptDate: compteurDate,
      // comptCompany: 0,
      // comptOtherComp: 0,
    },
    response: {
      state: SIMULATION_STATE.simulation,
      loader: false,
      typeClient: AccountTypes.salarie,
      product: ProductTypes.bconvcp,
      clientBOA: true,
      openAccountBOA: true,
      birthday: new Date(1994, 11, 12),
      // companyName: '',
      // otherEmploye: false,
      // nameOtherEmploye: '',
      creditAmount: 105000,
      creditDuration: 48,
      monthlyPayment: 1400,
      // codeConvention: '0',
      phone: '',
    },
    errors: {},
  },
  lang: getSessionStorage('lang'),
});

export const HOME_REDUCER = 'HOME_REDUCER';

export interface HomeStateModel {
  simulation: {
    errors: ValidateJsError;
  };
  lang: any;
}

export type HomeState = StoreOf<HomeStateModel>;

export type SimulationActions =
  | IsOnlineAction
  | UpdateFieldByArrayAction
  | SubmitSimulationErrorsAction
  | UpdateConventionAction
  | UpdateSimulationAction
  | UpdateSimulationLoaderAction
  | UpdateSectionAction
  | UpdateSimulationResponseAction
  | SubmitSimulationCreaEspaceAction
  | InitSimulationATAction
  | SetLangAction;

export function SimulationReducer(
  state = initState,
  action: SimulationActions,
): HomeState {
  switch (action.type) {
    case SimulationAT.setLang:
      setSessionStorage('lang', action.value);
      return state.set('lang', fromJS(action.value));
    case SimulationAT.isOnline:
      return state.setIn(['isOnline'], fromJS(action.value));
    case SimulationAT.updateField:
      if (action.array[1] === 'fields') {
        return state
          .setIn(action.array, fromJS(action.value))
          .removeIn([action.array[0], 'errors', action.array[2]])
          .removeIn([action.array[0], 'response', action.array[2]]);
      }
      return state.setIn(action.array, fromJS(action.value));
    case SimulationAT.updateSection:
      return state.mergeIn(
        ['simulation', action.section],
        fromJS(action.value),
      );
    case SimulationAT.submitSimulationError:
      return state.setIn(['simulation', 'errors'], fromJS(action.errors));
    case SimulationAT.updateConvention:
      return state.setIn(['simulation', 'convention'], fromJS(action.data));
    case SimulationAT.updateState:
      return state.setIn(
        ['simulation', 'response', 'state'],
        fromJS(action.value),
      );
    case SimulationAT.getSimulationSuccess:
      return state
        .mergeIn(['simulation', 'response'], fromJS(action.data))
        .setIn(
          ['simulation', 'response', 'creditAmount'],
          fromJS(action.data.creditAmount),
        )
        .setIn(
          ['simulation', 'response', 'monthlyPayment'],
          fromJS(action.data.monthlyPayment),
        );
    case SimulationAT.getSimulationResponse:
      return state
        .mergeIn(['simulation', 'response'], fromJS(action.simulationResponse))
        .setIn(
          ['simulation', 'response', 'creditAmount'],
          fromJS(action.simulationResponse.creditAmount),
        )
        .setIn(
          ['simulation', 'response', 'monthlyPayment'],
          fromJS(action.simulationResponse.monthlyPayment),
        );
    case SimulationAT.initSimulationAT:
      return initState.setIn(['lang'], fromJS(getSessionStorage('lang')));
    default:
      return state;
  }
}
