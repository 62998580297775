export const caseStatus: any = {
  0: {
    step: '0',
    title: '',
    icon: '',
    status: '',
  },
  1: {
    step: '1',
    title: 'demandeSent',
    icon: 'valideIcon',
    status: 'demandeSentComment',
  },
  2: {
    step: '2',
    title: 'demandeSent',
    icon: 'valideIcon',
    status: 'demandeSentComment',
  },
  3: {
    step: '3',
    title: 'demandeSent',
    icon: 'valideIcon',
    status: 'demandeSentComment',
  },
  4: {
    step: '4',
    title: 'demandeVerification',
    icon: 'defaultIcon',
    status: 'verifDossIncom',
  },
  5: {
    step: '5',
    title: 'demandeVerification',
    icon: 'defaultIcon',
    status: 'demandeEncoursetud',
  },
  6: {
    step: '6',
    title: 'demandeVerification',
    icon: 'valideIcon',
    status: 'verifDossCom',
  },
  7: {
    step: '7',
    title: 'demandeVerification',
    icon: 'defaultIcon',
    status: 'demandeEncoursetud',
  },
  8: {
    step: '8',
    title: 'demandeVerification',
    icon: 'defaultIcon',
    status: 'demandeEncoursetud',
  },
  9: {
    step: '9',
    title: 'demandeStudyFile',
    icon: 'valideIcon',
    status: 'demandeStudyFileAccepted',
  },
  10: {
    step: '10',
    title: 'demandeStudyFile',
    icon: 'defaultIcon',
    status: 'demandeStudyFileRejected',
  },
  11: {
    step: '11',
    title: 'demandeContract',
    icon: 'valideIcon',
    status: 'demandeContractComment',
  },
  12: {
    step: '12',
    title: 'demandeContract',
    icon: 'valideIcon',
    status: 'dossiercloture',
  },
  13: {
    step: '13',
    title: 'demandeContract',
    icon: 'valideIcon',
    status: 'dossiercloture',
  },
};
