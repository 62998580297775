import { lowerCase } from 'lodash';
import { AccountTypes, ProductTypes } from 'features/auth/constants';
import { SCORE_PROPOISITION } from 'features/situation/constants.situation';

export const toJS = <T>(selectorImmutable: any): Readonly<T> => {
  const record = selectorImmutable;
  if (record && typeof record.toJS === 'function') {
    return record.toJS();
  }
  return selectorImmutable;
};

export const getRoute = (
  typeClient: string,
  product: string,
  clientBOA: boolean,
  stepClient: string,
  appreciation: string,
  preAttributionBmceDirect: boolean = false,
): string => {
  let _url: string = '/credit-conso'; // Default URL
  if (stepClient === '1') {
    _url = '/sign-up';
  }
  if (stepClient === '2') {
    /* ####### SALARIE ###### */
    if (typeClient === AccountTypes.salarie) {
      if (product === ProductTypes.bconvcp && clientBOA) {
        // preAttributionBmceDirect
        //   ? (_url = '/preattributionSalarie/conv')
        //   :
        _url = '/salarie/conv';
      } else if (product === ProductTypes.cp && clientBOA) {
        // preAttributionBmceDirect
        //   ? (_url = '/preattributionSalarie/noConv')
        //   :
        _url = '/salarie/noConv';
      } else {
        _url = '/salarie/prospect';
      }
    }

    /* ####### FONCTIONNAIRE ###### */
    if (typeClient === AccountTypes.fonctionnaire) {
      if (product === ProductTypes.bconvppr && clientBOA) {
        // preAttributionBmceDirect
        //   ? (_url = '/preattributionFonctionnaire/conv')
        //   :
        _url = '/fonctionnaire/conv';
      } else if (product === ProductTypes.ppr && clientBOA) {
        // preAttributionBmceDirect
        //   ? (_url = '/preattributionFonctionnaire/noConv')
        //   :
        _url = '/fonctionnaire/noConv';
      } else {
        _url = '/fonctionnaire/prospect';
      }
    }

    /* ####### RETRAITE ###### */
    if (typeClient === AccountTypes.retraite) {
      if (product === ProductTypes.bconvcp && clientBOA) {
        // preAttributionBmceDirect
        //   ? (_url = '/preattributionRetraite/conv')
        //   :
        _url = '/retraite/conv';
      } else if (product === ProductTypes.cp && clientBOA) {
        // preAttributionBmceDirect
        //   ? (_url = '/preattributionRetraite/noConv')
        //   :
        _url = '/retraite/noConv';
      } else {
        _url = '/retraite/prospect';
      }
    }

    /* ####### PROFESSIONNEL ###### */
    if (typeClient === AccountTypes.professionnel) {
      if (product === ProductTypes.cp) {
        _url = '/professionnel/client';
      } else {
        // produit => cp_slf
        _url = '/professionnel/prospect';
      }
    }
  }
  if (stepClient === '3') {
    if (lowerCase(appreciation) === lowerCase(SCORE_PROPOISITION.ACCORD_PLUS)) {
      _url = '/proposition';
    } else if (
      lowerCase(appreciation) === lowerCase(SCORE_PROPOISITION.ACCORD) ||
      lowerCase(appreciation) === lowerCase(SCORE_PROPOISITION.A_DEROGER)
    ) {
      _url = '/derogation-proposition';
    } else {
      _url = '/reject-proposition';
    }
  }
  if (stepClient === '5') {
    _url = '/upload-documents';
  }
  if (stepClient === '6') {
    _url = '/case-tracking';
  }
  return _url;
};

export const scrollToElement = (elementId?: string, yOffset: number = -90) => {
  const elm = document.getElementById(elementId || 'root'); // get element by ID otherwise it will take root ID

  if (elm) {
    const y = elm.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }
};
