// import { DUPLICATE_EMAIL } from 'common/utils/validateJs/contants';
import { selectWording } from 'common/wording/selectors.wording';
import React from 'react';
import { useSelector } from 'react-redux';
// import { Link } from 'react-router-dom';

const FieldError = (errors: string[] | undefined) => {
  const { errors: errorsWording } = useSelector(selectWording) || {};
  // const hasDuplicateEmail = errors && errors.includes(DUPLICATE_EMAIL);

  return errors ? <span>{errorsWording?.[errors?.[0]]}</span> : null;
};

export default FieldError;
