import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Switch, { SwitchClassKey, SwitchProps } from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import { GlobalTheme } from 'features/app/app.theme';
import React from 'react';
import FormLabel from '@material-ui/core/FormLabel';
import Tooltip from 'common/components/customFields/CustomTooltip';
import HighiledIcon from 'assets/svg/highlitedIcon.svg';

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface Props extends SwitchProps {
  classes: Styles;
}

const useStyles = makeStyles<
  GlobalTheme & Theme,
  Pick<PropsCustomizedSwitches, 'isHighlighted' | 'tooltip'>
>((theme: GlobalTheme & Theme) =>
  createStyles({
    highlited: ({ isHighlighted }) =>
      !isHighlighted
        ? {}
        : {
            backgroundColor: 'rgba(249, 50, 118, 0.1)',
            padding: 20,
            border: '1px solid rgba(249, 50, 118, 0.2)',
            borderRadius: '14px',
          },
    highltsvg: { width: 30, marginRight: 10 },
    root: {
      width: 46,
      height: 26,
      padding: 0,
      margin: '8px 3px 8px 3px',
      overflow: 'initial',
      '& .MuiSwitch-switchBase': {
        padding: '7px !important',
      },
      '& .MuiSwitch-track': {
        opacity: '1 !important',
      },
    },
    switchBase: {
      padding: 4,
      color: theme.secondary.title,
      '&$checked': {
        '& + $track': {
          backgroundColor: 'white',
        },
      },
    },
    label: {
      fontSize: '0.875rem',
      letterSpacing: '0px',
      color: theme.secondary.title,
      opacity: 1,
      fontFamily: theme.fonts.medium,
    },
    gridSwitch: {
      width: 'max-content !important',
    },
    thumb: {
      width: '14px !important',
      height: '14px !important',
    },
    track: {
      borderRadius: 26 / 2,
      border: `0.063em solid #003D7C`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    globalTooltip: {
      display: 'flex',
      alignItems: 'center',
      // flexWrap: 'wrap',
    },
    tooltip: {
      padding: '0',
      margin: 'auto',
      marginLeft: '10px',
    },
    formLabel: ({ isHighlighted, tooltip }) => ({
      fontSize: '0.9375rem',
      textAlign: 'left',
      fontFamily: theme.fonts.medium,
      color: isHighlighted ? theme.secondary.main : theme.secondary.title,
      marginBottom: tooltip ? 0 : '17px',
    }),
  }),
);

const CustomSwitch = (props: any) => {
  const classes = useStyles({});
  return (
    <Switch
      disableRipple
      size='small'
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
};

interface PropsCustomizedSwitches {
  checked: boolean;
  onChange?: (e: any) => void;
  name: string;
  labelPrefix: string;
  labelSufix: string;
  formLabel?: string;
  tooltip?: string;
  isHighlighted?: boolean;
}

export default function CustomizedSwitches({
  checked = false,
  onChange = () => ({}),
  name = '',
  labelPrefix = '',
  labelSufix = '',
  formLabel = '',
  tooltip = '',
  isHighlighted,
}: PropsCustomizedSwitches) {
  const classes = useStyles({ isHighlighted, tooltip });

  const customOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const output = {
      target: {
        value: e.target.checked,
        name: e.target.name,
      },
    };
    onChange(output);
  };

  return (
    <div className={classes.highlited}>
      <div className={classes.globalTooltip}>
        {isHighlighted && (
          <img
            alt='highlited svg'
            className={classes.highltsvg}
            src={HighiledIcon}
          />
        )}
        {formLabel && (
          <FormLabel className={classes.formLabel} component='legend'>
            {formLabel}
          </FormLabel>
        )}
        {tooltip && <Tooltip content={tooltip} />}
      </div>
      <Typography component='div'>
        <Grid
          component='label'
          className={classes.gridSwitch}
          container
          alignItems='center'
          spacing={1}
        >
          <Grid item className={classes.label}>
            {labelPrefix}
          </Grid>
          <Grid item>
            <CustomSwitch
              checked={checked}
              onChange={customOnChange}
              name={name}
            />
          </Grid>
          <Grid item className={classes.label}>
            {labelSufix}
          </Grid>
        </Grid>
      </Typography>
    </div>
  );
}
