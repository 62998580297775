import React from 'react';
import Seo from 'common/components/seo/Seo';
import { Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { GlobalTheme } from 'features/app/app.theme';
import { useSelector } from 'react-redux';
import { selectWording } from 'common/wording/selectors.wording';
import { Wording } from 'common/wording/reducer.wording';
import logoMobile from 'assets/svg/logoMobile.svg';
import logo from 'assets/images/logo.png';
import logoCreditDaba from 'assets/svg/logoCreditDaba-dark.svg';
import BackgroundRightContainer from 'assets/images/BackgroundConfirmationMail.png';
import { getSimulationInfoByArraySelector } from 'features/home/selector.simulation';
import { LANG_STATE } from 'features/home/constants.simulation';

const useStyles = makeStyles((theme: GlobalTheme) =>
  createStyles({
    rootHome: {
      display: 'flex',
      flexDirection: 'column',
      backgroundSize: 'cover',
      backgroundImage: `url(${BackgroundRightContainer})`,
      backgroundRepeat: 'no-repeat',
      width: '100%',
      height: '100vh',
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '200px',
      padding: '5px',
      '@media (max-width: 768px)': {
        marginTop: '100px',
      },
    },
    title: {
      textTransform: 'uppercase',
      textAlign: 'center',
    },
    subtitle: {
      marginTop: 10,
      textAlign: 'center',
      color: '#003D7C',
    },
    logo: {
      display: 'block',
      width: '200px',
      marginLeft: '1em',
      marginRight: '1em',
      '@media (max-width: 425px)': {
        content: `url(${logoMobile})`,
      },
    },
    rootImg: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      height: '82px',
      position: 'sticky',
      top: 0,
      alignItems: 'center',
      marginBottom: '15px',
    },
    rootImgAr: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      height: '82px',
      position: 'sticky',
      top: 0,
      alignItems: 'center',
      marginBottom: '15px',
    },
    logoCenter: {
      display: 'block',
      marginBottom: '50px',
      width: '350px',
      maxWidth: '85%',
    },
    logoBottom: {
      display: 'block',
      marginTop: '20px',
      width: '100px',
      maxWidth: '45%',
    },
  }),
);

const NotFound = () => {
  const classes = useStyles();
  const wording: Wording = useSelector(selectWording);
  const langSelected: any =
    useSelector(getSimulationInfoByArraySelector(['lang'])) || LANG_STATE.FR;
  return (
    <div className={classes.rootHome}>
      <Seo titleKey='title_29' keywordsKey='keywords_29'>
        <meta name='robots' content='noindex, nofollow' />
      </Seo>
      <div
        className={
          langSelected === LANG_STATE.FR ? classes.rootImg : classes.rootImgAr
        }
      >
        <img
          className={classes.logo}
          alt={wording?.homePage.logoLogin}
          src={logo}
        />
      </div>
      <div className={classes.root}>
        <img
          className={classes.logoCenter}
          alt='logo-center'
          src={logoCreditDaba}
        />
        <Typography variant='h2' className={classes.title}>
          {wording?.homePage.introuvable}
        </Typography>
        <Typography variant='h4' className={classes.subtitle}>
          <div
            dangerouslySetInnerHTML={{
              __html: wording?.homePage.ici,
            }}
          />
        </Typography>
      </div>
    </div>
  );
};

export default NotFound;
