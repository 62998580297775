import logo from 'assets/images/logo.png';
interface ISocielItem {
  id: string;
  link: string;
  creator: string;
}

interface ISociel {
  facebook: ISocielItem;
  twitter: ISocielItem;
  youtube: ISocielItem;
  linkedin: ISocielItem;
}

interface IAddress {
  city: string;
  region: string;
  country: string;
  zipCode: number;
}

interface IContact {
  email: string;
  phone: string;
}

export const url: string = 'https://www.creditdaba.ma';
export const founders: string = 'BANK OF AFRICA';

export const social: ISociel = {
  facebook: {
    id: 'facebookid',
    creator: '@creditdaba',
    link: 'https://www.facebook.com/BMCEBankOfAfrica',
  },
  twitter: {
    id: '@creditdaba',
    creator: '@creditdaba',
    link: 'https://twitter.com/Bank_OfAfrica',
  },
  youtube: {
    id: 'youtubeid',
    creator: '@creditdaba',
    link: 'https://www.youtube.com/c/BMCEBankOfAfrica',
  },
  linkedin: {
    id: 'linkedinid',
    creator: '@creditdaba',
    link: 'https://www.linkedin.com/company/bank-of-africa-bmce-group',
  },
};
export const address: IAddress = {
  city: 'Casablanca',
  region: 'Grand Casablanca',
  country: 'Maroc',
  zipCode: 20000,
};
export const contact: IContact = {
  phone: '+212 (0) 5 20 39 30 30',
  email: 'agencedirecte@bankofafrica.ma',
};
export const foundingDate = 'foundingDate';
export const legalName = 'crédit daba';
export const home_url = '/credit_conso';
export const type = 'website';
export const local_fr = 'fr_FR';
export const local_ar = 'ar_AR';

export const structuredDataOrganization = `{
  "@context": "http://schema.org",
  "@type": "${type}",
  "legalName": "${legalName}",
  "url": "${url}",
  "logo": "${url}/${logo}",
  "foundingDate": "${foundingDate}",
  "founders": [{
    "@type": "Person",
    "name": "${founders}"
  }],
  "contactPoint": [{
    "@type": "ContactPoint",
    "email": "${contact.email}",
    "telephone": "${contact.phone}",
    "contactType": "customer service"
  }],
  "address": {
    "@type": "PostalAddress",
    "addressLocality": "${address.city}",
    "addressRegion": "${address.region}",
    "addressCountry": "${address.country}",
    "postalCode": "${address.zipCode}"
  },
  "sameAs": [
    "${social.twitter.link}",
    "${social.youtube.link}",
    "${social.linkedin.link}"
  ]
}`;
