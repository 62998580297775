import { fromJS } from 'immutable';
import { StoreOf } from '../../core/root.model';
import {
  IsAuthenticatedInterface,
  AuthAT,
  SubmitSignUpErrorsAction,
  SubmitSignInErrorsAction,
  UpdateFieldSignInAction,
  UpdateFieldSignUpAction,
  ValidateJsError,
  SubmitSignUpBmceDirectErrorsAction,
  UpdateStepAction,
  SubmitPasswordOublieErrorsAction,
  IsaveUserProfileAction,
  LoGout,
} from './actions.auth';
import {
  getSessionStorage,
  setSessionStorage,
} from 'common/utils/sessionStorage.util';
import { IUserProfile } from './constants';
import moment from 'moment';

const civility = getSessionStorage('civility');
const email = getSessionStorage('email');
const lastName = getSessionStorage('lastName');
const firstName = getSessionStorage('firstName');
const cin = getSessionStorage('cin');
const rib = getSessionStorage('rib');
const bmceDirect = getSessionStorage('bmceDirect');
const userProfile = getSessionStorage('userProfile');
const date = getSessionStorage('birthday');
const compteurDate = getSessionStorage('comptDate');

export const initState: AuthState = fromJS({
  isAuthenticated: !!getSessionStorage('isAuthenticated'),
  confirmationMail: {
    loader: false,
    sendingEmail: false,
  },
  signUp: {
    loader: false,
    firstName: firstName ? firstName : '',
    lastName: lastName ? lastName : '',
    civility: civility ? civility : 'F',
    cin: cin ? cin : '',
    rib: rib ? rib : '',
    bmceDirect: bmceDirect ? bmceDirect : false,
    email: email ? email : '',
    // format only if date exist and in french format (otherways the date is in timezone format)
    birthday: date
      ? moment(date, date.length <= 10 ? 'DD/MM/yyyy' : undefined).format()
      : '',
    comptDate: compteurDate,
    companyName: '',
    nameOtherEmploye: '',
    codeConvention: '0',
    comptCompany: 0,
    comptOtherComp: 0,
    errors: {},
  },
  signIn: {
    loader: false,
    bmceDirect: 'mouhsni',
    errors: {},
  },
  signUpBMCEDirect: {
    loader: false,
    sendingEmail: false,
    checkValidateClient: false,
    errors: {},
  },
  passwordOublie: {
    loader: false,
    errors: {},
  },

  userProfile: userProfile ? userProfile : {},
});

export const resetState: AuthState = fromJS({
  isAuthenticated: false,
  confirmationMail: {
    loader: false,
    sendingEmail: false,
  },
  signUp: {
    loader: false,
    firstName: '',
    lastName: '',
    email: '',
    civility: 'F',
    cin: '',
    rib: '',
    bmceDirect: false,
    birthday: '',
    companyName: '',
    nameOtherEmploye: '',
    codeConvention: '0',
    comptCompany: 0,
    comptDate: 0,
    comptOtherComp: 0,
    errors: {},
  },
  signIn: {
    loader: false,
    bmceDirect: 'signInByBmceDirect',
    errors: {},
  },
  signUpBMCEDirect: {
    loader: false,
    sendingEmail: false,
    checkValidateClient: false,
    errors: {},
  },
  passwordOublie: {
    loader: false,
    errors: {},
  },

  userProfile: fromJS({}),
});

export const AUTH_REDUCER = 'AUTH_REDUCER';

export function AuthReducer(state = initState, action: AuthActions): AuthState {
  switch (action.type) {
    case AuthAT.isAuthenticated:
      setSessionStorage('isAuthenticated', action.value);
      return state.set('isAuthenticated', fromJS(action.value));
    case AuthAT.updateFieldSignUp:
    case AuthAT.updateFieldSignIn:
      return state
        .setIn([action.step, action.name], fromJS(action.value))
        .removeIn([action.step, 'errors', action.name]);
    case AuthAT.submitSignUpError:
      return state.setIn(['signUp', 'errors'], fromJS(action.errors));
    case AuthAT.submitSignInError:
      return state.setIn(['signIn', 'errors'], fromJS(action.errors));
    case AuthAT.submitPasswordOublieError:
      return state.setIn(['passwordOublie', 'errors'], fromJS(action.errors));
    case AuthAT.submitSignUpBMCEDirectError:
      return state.setIn(['signUpBMCEDirect', 'errors'], fromJS(action.errors));
    case AuthAT.updateStep:
      return state.setIn([action.step], fromJS(action.value));
    case AuthAT.saveUserProfile:
      return state.setIn(['userProfile'], fromJS(action.value));
    case AuthAT.loGout:
      return resetState;
    default:
      return state;
  }
}

export interface AuthStateModel {
  isAuthenticated: boolean;
  signUp: {
    loader: false;
    firstName: '';
    lastName: '';
    email: '';
    civility: 'F';
    cin: '';
    rib: '';
    bmceDirect: false;
    errors: ValidateJsError;
  };
  signIn: {
    loader: false;
    errors: ValidateJsError;
  };
  signUpBMCEDirect: {
    loader: false;
    errors: ValidateJsError;
  };
  passwordOublie: {
    loader: false;
    errors: ValidateJsError;
  };
  accessCode: {
    loader: false;
    accessCode: '';
    errors: ValidateJsError;
  };
  userProfile: IUserProfile;
}

export type AuthState = StoreOf<AuthStateModel>;

export type AuthActions =
  | IsAuthenticatedInterface
  | SubmitSignUpErrorsAction
  | UpdateFieldSignInAction
  | UpdateFieldSignUpAction
  | SubmitSignInErrorsAction
  | SubmitSignUpBmceDirectErrorsAction
  | UpdateStepAction
  | SubmitPasswordOublieErrorsAction
  | IsaveUserProfileAction
  | LoGout;
