import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from 'assets/images/closeIcon.png';
import { GlobalTheme } from 'features/app/app.theme';
import { Wording } from 'common/wording/reducer.wording';
import { useSelector } from 'react-redux';
import {
  selectCompWording,
  selectWording,
} from 'common/wording/selectors.wording';

const useStyles = makeStyles((theme: GlobalTheme) => ({
  header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'left',
    marginTop: '30px',
    marginBottom: '30px',
    marginLeft: '90px',
    marginRight: '10px',
    position: 'relative',
    '@media (max-width: 768px)': {
      margin: '10px 30px 10px 30px',
    },
  },
  title: {
    textAlign: 'left',
    fontFamily: theme.fonts.medium,
    color: theme.primary.title,
    textTransform: 'uppercase',
    opacity: 1,
    fontWeight: 500,
    fontSize: '1.25rem',
    marginBottom: '7px',
  },
  closeIcon: {
    position: 'absolute',
    right: '25px',
    width: '15px',
    cursor: 'pointer',
    '@media (max-width: 768px)': {
      right: '0px',
    },
  },
  subTitle: {
    color: theme.secondary.title,
    fontFamily: theme.fonts.regular,
    fontSize: '0.75rem',
    textAlign: 'left',
  },
  conditionComponent: {
    margin: '30px 34px 40px 90px',
    overflowY: 'scroll',
    paddingRight: '17px',
    boxSizing: 'content-box',
    msOverflowStyle: 'none',
    position: 'relative',
    '@media (max-width: 768px)': {
      margin: '30px 30px 30px 30px',
    },
  },
  sectionTitle: {
    textAlign: 'left',
    fontFamily: theme.fonts.medium,
    color: theme.primary.title,
    opacity: 1,
    fontWeight: 500,
    fontSize: '1rem',
    marginBottom: '5px',
  },
  paragDescription: {
    color: theme.secondary.title,
    fontFamily: theme.fonts.regular,
    fontSize: '0.8125rem',
    textAlign: 'justify',
    marginBottom: '20px',
  },
  conditionOfUseFooter: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  buttonSubmit: {
    borderRadius: '14px 0px 0px',
    textTransform: 'inherit',
    padding: '14px 50px',
    fontFamily: theme.fonts.regular,
    fontSize: '0.6875rem',
    width: '25%',
    '&:hover': {
      backgroundColor: theme.secondary.main,
    },
  },
  progressContainer: {
    width: '75%',
    height: '8px',
    background: theme.primary.background,
    bottom: 0,
  },
  progressBar: {
    width: '0%',
    minWidth: '10%',
    height: '8px',
    background: theme.secondary.main,
  },
  popup: {
    display: 'flex',
    flexDirection: 'column',
    direction: 'ltr',
    '@media (max-width: 450px)': {
      '& .MuiDialog-paperFullWidth': {
        width: '100%',
      },
    },
  },
}));

interface Props {
  open: boolean;
  onClose: () => void;
  onValidate: () => void;
}

function GeneralCondition({ open, onClose, onValidate }: Props) {
  const wordingGeneralCondition: Wording =
    useSelector(selectCompWording('generalCondition')) || {};
  const wording = useSelector(selectWording) || {};
  const classes = useStyles();
  const [isNotReaded, setIsNotReaded] = React.useState(true);

  const generalCondition = [
    {
      title: wording?.generalCondition?.objet,
      description: (
        <div
          dangerouslySetInnerHTML={{
            __html: wording?.generalCondition?.p_objet,
          }}
        />
      ),
    },
    {
      title: wording?.generalCondition?.editeur,
      description: (
        <div
          dangerouslySetInnerHTML={{
            __html: wording?.generalCondition?.p_editeur,
          }}
        />
      ),
    },
    {
      title: wording?.generalCondition?.service,
      description: (
        <div
          dangerouslySetInnerHTML={{
            __html: wording?.generalCondition?.p_service,
          }}
        />
      ),
    },
    {
      title: wording?.generalCondition?.donnees,
      description: (
        <div
          dangerouslySetInnerHTML={{
            __html: wording?.generalCondition?.p_donnees,
          }}
        />
      ),
    },
    {
      title: wording?.generalCondition?.prospection,
      description: (
        <div
          dangerouslySetInnerHTML={{
            __html: wording?.generalCondition?.p_prospection,
          }}
        />
      ),
    },
    {
      title: wording?.generalCondition?.traitement,
      description: (
        <div
          dangerouslySetInnerHTML={{
            __html: wording?.generalCondition?.p_traitement,
          }}
        />
      ),
    },
    {
      title: wording?.generalCondition?.commerciale,
      description: (
        <div
          dangerouslySetInnerHTML={{
            __html: wording?.generalCondition?.p_commerciale,
          }}
        />
      ),
    },
    {
      title: wording?.generalCondition?.reflexion,
      description: (
        <div
          dangerouslySetInnerHTML={{
            __html: wording?.generalCondition?.p_reflexion,
          }}
        />
      ),
    },
    {
      title: wording?.generalCondition?.retractation,
      description: (
        <div
          dangerouslySetInnerHTML={{
            __html: wording?.generalCondition?.p_retractation,
          }}
        />
      ),
    },
    {
      title: wording?.generalCondition?.intellectuelle,
      description: (
        <div
          dangerouslySetInnerHTML={{
            __html: wording?.generalCondition?.p_intellectuelle,
          }}
        />
      ),
    },
    {
      title: wording?.generalCondition?.responsabilite,
      description: (
        <div
          dangerouslySetInnerHTML={{
            __html: wording?.generalCondition?.p_responsabilite,
          }}
        />
      ),
    },
    {
      title: wording?.generalCondition?.liens,
      description: (
        <div
          dangerouslySetInnerHTML={{
            __html: wording?.generalCondition?.p_liens,
          }}
        />
      ),
    },
    {
      title: wording?.generalCondition?.cookies,
      description: (
        <div
          dangerouslySetInnerHTML={{
            __html: wording?.generalCondition?.p_cookies,
          }}
        />
      ),
    },
  ];

  const handleClose = () => {
    onClose();
  };
  const handleValidate = () => {
    onValidate();
    onClose();
  };

  const handleisNotReaded = () => {
    setIsNotReaded(false);
  };

  const scroll = () => {
    const progressBar: HTMLElement = document.getElementById('progressBar')!;
    const conditionOfUseContent: HTMLElement = document.getElementById(
      'conditionOfUseContent',
    )!;

    const height =
      conditionOfUseContent.scrollHeight - conditionOfUseContent.clientHeight;
    const scrolled = (conditionOfUseContent.scrollTop / height) * 100;
    progressBar.style.width = scrolled + '%';
    // ajouter 5 a la valeur du scrol pour accélérer l'animation du scroll
    if (parseInt(String(scrolled), 0) + 5 >= 98) {
      progressBar.style.width = '100%';
      handleisNotReaded();
    } else {
      setIsNotReaded(true);
    }
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth='sm'
      open={open}
      onClose={handleClose}
      onScroll={scroll}
      className={classes.popup}
    >
      <div className={classes.header}>
        <div className={classes.title}>{wordingGeneralCondition.title}</div>
        <img
          src={CloseIcon}
          className={classes.closeIcon}
          onClick={handleClose}
          alt=''
        />
        <div className={classes.subTitle}>
          {wordingGeneralCondition.modifiedAt}
        </div>
      </div>
      <div className={classes.conditionComponent} id='conditionOfUseContent'>
        {generalCondition.map((element, i) => (
          <div key={i}>
            <div className={classes.sectionTitle}> {element.title} </div>
            <div className={classes.paragDescription}>
              {element.description}
            </div>
          </div>
        ))}
      </div>

      <div className={classes.conditionOfUseFooter}>
        <div className={classes.progressContainer}>
          <div className={classes.progressBar} id='progressBar' />
        </div>

        <Button
          className={classes.buttonSubmit}
          variant='contained'
          color='secondary'
          disabled={isNotReaded}
          onClick={handleValidate}
        >
          {wordingGeneralCondition.validate}
        </Button>
      </div>
    </Dialog>
  );
}

export default GeneralCondition;
