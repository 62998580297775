import { fromJS } from 'immutable';
import { StoreOf } from '../../core/root.model';
import {
  CongratulationAT,
  SubmitCongratulationAction,
  InitCongratulationATAction,
} from './actions.congratulation';

export const initState: CongratulationState = fromJS({
  recap: {
    activityPro: 'commercant',
    bankingSeniority: 'lessThanYear',
    additionalIncomeType: false,
    additionalIncome: false,
    activitySeniority: 'lessThanYear',
    seniorityBankOfAfrica: 'lessThanSixMonth',
    errors: {},
  },
});

export const CONGRATULATION_REDUCER = 'CONGRATULATION_REDUCER';

export interface CongratulationStateModel {
  congratulation: {};
}

export type CongratulationState = StoreOf<CongratulationStateModel>;

export type CongratulationActions =
  | SubmitCongratulationAction
  | InitCongratulationATAction;

export function CongratulationReducer(
  state = initState,
  action: CongratulationActions,
): CongratulationState {
  if (action.type === CongratulationAT.initCongratulationAT) {
    return initState;
  } else {
    return state;
  }
}
