import { makeStyles } from '@material-ui/core/styles';
import { Wording } from 'common/wording/reducer.wording';
import React from 'react';
import { selectWording } from 'common/wording/selectors.wording';
import { useSelector } from 'react-redux';
import { GlobalTheme } from 'features/app/app.theme';
import CustomTitle from 'common/components/customFields/CustomTitle';
import NeedLeftElement from './NeedLeftElement';
import NeedRightElement from './NeedRightElement';

const useStyles = makeStyles((theme: GlobalTheme) => ({
  root: {
    // width: '100%',
    marginLeft: '100px',
    marginTop: '20px',
    '@media (max-width: 1024px)': {
      marginLeft: '0px',
    },
  },
  content: {
    margin: 'auto',
    display: 'flex',
    padding: '10px 101px',
    '@media (max-width: 1024px)': {
      padding: '10px 51px',
      marginLeft: '0px',
    },
    '@media (max-width: 768px)': {
      padding: '10px 34px',
      flexWrap: 'nowrap',
      overflowX: 'auto',
      marginLeft: '0px',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
}));

const NeedsHomePage = () => {
  const classes = useStyles();
  const wording: Wording = useSelector(selectWording);
  return (
    <div className={classes.root}>
      <CustomTitle
        title={wording?.needsBlockHome.title}
        colorBorder={'blue'}
        positionBorder={'left'}
        positionTitle={'left'}
      />
      <div className={classes.content}>
        <NeedLeftElement />
        <NeedRightElement />
      </div>
    </div>
  );
};

export default NeedsHomePage;
