export enum UpdateInformationAT {
  initUpdateInformationAT = 'UPDATEINFORMATIONAT/INITUPDATEINFORMATIONAT',
  submitInformation = 'UPDATEINFORMATIONAT/SUBMITINFORMATION',
  updateInformation = 'UPDATEINFORMATIONAT/UPDATEINFORMATION',
  updateFieldInformation = 'UPDATEINFORMATIONAT/UPDATEFIELDINFORMATION',
  submitSignUpError = 'UPDATEINFORMATIONAT/SUBMITSIGNUPERROR',
}

export interface InitUpdateInformationATAction {
  type: UpdateInformationAT.initUpdateInformationAT;
}

export interface SubmiInformationAction {
  type: UpdateInformationAT.submitInformation;
}

export interface UpdateInformationAction {
  type: UpdateInformationAT.updateInformation;
  data: any;
}

export interface UpdateFieldInformationAction {
  type: UpdateInformationAT.updateFieldInformation;
  step: string;
  name: string;
  value: string | boolean;
}

export interface ValidateJsError {
  [key: string]: string[];
}

export interface SubmitSignUpErrorsAction {
  type: UpdateInformationAT.submitSignUpError;
  errors: ValidateJsError;
}

export const updateFieldInformationAction = (
  step: string,
  name: string,
  value: string | boolean,
): UpdateFieldInformationAction => ({
  type: UpdateInformationAT.updateFieldInformation,
  step,
  name,
  value,
});
