import { toJS } from 'common/utils/app.util';
import { createSelector } from 'reselect';
import { AppStore } from '../../core/root.model';
import { UPLOAD_REDUCER } from './reducer.upload';
import { Documents } from './actions.upload';

const getUpload = (state: AppStore) => state.get(UPLOAD_REDUCER);

export const getUploadInfoByArraySelector = (selector: string[]) =>
  createSelector(getUpload, (uploadFiles) =>
    toJS<Documents>(uploadFiles.getIn(selector)),
  );
