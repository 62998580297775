import { fromJS } from 'immutable';
import { StoreOf } from '../../core/root.model';
import {
  UpdateInformationAT,
  SubmiInformationAction,
  UpdateInformationAction,
  UpdateFieldInformationAction,
  SubmitSignUpErrorsAction,
  InitUpdateInformationATAction,
} from './actions.updateInformation';
import { getSessionStorage } from 'common/utils/sessionStorage.util';

const updateInformation = getSessionStorage('updateInformation');

export const initState: UpdateInformationState = fromJS({
  updateInformation: {
    fields: updateInformation
      ? updateInformation
      : {
          lastName: '',
          firstName: '',
          activityPro: 'commercant',
          bankingSeniority: 'lessThanYear',
          additionalIncomeType: false,
          additionalIncome: false,
          activitySeniority: 'lessThanYear',
          seniorityBankOfAfrica: 'lessThanSixMonth',
          civility: 'F',
        },
    errors: {},
  },
});

export const resetState: UpdateInformationState = fromJS({
  updateInformation: {
    fields: {
      lastName: '',
      firstName: '',
      activityPro: 'commercant',
      bankingSeniority: 'lessThanYear',
      additionalIncomeType: false,
      additionalIncome: false,
      activitySeniority: 'lessThanYear',
      seniorityBankOfAfrica: 'lessThanSixMonth',
      civility: 'F',
    },
    errors: {},
  },
});

export const UPDATEINFORMATION_REDUCER = 'UPDATEINFORMATION_REDUCER';

export interface UpdateInformationStateModel {
  updateInformation: {};
}

export type UpdateInformationState = StoreOf<UpdateInformationStateModel>;

export type UpdateInformationActions =
  | SubmiInformationAction
  | UpdateInformationAction
  | UpdateFieldInformationAction
  | SubmitSignUpErrorsAction
  | InitUpdateInformationATAction;

export function UpdateInformationReducer(
  state = initState,
  action: UpdateInformationActions,
): UpdateInformationState {
  switch (action.type) {
    case UpdateInformationAT.updateFieldInformation:
      return state
        .setIn([action.step, 'fields', action.name], fromJS(action.value))
        .removeIn([action.step, 'errors', action.name]);
    case UpdateInformationAT.updateInformation:
      return state.setIn(['updateInformation', 'fields'], fromJS(action.data));
    case UpdateInformationAT.submitSignUpError:
      return state.setIn(
        ['updateInformation', 'errors'],
        fromJS(action.errors),
      );
    case UpdateInformationAT.initUpdateInformationAT:
      return resetState;
    default:
      return state;
  }
}
