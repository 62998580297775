import { fromJS } from 'immutable';
import { StoreOf } from '../../core/root.model';
import {
  UpdateCaseAction,
  CaseTrackingAT,
  InitCaseTrackingATAction,
} from './actions.caseTracking';

export const initState: CaseTrackingState = fromJS({
  caseTracking: {
    step: 2,
  },
});

export const CASETRACKING_REDUCER = 'CASETRACKING_REDUCER';

export interface CaseTrackingStateModel {
  caseTracking: {};
}

export type CaseTrackingState = StoreOf<CaseTrackingStateModel>;

export type CaseTrackingActions = UpdateCaseAction | InitCaseTrackingATAction;

export function CaseTrackingReducer(
  state = initState,
  action: CaseTrackingActions,
): CaseTrackingState {
  switch (action.type) {
    case CaseTrackingAT.updateFieldCase:
      return state.setIn([action.step, action.name], fromJS(action.value));
    case CaseTrackingAT.initCaseTrackingAT:
      return initState;
    default:
      return state;
  }
}
