import Box from '@material-ui/core/Box';
import React from 'react';
import BlocTitle from './BlocTitle';
import reduire from 'assets/svg/reduire.svg';
import benefice from 'assets/svg/benefice.svg';
import simplifier from 'assets/svg/simplifier.svg';
import whyRachat from 'assets/svg/whyRachat.svg';
import AdvantageBloc from './AdvantageBloc';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Wording } from 'common/wording/reducer.wording';
import { useSelector } from 'react-redux';
import { selectCompWording } from 'common/wording/selectors.wording';

const useStyles = makeStyles(() => ({
  image: {
    display: 'block',
    width: '80%',
    margin: '0 auto',
    '@media (max-width: 600px)': {
      width: '50%',
      marginTop: '2rem',
    },
  },
  gridContainer: {
    marginTop: '20px',
    '@media (max-width: 600px)': {
      flexDirection: 'column-reverse',
    },
  },
}));

const WhyRachatCredit = () => {
  const wording: Wording = useSelector(selectCompWording('contextual'));
  const classes = useStyles();
  return (
    <Box mb={5} mt={5}>
      <BlocTitle title={wording?.title_why_rachat} />
      <Grid
        container
        spacing={3}
        alignItems='center'
        className={classes.gridContainer}
      >
        <Grid item xs={12} sm={7} md={8}>
          <AdvantageBloc
            icon={reduire}
            text={wording?.title_1_why_rachat}
            description={wording?.description_1_why_rachat}
          />
          <AdvantageBloc
            icon={simplifier}
            text={wording?.title_2_why_rachat}
            description={wording?.description_2_why_rachat}
          />
          <AdvantageBloc
            icon={benefice}
            text={wording?.title_3_why_rachat}
            description={wording?.description_3_why_rachat}
          />
        </Grid>
        <Grid item xs={12} sm={5} md={4}>
          <img
            className={classes.image}
            src={whyRachat}
            alt={wording?.title_why_rachat}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default WhyRachatCredit;
