import { makeStyles } from '@material-ui/core/styles';
import { GlobalTheme } from 'features/app/app.theme';
import React from 'react';
import { ListItem, ListItemText } from '@material-ui/core';
import valideIcon from 'assets/svg/statusValideIcon.svg';
import waitingIcon from 'assets/svg/statusWaitingIcon.svg';
import defaultIcon from 'assets/svg/statusDefaultIcon.svg';
import rejectIcon from 'assets/svg/statusRejectedIcon.svg';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';
import Seo from 'common/components/seo/Seo';

const useStyles = makeStyles((theme: GlobalTheme) => ({
  listStyle: {
    color: theme.secondary.title,
    fontFamily: theme.fonts.medium,
    padding: '21px',
    borderBottom: '0.063em solid #e1e5eb',
    '&>span': {
      fontFamily: theme.fonts.regular,
      fontSize: '0.9375rem',
      textAlign: 'right',
      width: '48%',
    },
    '@media (max-width: 450px)': {
      padding: '14px',
      '&>span': {
        fontSize: '0.875rem',
      },
    },
  },
  titleStyle: {
    width: '100%',
    '& .MuiTypography-root': {
      fontSize: '1rem',
      color: theme.secondary.title,
      fontFamily: theme.fonts.bold,
      marginLeft: '26px',
    },
    '@media (max-width: 450px)': {
      '& .MuiTypography-root': {
        fontSize: '0.875rem',
      },
    },
  },
  input: {},
  link: {
    color: theme.secondary.title,
    fontSize: '0.875rem',
    textDecoration: 'underline',
  },
  text: {
    fontSize: '0.9375rem',
    textAlign: 'right',
  },
  allcontent: {
    width: '100%',
  },
}));

interface ElementDetails {
  name?: string;
  title: string;
  statusFolder?: string;
  icon: string;
  className?: any;
  step?: number;
}

function CaseTrackingElement(props: ElementDetails) {
  const { title, statusFolder, icon, className, step } = props;
  const classes = useStyles();
  const history = useHistory();
  const toUpload = () => history.push('/upload-documents');
  return (
    <>
      <Seo titleKey='title_26' keywordsKey='keywords_26' />
      <ListItem key={step} className={classes.listStyle}>
        {icon === 'waitingIcon' && <img src={waitingIcon} alt='' />}
        {icon === 'valideIcon' && <img src={valideIcon} alt='' />}
        {icon === 'rejectIcon' && <img src={rejectIcon} alt='' />}
        {icon === 'defaultIcon' && <img src={defaultIcon} alt='' />}

        <ListItemText primary={title} className={classes.titleStyle} />
        <Typography
          className={`${classes.text} ${
            statusFolder ? classes.allcontent : ''
          }`}
        >
          <span className={`${classes.input} ${className}`}>
            {statusFolder}
          </span>{' '}
          {statusFolder !== '' &&
          statusFolder !== 'demandeSentComment' &&
          step === 4 ? (
            <Link
              component='button'
              variant='body2'
              className={classes.link}
              onClick={toUpload}
            >
              Cliquer Ici
            </Link>
          ) : (
            ''
          )}
        </Typography>
      </ListItem>
    </>
  );
}
export default CaseTrackingElement;
