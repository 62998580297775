import { toJS } from 'common/utils/app.util';
import { createSelector } from 'reselect';
import { AppStore } from '../../core/root.model';
import {
  professionListInterface,
  ProffessionType,
} from './constants.situation';
import { SITUATION_REDUCER } from './reducer.situation';

const getSituation = (state: AppStore) => state.get(SITUATION_REDUCER);

export const getSituationErrorsSelector = createSelector(
  getSituation,
  (situation) => toJS(situation.getIn(['situationPro', 'errors'])),
);

export const getSituationInfoByArraySelector = (selector: string[]) =>
  createSelector(getSituation, (situation) => toJS(situation.getIn(selector)));

export const getFiltredProfessionListByCriteria = (
  isProfessionnel: boolean,
  isOtherEmployer: boolean,
) =>
  createSelector(getSituation, (situation) => {
    const globalLIst = toJS<professionListInterface[]>(
      situation.getIn(['situationSalarie', 'config', 'profession']),
    );

    // return professionnel list
    if (isProfessionnel) {
      // return professionnel list
      return globalLIst?.filter((prof) => prof?.type === ProffessionType.PRO);
    } else if (isOtherEmployer) {
      // return INDIVIDUAL(parts) list
      return globalLIst?.filter(
        (prof) => prof?.type === ProffessionType.INDIVIDUAL,
      );
    } else {
      // reurn fulllist
      return globalLIst;
    }
  });
