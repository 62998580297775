import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import { selectCompWording } from 'common/wording/selectors.wording';
import { Wording } from 'common/wording/reducer.wording';
const useStyles = makeStyles(() => ({
  p: { fontSize: '0.775rem' },
}));
const ReCaptcha = () => {
  const classes = useStyles();
  const wording: Wording = useSelector(selectCompWording('recaptcha'));
  return (
    <div>
      <small>
        <Typography variant={'subtitle2'} className={classes.p}>
          {wording?.recaptchaText1}{' '}
          <a
            href='https://policies.google.com/privacy'
            target='_blank'
            rel='noopener noreferrer'
          >
            {wording?.recaptchaText2}
          </a>{' '}
          {wording?.recaptchaText3}{' '}
          <a
            href='https://policies.google.com/terms'
            target='_blank'
            rel='noopener noreferrer'
          >
            {wording?.recaptchaText4}
          </a>{' '}
          {wording?.recaptchaText5}
        </Typography>
      </small>
    </div>
  );
};

export default ReCaptcha;
