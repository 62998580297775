import { makeStyles } from '@material-ui/core/styles';
import { GlobalTheme } from 'features/app/app.theme';

const useProfStyles = makeStyles((theme: GlobalTheme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 'auto',
    // '@media (max-width: 980px)': {
    //   '&::-webkit-scrollbar': {
    //     display: 'none',
    //   },
    // },
  },
  parag: {
    width: '88%',
    padding: '10px',
    marginBottom: '20px',
    textAlign: 'left',
  },
  paragTitle: {
    fontFamily: theme.fonts.medium,
    color: theme.primary.title,
    textTransform: 'uppercase',
    fontSize: '1.75rem',
    '@media (max-width: 450px)': {
      fontSize: '1.3125rem',
    },
  },
  paragTitleAr: {
    fontFamily: theme.fonts.medium,
    color: theme.primary.title,
    textTransform: 'uppercase',
    fontSize: '1.75rem',
    fontWeight: 'bold',
    '@media (max-width: 450px)': {
      fontSize: '1.3125rem',
    },
  },
  paragSubTitle: {
    width: '591px',
    '@media (max-width: 768px)': {
      width: '400px',
    },
    '@media (max-width: 560px)': {
      width: '300px',
    },
    '@media (max-width: 414px)': {
      width: '255px',
    },
  },
  groupForm: {
    width: '100%',
    backgroundColor: '#FFF',
    borderRadius: '24px',
    direction: 'ltr',
  },

  groupFormFooter: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonSubmit: {
    padding: '18px 91px',
    borderRadius: '14px 0',
    '@media (max-width: 768px)': {
      padding: '16px 44px',
    },
    '@media (max-width: 414px)': {
      padding: '16px 44px',
    },
  },
  saveButton: {
    borderRadius: '0 14px',
    backgroundColor: theme.primary.title,
    '&:hover': {
      backgroundColor: theme.primary.title,
    },
  },
}));

export default useProfStyles;
