import { makeStyles } from '@material-ui/core/styles';
import { Wording } from 'common/wording/reducer.wording';
import React from 'react';
import { selectCompWording } from 'common/wording/selectors.wording';
import { useSelector } from 'react-redux';
import { GlobalTheme } from 'features/app/app.theme';
import traceWithout from 'assets/svg/traceWithout.svg';
import traceWithFlesh from 'assets/svg/traceWithFlesh.svg';
import ticksquare from 'assets/svg/tick-square1.svg';
import contract from 'assets/svg/contract.svg';
import mobile from 'assets/svg/mobile3.svg';
import files from 'assets/svg/files.svg';
import cursor from 'assets/svg/cursor5.svg';
import checklist from 'assets/svg/checklist6.svg';
import ParcoursElement from './ParcoursElement';
import { getSimulationInfoByArraySelector } from 'features/home/selector.simulation';
import { LANG_STATE } from 'features/home/constants.simulation';

const useStyles = makeStyles((theme: GlobalTheme) => ({
  root: {
    width: '100%',
    margin: '30px auto 0',
  },
  bodyContent: {
    width: '100%',
    height: 'auto',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'center',
    '@media (max-width: 1024px)': {
      '& .MuiListItem-root:nth-of-type(1)': {
        order: 1,
      },
      '& .MuiListItem-root:nth-of-type(2)': {
        order: 2,
      },
      '& .MuiListItem-root:nth-of-type(3)': {
        order: 4,
      },
      '& .MuiListItem-root:nth-of-type(4)': {
        order: 6,
      },
      '& .MuiListItem-root:nth-of-type(5)': {
        order: 5,
      },
      '& .MuiListItem-root:nth-of-type(6)': {
        order: 4,
      },
    },
    '@media (max-width: 768px)': {
      padding: '0px',
    },
  },
  imgTrace: {
    marginLeft: '-38px',
    marginRight: '-38px',
    width: '150px',
    marginBottom: '50px',
    '@media (max-width: 1024px)': {
      display: 'none',
    },
  },
  imgTraceWithFlesh: {
    marginLeft: '0px',
    marginRight: '-76px',
  },
  imgTraceWithFleshAr: {
    marginLeft: '0px',
    marginRight: '-76px',
    transform: 'scaleX(-1)',
  },

  imgTraceUp: {
    marginTop: '-230px',
    marginLeft: '-240px',
    marginRight: '85px',
    zIndex: 0,
    '@media (max-width: 1024px)': {
      display: 'none',
    },
  },
}));

const Parcours = () => {
  const classes = useStyles();
  const wording: Wording = useSelector(selectCompWording('contextual'));
  const langSelected: any =
    useSelector(getSimulationInfoByArraySelector(['lang'])) || LANG_STATE.FR;
  return (
    <div className={classes.root}>
      <div className={classes.bodyContent}>
        <ParcoursElement
          content={wording?.parcours1_conso_page}
          icon={ticksquare}
        />
        <img src={traceWithout} alt={'icon'} className={classes.imgTrace} />
        <ParcoursElement
          content={wording?.parcours2_conso_page}
          icon={contract}
        />
        <img src={traceWithout} alt={'icon'} className={classes.imgTrace} />
        <ParcoursElement
          content={wording?.parcours3_conso_page}
          icon={mobile}
        />
        <ParcoursElement
          content={wording?.parcours6_conso_page}
          icon={checklist}
        />
        <img
          src={traceWithFlesh}
          alt={'icon'}
          className={`${classes.imgTrace} ${
            langSelected === LANG_STATE.FR
              ? classes.imgTraceWithFlesh
              : classes.imgTraceWithFleshAr
          }`}
        />
        <ParcoursElement
          content={wording?.parcours5_conso_page}
          icon={cursor}
        />
        <img src={traceWithout} alt={'icon'} className={classes.imgTrace} />
        <ParcoursElement content={wording?.parcours4_conso_page} icon={files} />
        <img
          src={traceWithout}
          alt={'icon'}
          className={`${classes.imgTrace} ${classes.imgTraceUp}`}
          style={
            langSelected === LANG_STATE.AR
              ? {
                  transform: 'rotate(90deg)',
                }
              : {
                  transform: 'rotate(-90deg)',
                }
          }
        />
      </div>
    </div>
  );
};

export default Parcours;
