import CircularProgress from '@material-ui/core/CircularProgress';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from 'common/components/customFields/CustomTooltip';
import fileExist from 'assets/svg/fileExist.svg';
import fileNotExist from 'assets/svg/fileNotExist.svg';
import trash from 'assets/svg/trash.svg';
import uploadDoc from 'assets/svg/uploadDoc.svg';
import { Wording } from 'common/wording/reducer.wording';
import { GlobalTheme } from 'features/app/app.theme';
import React from 'react';
import { DocumentDetail, UploadDocumentAT } from '../actions.upload';
import { Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { getSimulationInfoByArraySelector } from 'features/home/selector.simulation';
import { LANG_STATE } from 'features/home/constants.simulation';
import { useSelector } from 'react-redux';

const usestyles = makeStyles((theme: GlobalTheme) => ({
  root: {
    display: 'flex',
    borderBottom: '0.063em solid #e1e5eb',
    padding: '21px 0px',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (max-width: 450px)': {
      justifyContent: 'left',
      flexWrap: 'wrap',
    },
  },
  hiddenInput: {
    display: 'none',
  },
  label: {
    display: 'flex',
    width: '65%',
    alignItems: 'center',
    '@media (max-width: 768px)': {
      width: '44%',
    },
    '@media (max-width: 450px)': {
      marginLeft: '15px',
      width: '80%',
      fontSize: '0.8125rem',
    },
    '@media (max-width: 360px)': {
      width: '75%',
    },
  },
  doclabelWrapper: {
    cursor: 'pointer',
    display: 'flex',
    width: '185px',
    justifyContent: 'space-around',
    alignItems: 'center',
    '@media (max-width: 450px)': {
      margin: 'auto',
    },
  },
  doclabelContent: {
    fontFamily: theme.fonts.bold,
    textDecoration: 'underline',
    '@media (max-width: 450px)': {
      fontSize: '0.8125rem',
    },
  },
  imgStyle: {
    paddingRight: '0px',
  },
  numberElement: {
    color: theme.primary.main,
    fontSize: '0.9375rem',
    fontFamily: theme.fonts.bold,
    marginLeft: '5px',
  },
  loaderWrapper: {
    width: '185px',
    display: 'flex',
    justifyContent: 'center',
  },
  loader: {
    width: '35px !important',
    height: '35px !important',
  },
  tooltip: {
    padding: '0',
    marginLeft: '10px',
    marginTop: '-10px',
  },
  fileExistStyle: {
    marginRight: '4px',
  },
}));

interface UploadDocumentProps {
  label: string;
  uploadFile?: (event: any) => void;
  name: string;
  id: string;
  document: DocumentDetail;
  documentWording: Wording;
  inf?: string;
  listDocIds?: any;
}

const UploadDocument = (props: UploadDocumentProps) => {
  const {
    label,
    uploadFile,
    name,
    document,
    id,
    documentWording,
    inf,
    listDocIds,
  } = props;
  const classes = usestyles();
  const dispatch = useDispatch();

  const langSelected: unknown =
    useSelector(getSimulationInfoByArraySelector(['lang'])) || LANG_STATE.FR;

  const loader = document ? document.loader : false;
  const labelDoc = `${documentWording?.documents} ${id}`;

  // tslint:disable-next-line: no-shadowed-variable
  const downloadDoc = (documents: any) => (event: any) => {
    dispatch({
      type: UploadDocumentAT.downloadDocuments,
      documents,
    });
  };

  const deleteDoc = (documents: any) => (event: any) => {
    dispatch({
      type: UploadDocumentAT.deleteDocuments,
      documents,
      listDocIds,
    });
  };

  return (
    <ListItem key={name} className={classes.root}>
      {document ? (
        <img className={classes.fileExistStyle} src={fileExist} alt='' />
      ) : (
        <img className={classes.fileExistStyle} src={fileNotExist} alt='' />
      )}
      <Typography variant='h5' component={'span'} className={classes.label}>
        <div dangerouslySetInnerHTML={{ __html: label }} />
        {inf && (
          <Tooltip
            textAlign={langSelected === LANG_STATE.FR ? 'left' : 'right'}
            content={<div dangerouslySetInnerHTML={{ __html: inf }} />}
          />
        )}
      </Typography>

      {document && !loader && (
        <label htmlFor={`upload-${name}`} className={classes.doclabelWrapper}>
          <span onClick={downloadDoc(document)}>
            <Typography
              variant='h5'
              component={'span'}
              className={classes.doclabelContent}
            >
              {labelDoc}
            </Typography>
            <span className={classes.numberElement}>
              ({document ? document.numberOfFiles : null})
            </span>
          </span>
          <Tooltip content={documentWording?.deleteDocument}>
            <img
              src={trash}
              id={id}
              className={classes.imgStyle}
              onClick={deleteDoc(document)}
              alt=''
            />
          </Tooltip>
        </label>
      )}
      {!document && (
        <label htmlFor={id} className={classes.doclabelWrapper}>
          <input
            name={name}
            onChange={uploadFile}
            disabled={loader}
            className={classes.hiddenInput}
            id={id}
            type='file'
            multiple
          />
          <Typography
            variant='h5'
            component={'span'}
            className={classes.doclabelContent}
          >
            {documentWording?.uploadDocument}
          </Typography>
          <img src={uploadDoc} alt='' className={classes.imgStyle} />
        </label>
      )}

      {document && loader && (
        <div className={classes.loaderWrapper}>
          <CircularProgress color='secondary' className={classes.loader} />
        </div>
      )}
    </ListItem>
  );
};

export default UploadDocument;
