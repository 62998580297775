import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { GlobalTheme } from 'features/app/app.theme';
import Typography from '@material-ui/core/Typography';
import { createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: GlobalTheme) =>
  createStyles({
    header: {
      margin: '15px 5px',
    },
    headerTitle: ({ props }: any) => ({
      textTransform: 'uppercase',
      color: theme.secondary.main,
      fontFamily: theme.fonts.bold,
      fontSize: '1.75rem',
      textAlign:
        props.positionTitle === ''
          ? 'center'
          : props.positionTitle === 'left'
          ? 'left'
          : 'right',
      '@media (max-width: 1024px)': {
        fontSize: '1.45rem',
        width: '100%',
      },
    }),
    borderTitle: ({ props }: any) => ({
      border: '3px solid',
      width: '55px',
      borderRadius: '5px',
      marginTop: '3px',
      margin: 'auto',
      float: props.positionBorder,
    }),
    positionTitle: ({ props }: any) =>
      props.positionTitle === ''
        ? {}
        : props.positionTitle === 'left'
        ? {
            marginLeft: 0,
            paddingLeft: '100px',
            '@media (max-width: 1024px)': {
              paddingLeft: '50px',
            },
            '@media (max-width: 768px)': {
              paddingLeft: '32px !important',
            },
          }
        : {
            marginRight: 0,
            paddingRight: '100px',
            '@media (max-width: 1024px)': {
              paddingRight: '50px',
            },
            '@media (max-width: 768px)': {
              paddingRight: '32px !important',
            },
          },
    blue: {
      borderColor: `${theme.primary.title} !important`,
    },
    pink: {
      borderColor: `${theme.secondary.main} !important`,
    },
  }),
);

interface CustomProps {
  title: string;
  colorBorder: any;
  positionBorder?: string;
  positionTitle?: string;
}

function CustomTitle(props: CustomProps) {
  const { title, colorBorder } = props;
  const classes = useStyles({ props });

  return (
    <div className={`${classes.header} ${classes.positionTitle}`}>
      <Typography variant='h2' className={classes.headerTitle}>
        {title}
      </Typography>
      {colorBorder === 'blue' && (
        <div className={`${classes.borderTitle} ${classes.blue}`} />
      )}
      {colorBorder === 'pink' && (
        <div className={`${classes.borderTitle} ${classes.pink}`} />
      )}
    </div>
  );
}

export default CustomTitle;
