import { toJS } from 'common/utils/app.util';
import { createSelector } from 'reselect';
import { AppStore } from '../../core/root.model';
import { UPDATEINFORMATION_REDUCER } from './reducer.updateInformation';

const getInformation = (state: AppStore) =>
  state.get(UPDATEINFORMATION_REDUCER);

export const getUpdateInfoByArraySelector = (selector: string[]) =>
  createSelector(getInformation, (updateInformation) =>
    toJS(updateInformation.getIn(selector)),
  );
