import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import information from 'assets/svg/document.svg';
import errorUpload from 'assets/svg/errorUpload.svg';
import edit from 'assets/svg/upload.svg';
import { isEmpty } from 'lodash';
import {
  FILE_MALICIOUS,
  MAX_FILE_SIZE_ERROR,
  VERIFY_FILE_EXTENTIONS,
} from 'common/utils/validateJs/contants';
import { selectCompWording } from 'common/wording/selectors.wording';
import { GlobalTheme } from 'features/app/app.theme';
import { useDispatch, useSelector } from 'react-redux';
import { UploadDocumentAT } from './actions.upload';
import UploadDocElement from './components/UploadDocElement';
import { getUploadInfoByArraySelector } from './selector.upload';
import { getRecapInfoByArraySelector } from 'features/recap/selector.recap';
import { getAuthInfoByArraySelector } from 'features/auth/selector.auth';
import { getSimulationInfoByArraySelector } from 'features/home/selector.simulation';
import { LANG_STATE } from 'features/home/constants.simulation';
import {
  AccountTypes,
  ProductTypes,
  pushPageView,
} from 'features/auth/constants';
import { SituationAT } from 'features/situation/actions.situation';
import { getSituationInfoByArraySelector } from 'features/situation/selector.situation';

const useStyles = makeStyles((theme: GlobalTheme) => ({
  root: {
    display: 'flex',
    margin: 'auto',
    flexDirection: 'column',
    alignItems: 'center',
  },
  parag: {
    width: '88%',
    padding: '10px',
    marginBottom: '20px',
  },
  paragTitle: {
    textAlign: 'left',
    fontFamily: theme.fonts.medium,
    color: theme.primary.title,
    textTransform: 'uppercase',
    opacity: 1,
    letterSpacing: '0px',
    fontWeight: 500,
    fontSize: '1.5rem',
    margin: 'auto',
    '@media (max-width: 414px)': {
      fontSize: '1.3125rem',
    },
  },
  paragTitleAr: {
    textAlign: 'left',
    fontFamily: theme.fonts.medium,
    color: theme.primary.title,
    textTransform: 'uppercase',
    opacity: 1,
    letterSpacing: '0px',
    fontWeight: 'bold',
    fontSize: '1.5rem',
    margin: 'auto',
    '@media (max-width: 414px)': {
      fontSize: '1.3125rem',
    },
  },
  paragSubTitle: {
    color: theme.secondary.title,
    fontFamily: theme.fonts.regular,
    fontSize: '0.875rem',
    textAlign: 'left',
    direction: 'ltr',
  },
  groupForm: {
    width: '100%',
    backgroundColor: '#FFF',
    borderRadius: '23px',
    direction: 'ltr',
  },
  groupFormHeader: {
    padding: '25px 45px 45px',
    '& .MuiListItem-root': {
      '&:last-child': {
        borderBottom: 'none',
      },
    },
    '@media (max-width: 450px)': {
      padding: '19px',
    },
  },
  groupFormFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    '@media (max-width: 600px)': {
      position: 'fixed',
      bottom: '2%',
      width: '94%',
      left: '3%',
      justifyContent: 'center',
    },
  },
  buttonValidate: {
    borderRadius: '14px 0',
    textTransform: 'inherit',
    padding: '16px 83px',
    fontFamily: theme.fonts.regular,
    fontSize: '0.875rem',
    '&:hover': {
      backgroundColor: '#F93276',
    },
    '@media (max-width: 600px)': {
      flex: 1,
    },
  },
  uploadDocumentHeader: {
    padding: '30px 53px 0 30px',
    display: 'flex',
    alignItems: 'flex-end',
    '@media (max-width: 450px)': {
      flexWrap: 'wrap',
      padding: '30px 53px 0 53px',
    },
  },
  rightTitleIcon: {
    marginRight: '10px',
    height: '40px',
  },
  rightIcon: {
    marginRight: '10px',
    height: '17px',
  },
  headerTitle: {
    textAlign: 'left',
    fontSize: '1.25rem',
    color: theme.primary.title,
    fontFamily: theme.fonts.medium,
    letterSpacing: '0px',
    flex: 1,
    paddingLeft: 10,
    '@media (max-width: 1333px)': {
      paddingLeft: 50,
    },
    '@media (max-width: 450px)': {
      paddingLeft: 10,
    },
  },
  headerTitleAr: {
    textAlign: 'left',
    fontSize: '1.25rem',
    color: theme.primary.title,
    fontFamily: theme.fonts.medium,
    letterSpacing: '0px',
    flex: 1,
    paddingLeft: 10,
    fontWeight: 'bold',
    '@media (max-width: 1333px)': {
      paddingLeft: 50,
    },
    '@media (max-width: 450px)': {
      paddingLeft: 10,
    },
  },
  error: {
    backgroundColor: '#F932761A',
    width: '100%',
    display: 'flex',
    marginTop: '40px',
  },
  errorMessage: {
    fontSize: '0.9375rem',
    margin: 'auto',
    width: 'max-content',
    color: '#556584',
    fontFamily: theme.fonts.regular,
  },
  psMessage: {
    fontSize: '0.9375rem',
    margin: '30px 20px 0px 40px',
    width: '80%',
    color: theme.primary.title,
    fontFamily: theme.fonts.regular,
  },
  iconErrorUp: {
    width: '43px',
  },
  boldMessage: {
    fontWeight: 'bold',
    fontFamily: theme.fonts.regular,
  },
  uploadButton: {
    marginTop: '20px',
    fontWeight: 'bold',
    '@media (max-width: 450px)': {
      margin: '20px auto',
    },
  },
}));

const UploadDocuments = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const documents: any = useSelector(
    getUploadInfoByArraySelector(['upload', 'documents']),
  );
  const elements: any = useSelector(getUploadInfoByArraySelector(['upload']));
  const langSelected: any =
    useSelector(getSimulationInfoByArraySelector(['lang'])) || LANG_STATE.FR;
  const recap: any = useSelector(
    getRecapInfoByArraySelector(['recap', 'fields']),
  );
  const userProfile: any = useSelector(
    getAuthInfoByArraySelector(['userProfile']),
  );
  const errors: any = useSelector(
    getUploadInfoByArraySelector(['upload', 'errors']),
  );
  // const clientBOA = getSessionStorage('clientBOA');
  const wordingUpload = useSelector(selectCompWording('upload')) || {};
  const documentWording = useSelector(selectCompWording('document'));

  const { clientBOA }: any = useSelector(
    getSituationInfoByArraySelector(['situationSalarie', 'fields']),
  );

  const _accountTypes = !isEmpty(elements.type)
    ? elements.type
    : !isEmpty(recap.typeClient)
    ? recap.typeClient
    : userProfile.typeClient;
  const _productTypes = !isEmpty(elements.product)
    ? elements.product
    : !isEmpty(recap.product)
    ? recap.product
    : userProfile.product;
  // LISTDOC001
  const documentSalarieConventione = [
    {
      name: 'CINS',
      label: wordingUpload?.doc_cins,
      identifiantPiece: '101',
    },
    {
      name: 'JADD',
      label: wordingUpload?.doc_jadd,
      identifiantPiece: '26',
    },
    {
      name: 'DCSS',
      label: wordingUpload?.doc_dcss,
      identifiantPiece: '5',
    },
    {
      name: 'AT',
      label: wordingUpload?.doc_at,
      identifiantPiece: '106',
    },
    {
      name: 'BP001',
      label: wordingUpload?.doc_bp001,
      identifiantPiece: '23',
    },
    {
      name: 'ADS_CNSS',
      label: wordingUpload?.doc_ads_cnss,
      identifiantPiece: '105',
    },
    {
      name: 'EDR',
      label: wordingUpload?.doc_edr,
      identifiantPiece: '25',
    },
    {
      name: 'DRB',
      label: wordingUpload?.doc_drb,
      identifiantPiece: '24',
    },
    {
      name: 'AUT',
      label: wordingUpload?.doc_aut,
      identifiantPiece: '55',
    },
  ];
  // LISTDOC002
  const documentSalarieNonConventione = [
    {
      name: 'CINS',
      label: wordingUpload?.doc_cins,
      identifiantPiece: '101',
    },
    {
      name: 'JADD',
      label: wordingUpload?.doc_jadd,
      identifiantPiece: '26',
    },
    {
      name: 'DCS',
      label: wordingUpload?.doc_dcs,
      identifiantPiece: '5',
    },
    {
      name: 'BP2',
      label: wordingUpload?.doc_bp2,
      identifiantPiece: '104',
    },
    {
      name: 'ADS_CNSS',
      label: wordingUpload?.doc_ads_cnss,
      identifiantPiece: '105',
    },
    {
      name: 'EDR',
      label: wordingUpload?.doc_edr,
      identifiantPiece: '25',
    },
    {
      name: 'DRB',
      label: wordingUpload?.doc_drb,
      identifiantPiece: '24',
    },
    {
      name: 'AUT',
      label: wordingUpload?.doc_aut,
      identifiantPiece: '55',
    },
  ];
  // LISTDOC003
  const documentSalarieProspect = [
    {
      name: 'CINS',
      label: wordingUpload?.doc_cins,
      identifiantPiece: '101',
    },
    {
      name: 'JADD',
      label: wordingUpload?.doc_jadd,
      identifiantPiece: '26',
    },
    {
      name: 'DCS',
      label: wordingUpload?.doc_dcs,
      identifiantPiece: '5',
    },
    {
      name: 'ATS',
      label: wordingUpload?.doc_ats,
      identifiantPiece: '21',
    },
    {
      name: 'BP2',
      label: wordingUpload?.doc_bp2,
      identifiantPiece: '104',
    },
    {
      name: 'DRB3',
      label: wordingUpload?.doc_drb3,
      identifiantPiece: '103',
    },
    {
      name: 'JCB',
      label: wordingUpload?.doc_jcb,
      identifiantPiece: '77',
      inf: 'JCB_TOOLTIP',
    },
    {
      name: 'AUT',
      label: wordingUpload?.doc_aut,
      identifiantPiece: '55',
    },
  ];
  // LISTDOC004
  const documentFonctionnairesConventione = [
    {
      name: 'CINS',
      label: wordingUpload?.doc_cins,
      identifiantPiece: '101',
    },
    {
      name: 'JADD',
      label: wordingUpload?.doc_jadd,
      identifiantPiece: '26',
    },
    {
      name: 'DCS004',
      label: wordingUpload?.doc_dcs004,
      identifiantPiece: '5',
    },
    {
      name: 'BP004',
      label: wordingUpload?.doc_bp004,
      identifiantPiece: '23',
    },
    {
      name: 'EDRC',
      label: wordingUpload?.doc_edrc,
      identifiantPiece: '25',
    },
    {
      name: 'DRB',
      label: wordingUpload?.doc_drb,
      identifiantPiece: '24',
    },
    {
      name: 'AUT',
      label: wordingUpload?.doc_aut,
      identifiantPiece: '55',
    },
  ];
  // LISTDOC005
  const documentFonctionnairesNonConventione = [
    {
      name: 'CINS',
      label: wordingUpload?.doc_cins,
      identifiantPiece: '101',
    },
    {
      name: 'JADD',
      label: wordingUpload?.doc_jadd,
      identifiantPiece: '26',
    },
    {
      name: 'DCS005',
      label: wordingUpload?.doc_dcs005,
      identifiantPiece: '5',
    },
    {
      name: 'BPP',
      label: wordingUpload?.doc_bpp,
      identifiantPiece: '23',
    },
    {
      name: 'EDRC',
      label: wordingUpload?.doc_edrc,
      identifiantPiece: '25',
    },
    {
      name: 'DRB',
      label: wordingUpload?.doc_drb,
      identifiantPiece: '24',
    },
    {
      name: 'AUT',
      label: wordingUpload?.doc_aut,
      identifiantPiece: '55',
    },
  ];
  // LISTDOC006
  const documentFonctionnairesProspect = [
    {
      name: 'CINS',
      label: wordingUpload?.doc_cins,
      identifiantPiece: '101',
    },
    {
      name: 'JADD',
      label: wordingUpload?.doc_jadd,
      identifiantPiece: '26',
    },
    {
      name: 'BPP',
      label: wordingUpload?.doc_bpp,
      identifiantPiece: '23',
    },
    {
      name: 'DCS',
      label: wordingUpload?.doc_dcs,
      identifiantPiece: '5',
    },
    {
      name: 'DRB2',
      label: wordingUpload?.doc_drb2,
      identifiantPiece: '103',
    },
    {
      name: 'JCB',
      label: wordingUpload?.doc_jcb,
      identifiantPiece: '77',
      inf: 'JCB_TOOLTIP',
    },
    {
      name: 'AUT',
      label: wordingUpload?.doc_aut,
      identifiantPiece: '55',
    },
  ];
  // LISTDOC007
  const documentRetraiteConventione = [
    {
      name: 'CINS',
      label: wordingUpload?.doc_cins,
      identifiantPiece: '101',
    },
    {
      name: 'JADD',
      label: wordingUpload?.doc_jadd,
      identifiantPiece: '26',
    },
    {
      name: 'DCSS',
      label: wordingUpload?.doc_dcss,
      identifiantPiece: '5',
    },
    {
      name: 'ATP',
      label: wordingUpload?.doc_atp,
      identifiantPiece: '23',
    },
    {
      name: 'EDRP',
      label: wordingUpload?.doc_edrp,
      identifiantPiece: '25',
    },
    {
      name: 'DRB',
      label: wordingUpload?.doc_drb,
      identifiantPiece: '24',
    },
    {
      name: 'AUT',
      label: wordingUpload?.doc_aut,
      identifiantPiece: '55',
    },
  ];
  // LISTDOC008
  const documentRetraiteNonConventione = [
    {
      name: 'CINS',
      label: wordingUpload?.doc_cins,
      identifiantPiece: '101',
    },
    {
      name: 'JADD',
      label: wordingUpload?.doc_jadd,
      identifiantPiece: '26',
    },
    {
      name: 'DCS',
      label: wordingUpload?.doc_dcs,
      identifiantPiece: '5',
    },
    {
      name: 'ATPP',
      label: wordingUpload?.doc_atpp,
      identifiantPiece: '23',
    },
    {
      name: 'EDRP',
      label: wordingUpload?.doc_edrp,
      identifiantPiece: '25',
    },
    {
      name: 'DRB',
      label: wordingUpload?.doc_drb,
      identifiantPiece: '24',
    },
    {
      name: 'AUT',
      label: wordingUpload?.doc_aut,
      identifiantPiece: '55',
    },
  ];
  // LISTDOC009
  const documentRetraiteProspect = [
    {
      name: 'CINS',
      label: wordingUpload?.doc_cins,
      identifiantPiece: '101',
    },
    {
      name: 'JADD',
      label: wordingUpload?.doc_jadd,
      identifiantPiece: '26',
    },
    {
      name: 'DCS',
      label: wordingUpload?.doc_dcs,
      identifiantPiece: '5',
    },
    {
      name: 'ATP2',
      label: wordingUpload?.doc_atp2,
      identifiantPiece: '23',
    },
    {
      name: 'DRB3',
      label: wordingUpload?.doc_drb3,
      identifiantPiece: '103',
    },
    {
      name: 'JCB',
      label: wordingUpload?.doc_jcb,
      identifiantPiece: '77',
      inf: 'JCB_TOOLTIP',
    },
    {
      name: 'AUT',
      label: wordingUpload?.doc_aut,
      identifiantPiece: '55',
    },
  ];
  // LISTDOC010
  const documentProfessionnelClient = [
    {
      name: 'CINS',
      label: wordingUpload?.doc_cins,
      identifiantPiece: '101',
    },
    {
      name: 'JADD',
      label: wordingUpload?.doc_jadd,
      identifiantPiece: '26',
    },
    {
      name: 'DCS',
      label: wordingUpload?.doc_dcs,
      identifiantPiece: '5',
    },
    {
      name: 'BP2',
      label: wordingUpload?.doc_bp2,
      identifiantPiece: '104',
    },
    {
      name: 'ADS_CNSS',
      label: wordingUpload?.doc_ads_cnss,
      identifiantPiece: '105',
    },
    {
      name: 'EDR',
      label: wordingUpload?.doc_edr,
      identifiantPiece: '25',
    },
    {
      name: 'DRB',
      label: wordingUpload?.doc_drb,
      identifiantPiece: '24',
    },
    {
      name: 'PRO_AE',
      label: wordingUpload?.pro_ae,
      identifiantPiece: '90',
    },
    {
      name: 'PRO_RC',
      label: wordingUpload?.pro_rc,
      identifiantPiece: '91',
    },
    {
      name: 'PRO_TAXE',
      label: wordingUpload?.pro_taxe,
      identifiantPiece: '92',
    },
    {
      name: 'PRO_DG',
      label: wordingUpload?.pro_dg,
      identifiantPiece: '93',
    },
  ];
  // LISTDOC011
  const documentProfessionnelProspect = [
    {
      name: 'CINS',
      label: wordingUpload?.doc_cins,
      identifiantPiece: '101',
    },
    {
      name: 'JADD',
      label: wordingUpload?.doc_jadd,
      identifiantPiece: '26',
    },
    {
      name: 'DCS',
      label: wordingUpload?.doc_dcs,
      identifiantPiece: '5',
    },
    {
      name: 'PRO_SLF_RLV',
      label: wordingUpload?.pro_slf_rlv,
      identifiantPiece: '94',
    },
    {
      name: 'JCB',
      label: wordingUpload?.doc_jcb,
      identifiantPiece: '77',
      inf: 'JCB_TOOLTIP',
    },
    {
      name: 'PRO_JP',
      label: wordingUpload?.pro_doc_jp,
      identifiantPiece: '95',
      inf: 'PRO_JP_TOOLTIP',
    },
    {
      name: 'AUT',
      label: wordingUpload?.doc_aut,
      identifiantPiece: '55',
    },
  ];

  let listDocIds = [] as any;
  if (_accountTypes === AccountTypes.salarie) {
    if (_productTypes === ProductTypes.bconvcp) {
      listDocIds = listDocIds.concat(documentSalarieConventione); // documentSalarieConventione LISTDOC001
    } else if (_productTypes === ProductTypes.cp) {
      listDocIds = listDocIds.concat(documentSalarieNonConventione); // documentSalarieNonConventione LISTDOC002
    } else {
      listDocIds = listDocIds.concat(documentSalarieProspect); // documentSalarieProspect LISTDOC003
    }
  } else if (_accountTypes === AccountTypes.fonctionnaire) {
    if (_productTypes === ProductTypes.bconvppr) {
      listDocIds = listDocIds.concat(documentFonctionnairesConventione); // documentFonctionnairesConventione LISTDOC004
    } else if (_productTypes === ProductTypes.ppr) {
      listDocIds = listDocIds.concat(documentFonctionnairesNonConventione); // documentFonctionnairesNonConventione LISTDOC005
    } else {
      listDocIds = listDocIds.concat(documentFonctionnairesProspect); // documentFonctionnairesProspect LISTDOC006
    }
  } else if (_accountTypes === AccountTypes.retraite) {
    if (_productTypes === ProductTypes.bconvcp) {
      listDocIds = listDocIds.concat(documentRetraiteConventione); // documentRetraiteConventione LISTDOC007
    } else if (_productTypes === ProductTypes.cp) {
      listDocIds = listDocIds.concat(documentRetraiteNonConventione); // documentRetraiteNonConventione LISTDOC008
    } else {
      listDocIds = listDocIds.concat(documentRetraiteProspect); // documentRetraiteProspect LISTDOC009
    }
  } else if (_accountTypes === AccountTypes.professionnel) {
    if (
      _productTypes === ProductTypes.cp_slf ||
      (_productTypes === ProductTypes.cp && !clientBOA)
    ) {
      listDocIds = listDocIds.concat(documentProfessionnelProspect); // documentProfessionnelClient LISTDOC011
    } else if (_productTypes === ProductTypes.cp) {
      listDocIds = listDocIds.concat(documentProfessionnelClient); // documentProfessionnelClient LISTDOC010
    }
  }
  // Retourner les pieces du simulation par le jwt d'utilisateur qui contient l'id simulation
  useEffect(() => {
    dispatch({
      type: UploadDocumentAT.getDocuments,
      listDocIds,
    });
    pushPageView('step_5_upload_pj');
    // eslint-disable-next-line react-hooks/exhaustive-deps,
  }, []);

  const uploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target && event.target.files && event.target.files.length > 0) {
      const identifiantPiece = event.target.name;
      const label = event.target.id;
      const files = event.target.files;
      submitDocuments(files, label, identifiantPiece);
    }
  };
  const submitDocuments = (
    files: any,
    label: string,
    identifiantPiece: string,
  ) => {
    dispatch({
      type: UploadDocumentAT.submitDocument,
      files,
      label,
      identifiantPiece,
    });
  };
  const validatDocuments = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch({
      type: SituationAT.saveOrConfirmeSituation,
      name: 'confirme',
    });
    dispatch({ type: UploadDocumentAT.validateUploadDocumentsStep });
  };
  const downloadDocs = () => {
    const link = document.createElement('a');
    if (_accountTypes === AccountTypes.salarie) {
      if (_productTypes === ProductTypes.bconvcp) {
        link.download = 'LISTDOC001';
        link.href = 'pdf/LISTDOC001.pdf';
      } else if (_productTypes === ProductTypes.cp) {
        link.download = 'LISTDOC002';
        link.href = 'pdf/LISTDOC002.pdf';
      } else {
        link.download = 'LISTDOC003';
        link.href = 'pdf/LISTDOC003.pdf';
      }
    } else if (_accountTypes === AccountTypes.fonctionnaire) {
      if (_productTypes === ProductTypes.bconvppr) {
        link.download = 'LISTDOC004';
        link.href = 'pdf/LISTDOC004.pdf';
      } else if (_productTypes === ProductTypes.ppr) {
        link.download = 'LISTDOC005';
        link.href = 'pdf/LISTDOC005.pdf';
      } else {
        link.download = 'LISTDOC006';
        link.href = 'pdf/LISTDOC006.pdf';
      }
    } else if (_accountTypes === AccountTypes.retraite) {
      if (_productTypes === ProductTypes.bconvcp) {
        link.download = 'LISTDOC007';
        link.href = 'pdf/LISTDOC007.pdf';
      } else if (_productTypes === ProductTypes.cp) {
        link.download = 'LISTDOC008';
        link.href = 'pdf/LISTDOC008.pdf';
      } else {
        link.download = 'LISTDOC009';
        link.href = 'pdf/LISTDOC009.pdf';
      }
    } else if (_accountTypes === AccountTypes.professionnel) {
      if (
        _productTypes === ProductTypes.cp_slf ||
        (_productTypes === ProductTypes.cp && !clientBOA)
      ) {
        link.download = 'LISTDOC011';
        link.href = 'pdf/LISTDOC011.pdf';
      } else if (_productTypes === ProductTypes.cp) {
        link.download = 'LISTDOC010';
        link.href = 'pdf/LISTDOC010.pdf';
      }
    }
    link.click();
  };
  return (
    <div className={classes.root}>
      <div className={classes.parag}>
        <h2
          className={
            langSelected === LANG_STATE.FR
              ? classes.paragTitle
              : classes.paragTitleAr
          }
        >
          {wordingUpload?.titleUpload}
        </h2>
        <p className={classes.paragSubTitle}>
          {wordingUpload?.subTitleUpload?.split('  ').map((line: string) => (
            <React.Fragment key={line}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </p>
        <p className={classes.paragSubTitle}>
          {langSelected === LANG_STATE.FR ? (
            <>
              En cas de difficulté rencontrée, vous pouvez adresser vos
              documents par email à l'adresse suivante :
            </>
          ) : (
            <>
              في حالة مواجهة صعوبة ، يمكنك إرسال المستندات عن طريق البريد
              الإلكتروني على العنوان التالي :
            </>
          )}
          <br />
          <strong>credit-daba@bankofafrica.ma</strong>
        </p>
      </div>
      <form className={classes.groupForm} onSubmit={validatDocuments}>
        <div className={classes.uploadDocumentHeader}>
          <img src={information} alt='' className={classes.rightTitleIcon} />
          <Typography
            variant='h4'
            className={
              langSelected === LANG_STATE.FR
                ? classes.headerTitle
                : classes.headerTitleAr
            }
          >
            {wordingUpload?.myDocuments}
          </Typography>
          <Button
            id='downloadDocs'
            variant='contained'
            className={classes.uploadButton}
            onClick={downloadDocs}
          >
            <img src={edit} alt='downloadList' className={classes.rightIcon} />
            {wordingUpload?.downloadList}
          </Button>
        </div>
        {errors === MAX_FILE_SIZE_ERROR && (
          <div className={classes.error}>
            <img src={errorUpload} alt='' className={classes.iconErrorUp} />
            <p className={classes.errorMessage}>
              {wordingUpload?.firstContentErr}
              <span className={classes.boldMessage}>
                {wordingUpload?.secondContentErr}{' '}
              </span>
              {wordingUpload?.thirdContentErr}
            </p>
          </div>
        )}
        {errors === VERIFY_FILE_EXTENTIONS && (
          <div className={classes.error}>
            <img src={errorUpload} alt='' className={classes.iconErrorUp} />
            <p className={classes.errorMessage}>
              {wordingUpload?.fileExtensionError}
            </p>
          </div>
        )}
        {errors === FILE_MALICIOUS && (
          <div className={classes.error}>
            <img src={errorUpload} alt='' className={classes.iconErrorUp} />
            <p className={classes.errorMessage}>
              {wordingUpload?.kasperskScanError}
            </p>
          </div>
        )}
        {_productTypes === ProductTypes.bconvcp && clientBOA && (
          <p className={classes.psMessage}>
            <span className={classes.boldMessage}>{wordingUpload?.PS}</span>
          </p>
        )}
        <List className={classes.groupFormHeader}>
          {listDocIds.map((id: any) => {
            return (
              <UploadDocElement
                key={id.identifiantPiece}
                id={id.label}
                listDocIds={listDocIds}
                name={id.identifiantPiece}
                document={documents[id.label]}
                uploadFile={uploadFile}
                label={wordingUpload[id.name]}
                documentWording={documentWording}
                inf={wordingUpload[id.inf]}
              />
            );
          })}
        </List>
        <div className={classes.groupFormFooter}>
          <Button
            type='submit'
            className={classes.buttonValidate}
            variant='contained'
            color='secondary'
            disabled={isEmpty(documents)}
          >
            {wordingUpload?.validate}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default UploadDocuments;
