import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import whatsapp from 'assets/svg/WP.svg';
import { getSimulationInfoByArraySelector } from 'features/home/selector.simulation';
import {
  selectWording,
  selectWordingLoading,
} from 'common/wording/selectors.wording';
import { Wording } from 'common/wording/reducer.wording';
import { setLangAction } from 'features/home/actions.simulation';
import logo from 'assets/images/logo.png';
import logoMobile from 'assets/svg/logoMobile.svg';
import Button from '@material-ui/core/Button';
import { GlobalTheme } from 'features/app/app.theme';
import { pushEvent } from 'features/auth/constants';
import useIsAuthenticated from 'common/hooks/useIsAuthenticate';
import Logout from 'common/components/layout/Logout';
import { MenuItem } from '@material-ui/core';
import { LANG_STATE } from 'features/home/constants.simulation';
import Spinner from 'common/components/Spinner';
import { FR, AR } from 'features/app/app.constants';

const useStyles = makeStyles((theme: GlobalTheme & Theme) => ({
  root: {
    padding: '0 2%',
    display: 'flex',
    justifyContent: 'space-between',
    height: '75px',
    top: 0,
    alignItems: 'center',
    borderRadius: '0 0 20px 20px',
    '@media (max-width: 768px)': {
      background: 'transparent',
    },
  },
  logo: {
    display: 'block',
    width: '200px',
    '@media (max-width: 425px)': {
      content: `url(${logoMobile})`,
      width: '180px',
      margin: '10px',
    },
  },
  homeRight: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    justifyItems: 'center',
    justifySelf: 'center',
  },
  connexion: {
    backgroundColor: '#00B2BB',
    margin: '0 5px 0 5px',
    fontFamily: theme.fonts.medium,
    fontSize: '0.75rem',
    letterSpacing: '0.3px',
    '&:hover': {
      backgroundColor: theme.primary.title,
    },
    padding: '9px 34px',
    height: 'max-content',
    '@media (max-width: 768px)': {
      padding: '7px 15px',
    },
  },
  langSelect: {
    fontFamily: theme.fonts.bold,
    fontSize: '0.85rem',
    fontWeight: 900,
    color: 'white',
    background: '#095098',
    borderRadius: '20px',
    width: '55px',
    height: '40px',
    maxHeight: '40px',
    minHeight: '40px',
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    margin: '0.5rem',
    '&:hover': {
      fontFamily: theme.fonts.bold,
      fontSize: '0.85rem',
      fontWeight: 900,
      color: '#095098',
    },
    '@media (max-width: 768px)': {
      width: '40px',
      height: 'auto',
      minHeight: '35px',
    },
  },
  langSelectExtraAr: {
    '& div': {
      width: '35px',
      background: 'transparent',
      textOverflow: 'clip !important',
      color: theme.primary.title,
      fontFamily: 'tajawal-bold',
      fontSize: '0.75rem',
      paddingRight: '5px !important',
      paddingLeft: '5px !important',
      '&:focus': {
        background: 'transparent',
      },
    },
    '& .MuiSelect-icon': {
      color: theme.primary.title,
    },
    color: theme.primary.title,
    fontFamily: 'tajawal-bold',
    fontSize: '0.75rem',
    border: 'none',
  },
  langSelectItem: {
    '& div': {
      width: '35px',
      background: 'transparent',
      textOverflow: 'clip !important',
      color: theme.primary.title,
      fontFamily: 'tajawal-bold',
      fontSize: '0.75rem',
      paddingRight: '5px !important',
      paddingLeft: '5px !important',
      '&:focus': {
        background: 'transparent',
      },
    },
    '& .MuiSelect-icon': {
      color: theme.primary.title,
    },
    color: theme.primary.title,
    fontFamily: 'tajawal-bold',
    fontSize: '1rem',
    border: 'none',
  },
  langSelectAr: {
    marginTop: '0.8em',
    '@media (max-width: 768px)': {
      marginTop: '0',
    },
    '& div': {
      width: '35px',
      background: 'transparent',
      textOverflow: 'clip !important',
      color: theme.primary.title,
      fontFamily: theme.fonts.bold,
      fontSize: '0.875rem',
      paddingRight: '5px !important',
      paddingLeft: '5px !important',
      '&:focus': {
        background: 'transparent',
      },
    },
    '& .MuiSelect-icon': {
      color: theme.primary.title,
      paddingRight: '25px !important',
      paddingLeft: '25px !important',
    },
    border: 'none',
  },
  langSelectItemAr: {
    fontSize: '0.85rem',
    fontWeight: 'bold',
    color: 'white',
    background: '#095098',
    borderRadius: '20px',
    width: '55px',
    height: '40px',
    maxHeight: '40px',
    minHeight: '40px',
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    margin: '0.5rem',
    '&:hover': {
      fontFamily: theme.fonts.boldAr,
      fontSize: '0.85rem',
      fontWeight: 900,
      color: '#095098',
    },
    '@media (max-width: 768px)': {
      width: '40px',
      height: 'auto',
      minHeight: '35px',
      lineHeight: '0.4',
    },
  },
  logoWhatsapp: {
    position: 'fixed',
    right: '-10px',
    zIndex: 1000,
    bottom: '60px',
    '@media (min-width: 769px)': {
      display: 'none',
    },
  },
  logoWhatsappAr: {
    position: 'fixed',
    left: '-25px',
    zIndex: 1000,
    bottom: '60px',
    '@media (min-width: 769px)': {
      display: 'none',
    },
  },
  logoWhatsappNone: {
    display: 'none',
  },
  logoWhatsappImg: {
    display: 'block',
    width: '110px',
    height: '110px',
  },
}));

const LoginHomePage = () => {
  const classes = useStyles();
  const wording: Wording = useSelector(selectWording);
  const wordingLoader = useSelector(selectWordingLoading);
  const history = useHistory();
  const location = useLocation();
  const toConnexion = () => {
    let eventObj = {
      event: 'click_cta',
      click_label: 'ma_demande',
      element_category: 'header',
    };
    pushEvent(eventObj);
    history.push('/sign-in');
  };

  const isAuthenticated = useIsAuthenticated();

  const langSelected: any =
    useSelector(getSimulationInfoByArraySelector(['lang'])) || LANG_STATE.FR;
  const dispatch = useDispatch();

  const handleWhatsapp = () => {
    window.open(
      `https://api.whatsapp.com/send?phone=${wording?.social.phoneWhatsappValue}&text&app_absent=0`,
      '_blank',
    );
    let eventObj = {
      event: 'click_icon',
      click_label: 'whatsapp',
      element_category: 'body',
    };
    pushEvent(eventObj);
  };

  const handleChange = (nextLang: any) => {
    dispatch(setLangAction(nextLang));
    // force redirection if route is home page to the right lang URL
    if (nextLang === LANG_STATE.FR && [AR].includes(location.pathname)) {
      history.push(FR);
    } else if (
      nextLang === LANG_STATE.AR &&
      [FR, '/home', '/', '/credit-conso'].includes(location.pathname)
    ) {
      history.push(AR);
    }
  };

  return (
    <div>
      <Spinner loader={wordingLoader} />
      <div
        className={
          location.pathname === '/home' ||
          location.pathname === '/' ||
          location.pathname === AR ||
          location.pathname === FR ||
          location.pathname === '/credit-conso'
            ? langSelected !== LANG_STATE.FR
              ? classes.logoWhatsappAr
              : classes.logoWhatsapp
            : classes.logoWhatsappNone
        }
      >
        <img
          onClick={handleWhatsapp}
          className={classes.logoWhatsappImg}
          alt={wording?.alt.logo_wapp_home}
          title={wording?.homePage.logoWhatsappImg}
          src={whatsapp}
        />
      </div>
      <div className={classes.root} style={{ direction: 'ltr' }}>
        <Link to='/'>
          <img
            className={classes.logo}
            alt={wording?.alt.logo_BOA}
            src={logo}
          />
        </Link>

        <div className={classes.homeRight}>
          {langSelected !== LANG_STATE.FR ? (
            <MenuItem
              className={classes.langSelect}
              onClick={() => handleChange(LANG_STATE.FR)}
              value={wording?.homePage.labelfr}
            >
              {wording?.homePage.labelfr}
            </MenuItem>
          ) : (
            <MenuItem
              className={classes.langSelectItemAr}
              onClick={() => handleChange(LANG_STATE.AR)}
              value={wording?.homePage.labelar}
            >
              {wording?.homePage.labelar}
            </MenuItem>
          )}

          {!isAuthenticated ? (
            <Button
              type='button'
              variant='contained'
              color='primary'
              className={classes.connexion}
              onClick={toConnexion}
              title={wording?.homePage.logInTitle}
            >
              {wording?.homePage.connexion}
            </Button>
          ) : (
            <div>
              <Logout />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginHomePage;
