import AppLayout from 'common/components/layout/AppLayout';
import { selectWording } from 'common/wording/selectors.wording';
import Auth from 'features/auth/Auth';
import React, { ReactElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { stepsName } from './constants';
import Proposition from 'features/proposition/Proposition';
import DerogationProposition from 'features/proposition/DerogationProposition';
import RejectProposition from 'features/proposition/RejectProposition';
import Situation from 'features/situation/Situation';
import Recap from 'features/recap/Recap';
import Congratulation from 'features/congratulation/Congratulation';
import CaseTracking from 'features/suivieDossier/CaseTracking';
import Simulation from 'features/home/components/Simulation';
import UploadDocuments from 'features/upload/UploadDocuments';
import CreationProfileBMCE from '../auth/components/CreationProfileBMCE';
import CreateProfile from 'features/auth/components/CreateProfile';
import Salarie from 'features/situation/components/salarie/Salarie';
import Professionnel from 'features/situation/components/professionnel/Professionnel';
import Retraite from 'features/situation/components/retraite/Retraite';
import Fonctionnaire from 'features/situation/components/fonctionnaire/Fonctionnaire';
import ModifySimulation from 'features/home/components/ModifySimulation';
import UpdateInformation from 'features/updateInformation/UpdateInformation';
import { AccountTypes } from 'features/auth/constants';
import PreattSalarie from './../situation/components/preattributionSalarie/PreattSalarie';
import PreattFonctionnaire from './../situation/components/preattributionFonctionnaire/PreattFonctionnaire';
import PreattRetraite from './../situation/components/preattributionRetraite/PreattRetraite';
import { scrollToElement } from 'common/utils/app.util';

interface Step {
  key: number;
  id: string;
  name: string;
  url: string;
  component: () => ReactElement<any>;
}

const steps: Step[] = [
  {
    key: 0,
    id: stepsName.quickSimulation,
    name: stepsName.quickSimulation,
    url: '/quick-simulation',
    component: Simulation,
  },
  {
    key: 1,
    id: stepsName.auth,
    name: stepsName.auth,
    url: '/sign-up',
    component: Auth,
  },
  {
    key: 2,
    id: stepsName.mySituation,
    name: stepsName.mySituation,
    url: '/my-situation',
    component: Situation,
  },
  {
    key: 3,
    id: stepsName.proposition,
    name: stepsName.proposition,
    url: '/proposition',
    component: Proposition,
  },
  {
    key: 4,
    id: stepsName.derogationProposition,
    name: stepsName.derogationProposition,
    url: '/derogation-proposition',
    component: () => <DerogationProposition />,
  },
  {
    key: 5,
    id: stepsName.rejectProposition,
    name: stepsName.rejectProposition,
    url: '/reject-proposition',
    component: () => <RejectProposition withProposal={false} />,
  },
  {
    key: 6,
    id: stepsName.recap,
    name: stepsName.recap,
    url: '/recap',
    component: Recap,
  },
  {
    key: 7,
    id: stepsName.congra,
    name: stepsName.congra,
    url: '/congra',
    component: Congratulation,
  },
  {
    key: 8,
    id: stepsName.caseTracking,
    name: stepsName.caseTracking,
    url: '/case-tracking',
    component: CaseTracking,
  },
  {
    key: 9,
    id: stepsName.uploadDocuments,
    name: stepsName.uploadDocuments,
    url: '/upload-documents',
    component: UploadDocuments,
  },
  {
    key: 10,
    id: stepsName.creationProfileBMCE,
    name: stepsName.creationProfileBMCE,
    url: '/create-bmce-profile',
    component: CreationProfileBMCE,
  },
  {
    key: 11,
    id: stepsName.createProfile,
    name: stepsName.createProfile,
    url: '/create-profile',
    component: CreateProfile,
  },
  {
    key: 12,
    id: AccountTypes.salarie,
    name: AccountTypes.salarie,
    url: '/salarie',
    component: Salarie,
  },
  {
    key: 13,
    id: AccountTypes.retraite,
    name: AccountTypes.retraite,
    url: '/retraite',
    component: Retraite,
  },
  {
    key: 14,
    id: AccountTypes.fonctionnaire,
    name: AccountTypes.fonctionnaire,
    url: '/fonctionnaire',
    component: Fonctionnaire,
  },
  {
    key: 15,
    id: stepsName.modifySimulation,
    name: stepsName.modifySimulation,
    url: '/modify-simulation',
    component: ModifySimulation,
  },
  {
    key: 16,
    id: stepsName.updateInformation,
    name: stepsName.updateInformation,
    url: '/update-information',
    component: UpdateInformation,
  },
  {
    key: 17,
    id: stepsName.preattributionSalarie,
    name: stepsName.preattributionSalarie,
    url: '/preattributionSalarie',
    component: PreattSalarie,
  },
  {
    key: 18,
    id: stepsName.preattributionFonctionnaire,
    name: stepsName.preattributionFonctionnaire,
    url: '/preattributionFonctionnaire',
    component: PreattFonctionnaire,
  },
  {
    key: 19,
    id: stepsName.preattributionRetraite,
    name: stepsName.preattributionRetraite,
    url: '/preattributionRetraite',
    component: PreattRetraite,
  },
  {
    key: 20,
    id: AccountTypes.professionnel,
    name: AccountTypes.professionnel,
    url: '/professionnel',
    component: Professionnel,
  },
];

const StepContainer = (props: any) => {
  const wording = useSelector(selectWording);
  const location = props.location.pathname;

  /* ######## scrol to top on location change ########  */
  useEffect(() => {
    scrollToElement(undefined, 1);
  }, [location]);
  /* ######## END scrol change ######## */
  return (
    <AppLayout wording={wording}>
      {steps.map((step) => {
        if (location?.startsWith(step.url)) {
          return <step.component key={step.key} />;
        }
        return null;
      })}
    </AppLayout>
  );
};

export default StepContainer;
