import bigInt from 'big-integer';
import { INVALID_RIB } from './contants';
/* #### EXAMPLE OF A VALID BOA RIB 
@@@  011780000016200000541667  @@@@
########               #########    */
export const ribValidation = (rib: string) => {
  console.log('RIB TO VALIDATE:', rib);
  const newRib = rib?.replace(/ /g, '');
  if (newRib.length !== 24 || !/^\d+$/.test(newRib)) {
    return { rib: [INVALID_RIB] };
  }

  // check rib structure
  const isValidRib = bigInt(
    bigInt(newRib.substring(0, 12))
      .mod(97)
      .toString()
      .concat(newRib.substring(12, 22))
      .concat('00'),
  )
    .mod(97)
    .minus(97)
    .multiply(-1)
    .equals(parseInt(newRib.substring(22, 24), 10));

  // is rib of BOA
  if (isValidRib && !newRib.startsWith('011')) {
    return { rib: [INVALID_RIB] };
  }

  // return check result
  if (!isValidRib) {
    return { rib: [INVALID_RIB] };
  } else {
    return null;
  }
};
