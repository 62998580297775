import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from 'assets/images/closeIcon.png';
import iconInfo from 'assets/svg/iconInfo.svg';
import iconServiceDown from 'assets/svg/iconServiceDown.svg';
import React from 'react';
import { GlobalTheme } from 'features/app/app.theme';
import { Wording } from 'common/wording/reducer.wording';
import { useSelector } from 'react-redux';
import { selectCompWording } from 'common/wording/selectors.wording';
import Typography from '@material-ui/core/Typography';
import {
  ERROR_CHANGE_TEL,
  ERROR_CLIENT_SALAFIN,
  COMPTE_MULTI_TITULAIRE,
  TOKEN_BMCEDIRECT_EXPIRED,
  EXCEED_MAX_ATTEMPTS,
  DUPLICATE_EMAIL,
  // DURATION_OR_AMOUNT_NULL,
} from 'common/utils/validateJs/contants';
import { errorsListFromBmceDirect } from 'features/app/app.constants';

const useStyles = makeStyles((theme: GlobalTheme) => ({
  header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'left',
    margin: '70px 90px 10px 90px',
    '@media (max-width: 768px)': {
      margin: '70px 30px 10px 30px',
    },
  },
  closeIcon: {
    position: 'absolute',
    right: '20px',
    top: '30px',
    width: '15px',
    cursor: 'pointer',
  },
  conditionOfUseFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonSubmit: {
    borderRadius: '14px 0px 0px',
    textTransform: 'inherit',
    padding: '14px 50px',
    fontFamily: theme.fonts.regular,
    fontSize: '0.6875rem',
    width: '25%',
    '&:hover': {
      backgroundColor: theme.secondary.main,
    },
  },
  popup: {
    '& .MuiDialog-paperFullWidth': {
      width: '40%',
      margin: 'auto',
      '@media (max-width: 1024px)': {
        width: '60%',
      },
      '@media (max-width: 450px)': {
        width: '85%',
      },
    },
  },
  textInfo: {
    padding: '5px',
    textAlign: 'center',
    margin: '25px auto',
    color: theme.primary.title,
    '@media (max-width: 450px)': {
      textAlign: 'left',
    },
  },
  message: {
    fontSize: '0.9375rem',
    color: '#556584',
    fontFamily: theme.fonts.regular,
  },
  bold: {
    color: theme.primary.title,
    fontFamily: `${theme.fonts.bold} !important`,
    textDecoration: 'underline',
  },
  pointer: {
    cursor: 'pointer',
  },
  iconServiceDown: {
    width: '84px',
    height: '84px',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
  },
  infoIcon: {
    width: '43px',
    margin: 'auto',
  },
}));

interface Props {
  open: boolean;
  onClose: () => void;
  onValidate?: () => void;
  error: string;
}

function PopUpInfo({ open, onClose, onValidate, error }: Props) {
  const wording: Wording = useSelector(selectCompWording('simulation'));
  const classes = useStyles();

  const handleClose = () => {
    onClose();
  };
  return (
    <Dialog
      fullWidth={true}
      maxWidth='sm'
      open={open}
      onClose={handleClose}
      className={classes.popup}
    >
      {error === ERROR_CHANGE_TEL && (
        <div className={classes.header}>
          <img
            src={CloseIcon}
            className={classes.closeIcon}
            onClick={handleClose}
            alt=''
          />
          <img className={classes.infoIcon} src={iconInfo} alt='' />
          <Typography variant='h3' className={classes.textInfo}>
            <span className={classes.message}>{wording?.contMaxEmployOne}</span>{' '}
            <span className={classes.message}>{wording?.contMaxEmployTwo}</span>{' '}
            <span
              className={`${classes.message} ${classes.bold} ${classes.pointer}`}
            >
              {wording?.contMaxEmployThree}
            </span>{' '}
            <span className={classes.message}>
              {wording?.contMaxEmployFour}
            </span>{' '}
            <span
              className={`${classes.message} ${classes.bold} ${classes.pointer}`}
            >
              {wording?.contMaxEmployFive}
            </span>{' '}
          </Typography>
        </div>
      )}
      {error === COMPTE_MULTI_TITULAIRE && (
        <div className={classes.header}>
          <img className={classes.infoIcon} src={iconInfo} alt='' />
          <Typography variant='h3' className={classes.textInfo}>
            <span className={classes.message}>
              {wording?.compteMultiTitulaire}
            </span>{' '}
          </Typography>
        </div>
      )}
      {error === DUPLICATE_EMAIL && (
        <div className={classes.header}>
          <img className={classes.infoIcon} src={iconInfo} alt='' />
          <Typography variant='h3' className={classes.textInfo}>
            <span className={classes.message}>{wording?.duplicateEmail}</span>{' '}
          </Typography>
        </div>
      )}
      {error === ERROR_CLIENT_SALAFIN && (
        <div className={classes.header}>
          <img
            src={CloseIcon}
            className={classes.closeIcon}
            onClick={handleClose}
            alt=''
          />
          <img
            className={classes.iconServiceDown}
            src={iconServiceDown}
            alt=''
          />
          <Typography variant='h3' className={classes.textInfo}>
            <span className={classes.message}>
              {wording?.serviceSalafinDown}
            </span>{' '}
          </Typography>
        </div>
      )}
      {errorsListFromBmceDirect.includes(error) && (
        <div className={classes.header}>
          <img
            className={classes.iconServiceDown}
            src={iconServiceDown}
            alt=''
          />
          <Typography variant='h3' className={classes.textInfo}>
            {/*  wording à ajouter au niveau de  strapi */}
            <span className={classes.message}>
              { error === EXCEED_MAX_ATTEMPTS
                ? wording?.exceedAttempts
                : error === TOKEN_BMCEDIRECT_EXPIRED
                ? wording?.tokenExpire
                :wording?.serviceDown
                }
            </span>{' '}
          </Typography>
        </div>
      )}

      <div className={classes.conditionOfUseFooter}>
        {!errorsListFromBmceDirect.includes(error) &&
          error !== DUPLICATE_EMAIL &&
          error !== COMPTE_MULTI_TITULAIRE && (
            <Button
              className={classes.buttonSubmit}
              variant='contained'
              color='secondary'
              onClick={handleClose}
            >
              {wording?.close}
            </Button>
          )}
      </div>
    </Dialog>
  );
}

export default PopUpInfo;
