export enum UploadDocumentAT {
  initUploadDocumentAT = 'UPLOADDOCUMENTAT/INITUPLOADDOCUMENTAT',
  updateDocumentInfo = 'UPLOADDOCUMENTAT/UPDATEDOCUMENTINFO',
  updateDocuments = 'UPLOADDOCUMENTAT/UPDATEDOCUMENTS',
  submitDocument = 'UPLOADDOCUMENTAT/SUBMITDOCUMENT',
  submitDocumentError = 'UPLOADDOCUMENTAT/SUBMITDOCUMENTERROR',
  getDocuments = 'UPLOADDOCUMENTAT/GETDOCUMENTS',
  getDocumentsError = 'UPLOADDOCUMENTAT/GETDOCUMENTSERROR',
  validateUploadDocumentsStep = 'UPLOADDOCUMENTAT/VALIDATEUPLOADDOCUMENTSSTEP',
  updateField = 'UPLOADDOCUMENTAT/UPDATEFIELD',
  downloadDocuments = 'UPLOADDOCUMENTAT/DOWNLOADDOCUMENTS',
  deleteDocuments = 'UPLOADDOCUMENTAT/DELETEDOCUMENTS',
  deleteDocumentSuccess = 'UPLOADDOCUMENTAT/DELETEDOCUMENTSUCCESS',
}

export interface InitUploadDocumentATAction {
  type: UploadDocumentAT.initUploadDocumentAT;
}

export interface Document {
  loader: boolean;
  fileName: string;
}

export interface SubmitDocumentsAction {
  type: UploadDocumentAT.submitDocument;
  files: File[];
  label: string;
  identifiantPiece: string;
}

export interface GetDocumentsAction {
  type: UploadDocumentAT.getDocuments;
  listDocIds: [];
}

export function getDocumentsAction(listDocIds: any): GetDocumentsAction {
  return {
    listDocIds,
    type: UploadDocumentAT.getDocuments,
  };
}

export interface DownloadDocumentsAction {
  type: UploadDocumentAT.downloadDocuments;
  documents: any;
}

export interface DeleteDocumentsAction {
  type: UploadDocumentAT.deleteDocuments;
  documents: any;
  listDocIds: [];
}

export interface DeleteDocumentSuccessAction {
  type: UploadDocumentAT.deleteDocumentSuccess;
  label: string;
}

export function deleteDocumentSuccessAction(
  label: string,
): DeleteDocumentSuccessAction {
  return {
    label,
    type: UploadDocumentAT.deleteDocumentSuccess,
  };
}

export interface Document {
  loader: boolean;
  fileName: string;
  numberFiles: number;
}

export interface Documents {
  [key: string]: DocumentDetail;
}

export interface DocumentDetail {
  identifiantPiece: string;
  label: string;
  numberOfFiles: number;
  loader: boolean;
}

export interface SubmitDocumentErrorsAction {
  type: UploadDocumentAT.submitDocumentError;
  error: any;
}

export function errorDocumentUpAction(error: any): SubmitDocumentErrorsAction {
  return {
    error,
    type: UploadDocumentAT.submitDocumentError,
  };
}

export interface ValidateDocumentsAction {
  type: UploadDocumentAT.validateUploadDocumentsStep;
}

export interface UpdateDocumentsAction {
  type: UploadDocumentAT.updateDocuments;
  value: any;
}

export function updateDocumentsAction(value: any): UpdateDocumentsAction {
  return {
    value,
    type: UploadDocumentAT.updateDocuments,
  };
}

export interface UpdateDocumentInfoAction {
  type: UploadDocumentAT.updateDocumentInfo;
  label: string;
  key: string;
  value: number | boolean | string;
}

export function updateDocumentInfoAction(
  label: string,
  key: string,
  value: number | boolean | string,
): UpdateDocumentInfoAction {
  return {
    label,
    key,
    value,
    type: UploadDocumentAT.updateDocumentInfo,
  };
}

export interface UpdateFieldUpAction {
  type: UploadDocumentAT.updateField;
  step: string;
  name: string;
  value: string;
}

export const updateFieldUpAction = (
  step: string,
  name: string,
  value: string,
): UpdateFieldUpAction => ({
  type: UploadDocumentAT.updateField,
  step,
  name,
  value,
});
