import { createStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import Tooltip from 'common/components/customFields/CustomTooltip';
import { GlobalTheme } from 'features/app/app.theme';
import React from 'react';

const useStyles = makeStyles((theme: GlobalTheme) =>
  createStyles({
    input: (hasDefaultBorder) => ({
      '& .MuiInputBase-input': hasDefaultBorder
        ? {
            border: `solid 0.063em ${theme.primary.gray}`,
          }
        : {},
      '& .Mui-disabled': {
        backgroundColor: '#F5F6F9',
        color: theme.secondary.title,
        borderRadius: '40px',
      },
      '& .MuiFormHelperText-root.Mui-error': {
        color: `${theme.secondary.main} !important`,
      },
      '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: `${theme.secondary.main} !important`,
      },
    }),
    bordergrey: {
      borderWidth: '0.063em !important',
      borderColor: '#556584B3 !important',
      border: 'solid !important',
      borderRadius: '40px',
    },
    formLabelTooltip: {
      fontSize: '0.865rem',
      textAlign: 'left',
      fontFamily: theme.fonts.medium,
      color: theme.secondary.title,
    },
    tooltip: {
      padding: '0',
      margin: 'auto',
      marginLeft: '10px',
    },
    formLabel: {
      fontSize: '0.865rem',
      textAlign: 'left',
      fontFamily: theme.fonts.medium,
      color: theme.secondary.title,
      marginBottom: '7px',
    },
    globalTooltip: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    root: {
      width: '100%',
      marginBottom: '18px',
      '@media (max-width: 600px)': {
        marginBottom: '12px',
      },
    },
  }),
);

interface PropsCustomInput {
  label?: string;
  formLabel?: string;
  tooltip?: string;
  value?: string;
  select?: boolean;
  name: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  onPaste?: (e: React.ClipboardEvent) => void;
  error?: string | null | JSX.Element;
  className?: any;
  type?: string;
  pattern?: any;
  placeholder?: string;
  InputProps?: any;
  inputProps?: any;
  children?: any;
  SelectProps?: any;
  disabled?: boolean | any;
  hasDefaultBorder?: boolean;
}

export const CustomInput = ({
  value = '',
  onChange = () => ({}),
  onKeyPress = () => ({}),
  onBlur = () => ({}),
  onKeyDown = () => ({}),
  onPaste = () => ({}),
  error = null,
  hasDefaultBorder = false,
  label = '',
  name = '',
  className,
  placeholder = '',
  InputProps = {},
  inputProps = {},
  children,
  disabled,
  type = 'text',
  formLabel,
  tooltip,
  ...rest
}: PropsCustomInput) => {
  const classes = useStyles(hasDefaultBorder);
  const greyClass = value
    ? {
        ...InputProps,
        classes: {
          ...InputProps?.classes,
          notchedOutline: classes.bordergrey,
        },
      }
    : InputProps;

  return (
    <div className={classes.root}>
      {formLabel && (
        <div className={classes.globalTooltip}>
          <FormLabel
            className={tooltip ? classes.formLabelTooltip : classes.formLabel}
            component='legend'
          >
            {formLabel}
            {tooltip && <Tooltip content={tooltip} />}
          </FormLabel>
        </div>
      )}
      <TextField
        className={`${classes.input} ${className}`}
        size='small'
        disabled={disabled}
        name={name}
        type={type}
        variant='outlined'
        error={!!error}
        value={value}
        onKeyDown={onKeyDown}
        onChange={onChange}
        onPaste={onPaste}
        onKeyPress={onKeyPress}
        label={label}
        helperText={error}
        placeholder={placeholder}
        InputLabelProps={{ shrink: false }}
        InputProps={greyClass}
        inputProps={inputProps}
        onBlur={onBlur}
        {...rest}
      >
        {children}
      </TextField>
    </div>
  );
};
