import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { GlobalTheme } from 'features/app/app.theme';
import React from 'react';

const useStyles = makeStyles((theme: GlobalTheme) => ({
  container: {
    backgroundColor: 'white',
    border: '1px solid rgba(0, 61, 124, 0.1)',
    boxShadow: '0px 0px 8px 0px #ececec',
    padding: '25px',
    borderRadius: 30,
  },
  title: {
    fontFamily: theme.fonts.bold,
    color: theme.primary.main,
    marginBottom: '1rem',
  },
  paragraph: {
    color: theme.primary.title,
    fontSize: '1rem',
    lineHeight: 1.5,
    // '& span': {
    //   display: 'block',
    //   lineHeight: '1.8rem',
    //   '@media (max-width: 600px)': {
    //     lineHeight: '1.5rem',
    //     marginBottom: '15px',
    //   },
    // },
  },
  paragraph2: {
    marginTop: '0.825rem',
  },
  icon: {
    display: 'block',
    maxWidth: '85px',
    marginLeft: 'auto',
    '@media (max-width: 600px)': {
      maxWidth: '75px',
      margin: '0 auto',
    },
  },
  gridContainer: {
    '@media (max-width: 600px)': {
      flexDirection: 'column-reverse',
    },
  },
}));

interface IHightLightedBox {
  title?: string;
  description?: string;
  subDescription?: string;
  icon?: string;
  children?: JSX.Element;
}
const HightLightedBox = ({
  title,
  description,
  subDescription,
  icon,
  children,
}: IHightLightedBox) => {
  const classes = useStyles();

  return (
    <Box mb={{ xs: 2, md: 3 }} mt={2} className={classes.container}>
      <Grid
        container
        justify='space-between'
        spacing={4}
        className={classes.gridContainer}
      >
        <Grid item xs>
          {title && (
            <Typography variant='h2' component='h3' className={classes.title}>
              {title}
            </Typography>
          )}

          {description && (
            <Typography
              className={classes.paragraph}
              variant='h5'
              component='p'
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
            </Typography>
          )}

          {children && (
            <Typography
              className={classes.paragraph}
              variant='h5'
              component='p'
            >
              {children}
            </Typography>
          )}

          {subDescription && (
            <Typography
              className={`${classes.paragraph} ${classes.paragraph2}`}
              variant='h5'
              component='p'
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: subDescription,
                }}
              />
            </Typography>
          )}
        </Grid>
        {icon && (
          <Grid item sm={2}>
            <img className={classes.icon} src={icon} alt={title} />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default HightLightedBox;
