export enum SituationAT {
  initSituationAT = 'SITUATIONAT/INITSITUATIONAT',
  updateField = 'SITUATIONAT/UPDATEFIELD',
  submitSituation = 'SITUATIONAT/SUBMITSITUATION',
  submitSituationError = 'SITUATIONAT/SUBMITSITUATIONERROR',
  submitSituationSalarie = 'SITUATIONAT/SUBMITSITUATIONSALARIE',
  submitPreattSituationSalarie = 'SITUATIONAT/SUBMITPREATTSITUATIONSALARIE',
  submitSituationSalarieError = 'SITUATIONAT/SUBMITSITUATIONSALARIEERROR',
  getConfig = 'SITUATIONAT/GETCONFIG',
  getConfigSuccess = 'SITUATIONAT/GETCONFIGSUCCESS',
  updateLoader = 'SITUATIONAT/UPDATELOADER',
  updateStep = 'SITUATIONAT/UPDATESTEP',
  resetSituation = 'SITUATIONAT/RESETSITUATION',
  saveOrConfirmeSituation = 'SITUATIONAT/SAVEORCONFIRMESITUATION',
  loanRepurchase = 'SITUATIONAT/LOANREPURCHASE',
  affectRepoRepurchase = 'SITUATIONAT/AFFECTREPOREPURCHASE',
}

export interface InitSituationATAction {
  type: SituationAT.initSituationAT;
}

export interface UpdateFieldAction {
  type: SituationAT.updateField;
  step: string;
  name: string;
  value: object | string | boolean | number | Date;
}

export interface SubmitSituationAction {
  type: SituationAT.submitSituation;
}

export interface SaveOrConfirmeSituationAction {
  type: SituationAT.saveOrConfirmeSituation;
  name: string;
}

export const updateFieldAction = (
  step: string,
  name: string,
  value: object | string | boolean | number | Date,
): UpdateFieldAction => ({
  type: SituationAT.updateField,
  step,
  name,
  value,
});

export interface ValidateJsError {
  [key: string]: string[];
}

export interface SubmitSituationErrorsAction {
  type: SituationAT.submitSituationError;
  errors: ValidateJsError;
}

export interface SubmitSituationSalarieAction {
  type: SituationAT.submitSituationSalarie;
}
export interface SubmitPreattSituationSalarieAction {
  type: SituationAT.submitPreattSituationSalarie;
}

export interface SubmitSituationSalarieErrorsAction {
  type: SituationAT.submitSituationSalarieError;
  errors: ValidateJsError;
}

export interface UpdateFieldByArrayAction {
  type: SituationAT.updateField;
  array: string[];
  value: any;
}
export interface UpdateStepAct {
  type: SituationAT.updateStep;
  step: string;
  value: any;
}

export interface LoanRepurchaseAction {
  type: SituationAT.loanRepurchase;
  value: string;
}

export interface AffectRepoRepurchaseAction {
  type: SituationAT.affectRepoRepurchase;
  value: string;
}

export const updateStepAct = (step: string, value: any): UpdateStepAct => ({
  type: SituationAT.updateStep,
  step,
  value,
});

export const updateFieldByArrayAction = (
  array: string[],
  value: any,
): UpdateFieldByArrayAction => ({
  type: SituationAT.updateField,
  array,
  value,
});

export interface GetConfigAction {
  type: SituationAT.getConfig;
  nature: string;
  value?: string;
}

export const getConfigAction = (
  nature: string,
  value?: string,
): GetConfigAction => ({
  type: SituationAT.getConfig,
  nature,
  value,
});

export interface GetConfigActionSuccess {
  type: SituationAT.getConfigSuccess;
  list: { label: string; value: string }[];
  nature: string;
}

export const getConfigSuccessAction = (
  nature: string,
  list: { label: string; value: string }[],
): GetConfigActionSuccess => ({
  type: SituationAT.getConfigSuccess,
  nature,
  list,
});

export interface UpdateLoaderAction {
  type: SituationAT.updateLoader;
  value: boolean;
}
