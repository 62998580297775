import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { has, isEmpty } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import proposition from 'assets/images/proposition.png';
import edit from 'assets/svg/edit.svg';
import rightFlesh from 'assets/svg/rightFlesh.svg';
import { Wording } from 'common/wording/reducer.wording';
import { selectCompWording } from 'common/wording/selectors.wording';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SimulationDetailLine } from 'common/components/SimulationDetailLine';
import { GlobalTheme } from 'features/app/app.theme';
import { PropositionAT } from './actions.proposition';
import { getPropositionInfoByArraySelector } from './selector.proposition';
import { useHistory } from 'react-router-dom';
import { updateFieldByArrayAction } from '../home/actions.simulation';
import { getSituationInfoByArraySelector } from '../situation/selector.situation';
import { CheckAppreciation } from 'common/components/PrivateRoute';
import { SCORE_PROPOISITION } from 'features/situation/constants.situation';
import { getSimulationInfoByArraySelector } from 'features/home/selector.simulation';
import { LANG_STATE } from 'features/home/constants.simulation';
import { pushPageView } from 'features/auth/constants';

const useStyles = makeStyles((theme: GlobalTheme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    direction: 'ltr',
  },
  propositionImg: {
    display: 'block',
    width: '80px',
    height: '77px',
  },
  header: {
    width: '100%',
    display: 'flex',
    marginBottom: 30,
    alignItems: 'center',
    textAlign: 'center',
    '@media (max-width: 450px)': {
      flexDirection: 'column',
    },
  },
  descriptionCounterProposal: {
    color: theme.primary.title,
    fontWeight: 'bold',
    marginTop: '5px',
    width: '100%',
    '@media (max-width: 998px)': {
      width: '80%',
    },
  },
  descriptionContainer: {
    paddingRight: 108,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    '@media (max-width: 1440px)': {
      paddingRight: 50,
    },
    '@media (max-width: 450px)': {
      paddingRight: 0,
    },
  },
  title: {
    textTransform: 'uppercase',
    marginBottom: 10,
    maxWidth: 420,
  },
  description: {
    maxWidth: 420,
    marginLeft: '12px',
    '@media (max-width: 998px)': {
      width: '80%',
    },
  },
  simulationDetail: {
    border: '0.063em solid #FFF',
    borderRadius: '22px',
    backgroundColor: '#FFF',
  },
  simulationDetailHeader: {
    padding: '30px 30px 0 30px',
    display: 'flex',
    '@media (max-width: 540px)': {
      flexDirection: 'column',
    },
  },
  headerTitle: {
    textAlign: 'center',
    fontWeight: 'bold',
    color: theme.primary.title,
    fontFamily: theme.fonts.medium,
    flex: 1,
    marginRight: '10px',
    paddingLeft: 107,
    '@media (max-width: 1333px)': {
      paddingLeft: 50,
    },
    '@media (max-width: 540px)': {
      marginBottom: '10px',
      paddingLeft: 0,
    },
  },
  rightIcon: {
    marginRight: '10px',
    height: '17px',
  },
  subscriptionButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '30px',
    '@media (max-width: 600px)': {
      position: 'fixed',
      bottom: '2%',
      width: '94%',
      left: '3%',
      justifyContent: 'center',
    },
  },
  differeDescText: {
    fontSize: '0.85rem',
    textAlign: 'left',
    color: theme.secondary.title,
  },
  subscriptionButton: {
    color: '#fff',
    backgroundColor: '#F93276',
    border: '0.063em solid #F93276',
    borderRadius: '20px 0',
    padding: '11px 53px',
    fontSize: '0.875rem',
    '&:hover': {
      backgroundColor: '#F93276',
    },
    '@media (max-width: 600px)': {
      flex: 1,
    },
  },
  rightIconSouscrip: {
    marginLeft: '19px',
  },
  rightIconSouscripAr: {
    marginLeft: '19px',
    transform: 'scaleX(-1)',
  },
  simulationDetailBody: {
    padding: '20px 60px 0 60px',
    '@media (max-width: 490px)': {
      margin: '26px',
      padding: 0,
    },
  },
  simulationDetailBodyisPurchase: {
    border: '0.063em solid #F93276',
    margin: '10px',
    padding: '15px',
    borderRadius: '57px',
    paddingBottom: '20px',
    '@media (min-width: 768px)': {
      margin: '30px',
      padding: '30px',
    },
  },
  simulationDetailFooter: {
    display: 'flex',
    padding: '50px 30px 50px 81px',
    justifyContent: 'space-between',
    '@media (max-width: 1130px)': {
      flexDirection: 'column',
      alignItems: 'center',
    },
    '@media (max-width: 1440px)': {
      padding: '50px 10px 50px 30px',
    },
  },
  downloadPDfButton: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  pdfIcon: {
    marginRight: '14px',
  },
  descText: {
    margin: '5px',
    textAlign: 'left',
    fontSize: '0.55rem',
    color: theme.secondary.title,
  },
  someDesc: {
    padding: '30px 25px 0',
    '@media (max-width: 450px)': {
      padding: '20px',
    },
  },
}));

const Proposition = () => {
  const wording: Wording = useSelector(selectCompWording('proposition'));
  const langSelected: any =
    useSelector(getSimulationInfoByArraySelector(['lang'])) || LANG_STATE.FR;
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const propositionResponse: any = useSelector(
    getPropositionInfoByArraySelector(['proposition', 'fields']),
  );
  const situationSalarie: any = useSelector(
    getSituationInfoByArraySelector(['situationSalarie']),
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch({ type: PropositionAT.submitProposition });
  };

  const modifySimulation = () => {
    dispatch(
      updateFieldByArrayAction(
        ['simulation', 'response', 'creditAmount'],
        propositionResponse.creditAmount,
      ),
    );
    dispatch(
      updateFieldByArrayAction(
        ['simulation', 'response', 'creditDuration'],
        propositionResponse.creditDuration,
      ),
    );
    dispatch(
      updateFieldByArrayAction(
        ['simulation', 'response', 'monthlyPayment'],
        propositionResponse.monthlyPayment,
      ),
    );
    history.push('/modify-simulation');
  };

  const isRachat = propositionResponse.rachat === 'O';

  const isPurchase =
    (propositionResponse.rachat === 'O' &&
      !isEmpty(propositionResponse.initialAmount) &&
      propositionResponse.initialAmount !== propositionResponse.creditAmount) ||
    (!isEmpty(propositionResponse.initialDuration) &&
      propositionResponse.initialDuration !==
        propositionResponse.creditDuration);

  useEffect(() => {
    pushPageView('step_3_accord_de_principe');
  }, []);

  return (
    <CheckAppreciation
      page={[SCORE_PROPOISITION.ACCORD_PLUS, SCORE_PROPOISITION.ACCORD]}
      proposition={propositionResponse}
    >
      <form onSubmit={handleSubmit} className={classes.root}>
        <div className={classes.header}>
          {isPurchase ? (
            <Typography
              variant='h5'
              className={classes.descriptionCounterProposal}
            >
              {wording?.descriptionDerogationLigne1}
              <br />
              {wording?.descriptionCounterPropositionLigne2}
              <br />
              {wording?.descriptionCounterPropositionLigne3}
            </Typography>
          ) : (
            <>
              <img
                className={classes.propositionImg}
                alt={''}
                src={`${proposition}`}
              />
              <div className={classes.descriptionContainer}>
                <Typography variant='h1' className={classes.title}>
                  {wording?.wordGreat}
                </Typography>
                <Typography variant='subtitle2' className={classes.description}>
                  {wording?.description}
                </Typography>
              </div>
            </>
          )}
        </div>
        <div>
          {isPurchase && (
            <div
              className={classes.simulationDetail}
              style={{ paddingBottom: '20px', marginBottom: '24px' }}
            >
              <div className={classes.simulationDetailHeader}>
                <Typography
                  variant='h4'
                  className={classes.headerTitle}
                  style={{ paddingLeft: '0px' }}
                >
                  {wording?.CounterPropositionDetailInitial}
                </Typography>
              </div>
              <div className={classes.simulationDetailBody}>
                <SimulationDetailLine
                  label={wording?.montantCreditDemande}
                  value={propositionResponse?.initialAmount}
                  unit={wording?.dhs}
                  show={false}
                />
                <SimulationDetailLine
                  label={wording?.dureeCredit}
                  value={propositionResponse?.initialDuration}
                  unit={wording?.month}
                  show={false}
                />
              </div>
            </div>
          )}

          <div className={classes.simulationDetail}>
            <div
              className={classes.simulationDetailHeader}
              style={{ paddingBottom: '30px' }}
            >
              <Typography variant='h4' className={classes.headerTitle}>
                {isPurchase
                  ? wording?.CounterPropositionDetail
                  : wording?.mySimulationDetail}
              </Typography>
              <Button
                variant='contained'
                type='button'
                onClick={modifySimulation}
              >
                <img src={edit} alt='' className={classes.rightIcon} />
                {wording?.buttonModify}
              </Button>
            </div>
            <div
              className={
                !isPurchase
                  ? classes.simulationDetailBody
                  : classes.simulationDetailBodyisPurchase
              }
            >
              <SimulationDetailLine
                label={wording?.mensualite}
                value={propositionResponse.monthlyPayment}
                unit={wording?.dhs}
                show={!isPurchase}
                inline={isPurchase}
              />
              <SimulationDetailLine
                label={wording?.montantCreditDemande}
                value={propositionResponse.creditAmount}
                unit={wording?.dhs}
                show={!isPurchase}
              />
              {isRachat && (
                <>
                  <SimulationDetailLine
                    label={wording?.outstandingAmount}
                    value={propositionResponse.rachatMntReste}
                    unit={wording?.dhs}
                  />
                  <SimulationDetailLine
                    label={wording?.amountToBeBlocked}
                    value={propositionResponse.debloquedAmount}
                    unit={wording?.dhs}
                    inline={isPurchase}
                  />
                </>
              )}
              <SimulationDetailLine
                label={wording?.dureeCredit}
                value={propositionResponse.creditDuration}
                unit={wording?.month}
                show={!isPurchase}
              />

              <SimulationDetailLine
                label={wording?.assurance}
                value={propositionResponse.amountFeesAssurance}
                unit={wording?.dhs}
                show={!isPurchase}
              />
              <SimulationDetailLine
                label={wording?.fraisDossier}
                value={
                  propositionResponse.amountFeesDemande &&
                  propositionResponse.amountFeesDemande?.toString() === '0'
                    ? wording?.free
                    : propositionResponse.amountFeesDemande
                }
                unit={
                  propositionResponse.amountFeesDemande &&
                  propositionResponse.amountFeesDemande?.toString() === '0'
                    ? ' '
                    : wording?.dhs
                }
                show={!isPurchase}
              />
              <SimulationDetailLine
                label={wording?.coutTotalCredit}
                value={propositionResponse.totalCostOfCredit}
                unit={wording?.dhs}
                show={!isPurchase}
              />
              {situationSalarie.fields.isDiffere &&
                has(situationSalarie.fields, 'differe') && (
                  <>
                    <SimulationDetailLine
                      label={wording?.startDate}
                      value={propositionResponse.startDate}
                      unit={''}
                    />
                    <SimulationDetailLine
                      label={wording?.optionDiffere}
                      value={situationSalarie.fields.differe}
                      unit={wording?.month}
                      show={!isPurchase}
                    />
                    {/* <Typography className={classes.differeDescText}>
                      {wording?.differeIsFree}
                    </Typography> */}
                  </>
                )}
            </div>
            <div className={classes.someDesc}>
              <Typography className={classes.descText}>
                {wording?.descriptionTextOne}
              </Typography>
              <Typography className={classes.descText}>
                {wording?.descriptionTextTwo}
              </Typography>
              <Typography className={classes.descText}>
                {wording?.descriptionTextThree}
              </Typography>
              <Typography className={classes.descText}>
                {wording?.descriptionTextFour}
              </Typography>
            </div>
            <div className={classes.subscriptionButtonContainer}>
              <Button
                variant='contained'
                color='primary'
                className={classes.subscriptionButton}
                type='submit'
              >
                {wording?.souscription}
                <img
                  src={rightFlesh}
                  alt=''
                  className={
                    langSelected === LANG_STATE.FR
                      ? classes.rightIconSouscrip
                      : classes.rightIconSouscripAr
                  }
                />
              </Button>
            </div>
          </div>
        </div>
      </form>
    </CheckAppreciation>
  );
};

export default Proposition;
