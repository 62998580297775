import { ERROR_WAF } from './../../common/utils/validateJs/contants';
import {
  CIN_PLUSIEURS_TIERS,
  DATA_IN_NOT_VALIDE,
  CIN_RIB_NULLS,
  CLIENT_WITH_MULTI_CPT,
  TOKEN_BMCEDIRECT_EXPIRED,
  EXCEED_MAX_ATTEMPTS,
  TOKEN_BMCEDIRECT_NOT_VALID,
  CIN_IS_NULL,
  DURATION_OR_AMOUNT_NULL
} from 'common/utils/validateJs/contants';

export const FR = '/fr';
export const AR = '/ar/محاكاة-قرض';

export const locale = [
  {
    value: 'en',
    label: 'English',
  },
  {
    value: 'fr',
    label: 'Frensh',
  },
];

export const OUI = 'Oui';

export const errorsListFromBmceDirect = [
  CIN_PLUSIEURS_TIERS,
  DATA_IN_NOT_VALIDE,
  CIN_RIB_NULLS,
  CLIENT_WITH_MULTI_CPT,
  TOKEN_BMCEDIRECT_EXPIRED,
  EXCEED_MAX_ATTEMPTS,
  ERROR_WAF,
  TOKEN_BMCEDIRECT_NOT_VALID,
  CIN_IS_NULL,
  DURATION_OR_AMOUNT_NULL
];
