export enum ValidationAT {
  initValidationnAT = 'VALIDATIONAT/INITVALIDATIONAT',
  VALIDATION_BMCEDIRECT_SUCCESS = 'VALIDATIONAT/VALIDATION_BMCEDIRECT_SUCCESS',
  VALIDATION_BMCEDIRECT = 'VALIDATIONAT/VALIDATION_BMCEDIRECT',
  VALIDATION_BMCEDIRECT_ERROR = 'VALIDATIONAT/VALIDATION_BMCEDIRECT_ERROR',
  VALIDATION_LOADER = 'VALIDATION/LOADER',
  updateField = 'VALIDATIONAT/UPDATEFIELD',
  VALIDATION_PREATTRIBUTION = 'VALIDATIONAT/PREATTRIBUTION'
}
export interface initValidationnATAction {
  type: ValidationAT.initValidationnAT;
}
export interface ValidationBmceDirectAction {
  type: ValidationAT.VALIDATION_BMCEDIRECT;
  token: string;
}
export interface ValidationBmceDirectSuccessAction {
  type: ValidationAT.VALIDATION_BMCEDIRECT_SUCCESS;
  data: any;
}
export interface ValidationBmceDirectErrorAction {
  type: ValidationAT.VALIDATION_BMCEDIRECT_ERROR;
  errors: string;
}

export interface UpdateFieldAction {
  type: ValidationAT.updateField;
  checkedClose: boolean;
}
export interface PreattributionAction {
  type: ValidationAT.VALIDATION_PREATTRIBUTION;
  preAttribution: boolean;
}
export interface ValidationLoaderAction {
  type: ValidationAT.VALIDATION_LOADER;
  loader: boolean;
}

export const validationBmceDirectAction = (
  token: string,
): ValidationBmceDirectAction => ({
  type: ValidationAT.VALIDATION_BMCEDIRECT,
  token,
});
export const validationBmceDirectSuccesAction = (
  data: any,
): ValidationBmceDirectSuccessAction => ({
  type: ValidationAT.VALIDATION_BMCEDIRECT_SUCCESS,
  data,
});
export const validationBmceDirectErrorAction = (
  errors: string,
): ValidationBmceDirectErrorAction => ({
  type: ValidationAT.VALIDATION_BMCEDIRECT_ERROR,
  errors,
});

export const updateFieldAction = (
  checkedClose: boolean,
): UpdateFieldAction => ({
  type: ValidationAT.updateField,
  checkedClose,
});
export interface ValidateJsError {
  [key: string]: string[];
}
