/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { selectCompWording } from 'common/wording/selectors.wording';
import ProfessionnelClient from './ProfessionnelClient';
import {
  SituationAT,
  updateFieldAction,
} from 'features/situation/actions.situation';
import { getLocation } from 'core/root.selector';
import ProfessionnelProspect from './ProfessionnelProspect';
import { getSituationInfoByArraySelector } from 'features/situation/selector.situation';
import Spinner from 'common/components/Spinner';
import { isEmpty } from 'lodash';
import {
  COMPTE_MULTI_TITULAIRE,
  CIN_PLUSIEURS_TIERS,
  CIN_RIB_NULLS,
  DATA_IN_NOT_VALIDE,
} from 'common/utils/validateJs/contants';
import PopUpInfo from '../../../home/components/PopUpInfo';
import { getSimulationInfoByArraySelector } from 'features/home/selector.simulation';
import { LANG_STATE } from 'features/home/constants.simulation';
import useProfStyles from './styles/useProfStyles';
import { pushPageView } from 'features/auth/constants';

function Professionnel() {
  const classes = useProfStyles();
  const dispatch = useDispatch();
  const langSelected: any =
    useSelector(getSimulationInfoByArraySelector(['lang'])) || LANG_STATE.FR;
  const wordingSalarie = useSelector(selectCompWording('detailedForm'));

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch({ type: SituationAT.submitSituationSalarie });
  };
  const handleSave = () => {
    dispatch({ type: SituationAT.saveOrConfirmeSituation, name: 'save' });
  };
  const location = useSelector(getLocation);

  const loader: any = useSelector(
    getSituationInfoByArraySelector(['situationSalarie', 'config', 'loader']),
  );

  // Detection des errors pour les Conventionnées, Non Conventionnées et Prospects.
  const errors: any = useSelector(
    getSituationInfoByArraySelector(['situationSalarie', 'errors']),
  );
  const situationSalarie: any = useSelector(
    getSituationInfoByArraySelector(['situationSalarie', 'fields']),
  );
  const formHeader: any = useRef();

  const scrollToTop = () => {
    formHeader.current.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start',
    });
    const element: any = document.getElementById('formHeader');
    element.scrollIntoView();
    element.scrollTop -= 10;
  };

  useEffect(() => {
    if (!isEmpty(errors)) {
      scrollToTop();
    }
  }, [errors]);

  const handleClose = () => {
    dispatch(
      updateFieldAction('situationSalarie', 'checkValidateClient', false),
    );
  };

  useEffect(() => {
    if (
      errors &&
      errors.global &&
      (errors.global[0] === CIN_PLUSIEURS_TIERS ||
        errors.global[0] === CIN_RIB_NULLS ||
        errors.global[0] === DATA_IN_NOT_VALIDE)
    ) {
      dispatch(updateFieldAction('situationSalarie', 'cinOrRib', 'rib'));
    }
  }, [errors]);

  useEffect(() => {
    pushPageView('step_2_formulaire');
  }, []);

  return (
    <div className={classes.root}>
      <Spinner loader={loader} />
      <div className={classes.parag}>
        <Typography
          variant='h4'
          className={
            langSelected === LANG_STATE.FR
              ? classes.paragTitle
              : classes.paragTitleAr
          }
        >
          {wordingSalarie?.title}
        </Typography>
      </div>
      <form
        id='formHeader'
        onSubmit={handleSubmit}
        className={classes.groupForm}
        ref={formHeader}
      >
        {errors.global && errors.global[0] === COMPTE_MULTI_TITULAIRE && (
          <PopUpInfo
            open={situationSalarie.checkValidateClient}
            onClose={handleClose}
            error={errors.global[0]}
          />
        )}

        {location === '/professionnel/client' ? (
          <ProfessionnelClient />
        ) : (
          location === '/professionnel/prospect' && <ProfessionnelProspect />
        )}

        <div className={classes.groupFormFooter}>
          <Button
            onClick={handleSave}
            className={`${classes.buttonSubmit} ${classes.saveButton}`}
            variant='contained'
            color='secondary'
          >
            {wordingSalarie?.save}
          </Button>
          <Button
            type='submit'
            className={classes.buttonSubmit}
            variant='contained'
            color='secondary'
          >
            {wordingSalarie?.submit}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default Professionnel;
