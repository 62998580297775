import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Wording } from 'common/wording/reducer.wording';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { GlobalTheme } from 'features/app/app.theme';
import LogoFooter from 'assets/images/logo.png';
import facebookIcon from 'assets/images/FacebookIcon.png';
import twitterIcon from 'assets/images/TwitterIcon.png';
import instagramIcon from 'assets/images/InstagramIcon.png';
import linkedinIcon from 'assets/images/LinkedinIcon.png';
import youtubeIcon from 'assets/images/YoutubeIcon.png';
import BackgroundRightContainer from 'assets/images/BackgroundConfirmationMail.png';
import { getSimulationInfoByArraySelector } from 'features/home/selector.simulation';
import { LANG_STATE } from 'features/home/constants.simulation';
import { pushEvent } from 'features/auth/constants';
import { selectCompWording } from 'common/wording/selectors.wording';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: GlobalTheme) => ({
  gridFooter: {
    height: 'auto',
    padding: '6rem 2% 0.51rem',
    direction: 'ltr',
    backgroundImage: `url(${BackgroundRightContainer})`,
    '@media (max-width: 1024px)': {
      paddingTop: '4rem',
      minHeight: '15.27rem',
      alignItems: 'center',
    },

    '@media (max-width: 600px)': {
      position: 'static',
      paddingTop: '2rem',
    },
  },
  logoFooter: {
    paddingRight: '2.036rem',
    '@media (max-width: 1024px)': {
      width: '100%',
      padding: '0px',
      textAlign: 'center',
      margin: 'auto',
      marginBottom: '1.018rem',
    },
  },
  logoFooterImage: {
    width: '12em',
    height: 'auto',
  },
  copyright: {
    textAlign: 'right',
    color: theme.primary.main,
    fontFamily: theme.fonts.medium,
    fontSize: '0.875rem',
    '@media (max-width: 1024px)': {
      width: '100%',
      padding: '0px',
      textAlign: 'center',
      margin: 'auto',
      fontSize: '0.625rem',
      justifyContent: 'center',
    },
  },
  linksIcons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    marginTop: '0.312rem',
    direction: 'ltr',
    '@media (max-width: 980px)': {
      marginTop: '0px',
      justifyContent: 'center',
    },
  },

  menuLinksContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    alignItems: 'center',
    flexWrap: 'wrap',
    '@media (max-width: 980px)': {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  separator: {
    marginLeft: '1.25rem',
    marginRight: '1.25rem',
    '@media (max-width: 980px)': {
      display: 'none',
    },
  },
  menuLinks: {
    color: theme.primary.title,
    textDecoration: 'none',
    fontFamily: theme.fonts.medium,
    fontSize: '0.875rem',
    cursor: 'pointer',
    '@media (max-width: 980px)': {
      margin: '0.5rem 0',
    },
  },
  socialLink: {
    margin: '0.93rem',
    '@media (max-width: 1024px)': {
      width: '0.93rem',
    },
  },
}));

function Footer() {
  const classes = useStyles();

  const wordingFooter: Wording = useSelector(selectCompWording('footer'));
  const wordingAlt: Wording = useSelector(selectCompWording('alt'));
  const langSelected: any =
    useSelector(getSimulationInfoByArraySelector(['lang'])) || LANG_STATE.FR;

  const TrackingGTM = (type: string) => {
    let eventObj = {
      event: 'click_icon',
      click_label: type,
      element_category: 'footer',
    };
    pushEvent(eventObj);
  };
  return (
    <footer className={classes.gridFooter}>
      <Grid
        container
        direction='row'
        justify='space-between'
        alignItems='center'
      >
        <Grid item xs={12} md={4}>
          <div className={classes.logoFooter}>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href={wordingFooter?.menuBankOfAfricaLink}
              className={`${classes.socialLink}`}
              title={wordingFooter?.instagram}
            >
              <img
                className={classes.logoFooterImage}
                src={LogoFooter}
                alt={wordingFooter?.logoAlt}
                title={wordingFooter?.logoAlt}
              />
            </a>
          </div>
        </Grid>
        <Grid item xs>
          <div className={classes.menuLinksContainer}>
            <Link
              to='/credit-a-la-consommation'
              className={`${classes.menuLinks}`}
            >
              {wordingFooter?.credit_consomation}
            </Link>
            <div className={classes.separator}>|</div>
            <Link to='/profils-eligibiles' className={`${classes.menuLinks}`}>
              {wordingFooter?.profil_eligible}
            </Link>
            <div className={classes.separator}>|</div>
            <Link to='/rachat-de-credit' className={`${classes.menuLinks}`}>
              {wordingFooter?.rachat_de_credit}
            </Link>
            <div className={classes.separator}>|</div>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href={
                langSelected === LANG_STATE.FR
                  ? require('../../assets/pdf/MentionsLegalesCreditdaba_Fr.pdf')
                  : require('../../assets/pdf/MentionsLegalesCreditdaba_Ar.pdf')
              }
              className={`${classes.menuLinks}`}
            >
              {wordingFooter?.menuLegalMention}
            </a>
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        direction='row'
        justify='space-between'
        alignItems='center'
      >
        <Grid item xs={12} md={4}>
          <div className={classes.linksIcons}>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href={wordingFooter?.facebookLink}
              className={`${classes.socialLink}`}
              title={wordingFooter?.facebook}
              onClick={() => TrackingGTM('facebook')}
            >
              <img
                src={facebookIcon}
                alt={wordingAlt?.logo_fb}
                title={wordingFooter?.facebook}
              />
            </a>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href={wordingFooter?.twitterLink}
              className={`${classes.socialLink}`}
              title={wordingFooter?.twitter}
              onClick={() => TrackingGTM('twitter')}
            >
              <img
                src={twitterIcon}
                alt={wordingAlt?.logo_twitter}
                title={wordingFooter?.twitter}
              />
            </a>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href={wordingFooter?.linkedinLink}
              className={`${classes.socialLink}`}
              title={wordingFooter?.linkedin}
              onClick={() => TrackingGTM('linkedin')}
            >
              <img
                src={linkedinIcon}
                alt={wordingAlt?.logo_lk}
                title={wordingFooter?.linkedin}
              />
            </a>
            <a
              href={wordingFooter?.youtubeLink}
              target='_blank'
              rel='noopener noreferrer'
              className={`${classes.socialLink}`}
              title={wordingFooter?.youtube}
              onClick={() => TrackingGTM('youtube')}
            >
              <img
                src={youtubeIcon}
                alt={wordingAlt?.logo_yb}
                title={wordingFooter?.youtube}
              />
            </a>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href={wordingFooter?.instagramLink}
              className={`${classes.socialLink}`}
              title={wordingFooter?.instagram}
              onClick={() => TrackingGTM('instagram')}
            >
              <img
                src={instagramIcon}
                alt={wordingAlt?.logo_insta}
                title={wordingFooter?.instagram}
              />
            </a>
          </div>
        </Grid>
        <Grid item xs>
          <Typography className={classes.copyright}>
            {wordingFooter?.copyright}
          </Typography>
        </Grid>
      </Grid>
    </footer>
  );
}

export default Footer;
