import { makeStyles } from '@material-ui/core/styles';
import { GlobalTheme } from 'features/app/app.theme';
import React from 'react';
import NeedImgRightElement from './NeedImgRightElement';

const useStyles = makeStyles((theme: GlobalTheme) => ({
  listStyle: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    margin: 'auto',
    marginTop: '-30px',
    marginRight: '0px',
    marginLeft: '0px',
    '@media (max-width: 1280px)': {
      marginTop: '0',
      marginLeft: '0',
    },
    '@media (max-width: 768px)': {
      flexWrap: 'unset',
      marginLeft: '0px',
      width: '100px',
    },
  },
  titleStyle: {
    textAlign: 'left',
    fontSize: '1.125rem',
  },
  subTitleStyle: {
    textAlign: 'left',
    fontFamily: theme.fonts.bold,
    color: theme.primary.title,
  },
  img: {
    width: '67px',
  },
  bodyContent: {
    margin: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

const NeedRightElement = () => {
  const classes = useStyles();
  return (
    <div className={classes.listStyle}>
      <NeedImgRightElement firstImg={'firstNeed'} secondImg={''} />
      <NeedImgRightElement firstImg={'secondNeed'} secondImg={'thirdNeed'} />
      <NeedImgRightElement firstImg={'lastNeed'} secondImg={''} />
    </div>
  );
};
export default NeedRightElement;
