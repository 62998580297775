import { Button, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import BackgroundRightContainer from 'assets/images/BackgroundConfirmationMail.png';
import Background from 'assets/images/bgSignIn.png';
import logo from 'assets/images/logo.png';
import logoCreditDaba from 'assets/svg/logoCreditDaba.svg';
import { CustomInput } from 'common/components/customFields/CustomInput';
import { GlobalError } from 'common/components/customFields/GlobalError';
import Footer from 'common/components/Footer';
import ReCaptcha from 'common/components/reCaptcha';
import Spinner from 'common/components/Spinner';
import { getSessionStorage } from 'common/utils/sessionStorage.util';
import { Wording } from 'common/wording/reducer.wording';
import { selectCompWording } from 'common/wording/selectors.wording';
import { GlobalTheme } from 'features/app/app.theme';
import { LANG_STATE } from 'features/home/constants.simulation';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { getSimulationInfoByArraySelector } from 'features/home/selector.simulation';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { AuthAT, updateFieldSignInAction } from '../actions.auth';
import { signInFields } from '../constants';
import { getAuthInfoByArraySelector } from '../selector.auth';

const useStyles = makeStyles((theme: GlobalTheme) =>
  createStyles({
    form: {
      margin: 'auto',
      borderRadius: '22px',
      backgroundImage: `url(${BackgroundRightContainer})`,
      '@media (min-width: 450px)': {
        width: '60vw',
        maxWidth: '600px',
        boxSizing: 'borderBox',
      },
    },
    formHeader: {
      padding: '10% 20% ',
      maxWidth: '100%',
      alignItems: 'center',
      height: '76px',
      fontSize: '1.55rem',
      justifyContent: 'center',
      '@media (max-width: 450px)': {
        padding: '60px 20px 10px',
      },
    },
    formContent: {
      marginTop: '-20px',
      padding: '10px 70px 60px',
      '@media (max-width: 1024px)': {
        padding: '10px 38px',
      },
      '@media (max-width: 414px)': {
        padding: '30px 15px',
      },
    },
    title: {
      textTransform: 'uppercase',
      paddingBottom: '30px',
      textAlign: 'center',
      fontWeight: 'bold',
    },
    subTitle: {
      fontSize: '1rem',
      textAlign: 'center',
      maxWidth: 400,
    },
    textfieldStyle: {
      minHeight: 60,
      marginBottom: '10px',
      '& .MuiOutlinedInput-inputMarginDense': {
        textAlign: 'left',
        '&::placeholder': {
          opacity: 1,
        },
      },
    },
    root: {
      minHeight: '100vh',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      backgroundImage: `url(${Background})`,
      backgroundSize: 'cover',
      '& .MuiButton-contained': {
        '&:hover': {
          boxShadow: 'unset',
          backgroundColor: 'unset',
        },
      },
    },
    logoBOA: {
      paddingLeft: 25,
      paddingTop: 12,
      display: 'block',
      width: '180px',
    },
    groupFormFooter: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    groupFormFooterAr: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
    radioGroup: {
      minWidth: '100%',
      justifyContent: 'space-around',
      '& .MuiTypography-root': {
        fontSize: '0.8125rem',
        color: theme.primary.title,
      },
      '@media (max-width: 414px)': {
        justifyContent: 'center',
      },
    },
    buttonSubmit: {
      borderRadius: '14px 0',
      textTransform: 'inherit',
      padding: '14px 50px',
      fontFamily: theme.fonts.regular,
      fontSize: '0.875rem',
      backgroundColor: `${theme.primary.main} !important`,
      '& .MuiButton-containedPrimary': {
        '&:hover': {
          backgroundColor: `${theme.primary.main} !important`,
        },
      },
      '@media (max-width: 450px)': {
        padding: '14px 15px',
        fontSize: '0.75rem',
        width: '50%',
      },
    },
    buttonSubmitDisabled: {
      borderRadius: '14px 0',
      textTransform: 'inherit',
      padding: '14px 50px',
      fontFamily: theme.fonts.regular,
      fontSize: '0.875rem',
      backgroundColor: `${theme.primary.gray} !important`,
      '& .MuiButton-containedPrimary': {
        '&:hover': {
          backgroundColor: `${theme.primary.gray} !important`,
        },
      },
      '@media (max-width: 450px)': {
        padding: '14px 15px',
        fontSize: '0.75rem',
        width: '50%',
      },
    },
    logoCreditConso: {
      display: 'block',
      margin: '12px auto',
      width: '450px',
      maxWidth: '85%',
    },
    globalContainer: {
      maxWidth: 662,
      margin: 'auto',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      '@media (max-width: 662px)': {
        maxWidth: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
      },
    },
    footer: {
      width: '100%',
      '@media (max-width: 1024px)': {
        textAlign: 'center',
      },
    },
    inputs: {
      padding: '0 40px',
      marginTop: '35px',
      '@media (max-width: 450px)': {
        padding: '0',
        marginTop: '5px',
      },
    },
    link: {
      padding: '0 26px',
      color: theme.secondary.title,
      fontSize: '0.9375rem',
      textDecoration: 'none',
    },
    reCaptcha: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
    logoButton: {
      backgroundColor: 'transparent',
      alignSelf: 'flex-start',
    },
    logoButtonAr: {
      backgroundColor: 'transparent',
      alignSelf: 'flex-start',
    },
  }),
);

const GenerateAccessCode = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const toHomePage = () => history.push('/credit-conso');
  // Selector Component
  const signInWording: Wording = useSelector(selectCompWording('signIn'));
  const errorsWording: Wording = useSelector(selectCompWording('errors'));
  const signIn: any = useSelector(getAuthInfoByArraySelector(['signIn']));
  const errors: any = useSelector(
    getAuthInfoByArraySelector(['signIn', 'errors']),
  );
  const accessCodeWording: Wording = useSelector(
    selectCompWording('accessCode'),
  );

  const langSelected: any =
    useSelector(getSimulationInfoByArraySelector(['lang'])) || LANG_STATE.FR;

  const emailExist = getSessionStorage('emailExist');

  // Function Component
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateFieldSignInAction('signIn', e.target.name, e.target.value));
  };

  // recaptch hook
  const { executeRecaptcha } = useGoogleReCaptcha();
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return null;
    }

    const token = await executeRecaptcha();
    console.log('recaptcha token ', token);
    return token;
    // Do whatever you want with the token
  }, [executeRecaptcha]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const token = await handleReCaptchaVerify();

    const email: any = getSessionStorage('email');
    dispatch(updateFieldSignInAction('signIn', 'email', email));
    dispatch(updateFieldSignInAction('signIn', 'bmceDirect', 'signInByEmail'));
    dispatch({ type: AuthAT.submitSignIn, reCAPTCHA: token });
  };

  useEffect(() => {
    const listener = (event: any) => {
      if (
        event.code === 'Enter' ||
        event.code === 13 ||
        event.code === '13' ||
        event.code === 'NumpadEnter' ||
        event.keyCode === 13 ||
        event.keyCode === '13'
      ) {
        handleSubmit(event);
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return emailExist ? (
    <>
      <Spinner loader={signIn.loader} />
      <div className={classes.root}>
        <Button
          variant='contained'
          type='button'
          onClick={toHomePage}
          className={
            langSelected === LANG_STATE.FR
              ? classes.logoButton
              : classes.logoButtonAr
          }
        >
          <img className={classes.logoBOA} alt='logoBOA' src={logo} />
        </Button>
        <img
          className={classes.logoCreditConso}
          alt='logo-center'
          src={logoCreditDaba}
        />
        <div className={classes.globalContainer}>
          <form
            className={classes.form}
            noValidate
            autoComplete='off'
            onSubmit={handleSubmit}
          >
            <div className={classes.formHeader}>
              <Typography variant='h3' className={classes.title}>
                {accessCodeWording?.title}{' '}
                {/* GENERATE ACCESS CODE WORDING   */}
              </Typography>
              <Typography variant='h3' className={classes.subTitle}>
                {accessCodeWording?.subtitle}{' '}
                {/* GENERATE ACCESS CODE WORDING   */}
              </Typography>
            </div>
            {errors && errors.global && (
              <GlobalError
                globalError={errors.global}
                errorWording={errorsWording}
              />
            )}

            <div className={classes.formContent}>
              <div className={classes.inputs}>
                <CustomInput
                  value={signIn.accessCode}
                  // type={'password'}
                  name={signInFields.accessCode}
                  onChange={handleChange}
                  placeholder={signInWording?.accessPswd}
                  className={classes.textfieldStyle}
                  error={
                    errors[signInFields.accessCode]
                      ? errorsWording[errors[signInFields.accessCode]]
                      : null
                  }
                />
              </div>
              <div className={classes.reCaptcha}>
                <ReCaptcha />
              </div>
            </div>
            <div
              className={
                langSelected === LANG_STATE.FR
                  ? classes.groupFormFooter
                  : classes.groupFormFooterAr
              }
            >
              <Button
                type='submit'
                variant='contained'
                color='primary'
                className={classes.buttonSubmit}
              >
                {accessCodeWording?.confirm}
              </Button>
            </div>
          </form>
        </div>
      </div>

      <div className={classes.footer}>
        <Footer />
      </div>
    </>
  ) : (
    <Redirect to='/sign-in' />
  );
};

export default GenerateAccessCode;
