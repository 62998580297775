import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { GlobalTheme } from 'features/app/app.theme';
import questionIcon from 'assets/svg/question-icon.svg';

const useStyles = makeStyles((theme: GlobalTheme) => ({
  blocStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: '#FFF',
    borderRadius: '35px',
    padding: '40px',
    margin: '30px 0',
    boxShadow: '0px 0px 8px 0px #ececec',
    '@media (max-width: 600px)': {
      padding: '20px',
    },
  },
  text: {
    fontFamily: theme.fonts.regular,
    color: theme.primary.title,
    fontSize: '1rem',
  },
  icon: {
    width: '55px',
    marginBottom: '15px',
  },
}));

interface ITextBloc {
  text: string;
}
const TextBloc = ({ text }: ITextBloc) => {
  const classes = useStyles();

  return (
    <div className={classes.blocStyle}>
      <Grid item xs={12} sm={12}>
        <img src={questionIcon} alt={'question'} className={classes.icon} />
        <Typography variant='body1' className={classes.text}>
          {text}
        </Typography>
      </Grid>
    </div>
  );
};

export default TextBloc;
