const processEnv = process.env;
const production =
  processEnv.REACT_APP_PROD &&
  processEnv.REACT_APP_PROD.toLowerCase() !== 'false';

export const env = {
  production,
  apiPath: `${window.location.origin}/${processEnv.REACT_APP_API_PATH}`,
  strapiUrl: processEnv.REACT_APP_STRAPI_URL,
  wootricToken: processEnv.REACT_APP_WOOTRIC_ACCOUNT_TOKEN,
  wootricEmail: processEnv.REACT_APP_WOOTRIC_ACCOUNT_EMAIL,
  useStrapi:
    processEnv.REACT_APP_USE_STRAPI &&
    processEnv.REACT_APP_USE_STRAPI.toLowerCase() !== 'false',
};
