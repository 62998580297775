export enum CongratulationAT {
  initCongratulationAT = 'CONGRATULATIONAT/INITCONGRATULATIONAT',
  submitCongratulation = 'CONGRATULATIONAT/SUBMITCONGRATULATION',
}

export interface InitCongratulationATAction {
  type: CongratulationAT.initCongratulationAT;
}
export interface SubmitCongratulationAction {
  type: CongratulationAT.submitCongratulation;
}
