import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { CustomInput } from 'common/components/customFields/CustomInput';
import CustomizedSwitches from 'common/components/customFields/CustomizedSwitches';
import InputNumber from 'common/components/customFields/InputNumber';
import SelectDown from 'common/components/customFields/SelectDown';
import { GlobalTheme } from 'features/app/app.theme';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateFieldAction, getConfigAction } from '../../actions.situation';
import { CustomRadioGroup } from 'common/components/customFields/CustomRadioGroup';
import { selectCompWording } from 'common/wording/selectors.wording';
import {
  situationSalarieFields,
  salarieSituationConfig,
  familialSituation,
} from '../../constants.situation';
import { getSituationInfoByArraySelector } from '../../selector.situation';
import { CustomAutoComplete } from 'common/components/customFields/CustomAutoComplete';
import { CustomDatePicker } from 'common/components/customFields/CustomDatePicker';
import { CheckAuthorization } from 'common/components/PrivateRoute';
import { AccountTypes, ProductTypes } from 'features/auth/constants';
import Seo from 'common/components/seo/Seo';
import Tooltip from 'common/components/customFields/CustomTooltip';

const useStyles = makeStyles((theme: GlobalTheme) => ({
  labelRenseing: {
    fontSize: '0.9375rem',
    textAlign: 'left',
    fontFamily: theme.fonts.medium,
    color: theme.secondary.title,
    marginBottom: '17px',
  },
  labelRenseingTooltip: {
    fontSize: '0.9375rem',
    textAlign: 'left',
    fontFamily: theme.fonts.medium,
    color: theme.secondary.title,
  },
  groupFormBody: {
    padding: '30px',
  },
  textFieldRib: {
    width: '110px',
    marginRight: '20px',
    '& .MuiOutlinedInput-marginDense': {
      direction: 'ltr',
      '& .MuiInputAdornment-positionEnd': {
        marginRight: '5px',
        marginLeft: '5px',
      },
    },
    '& .MuiInputBase-input': {
      direction: 'initial',
      textAlign: 'center',
      fontFamily: theme.fonts.bold,
      fontSize: '0.875rem',
    },
    '@media (max-width: 414px)': {
      width: '45%',
      float: 'left',
    },
  },
  textFieldRibAr: {
    width: '110px',
    marginRight: '20px',
    '& .MuiOutlinedInput-marginDense': {
      direction: 'ltr',
      padding: '0px',
      '& .MuiInputAdornment-positionEnd': {
        marginRight: '5px',
        marginLeft: '5px',
        '& p': {
          fontWeight: 'bold',
        },
      },
    },
    '& .MuiInputBase-input': {
      direction: 'initial',
      textAlign: 'center',
      fontFamily: theme.fonts.bold,
      fontSize: '0.875rem',
    },
    '@media (max-width: 414px)': {
      width: '45%',
      float: 'left',
    },
  },
  thirdTextFieldRib: {
    width: '240px',
    '@media (max-width: 414px)': {
      width: '100%',
    },
  },
  lastTextFieldRib: {
    width: '90px',
    '@media (max-width: 414px)': {
      width: '40%',
    },
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center',
  },
  rootTextField: {
    color: theme.secondary.title,
    border: '0.063em solid',
    borderRadius: '20px',
  },
  globalTooltip: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  radioGroup: {
    justifyContent: 'start',
  },
  displayDiv: {
    '@media (max-width: 540px)': {
      display: 'none',
    },
  },
}));

const SalarieProspect = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const wordingSalarie = useSelector(selectCompWording('detailedForm'));
  const errorsWording = useSelector(selectCompWording('errors'));
  const natureContratList = [
    {
      label: wordingSalarie?.cdi,
      value: 'cdi',
    },
    {
      label: wordingSalarie?.cdd,
      value: 'cdd',
    },
  ];
  const additionalIncomeNatureList = [
    {
      label: wordingSalarie?.foncierLoyer,
      value: '1',
    },
    {
      label: wordingSalarie?.placementsFinanciers,
      value: '2',
    },
    {
      label: wordingSalarie?.rente,
      value: '3',
    },
    {
      label: wordingSalarie?.pension,
      value: '4',
    },
    {
      label: wordingSalarie?.autresNature,
      value: '5',
    },
  ];

  const situationSalarie: any = useSelector(
    getSituationInfoByArraySelector(['situationSalarie', 'fields']),
  );
  const errors: any = useSelector(
    getSituationInfoByArraySelector(['situationSalarie', 'errors']),
  );
  const config: any = useSelector(
    getSituationInfoByArraySelector(['situationSalarie', 'config']),
  );
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (situationSalarie.familialSituation !== familialSituation.marie) {
      dispatch(updateFieldAction('situationSalarie', 'activeSpouse', false));
    }
    dispatch(
      updateFieldAction(
        'situationSalarie',
        event.target.name,
        event.target.value,
      ),
    );
  };

  const handleChangeCity = (
    event: React.ChangeEvent<{}>,
    city: { value: string; label: string },
  ) => {
    dispatch(updateFieldAction('situationSalarie', 'city', city));
  };

  /*const handleCddEndDateChange = (date: Date | null) => {
    dispatch(
      updateFieldAction(
        'situationSalarie',
        situationSalarieFields.cddEndDate,
        date as Date,
      ),
    );
  };
  const handleDateCreationOtherBankAccountChange = (date: Date | null) => {
    dispatch(
      updateFieldAction(
        'situationSalarie',
        situationSalarieFields.dateCreationOtherBankAccount,
        date as Date,
      ),
    );
  };
  const handleDateCurrentEmployerChange = (date: Date | null) => {
    dispatch(
      updateFieldAction(
        'situationSalarie',
        situationSalarieFields.dateCurrentEmployer,
        date as Date,
      ),
    );
  };*/

  useEffect(() => {
    dispatch(getConfigAction(salarieSituationConfig.familialSituation));
    dispatch(getConfigAction(salarieSituationConfig.activityArea));
    dispatch(getConfigAction(salarieSituationConfig.nationalities));
    dispatch(getConfigAction(salarieSituationConfig.cities));
    dispatch(getConfigAction(salarieSituationConfig.banks));
    dispatch(
      updateFieldAction('situationSalarie', 'type', AccountTypes.salarie),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CheckAuthorization
      roles={{
        typeClients: [AccountTypes.salarie],
        products: [ProductTypes.cp_slf],
      }}
    >
      <div className={classes.groupFormBody}>
        <Seo titleKey='title_13' keywordsKey='keywords_13' />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <SelectDown
              formLabel={wordingSalarie?.familialSituation}
              list={config[salarieSituationConfig.familialSituation]}
              label={wordingSalarie?.familialSituationPlaceHolder}
              name={situationSalarieFields.familialSituation}
              value={situationSalarie.familialSituation}
              onChange={handleChange}
              error={
                errors[situationSalarieFields.familialSituation]
                  ? errorsWording[
                      errors[situationSalarieFields.familialSituation]
                    ]
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {situationSalarie.familialSituation === familialSituation.marie && (
              <CustomizedSwitches
                formLabel={wordingSalarie?.activeSpouse}
                tooltip={wordingSalarie?.infoActiveSpouse}
                labelPrefix={wordingSalarie?.no}
                labelSufix={wordingSalarie?.yes}
                checked={situationSalarie.activeSpouse}
                onChange={handleChange}
                name={situationSalarieFields.activeSpouse}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomAutoComplete
              onChangeValue={handleChangeCity}
              options={config[salarieSituationConfig.cities]}
              name={situationSalarieFields.city}
              value={situationSalarie.city}
              formLabel={wordingSalarie?.city}
              placeholder={wordingSalarie?.cityOfResidencePlaceHolder}
              loadingText={'chargement'}
              error={
                errors[situationSalarieFields.city]
                  ? errorsWording[errors[situationSalarieFields.city]]
                  : null
              }
              hasDefaultBorder={false}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectDown
              formLabel={wordingSalarie?.nationality}
              list={config[salarieSituationConfig.nationalities]}
              label={wordingSalarie?.nationalityPlaceHolder}
              name={situationSalarieFields.nationality}
              value={situationSalarie.nationality}
              onChange={handleChange}
              error={
                errors[situationSalarieFields.nationality]
                  ? errorsWording[errors[situationSalarieFields.nationality]]
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputNumber
              formLabel={wordingSalarie?.numberPersonWhitoutChilds}
              onChange={handleChange}
              name={situationSalarieFields.numberPersonWhitoutChilds}
              value={situationSalarie.numberPersonWhitoutChilds}
              placeholder={wordingSalarie?.numberPersonWhitoutChildsPlaceHolder}
              suffix={''}
              endElement={''}
              hasDefaultBorder={true}
              error={
                errors[situationSalarieFields.numberPersonWhitoutChilds]
                  ? errorsWording[
                      errors[situationSalarieFields.numberPersonWhitoutChilds]
                    ]
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputNumber
              formLabel={wordingSalarie?.numberChilds}
              onChange={handleChange}
              name={situationSalarieFields.numberChilds}
              value={situationSalarie.numberChilds}
              placeholder={wordingSalarie?.numberChildsPlaceHolder}
              suffix={''}
              endElement={''}
              hasDefaultBorder={true}
              error={
                errors[situationSalarieFields.numberChilds]
                  ? errorsWording[errors[situationSalarieFields.numberChilds]]
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectDown
              formLabel={wordingSalarie?.activityArea}
              list={config[salarieSituationConfig.activityArea]}
              label={wordingSalarie?.activityAreaPlaceHolder}
              name={situationSalarieFields.activityArea}
              value={situationSalarie.activityArea}
              onChange={handleChange}
              error={
                errors[situationSalarieFields.activityArea]
                  ? errorsWording[errors[situationSalarieFields.activityArea]]
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} /> {/* empty line */}
          <Grid item xs={12} sm={6}>
            <CustomizedSwitches
              formLabel={wordingSalarie?.titulaire}
              tooltip={wordingSalarie?.infoTitulaire}
              labelPrefix={wordingSalarie?.no}
              labelSufix={wordingSalarie?.yes}
              checked={situationSalarie.titulaire}
              onChange={handleChange}
              name={situationSalarieFields.titulaire}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={classes.globalTooltip}>
              <FormLabel
                className={classes.labelRenseingTooltip}
                component='legend'
              >
                {wordingSalarie?.natureContrat}
                <Tooltip content={wordingSalarie?.infoNatureContrat} />
              </FormLabel>
            </div>
            <Grid item xs={12} sm={8} className={classes.gridItem}>
              <CustomRadioGroup
                className={classes.radioGroup}
                name={situationSalarieFields.natureContrat}
                onChange={handleChange}
                value={situationSalarie.natureContrat}
                listOfElements={natureContratList}
                error=''
              />
            </Grid>
          </Grid>
          {situationSalarie.natureContrat === 'cdd' && (
            <>
              <Grid item xs={12} sm={6}>
                <CustomDatePicker
                  isCDD={true}
                  formLabel={wordingSalarie?.cddEndDate}
                  name={situationSalarieFields.cddEndDate}
                  value={situationSalarie.cddEndDate}
                  onChange={handleChange}
                  format='dd/MM/yyyy'
                  placeholder={wordingSalarie?.cddEndDate}
                  hasDefaultBorder={true}
                  error={
                    errors[situationSalarieFields.cddEndDate]
                      ? errorsWording[errors[situationSalarieFields.cddEndDate]]
                      : null
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} className={classes.displayDiv} />
            </>
          )}
          <>
            <Grid item xs={12} sm={6}>
              <CustomDatePicker
                isMinMax={true}
                formLabel={wordingSalarie?.dateCurrentEmployer}
                name={situationSalarieFields.dateCurrentEmployer}
                value={situationSalarie.dateCurrentEmployer}
                onChange={handleChange}
                format='MM/yyyy'
                views={['year', 'month']}
                placeholder={wordingSalarie?.dateCurrentEmployer}
                hasDefaultBorder={true}
                error={
                  errors[situationSalarieFields.dateCurrentEmployer]
                    ? errorsWording[
                        errors[situationSalarieFields.dateCurrentEmployer]
                      ]
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomizedSwitches
                formLabel={wordingSalarie?.retirment}
                tooltip={wordingSalarie?.retraiteRetirmentInfo}
                labelPrefix={wordingSalarie?.no}
                labelSufix={wordingSalarie?.yes}
                checked={situationSalarie.retirment}
                onChange={handleChange}
                name={situationSalarieFields.retirment}
              />
            </Grid>
          </>
          <Grid item xs={12} sm={6}>
            <InputNumber
              formLabel={wordingSalarie?.monthlyIncome}
              tooltip={wordingSalarie?.infoMonthlyIncome}
              onChange={handleChange}
              hasDefaultBorder={true}
              name={situationSalarieFields.monthlyIncome}
              value={situationSalarie.monthlyIncome}
              placeholder={wordingSalarie?.placeHolderMonthlyIncome}
              suffix={''}
              endElement={wordingSalarie?.dhs}
              decimalSeparator=','
              error={
                errors[situationSalarieFields.monthlyIncome]
                  ? errorsWording[errors[situationSalarieFields.monthlyIncome]]
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedSwitches
              formLabel={wordingSalarie?.additionalIncome}
              tooltip={wordingSalarie?.infoAdditionalIncome}
              labelPrefix={wordingSalarie?.no}
              labelSufix={wordingSalarie?.yes}
              checked={situationSalarie.additionalIncome}
              onChange={handleChange}
              name={situationSalarieFields.additionalIncome}
            />
          </Grid>
          {situationSalarie.additionalIncome === true && (
            <>
              <Grid item xs={12} sm={6}>
                <SelectDown
                  formLabel={wordingSalarie?.additionalIncomeNature}
                  list={additionalIncomeNatureList}
                  label={wordingSalarie?.additionalIncomeNaturePlaceHolder}
                  name={situationSalarieFields.additionalIncomeNature}
                  value={situationSalarie.additionalIncomeNature}
                  onChange={handleChange}
                  error={
                    errors[situationSalarieFields.additionalIncomeNature]
                      ? errorsWording[
                          errors[situationSalarieFields.additionalIncomeNature]
                        ]
                      : null
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputNumber
                  formLabel={wordingSalarie?.amountadditionalIncome}
                  onChange={handleChange}
                  hasDefaultBorder={true}
                  name={situationSalarieFields.amountadditionalIncome}
                  value={situationSalarie.amountadditionalIncome}
                  placeholder={
                    wordingSalarie?.placeHolderAmountadditionalIncome
                  }
                  suffix={''}
                  endElement={wordingSalarie?.dhs}
                  decimalSeparator=','
                  error={
                    errors[situationSalarieFields.amountadditionalIncome]
                      ? errorsWording[
                          errors[situationSalarieFields.amountadditionalIncome]
                        ]
                      : null
                  }
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={6}>
            <SelectDown
              formLabel={wordingSalarie?.codeOtherBank}
              list={config[salarieSituationConfig.banks]}
              label={wordingSalarie?.codeOtherBankPlaceHolder}
              tooltip={wordingSalarie?.codeOtherBankInfo}
              name={situationSalarieFields.codeOtherBank}
              value={situationSalarie.codeOtherBank}
              onChange={handleChange}
              error={
                errors[situationSalarieFields.codeOtherBank]
                  ? errorsWording[errors[situationSalarieFields.codeOtherBank]]
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomDatePicker
              isDefault={true}
              formLabel={wordingSalarie?.dateCreationOtherBankAccount}
              name={situationSalarieFields.dateCreationOtherBankAccount}
              value={situationSalarie.dateCreationOtherBankAccount}
              tooltip={wordingSalarie?.dateCreationOtherBankAccountInfo}
              onChange={handleChange}
              format='MM/yyyy'
              views={['year', 'month']}
              placeholder={
                wordingSalarie?.dateCreationOtherBankAccountPlaceholder
              }
              hasDefaultBorder={true}
              error={
                errors[situationSalarieFields.dateCreationOtherBankAccount]
                  ? errorsWording[
                      errors[
                        situationSalarieFields.dateCreationOtherBankAccount
                      ]
                    ]
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputNumber
              hasDefaultBorder={true}
              formLabel={wordingSalarie?.bankDebit}
              tooltip={wordingSalarie?.infoMonthlyTotalCredits}
              onChange={handleChange}
              name={situationSalarieFields.bankDebit}
              value={situationSalarie.bankDebit}
              placeholder={wordingSalarie?.placeHolderMonthlyTotalCredits}
              suffix={''}
              endElement={wordingSalarie?.dhs}
              decimalSeparator=','
              error={
                errors[situationSalarieFields.bankDebit]
                  ? errorsWording[errors[situationSalarieFields.bankDebit]]
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputNumber
              formLabel={wordingSalarie?.monthlyCreditImmovable}
              tooltip={wordingSalarie?.infoMonthlyCreditImmovable}
              onChange={handleChange}
              name={situationSalarieFields.monthlyCreditImmovable}
              value={situationSalarie.monthlyCreditImmovable}
              placeholder={wordingSalarie?.placeHolderMonthlyCreditImmovable}
              suffix={''}
              hasDefaultBorder={true}
              endElement={wordingSalarie?.dhs}
              decimalSeparator=','
              error={
                errors[situationSalarieFields.monthlyCreditImmovable]
                  ? errorsWording[
                      errors[situationSalarieFields.monthlyCreditImmovable]
                    ]
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputNumber
              formLabel={wordingSalarie?.mouvementCredit}
              tooltip={wordingSalarie?.mouvementCreditInfo}
              onChange={handleChange}
              name={situationSalarieFields.mouvementCredit}
              value={situationSalarie.mouvementCredit}
              placeholder={wordingSalarie?.mouvementCreditPlaceHolder}
              suffix={''}
              hasDefaultBorder={true}
              endElement={wordingSalarie?.dhs}
              decimalSeparator=','
              error={
                errors[situationSalarieFields.mouvementCredit]
                  ? errorsWording[
                      errors[situationSalarieFields.mouvementCredit]
                    ]
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputNumber
              formLabel={wordingSalarie?.mouvementDebit}
              tooltip={wordingSalarie?.mouvementDebitInfo}
              onChange={handleChange}
              name={situationSalarieFields.mouvementDebit}
              value={situationSalarie.mouvementDebit}
              placeholder={wordingSalarie?.mouvementDebitPlaceHolder}
              suffix={''}
              hasDefaultBorder={true}
              endElement={wordingSalarie?.dhs}
              decimalSeparator=','
              error={
                errors[situationSalarieFields.mouvementDebit]
                  ? errorsWording[errors[situationSalarieFields.mouvementDebit]]
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputNumber
              formLabel={wordingSalarie?.balanceEndOfMonth}
              tooltip={wordingSalarie?.balanceEndOfMonthInfo}
              onChange={handleChange}
              name={situationSalarieFields.balanceEndOfMonth}
              value={situationSalarie.balanceEndOfMonth}
              placeholder={wordingSalarie?.balanceEndOfMonthPlaceHolder}
              suffix={''}
              hasDefaultBorder={true}
              endElement={wordingSalarie?.dhs}
              decimalSeparator=','
              error={
                errors[situationSalarieFields.balanceEndOfMonth]
                  ? errorsWording[
                      errors[situationSalarieFields.balanceEndOfMonth]
                    ]
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormLabel className={classes.labelRenseing} component='legend'>
              {wordingSalarie?.cin}
            </FormLabel>
            <CustomInput
              value={situationSalarie.cin}
              name={situationSalarieFields.cin}
              onChange={handleChange}
              hasDefaultBorder={true}
              placeholder={wordingSalarie?.placeHolderCin}
              error={
                errors[situationSalarieFields.cin]
                  ? errorsWording[errors[situationSalarieFields.cin]]
                  : null
              }
            />
          </Grid>
        </Grid>
      </div>
    </CheckAuthorization>
  );
};

export default SalarieProspect;
