import { makeStyles } from '@material-ui/core/styles';
import { toLower, isEmpty } from 'lodash';
import { SimulationDetailLine } from 'common/components/SimulationDetailLine';
import { Wording } from 'common/wording/reducer.wording';
import { selectCompWording } from 'common/wording/selectors.wording';
import { GlobalTheme } from 'features/app/app.theme';
import React from 'react';
import { useSelector } from 'react-redux';
import { getRecapInfoByArraySelector } from 'features/recap/selector.recap';
import { CheckAuthorization } from 'common/components/PrivateRoute';
import { AccountTypes, ProductTypes } from 'features/auth/constants';
import { OUI } from 'features/app/app.constants';
import { getValidationInfoByArraySelector } from 'features/validationBmceDirect/selector.validation';

const useStyles = makeStyles((theme: GlobalTheme) => ({
  recapDetailBody: {
    maxWidth: '80%',
    margin: 'auto',
    padding: '10px 0 10px 0',
  },
}));

const RetraiteNotConv = () => {
  const wording: Wording = useSelector(selectCompWording('detailedForm'));
  const classes = useStyles();

  const propositionResponse: any = useSelector(
    getRecapInfoByArraySelector(['recap', 'fields']),
  );

  const preAttributionBmceDirect: any = useSelector(
    getValidationInfoByArraySelector(['preAttribution']),
  );

  return (
    <CheckAuthorization
      roles={{
        typeClients: [AccountTypes.retraite],
        products: [ProductTypes.cp],
      }}
    >
      <div className={classes.recapDetailBody}>
        {!isEmpty(propositionResponse.formattedCivility) && (
          <SimulationDetailLine
            label={wording?.civility}
            value={propositionResponse.formattedCivility}
            unit={''}
          />
        )}
        {!isEmpty(propositionResponse.lastName) && (
          <SimulationDetailLine
            label={wording?.lastName}
            value={propositionResponse.lastName}
            unit={''}
          />
        )}
        {!isEmpty(propositionResponse.firstName) && (
          <SimulationDetailLine
            label={wording?.firstName}
            value={propositionResponse.firstName}
            unit={''}
          />
        )}
        {!isEmpty(propositionResponse.birthday) && (
          <SimulationDetailLine
            label={wording?.birthDay}
            value={propositionResponse.birthday}
            unit={''}
          />
        )}
        {!isEmpty(propositionResponse.phoneNumber) && (
          <SimulationDetailLine
            label={wording?.phoneNumber}
            value={propositionResponse.phoneNumber}
            unit={''}
          />
        )}
        {!isEmpty(propositionResponse.email) && (
          <SimulationDetailLine
            label={wording?.email}
            value={propositionResponse.email}
            unit={''}
          />
        )}
        {!isEmpty(propositionResponse.formattedFamilialSituation) && (
          <SimulationDetailLine
            label={wording?.familialSituation}
            value={propositionResponse.formattedFamilialSituation}
            unit={''}
          />
        )}
        {!isEmpty(propositionResponse.formattedactiveSpouse) &&
          propositionResponse.familialSituation === '1' && (
            <SimulationDetailLine
              label={wording?.activeSpouse}
              value={
                toLower(propositionResponse.formattedactiveSpouse) ===
                toLower(OUI)
                  ? wording?.yes
                  : wording?.no
              }
              unit={''}
            />
          )}
        {!preAttributionBmceDirect &&
          !isEmpty(propositionResponse.formattedCity) && (
            <SimulationDetailLine
              label={wording?.city}
              value={propositionResponse.formattedCity}
              unit={''}
            />
          )}
        {!preAttributionBmceDirect &&
          !isEmpty(propositionResponse.formattedNationality) && (
            <SimulationDetailLine
              label={wording?.nationality}
              value={propositionResponse.formattedNationality}
              unit={''}
            />
          )}
        {!isEmpty(propositionResponse.numberPersonWhitoutChilds) && (
          <SimulationDetailLine
            label={wording?.numberPersonWhitoutChilds}
            value={propositionResponse.numberPersonWhitoutChilds}
            unit={''}
          />
        )}
        {!isEmpty(propositionResponse.numberChilds) && (
          <SimulationDetailLine
            label={wording?.numberChilds}
            value={propositionResponse.numberChilds}
            unit={''}
          />
        )}
        {!preAttributionBmceDirect &&
          !isEmpty(propositionResponse.formattedProfession) && (
            <SimulationDetailLine
              label={wording?.profession}
              value={propositionResponse.formattedProfession}
              unit={''}
            />
          )}
        {!preAttributionBmceDirect &&
          !isEmpty(propositionResponse.monthlyIncome) && (
            <SimulationDetailLine
              label={wording?.monthlyIncome}
              value={propositionResponse.monthlyIncome}
              unit={wording?.dhs}
            />
          )}
        {!isEmpty(propositionResponse.amountadditionalIncome) &&
          propositionResponse.additionalIncome !== false && (
            <SimulationDetailLine
              label={wording?.additionalIncome}
              value={propositionResponse.amountadditionalIncome}
              unit={wording?.dhs}
            />
          )}
        {!isEmpty(propositionResponse.bankDebit) && (
          <SimulationDetailLine
            label={wording?.bankDebit}
            value={propositionResponse.bankDebit}
            unit={wording?.dhs}
          />
        )}
        {!isEmpty(propositionResponse.monthlyCreditImmovable) && (
          <SimulationDetailLine
            label={wording?.monthlyCreditImmovable}
            value={propositionResponse.monthlyCreditImmovable}
            unit={wording?.dhs}
          />
        )}
        {!preAttributionBmceDirect &&
          !isEmpty(propositionResponse.dateLastVirement) &&
          propositionResponse.dateLastVirement !== '00/00/00' && (
            <SimulationDetailLine
              label={wording?.retraiteDateLastVirement}
              value={propositionResponse.dateLastVirement}
              unit={''}
            />
          )}
        {!preAttributionBmceDirect && !isEmpty(propositionResponse.cin) && (
          <SimulationDetailLine
            label={wording?.cin}
            value={propositionResponse.cin}
            unit={''}
          />
        )}
        {!preAttributionBmceDirect &&
          propositionResponse.isBMCE === 'bmce' &&
          propositionResponse.rib !== '' && (
            <SimulationDetailLine
              label={wording?.rib}
              value={propositionResponse.rib}
              unit={''}
            />
          )}
      </div>
    </CheckAuthorization>
  );
};

export default RetraiteNotConv;
