import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { getSimulationInfoByArraySelector } from 'features/home/selector.simulation';
import { selectWording } from 'common/wording/selectors.wording';
import { Wording } from 'common/wording/reducer.wording';
import { GlobalTheme } from 'features/app/app.theme';
import logoCreditDaba from 'assets/svg/logoCreditDaba-dark.svg';
import Typography from '@material-ui/core/Typography';
import CercleElement from './CercleElement';
import { LANG_STATE } from 'features/home/constants.simulation';

const useStyles = makeStyles((theme: GlobalTheme) => ({
  root: {
    display: 'flex',
    height: 'auto',
    margin: 'auto',
    justifyContent: 'space-between',
  },
  rootAr: {
    display: 'flex',
    height: 'auto',
    margin: 'auto',
    justifyContent: 'space-between',
  },
  logo: {
    display: 'block',
    width: '220px',
    margin: '0 auto 15px',
    '@media (max-width: 425px)': {
      width: '220px',
      marginTop: '20px',
    },
  },
  information: {
    display: 'flex',
    padding: '10px',
    flexWrap: 'wrap',
    justifyContent: 'center',
    margin: '20px auto 50px',
    '@media (max-width: 768px)': {
      padding: '0',
    },
  },
  blueBackgroundCercle: {
    backgroundColor: theme.primary.title,
  },
  blueColorCercle: {
    color: theme.primary.title,
  },
  whiteBackgroundCercle: {
    backgroundColor: '#fff',
    '@media (max-width: 768px)': {
      backgroundColor: '#F1F1F1',
    },
  },
  whiteColorCercle: {
    color: '#FFF',
  },
  title: {
    textAlign: 'center',
    color: '#095098',
    fontSize: '2.1rem',
    marginBottom: '0.5rem',
    '@media (max-width: 600px)': {
      fontSize: '2rem',
    },
  },
  subTitle: {
    textAlign: 'center',
    color: '#095098',
    fontSize: '1.5rem',
    marginTop: '0.5rem',
    lineHeight: 1.3,
    // fontFamily: theme.fonts.bold,
    '@media (max-width: 600px)': {
      fontSize: '1.3rem',
    },

    '& small': {
      fontSize: '80%',
    },
  },
  rspContentStyle: {
    fontSize: '1.25rem',
    marginBottom: 0,
    marginTop: 0,
  },
  content: {
    padding: '35px 2rem 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: '1300px',
    '@media (max-width: 768px)': {
      padding: '40px 20px 0',
      alignItems: 'flex-start',
      backgroundColor: '#fff',
    },
  },
  reverse1: {
    '@media (max-width: 768px)': {
      order: 1,
    },
  },
  reverse2: {
    '@media (max-width: 768px)': {
      order: 2,
      zIndex: 1,
      height: '44px',
      marginBottom: '15px',
    },
  },
  reverse3: {
    '@media (max-width: 768px)': {
      order: 3,
      marginTop: '15px',
      height: '40px',
    },
  },
  reverse4: {
    '@media (max-width: 768px)': {
      order: 4,
    },
  },
}));

const HeaderHomePage = () => {
  const classes = useStyles();
  const wording: Wording = useSelector(selectWording);
  const langSelected: any =
    useSelector(getSimulationInfoByArraySelector(['lang'])) || LANG_STATE.FR;
  return (
    <div
      className={langSelected === LANG_STATE.FR ? classes.root : classes.rootAr}
      title={wording?.alt.couple_home}
    >
      <div className={classes.content}>
        <img
          className={classes.logo}
          alt={wording?.alt.logo_CD}
          src={logoCreditDaba}
        />

        <Typography variant='h1' className={classes.title}>
          {wording?.homePage.headerTitle1}
          <Typography variant='inherit' color='secondary'>
            {wording?.homePage.headerTitle2}
          </Typography>
          {wording?.homePage.headerTitle3}
        </Typography>

        <Typography variant='h2' className={classes.subTitle}>
          <span
            dangerouslySetInnerHTML={{
              __html: wording?.homePage.questionContentHeader,
            }}
          />
        </Typography>

        <div className={classes.information}>
          <CercleElement
            title={wording?.homePage.secondContentHeader}
            subTitle={''}
            width={'140px'}
            className={{
              classes: {
                backgroundCercle: classes.blueBackgroundCercle,
                textColorCercle: classes.whiteColorCercle,
                reverse: classes.reverse2,
              },
            }}
          />
          <CercleElement
            title={wording?.homePage.thirdContentHeader}
            subTitle={wording?.homePage.subTitlethirdContentHeader}
            width={'220px'}
            className={{
              classes: {
                backgroundCercle: classes.whiteBackgroundCercle,
                textColorCercle: classes.blueColorCercle,
                reverse: classes.reverse4,
              },
            }}
          />
          <CercleElement
            title={wording?.homePage.lastontentHeader}
            subTitle={''}
            width={'180px'}
            className={{
              classes: {
                backgroundCercle: classes.blueBackgroundCercle,
                textColorCercle: classes.whiteColorCercle,
                reverse: classes.reverse3,
              },
            }}
          />
          <CercleElement
            title={wording?.homePage.firstContentHeader}
            subTitle={''}
            width={'240px'}
            className={{
              classes: {
                backgroundCercle: classes.whiteBackgroundCercle,
                textColorCercle: classes.blueColorCercle,
                reverse: classes.reverse1,
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default HeaderHomePage;
