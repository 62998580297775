import { removeSessionStorage } from './../../common/utils/sessionStorage.util';
import { getValidationInfoByArraySelector } from './selector.validation';
import {
  ERROR_WAF,
  DURATION_OR_AMOUNT_NULL,
} from './../../common/utils/validateJs/contants';
import { updateStepAct } from './../situation/actions.situation';
import { updateFieldCaseAction } from './../suivieDossier/actions.caseTracking';
import { updateFieldUpAction } from './../upload/actions.upload';
import { PropositionAT } from './../proposition/actions.proposition';
import { isEmpty, trim } from 'lodash';
import { put, takeLatest, select } from 'redux-saga/effects';
import { getRoute } from 'common/utils/app.util';
import { ValidationAT } from './actions.validation';
import { apiGet, apiPost } from 'common/utils/request.util';
import { push } from 'connected-react-router';
import {
  isAuthenticatedAction,
  saveUserProfileAction,
} from 'features/auth/actions.auth';
import {
  setSessionStorage,
  getSessionStorage,
} from 'common/utils/sessionStorage.util';
import { updateFieldSignUpAction } from 'features/auth/actions.auth';
import { updateFieldByArrayAction } from 'features/home/actions.simulation';
import { SimulationAT } from 'features/home/actions.simulation';
import {
  LANG_STATE,
  SLIDER_PREATTRIBUTION,
} from './../home/constants.simulation';

export function* validateTokenBmceDirect(action: any) {
  // à priciser :  Conditions sur la durée et le montant
  const MontantCREDIT = parseInt(getSessionStorage('MontantCREDIT'));
  const duree = parseInt(getSessionStorage('duree'));
  const redirectionNormale = getSessionStorage('redirectionNormale');

  // chack validate params of preAtribution url
  const validParams = (param: any) => {
    if (isNaN(param) || param <= 0) return false;
    return true;
  };
  try {
    const wordApi: any = yield apiGet('/form-config/get-wordings');
    const { data } = yield apiPost('/auth/validate-token-bmce-direct', {
      token: action.token,
    });

    if (data && !isEmpty(wordApi) && !isEmpty(data.cin)) {
      data.cin = trim(data.cin);
    }
    if (data) {
      const lang = data.lang || LANG_STATE.FR;
      if (!redirectionNormale) {
        //  one or more of the params is invalid
        if (!validParams(duree) || !validParams(MontantCREDIT)) {
          yield put({ type: ValidationAT.VALIDATION_LOADER, loader: true });
          yield put({
            type: ValidationAT.VALIDATION_BMCEDIRECT_ERROR,
            errors: DURATION_OR_AMOUNT_NULL,
          });
          yield put({
            type: ValidationAT.VALIDATION_PREATTRIBUTION,
            preAttribution: false,
          });
          yield put({ type: ValidationAT.VALIDATION_LOADER, loader: false });
        } else {
          yield put({
            type: ValidationAT.VALIDATION_PREATTRIBUTION,
            preAttribution: true,
          });
        }
      }
      setSessionStorage('lang', lang);
      setSessionStorage('simulationId', data.idSimulation);
      setSessionStorage('rachat', data.rachat);
      if (isEmpty(data.rachat)) setSessionStorage('rachat', 'N');
      yield put({ type: SimulationAT.setLang, value: lang });
      if (!isEmpty(data.cin)) {
        yield put(isAuthenticatedAction(true));
      }
      const userProfile: any = {
        clientBOA: data.clientBOA,
        typeClient: data.typeClient,
        product: data.product,
        stepClient: data.stepClient,
        appreciation: data.appreciation,
      };
      //yield put(saveUserProfileAction(userProfile));
      yield saveUserProfileAndRedirect(userProfile);
      // Get URL
      yield put(
        updateStepAct('situationSalarie', {
          ...data,
        }),
      );

      setSessionStorage('situationSalarie', {
        ...data,
        listRibBmce: true,
        cinBmce: true,
      });
      let preAttributionBmce = yield select(
        getValidationInfoByArraySelector(['preAttribution']),
      );
      const _url = getRoute(
        data.typeClient,
        data.product,
        data.clientBOA,
        data.stepClient,
        data.appreciation,
        preAttributionBmce,
      );
      // check this later : preattribution vs redirectionNormale
      // ? which form to redirect to

      yield put(push(_url));
      yield put({ type: PropositionAT.updateProposition, data });
      setSessionStorage('proposition', data);
      yield put(updateFieldSignUpAction('signUp', 'bmceDirect', true));
      yield put(updateFieldUpAction('upload', 'type', data.typeClient));
      yield put(updateFieldUpAction('upload', 'product', data.product));
      yield put(
        updateFieldUpAction('upload', 'clientBOA', String(data.clientBOA)),
      );
      if (data.stepClient === '1') {
        yield put(push('/sign-up'));
      }
      if (data.stepClient === '6') {
        const datas = yield apiPost('/situation/suivi-dossier', '');
        setSessionStorage('statutDossier', datas.data.statusDemande);
        yield put(
          updateFieldCaseAction(
            'caseTracking',
            'step',
            datas.data.statusDemande,
          ),
        );
      }

      yield put({ type: ValidationAT.VALIDATION_BMCEDIRECT_SUCCESS, data });

      setSessionStorage('validation', {
        token: action.token,
        data,
        preAttribution: preAttributionBmce,
      });
      setSessionStorage('userProfile', userProfile);
      setSessionStorage('firstName', data.firstName);
      setSessionStorage('lastName', data.lastName);
      setSessionStorage('bmceDirect', data.bmceDirect);
      setSessionStorage('rib', data.rib);
      setSessionStorage('cin', data.cin);
      setSessionStorage('typeclient', data.typeClient);
      setSessionStorage('birthday', data.birthDay);
      setSessionStorage('employerName', data.employerName);
      setSessionStorage('comptDate', 1);
      setSessionStorage('email', data.email);
      yield put(updateFieldSignUpAction('signUp', 'firstName', data.firstName));
      yield put(updateFieldSignUpAction('signUp', 'lastName', data.lastName));
      yield put(updateFieldSignUpAction('signUp', 'email', data.email));
      yield put(updateFieldSignUpAction('signUp', 'birthday', data.birthDay));
      yield put(updateFieldSignUpAction('signUp', 'comptDate', 1));
      preAttributionBmce = yield select(
        getValidationInfoByArraySelector(['preAttribution']),
      );
      if (preAttributionBmce) {
        yield put(
          updateFieldByArrayAction(
            ['simulation', 'selectedConvention', 'maxAmount'],
            MontantCREDIT,
          ),
        );
        yield put(
          updateFieldByArrayAction(
            ['simulation', 'selectedConvention', 'minDuration'],
            SLIDER_PREATTRIBUTION.minDuration,
          ),
        );
        yield put(
          updateFieldByArrayAction(
            ['simulation', 'selectedConvention', 'maxDuration'],
            duree,
          ),
        );
        yield put(
          updateFieldByArrayAction(
            ['simulation', 'fields', 'creditDuration'],
            duree,
          ),
        );
        yield put(
          updateFieldByArrayAction(
            ['simulation', 'fields', 'creditAmount'],
            MontantCREDIT,
          ),
        );
      }
      removeSessionStorage('redirectionNormale');
      yield put({ type: ValidationAT.VALIDATION_LOADER, loader: false });
    } else {
      yield put({ type: ValidationAT.VALIDATION_LOADER, loader: true });
      yield put({
        type: ValidationAT.VALIDATION_BMCEDIRECT_ERROR,
        errors: ERROR_WAF,
      });
      yield put({ type: ValidationAT.VALIDATION_LOADER, loader: false });
    }
  } catch (e) {
    yield put({ type: ValidationAT.VALIDATION_LOADER, loader: true });
    const errors =
      !isEmpty(e.response) && e.response.data?.errors[0]?.errors[0];
    yield put({ type: ValidationAT.VALIDATION_BMCEDIRECT_ERROR, errors });
    yield put({ type: ValidationAT.VALIDATION_LOADER, loader: false });
  }
}

export function* validateTokenBmceDirectSaga() {
  yield takeLatest(ValidationAT.VALIDATION_BMCEDIRECT, validateTokenBmceDirect);
}

export function* saveUserProfileAndRedirect(userProfile: any) {
  setSessionStorage('userProfile', userProfile);
  yield put(saveUserProfileAction(userProfile));
}

export const validateTokenSagas = [validateTokenBmceDirectSaga];
