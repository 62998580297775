import { Checkbox, FormControlLabel } from '@material-ui/core';
import CheckedFilled from 'assets/svg/ActifCheckbox.svg';
import Unchecked from 'assets/svg/NonActifCheckbox.svg';
import React, { ReactElement } from 'react';
import { GlobalTheme } from 'features/app/app.theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { getSimulationInfoByArraySelector } from 'features/home/selector.simulation';
import { LANG_STATE } from 'features/home/constants.simulation';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme: GlobalTheme) =>
  createStyles({
    checkBoxIcon: {
      width: '20px',
    },
    checkBox: {
      marginRight: 0,
      marginLeft: 0,
      '& .MuiCheckbox-root': {
        marginBottom: '10px',
      },
    },
    checkBoxAr: {
      '& .MuiCheckbox-root': {
        marginBottom: '5px',
      },
    },
  }),
);

interface PropsCustomCheckBox {
  label: ReactElement<HTMLDivElement> | string;
  checked: boolean;
  onChange?: (e: any) => void;
  name: string;
}

export const CustomCheckBox = (
  {
    label = '',
    onChange = () => ({}),
    checked = false,
    name = '',
    ...rest
  }: PropsCustomCheckBox,
  ref: React.Ref<any>,
) => {
  const customOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const output = {
      target: {
        value: e.target.checked,
        name: e.target.name,
      },
    };
    onChange(output);
  };
  const classes = useStyles();
  const langSelected: any =
    useSelector(getSimulationInfoByArraySelector(['lang'])) || LANG_STATE.FR;
  return (
    <FormControlLabel
      className={
        langSelected === LANG_STATE.FR ? classes.checkBox : classes.checkBoxAr
      }
      control={
        <Checkbox
          icon={<img src={Unchecked} alt='' className={classes.checkBoxIcon} />}
          name={name}
          checked={checked}
          onChange={customOnChange}
          checkedIcon={
            <img src={CheckedFilled} alt='' className={classes.checkBoxIcon} />
          }
          {...rest}
        />
      }
      label={label}
    />
  );
};
