import { getAuthInfoByArraySelector } from 'features/auth/selector.auth';
import { useSelector } from 'react-redux';

// return nidex of step in the list
function useStepperMapper(): number {
  const userProfile: any = useSelector(
    getAuthInfoByArraySelector(['userProfile']),
  );

  switch (userProfile?.stepClient) {
    case '1':
      return 1;
    case '2':
      return 1; //  still the same step [1]
    case '3':
      return 2;
    case '4':
      return 2; // dont know what is step 4 so I return step 2 instead
    case '5':
      return 3;
    case '6':
      return 4;
    // default step => 1
    default:
      return 1;
  }
}

export default useStepperMapper;
