import React, { ReactElement } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { userProfileSelector } from 'features/auth/selector.auth';
import { useSelector } from 'react-redux';
import { getRoute } from 'common/utils/app.util';
import { anomalieCodeTypes } from 'features/auth/constants';
import useIsAuthenticated from 'common/hooks/useIsAuthenticate';
import { includes, toUpper, has } from 'lodash';
import { getValidationInfoByArraySelector } from './../../features/validationBmceDirect/selector.validation';
import { setSessionStorage } from 'common/utils/sessionStorage.util';

const PrivateRoute = ({
  _private = false,
  component,
  path,
  exact = false,
}: PropsPrivateRoute) => {
  const _isAuth = useIsAuthenticated();
  if (_private && !_isAuth) {
    return <Redirect to='/sign-in' />;
  }
  return <Route path={path} exact={exact} component={component} />;
};

export const CheckAuthorization = ({
  children,
  roles,
}: PropsCheckAuthorization) => {
  const user = useSelector(userProfileSelector);
  const preAttributionBmceDirect: any = useSelector(
    getValidationInfoByArraySelector(['preAttribution']),
  );
  const _url = getRoute(
    user.typeClient,
    user.product,
    user.clientBOA,
    user.stepClient,
    user.appreciation,
    preAttributionBmceDirect,
  );
  if (
    roles?.typeClients.includes(user.typeClient) &&
    roles?.products.includes(user.product)
  ) {
    return children;
  } else {
    return <Redirect to={_url} />;
  }
};

export const CheckAppreciation = ({
  children,
  proposition,
  page,
}: PropsCheckAppreciation) => {
  const user = useSelector(userProfileSelector);
  const preAttributionBmceDirect: any = useSelector(
    getValidationInfoByArraySelector(['preAttribution']),
  );
  const _url = getRoute(
    user.typeClient,
    user.product,
    user.clientBOA,
    '3', // Type 3 = Proposition
    user.appreciation,
    preAttributionBmceDirect,
  );
  setSessionStorage('userProfile', user);
  if (
    proposition.anomalieCode === anomalieCodeTypes.E2 ||
    (has(proposition, 'appreciation') &&
      includes(page, toUpper(proposition.appreciation))) ||
    proposition.anomalieCode === 'E3' ||
    (has(proposition, 'appreciation') &&
      proposition.anomalieCode === anomalieCodeTypes.E23) ||
    (has(proposition, 'appreciation') &&
      proposition.anomalieCode === anomalieCodeTypes.E100) ||
    proposition.anomalieCode === anomalieCodeTypes.E22
  ) {
    return children;
  } else {
    return <Redirect to={_url} />;
  }
};

export type PropsCheckAuthorization = {
  children: ReactElement;
  roles?: {
    typeClients: string[];
    products: string[];
  };
};

export type PropsCheckAppreciation = {
  children: ReactElement;
  proposition: any;
  page: any;
};

export interface PropsPrivateRoute {
  _private?: boolean;
  component: (props: any) => ReactElement<any>;
  path: string;
  exact?: boolean;
}

export default PrivateRoute;
