import { getSessionStorage } from 'common/utils/sessionStorage.util';

export enum signUpFields {
  civility = 'civility',
  login = 'login',
  password = 'password',
  cin = 'cin',
  redirectViaBmceDirect = 'redirectViaBmceDirect',
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  emailConfirmation = 'emailConfirmation',
  birthday = 'birthday',
  companyName = 'companyName',
  nameOtherEmploye = 'nameOtherEmploye',
  product = 'product',
  segClient = 'segClient',
  codeConvention = 'codeConvention',
  conditionOfUse = 'conditionOfUse',
  promotionalOffers = 'promotionalOffers',
  comptDate = 'comptDate',
  comptCompany = 'comptCompany',
  comptOtherComp = 'comptOtherComp',
  convention = 'convention',
  profession = 'profession',
}

export interface SignUpUser {
  [signUpFields.civility]: string;
  [signUpFields.login]: string;
  [signUpFields.password]: string;
  [signUpFields.firstName]: string;
  [signUpFields.lastName]: string;
  [signUpFields.email]: string;
  [signUpFields.emailConfirmation]: string;
  [signUpFields.birthday]: Date;
  [signUpFields.companyName]: string;
  [signUpFields.codeConvention]: string;
  [signUpFields.conditionOfUse]: boolean;
  [signUpFields.promotionalOffers]?: boolean;
}
export enum signInFields {
  login = 'login',
  email = 'email',
  password = 'password',
  bmceDirect = 'bmceDirect',
  reCAPTCHA = 'reCAPTCHA',
  accessCode = 'accessCode',
}

export enum passOublieFields {
  email = 'email',
}

export enum generateCodeFields {
  accessCode = 'accessCode',
}

export interface SignInUser {
  [signInFields.login]: string;
  [signInFields.password]: string;
  [signInFields.bmceDirect]: string | boolean;
}

export interface IUserProfile {
  typeClient: typeof AccountTypes;
  product: typeof ProductTypes;
  stepClient?: string;
  clientBOA: boolean;
  appreciation?: string;
}

/* Tracker Salafin PING-PONG (stepClient - etapClient - etapeWeb)
  1 - Création de profil
  2 - Formulaire détaillé
  3 - Proposition
  4 - Récap
  5 - Upload des Pj
  6 - Suivi du dossier
  7 - Simulation
  8 - Espace Personnel à créer
*/
export const etapeClientCreationProfile = '1';
export const etapeClientFormDetaille = '2';
export const etapeClientProposition = '3';
export const etapeClientRecap = '4';
export const etapeClientUploadPJ = '5';
export const etapeClientSuiviDossier = '6';
export const etapeClientSimulation = '7';
export const etapeClientEspacePersoACreer = '8';

export const AccountTypes = {
  salarie: 'salarie',
  fonctionnaire: 'fonctionnaire',
  retraite: 'retraite',
  professionnel: 'professionnel',
};

export const ProductTypes = {
  bconvcp: 'BCONVCP',
  cp: 'CP',
  bconvppr: 'BCONVPPR',
  ppr: 'PPR',
  cp_slf: 'CP_SLF',
};

export const anomalieCodeTypes = {
  E23: 'E23', // RIB incorrecte
  E22: 'E22',
  E100: 'E100',
  E2: 'E2',
};

export const professionTypes = {
  retraite: '11', // 11: retraité
};

export const SegClientTypes = {
  C: 'C',
  NC: 'NC',
  P: 'P',
};

export enum createProfileBMCEFields {
  idBMCEDirect = 'login',
  pwdBMCEDirect = 'password',
}

export enum WOOTRIC_LINK {
  PROPOSITION = '/proposition',
  DEROG_PROPOSITION = '/derogation-proposition',
  REJECT_PROPOSITION = '/reject-proposition',
  CASE_TRACKING = '/case-tracking',
}

export interface SignUpBMCEdirect {
  [createProfileBMCEFields.idBMCEDirect]: string;
  [createProfileBMCEFields.pwdBMCEDirect]: string;
}

interface Params {
  event: string;
  environment_type?: string;
  page_url?: string;
  page_name?: string;
  language?: string;
  audience?: string;
  is_client?: string;
  user_id?: string;
  page_typology?: string;
  click_label?: string;
  debt_type?: string;
  element_category?: string;
  form_type?: string;
  login_type?: string;
  creation_type?: string;
}

export function pushEvent(params: Params) {
  window?.dataLayer?.push(params);
}

export function pushPageView(
  pageName: string,
  pageType: string = 'onboarding',
) {
  let eventObj = {
    event: 'page_view',
    environment_type:
      window.location.hostname === 'www.creditdaba.ma' ? 'prod' : 'staging',
    page_url: window.location.href,
    page_name: pageName,
    page_typology: pageType,
    language: getSessionStorage('lang') !== 'FR' ? 'AR' : 'FR',
    audience:
      getSessionStorage('typeclient') ??
      getSessionStorage('userProfile')?.typeclient,
    is_client:
      getSessionStorage('clientBOA') ??
      getSessionStorage('userProfile')?.clientBOA,
    user_id:
      getSessionStorage('simulationId') ??
      getSessionStorage('situationSalarie')?.idSimulation,
  };
  window?.dataLayer?.push(eventObj);
}
