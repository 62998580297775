import { Wording, WordingError } from './reducer.wording';

export const LOAD_WORDING_SUCCESS = 'LOAD_WORDING_SUCCESS';
export const LOAD_WORDING_AR_SUCCESS = 'LOAD_WORDING_AR_SUCCESS';
export const LOAD_WORDING_FR_SUCCESS = 'LOAD_WORDING_FR_SUCCESS';
export const LOAD_WORDING = 'LOAD_WORDING';
export const LOAD_WORDING_ERROR = 'LOAD_WORDING_ERROR';
export const SET_LOADING = 'SET_LOADING';

export interface LoadWordingAction {
  type: typeof LOAD_WORDING;
}
export interface LoadWordingSuccessAction {
  type: typeof LOAD_WORDING_SUCCESS;
  wording: Wording;
}
export interface LoadWordingARSuccessAction {
  type: typeof LOAD_WORDING_AR_SUCCESS;
  wording: Wording;
}
export interface LoadWordingFRSuccessAction {
  type: typeof LOAD_WORDING_FR_SUCCESS;
  wording: Wording;
}

export interface LoadWordingErrorAction {
  type: typeof LOAD_WORDING_ERROR;
  error: WordingError;
}

export interface SetLoadingAction {
  type: typeof SET_LOADING;
  loading: boolean;
}

export function loadWordingAction(): LoadWordingAction {
  return {
    type: LOAD_WORDING,
  };
}

export function loadWordingErrorAction(
  error: WordingError,
): LoadWordingErrorAction {
  return {
    error,
    type: LOAD_WORDING_ERROR,
  };
}

export function setLoadingAction(
  loading: any,
): SetLoadingAction {
  return {
    loading,
    type: SET_LOADING,
  };
}

export function loadWordingSuccessAction(
  wording: any,
): LoadWordingSuccessAction {
  return {
    wording,
    type: LOAD_WORDING_SUCCESS,
  };
}

export function loadWordingARSuccessAction(
  wording: Wording,
): LoadWordingARSuccessAction {
  return {
    wording,
    type: LOAD_WORDING_AR_SUCCESS,
  };
}

export function loadWordingFRSuccessAction(
  wording: Wording,
): LoadWordingFRSuccessAction {
  return {
    wording,
    type: LOAD_WORDING_FR_SUCCESS,
  };
}
