import { useEffect } from 'react';
import { env } from 'env';
import { isEmpty } from 'lodash';
import { useLocation } from 'react-router-dom';
import { WOOTRIC_LINK } from './constants';
import useIsAuthenticated from 'common/hooks/useIsAuthenticate';
import { LANG_STATE } from 'features/home/constants.simulation';
import { getSessionStorage } from 'common/utils/sessionStorage.util';

const Wootric = ({ langSelected }: any) => {
  const location = useLocation();
  const _isAuth = useIsAuthenticated();
  const userData = getSessionStorage('userData');
  const storageEmail = getSessionStorage('email');
  const wootricEmail = env.wootricEmail;
  const email =
    !isEmpty(userData) && !isEmpty(userData.email)
      ? userData.email
      : !isEmpty(storageEmail)
      ? storageEmail
      : wootricEmail;
  const initializeWootric = () => {
    try {
      const setupScript = document.createElement('script');
      setupScript.type = 'text/javascript';
      setupScript.id = 'wootric-settings';
      setupScript.async = true;
      setupScript.innerHTML = `
      wootric_no_surveyed_cookie = true;
      wootric_survey_immediately = true;
      window.wootricSettings = {
          email: "${email}",
          language: "${
            langSelected === LANG_STATE.FR ? LANG_STATE.FR : LANG_STATE.AR_LABEL
          }",
          account_token: "${env.wootricToken}",
          product_name: "CRÉDIT DABA"
      };
    `;
      if (document.getElementById('wootric-settings') === null) {
        document.body.appendChild(setupScript);
      }

      // Beacon
      const beacon = document.createElement('script');
      beacon.type = 'text/javascript';
      beacon.id = 'wootric-beacon';
      beacon.async = true;
      beacon.src = 'https://cdn.wootric.com/wootric-sdk.js';

      beacon.onload = function run() {
        (window as any)?.wootric('run');
      };
      document.body.appendChild(beacon);
    } catch (e) {
      console.error('Something went wrong while initializing Wootric script.');
      console.error(e);
    }
  };
  useEffect(() => {
    if (
      _isAuth &&
      (location.pathname === WOOTRIC_LINK.PROPOSITION ||
        location.pathname === WOOTRIC_LINK.DEROG_PROPOSITION ||
        location.pathname === WOOTRIC_LINK.REJECT_PROPOSITION ||
        location.pathname === WOOTRIC_LINK.CASE_TRACKING)
    ) {
      initializeWootric();
    }
  });
  return null;
};

export default Wootric;
