import Box from '@material-ui/core/Box';
import { Wording } from 'common/wording/reducer.wording';
import { selectCompWording } from 'common/wording/selectors.wording';
import React from 'react';
import { useSelector } from 'react-redux';
import BlocTitle from './BlocTitle';
import HightLightedBox from './HightLightedBox';

const HowItWorks = () => {
  const wording: Wording = useSelector(selectCompWording('contextual'));

  return (
    <Box>
      <BlocTitle title={wording?.title_how_it_works} />
      <HightLightedBox
        title={wording?.title_1_how_it_works}
        description={wording?.description_1_how_it_works}
        subDescription={wording?.subdescription_1_how_it_works}
      />
      <HightLightedBox
        title={wording?.title_2_how_it_works}
        description={wording?.description_2_how_it_works}
      />
      <HightLightedBox
        title={wording?.title_3_how_it_works}
        description={wording?.description_3_how_it_works}
      />
    </Box>
  );
};

export default HowItWorks;
