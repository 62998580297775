import { IUserProfile } from './constants';

export enum AuthAT {
  loGout = 'AUTHAT/LOGOUT',
  updateFieldSignUp = 'AUTHAT/UPDATEFIELDSIGNUP',
  updateFieldSignIn = 'AUTHAT/UPDATEFIELDSIGNIN',
  submitSignUp = 'AUTHAT/SUBMITSIGNUP',
  submitSignIn = 'AUTHAT/SUBMITSIGNIN',
  isAuthenticated = 'AUTHAT/ISAUTHENTICATED',
  submitSignOut = 'AUTHAT/SUBMITSIGNOUT',
  submitSignUpError = 'AUTHAT/SUBMITSIGNUPERROR',
  submitSignInError = 'AUTHAT/SUBMITSIGNINERROR',
  submitSignUpBMCEDirect = 'AUTHAT/SUBMITSIGNUPBMCEDIRECT',
  submitSignUpBMCEDirectError = 'AUTHAT/SUBMITSIGNUPBMCEDIRECTERROR',
  updateStep = 'AUTHAT/UPDATESTEP',
  submitPasswordOublie = 'AUTHAT/SUBMITPASSWORDOUBLIE',
  submitPasswordOublieError = 'AUTHAT/SUBMITPASSWORDOUBLIEERROR',
  saveUserProfile = 'AUTHAT/SAVEUSERPROFILE',
  submitGenerateAccessCode = 'AUTHAT/SUBMITGENERATEACCESSCODE',
  submitGenerate = 'AUTHAT/SUBMITGENERATE',
}

export interface LoGout {
  type: AuthAT.loGout;
}

export interface SubmitPasswordOublieAction {
  type: AuthAT.submitPasswordOublie;
}

export interface IsAuthenticatedInterface {
  type: AuthAT.isAuthenticated;
  value: boolean;
}

export const isAuthenticatedAction = (
  value: boolean,
): IsAuthenticatedInterface => ({
  type: AuthAT.isAuthenticated,
  value,
});

export interface SubmitSignUpAction {
  type: AuthAT.submitSignUp;
}

export interface SubmitSignInAction {
  type: AuthAT.submitSignIn;
  reCAPTCHA: string;
}

export interface SubmitGenerateAction {
  type: AuthAT.submitGenerate;
  reCAPTCHA: string;
}

export interface ValidateJsError {
  [key: string]: string[];
}

export interface SubmitSignUpErrorsAction {
  type: AuthAT.submitSignUpError;
  errors: ValidateJsError;
}

export interface SubmitSignInErrorsAction {
  type: AuthAT.submitSignInError;
  errors: ValidateJsError;
}

export interface SubmitPasswordOublieErrorsAction {
  type: AuthAT.submitPasswordOublieError;
  errors: ValidateJsError;
}

export interface SubmitSignUpBmceDirectErrorsAction {
  type: AuthAT.submitSignUpBMCEDirectError;
  errors: ValidateJsError;
}

export interface SubmitSignOutAction {
  type: AuthAT.submitSignOut;
}

export interface UpdateFieldSignUpAction {
  type: AuthAT.updateFieldSignUp;
  step: string;
  name: string;
  value: any;
}

export interface UpdateFieldAction {
  type: AuthAT.updateFieldSignUp;
  step: string;
  name: string;
  value: string | boolean;
}

export interface UpdateStepAction {
  type: AuthAT.updateStep;
  step: string;
  value: any;
}

export interface UpdateFieldSignInAction {
  type: AuthAT.updateFieldSignIn;
  step: string;
  name: string;
  value: string | boolean;
}

export const updateFieldSignUpAction = (
  step: string,
  name: string,
  value: any,
): UpdateFieldSignUpAction => ({
  type: AuthAT.updateFieldSignUp,
  step,
  name,
  value,
});

export const updateFieldAuthAction = (
  step: string,
  name: string,
  value: string | boolean,
): UpdateFieldAction => ({
  type: AuthAT.updateFieldSignUp,
  step,
  name,
  value,
});

export const updateFieldSignInAction = (
  step: string,
  name: string,
  value: string | boolean,
): UpdateFieldSignInAction => ({
  type: AuthAT.updateFieldSignIn,
  step,
  name,
  value,
});

export const updateStepAction = (
  step: string,
  value: any,
): UpdateStepAction => ({
  type: AuthAT.updateStep,
  step,
  value,
});

export function signInErrorResponseAction(
  errorData: ValidateJsError,
): SubmitSignInErrorsAction {
  return {
    type: AuthAT.submitSignInError,
    errors: errorData,
  };
}

export interface IsaveUserProfileAction {
  type: AuthAT.saveUserProfile;
  value: IUserProfile;
}

export function saveUserProfileAction(value: IUserProfile) {
  return {
    type: AuthAT.saveUserProfile,
    value,
  };
}
export interface submitGenerateAccessCodeAction {
  type: AuthAT.submitGenerateAccessCode;
}
