import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { selectCompWording } from 'common/wording/selectors.wording';
import { GlobalTheme } from 'features/app/app.theme';
import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSimulationInfoByArraySelector } from '../selector.simulation';
import { updateSectionAction, SimulationAT } from '../actions.simulation';
import CustomTitle from 'common/components/customFields/CustomTitle';
import Passcode from 'assets/svg/Passcode.svg';
import Typography from '@material-ui/core/Typography';
import info from 'assets/svg/info.svg';
import { CustomInput } from 'common/components/customFields/CustomInput';
import { simulationFields } from '../constants.simulation';
import errorUpload from 'assets/svg/errorUpload.svg';
import Link from '@material-ui/core/Link';
import {
  ERROR_OTP_SERVICE_INVALIDE_PASSWORD,
  ERROR_OTP_SERVICE_NBR_TENTATIVE_MAX,
  ERROR_OTP_SERVICE_PASSWORD_TIMEOUT,
} from 'common/utils/validateJs/contants';
import { LANG_STATE } from 'features/home/constants.simulation';
import { scrollToElement } from 'common/utils/app.util';

const useStyles = makeStyles((theme: GlobalTheme) => ({
  simulationForm: {
    width: '950px',
    paddingTop: '15px',
    '@media (max-width: 1440px)': {
      width: '900px',
    },
    '@media (max-width: 1024px)': {
      width: '700px',
    },
    '@media (max-width: 768px)': {
      width: '100%',
    },
    '@media (max-width: 320px)': {
      width: '282px',
    },
  },
  formContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '29px',
  },
  inputOtp: {
    borderRadius: '40px',
    borderWidth: 1,
    borderColor: theme.primary.title,
    width: '55%',
    marginTop: '50px',
  },
  groupFormFooter: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  gridItem: {
    display: 'flex',
    padding: '7px !important',
    alignItems: 'flex-start',
    textAlign: 'left',
    '@media (max-width: 450px)': {
      width: '90%',
    },
  },
  logoOtp: {
    width: '135px',
    marginTop: '41px',
    marginLeft: '69px',
  },
  logoOtpAr: {
    width: '135px',
    marginTop: '41px',
    marginRight: '69px',
  },
  subTitle: {
    fontSize: '1.125rem',
    fontFamily: theme.fonts.regular,
    width: '80%',
    textAlign: 'center',
    marginTop: '60px',
  },
  description: {
    fontSize: '0.9375rem',
    fontWeight: 'bold',
    fontFamily: theme.fonts.medium,
    color: theme.primary.title,
    marginLeft: '9px',
  },
  deschide: {
    fontSize: '0.9375rem',
    fontWeight: 500,
    fontFamily: theme.fonts.medium,
    color: theme.primary.title,
    marginLeft: '9px',
  },
  linkOtp: {
    fontSize: '0.9375rem',
    fontFamily: theme.fonts.medium,
    color: theme.primary.title,
    marginLeft: '3px',
    textDecoration: 'underline',
  },
  message: {
    fontSize: '0.9375rem',
    color: '#556584',
    fontFamily: theme.fonts.regular,
  },
  buttonSubmit: {
    padding: '15px 55px',
    borderRadius: '14px 0',
    '@media (max-width: 450px)': {
      padding: '12px 30px',
    },
  },
  saveButton: {
    borderRadius: '0 14px',
    backgroundColor: theme.primary.title,
    '&:hover': {
      backgroundColor: theme.primary.title,
    },
  },
  error: {
    backgroundColor: '#F932761A',
    width: '100%',
    display: 'flex',
    marginTop: '40px',
  },
  errorMessage: {
    fontSize: '0.9375rem',
    color: '#556584',
    fontFamily: theme.fonts.regular,
  },
  iconErrorUp: {
    width: '43px',
  },
  bold: {
    fontFamily: theme.fonts.bold,
  },
  link: {
    color: theme.secondary.main,
    '& .MuiLink-underlineHover:hover': {
      textDecoration: 'none',
    },
  },
  textError: {
    padding: '5px',
    textAlign: 'left',
    margin: 'auto',
  },
  disableBut: {
    cursor: 'not-allowed',
    pointerEvents: 'none',
    backgroundColor: '#E7E9F1',
    color: theme.secondary.title,
  },
  underlineStyle: {
    fontFamily: theme.fonts.bold,
    textDecoration: 'underline',
  },
  InfoDesc: {
    display: 'flex',
    flexDirection: 'column',
  },
  info: {
    display: 'flex',
    alignItems: 'baseline',
    margin: 'auto',
  },
  columnElement: {
    display: 'flex',
    flexDirection: 'column',
  },
  contentDesc: {
    width: '57%',
    '@media (max-width: 768px)': {
      width: '95%',
    },
  },
  pointer: {
    cursor: 'pointer',
  },
}));

function ConfirmationOTP() {
  const confirmationOTP: any = useRef();
  const scrollToBottom = () => {
    confirmationOTP.current.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start',
    });
  };

  useEffect(() => {
    if (confirmationOTP.current) {
      scrollToBottom();
    }
  }, [confirmationOTP]);

  const classes = useStyles();

  const dispatch = useDispatch();
  const langSelected: any =
    useSelector(getSimulationInfoByArraySelector(['lang'])) || LANG_STATE.FR;
  const wordingSimulation = useSelector(selectCompWording('simulation'));
  const errorsWording = useSelector(selectCompWording('errors'));
  const simulation: any = useSelector(
    getSimulationInfoByArraySelector(['simulation', 'fields']),
  );
  const errors: any = useSelector(
    getSimulationInfoByArraySelector(['simulation', 'errors']),
  );
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^0-9]/gi, '');
    dispatch(updateSectionAction('fields', { [e.target.name]: value }));
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch({ type: SimulationAT.submitOtp });
  };

  const ResendSms = () => {
    // TODO loader here
    dispatch({ type: SimulationAT.resendOtp });
  };
  const [showInfo, setShowInfo] = useState(false);
  const showText = () => {
    setShowInfo(!showInfo);
  };

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter' || e.key === 13) {
      e.preventDefault();
      dispatch({ type: SimulationAT.submitOtp });
    }
  };

  useEffect(() => {
    scrollToElement(undefined, 400);
  }, []);

  return (
    <form
      id={'simulateur'}
      onSubmit={handleSubmit}
      className={classes.simulationForm}
      ref={confirmationOTP}
    >
      <CustomTitle
        title={wordingSimulation?.titleConfirmationOtp}
        colorBorder={'blue'}
        positionBorder={''}
        positionTitle={''}
      />
      {errors.global &&
        errors.global[0] === ERROR_OTP_SERVICE_NBR_TENTATIVE_MAX && (
          <div className={classes.error}>
            <img src={errorUpload} alt='' className={classes.iconErrorUp} />
            <Typography variant='h3' className={classes.textError}>
              <span className={classes.errorMessage}>
                {wordingSimulation?.maxEnterOtpFirstCont}
              </span>{' '}
              <span className={`${classes.errorMessage} ${classes.bold}`}>
                {wordingSimulation?.maxEnterOtpSecondCont}
              </span>{' '}
              <span className={classes.errorMessage}>
                {wordingSimulation?.maxEnterOtpThirdCont}
              </span>{' '}
              <Link
                component='span'
                variant='body2'
                className={`${classes.errorMessage} ${classes.link} `}
              >
                {wordingSimulation?.maxEnterOtpLastCont}
              </Link>
            </Typography>
          </div>
        )}
      {errors.global &&
        errors.global[0] === ERROR_OTP_SERVICE_INVALIDE_PASSWORD && (
          <div className={classes.error}>
            <img src={errorUpload} alt='' className={classes.iconErrorUp} />
            <Typography variant='h3' className={classes.textError}>
              <span className={classes.errorMessage}>
                {wordingSimulation?.errorOtp}
              </span>
            </Typography>
          </div>
        )}
      {errors.global &&
        errors.global[0] === ERROR_OTP_SERVICE_PASSWORD_TIMEOUT && (
          <div className={classes.error}>
            <img src={errorUpload} alt='' className={classes.iconErrorUp} />
            <Typography variant='h3' className={classes.textError}>
              <span className={classes.errorMessage}>
                {wordingSimulation?.errorOtpTimeOut}
              </span>
            </Typography>
          </div>
        )}
      <Grid className={classes.formContent} container spacing={5}>
        <Grid className={classes.formContent} item xs={12} sm={12}>
          <img
            src={Passcode}
            alt=''
            className={
              langSelected === LANG_STATE.FR
                ? classes.logoOtp
                : classes.logoOtpAr
            }
          />
          <Typography variant='h4' className={classes.subTitle}>
            {wordingSimulation?.subTitleOtp}
          </Typography>
          <CustomInput
            disabled={
              errors.global &&
              errors.global[0] === ERROR_OTP_SERVICE_NBR_TENTATIVE_MAX
            }
            hasDefaultBorder={true}
            className={classes.inputOtp}
            inputProps={{
              maxLength: 6,
            }}
            type='number'
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            name={simulationFields.otp}
            value={simulation.otp}
            placeholder={wordingSimulation?.validationCode}
            error={
              errors[simulationFields.otp]
                ? errorsWording[errors[simulationFields.otp]]
                : null
            }
          />
        </Grid>
        <Grid
          className={`${classes.formContent} ${classes.contentDesc}`}
          item
          xs={12}
          sm={12}
        >
          <div className={`${classes.info}`}>
            <img src={info} alt='' />
            <div className={`${classes.InfoDesc}`}>
              <div className={`${classes.gridItem}`}>
                <Typography variant='h3' className={classes.description}>
                  <span className={classes.message}>
                    {wordingSimulation?.descriptionOtpOne}
                  </span>
                  <Link
                    className={`${classes.linkOtp} ${classes.pointer}
                  ${
                    errors.global &&
                    errors.global[0] === ERROR_OTP_SERVICE_NBR_TENTATIVE_MAX
                      ? classes.disableBut
                      : ''
                  }`}
                    onClick={ResendSms}
                  >
                    {wordingSimulation?.descriptionOtpOneLink}
                  </Link>
                </Typography>
              </div>
              <div className={`${classes.columnElement}`}>
                <div className={`${classes.gridItem}`}>
                  <Typography variant='h3' className={classes.description}>
                    <span className={classes.message}>
                      {wordingSimulation?.descOtpInfo}
                    </span>
                    <Link
                      onClick={showText}
                      className={`${classes.linkOtp} ${classes.pointer}`}
                    >
                      {wordingSimulation?.descOtpInfoLink}
                    </Link>
                  </Typography>
                </div>
                {showInfo && (
                  <div className={`${classes.gridItem}`}>
                    <Typography variant='h3' className={classes.deschide}>
                      <span className={classes.message}>
                        {wordingSimulation?.descriptionOtpTwo}
                      </span>{' '}
                      <span className={`${classes.message} ${classes.bold}`}>
                        {wordingSimulation?.descriptionOtpThree}
                      </span>
                      <span className={classes.message}>
                        {wordingSimulation?.descriptionOtpFour}
                      </span>{' '}
                      <span className={`${classes.message}`}>
                        <strong>{wordingSimulation?.descriptionOtpFive}</strong>
                        {wordingSimulation?.descriptionOtpSix}{' '}
                        {wordingSimulation?.descriptionOtpSeven}
                      </span>
                    </Typography>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <div className={classes.groupFormFooter}>
        <Button
          type='submit'
          className={`${classes.buttonSubmit} ${
            errors.global &&
            errors.global[0] === ERROR_OTP_SERVICE_NBR_TENTATIVE_MAX
              ? classes.disableBut
              : ''
          }`}
          variant='contained'
          color='secondary'
        >
          {wordingSimulation?.buttonValide}
        </Button>
      </div>
    </form>
  );
}

export default ConfirmationOTP;
