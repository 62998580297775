import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { ThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import { StylesProvider, jssPreset } from '@material-ui/styles';
import { create } from 'jss';
import rtl from 'jss-rtl';
import PrivateRoute from 'common/components/PrivateRoute';
import PopUpInfo from 'common/components/PopUpInfo';
import {
  selectWordingLoading,
  selectWording,
} from 'common/wording/selectors.wording';
import { ConnectedRouter } from 'connected-react-router/immutable';
import StepContainer from 'features/stepContainer/StepContainer';
import { Wording } from 'common/wording/reducer.wording';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { history } from '../../store';
import appTheme from './app.theme';
import appThemeAr from './app.themeAr';
import Social from './components/Social';
import SignInForm from 'features/auth/components/SignInForm';
import Home from 'features/home/Home';
import PasswordOublie from 'features/auth/components/PasswordOublie';
import Wootric from 'features/auth/Wootric';
import { getSimulationInfoByArraySelector } from 'features/home/selector.simulation';
import { isAuthenticatedAction } from 'features/auth/actions.auth';
import useIsAuthenticated from 'common/hooks/useIsAuthenticate';
import NotFound from 'features/home/components/NotFound';
import ValidationToken from 'features/home/components/ValidationToken';
import { LOAD_WORDING } from 'common/wording/actions.wording';
import { LANG_STATE } from 'features/home/constants.simulation';
import Spinner from 'common/components/Spinner';
import { FR, AR } from 'features/app/app.constants';
import GenerateAccessCode from 'features/auth/components/GenerateAccessCode';
import RachatCredit from 'features/contextual/RachatCredit';
import CreditConso from 'features/contextual/CreditConso';
import Eligibility from 'features/contextual/Eligibility';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const App = () => {
  const dispatch = useDispatch();
  const wordingLoader = useSelector(selectWordingLoading);
  const wording: Wording = useSelector(selectWording);
  const isOnline: any = useSelector(
    getSimulationInfoByArraySelector(['isOnline']),
  );

  const langSelected: any =
    useSelector(getSimulationInfoByArraySelector(['lang'])) || LANG_STATE.FR;

  const theme =
    langSelected === LANG_STATE.AR
      ? createTheme({
          direction: 'rtl',
          ...appThemeAr,
        })
      : createTheme({
          direction: 'ltr',
          ...appTheme,
        });
  const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
  useEffect(() => {
    dispatch({
      type: LOAD_WORDING,
      value: langSelected,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps,
  }, [langSelected]);

  const isAuthenticated = useIsAuthenticated();
  useEffect(() => {
    if (isAuthenticated) {
      dispatch(isAuthenticatedAction(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps,
  }, []);

  return (
    <StylesProvider jss={jss}>
      <ThemeProvider theme={theme}>
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.REACT_APP_RECAPTCHA as string}
          language={'fr'}
          useEnterprise={true}
        >
          <ConnectedRouter history={history}>
            {!isOnline?.check && <PopUpInfo open={!isOnline.check} />}
            {!isEmpty(wording) && <Spinner loader={wordingLoader} />}
            <Switch>
              <Route path='/' exact component={Home} />
              <PrivateRoute _private={false} path={AR} exact component={Home} />
              <PrivateRoute _private={false} path={FR} exact component={Home} />
              <PrivateRoute
                _private={false}
                path='/credit-conso'
                exact
                component={Home}
              />
              <Route
                path='/token-creditconso/:token'
                component={ValidationToken}
              />
              <PrivateRoute
                _private={false}
                path='/sign-up'
                component={StepContainer}
              />
              <PrivateRoute
                _private={true}
                path='/proposition'
                component={StepContainer}
              />
              <PrivateRoute
                _private={true}
                path='/derogation-proposition'
                component={StepContainer}
              />
              <PrivateRoute
                _private={true}
                path='/reject-proposition'
                component={StepContainer}
              />
              <PrivateRoute
                _private={true}
                path='/my-situation'
                component={StepContainer}
              />
              <PrivateRoute
                _private={true}
                path='/recap'
                component={StepContainer}
              />
              <PrivateRoute
                _private={true}
                path='/congra'
                component={StepContainer}
              />
              <PrivateRoute
                _private={true}
                path='/upload-documents'
                component={StepContainer}
              />
              <PrivateRoute
                _private={true}
                path='/case-tracking'
                component={StepContainer}
              />
              <PrivateRoute
                _private={false}
                path='/sign-in'
                component={SignInForm}
              />
              <PrivateRoute
                path='/create-bmce-profile'
                component={StepContainer}
              />
              <PrivateRoute
                _private={false}
                path='/create-profile'
                component={StepContainer}
              />
              <PrivateRoute
                _private={true}
                path='/salarie'
                component={StepContainer}
              />
              <PrivateRoute
                _private={true}
                path='/professionnel'
                component={StepContainer}
              />
              <PrivateRoute
                _private={true}
                path='/retraite'
                component={StepContainer}
              />
              <PrivateRoute
                _private={true}
                path='/fonctionnaire'
                component={StepContainer}
              />
              <PrivateRoute
                _private={false}
                path='/forget-password'
                component={PasswordOublie}
              />
              <PrivateRoute
                _private={true}
                path='/modify-simulation'
                component={StepContainer}
              />
              <PrivateRoute
                _private={true}
                path='/update-information'
                component={StepContainer}
              />
              <PrivateRoute
                _private={false}
                path='/generate-access-code'
                component={GenerateAccessCode}
              />
              <PrivateRoute
                _private={true}
                path='/preattributionSalarie'
                component={StepContainer}
              />
              <PrivateRoute
                _private={true}
                path='/preattributionFonctionnaire'
                component={StepContainer}
              />
              <PrivateRoute
                _private={true}
                path='/preattributionRetraite'
                component={StepContainer}
              />
              <PrivateRoute
                _private={false}
                path='/rachat-de-credit'
                component={RachatCredit}
              />
              <PrivateRoute
                _private={false}
                path='/credit-a-la-consommation'
                component={CreditConso}
              />
              <PrivateRoute
                _private={false}
                path='/profils-eligibiles'
                component={Eligibility}
              />
              <PrivateRoute
                _private={false}
                path='/404'
                exact
                component={NotFound}
              />
              <Route path='*'>
                <Redirect to='/404' />
              </Route>
            </Switch>
            <Social />
            <Wootric langSelected={langSelected} />
          </ConnectedRouter>
        </GoogleReCaptchaProvider>
      </ThemeProvider>
    </StylesProvider>
  );
};

export default App;
