import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { GlobalTheme } from 'features/app/app.theme';
import { ListItem, Typography } from '@material-ui/core';
import contract from 'assets/svg/contract.svg';
import sale from 'assets/svg/sale.svg';
import files from 'assets/svg/files.svg';
import personalInformation from 'assets/svg/personalInformation.svg';
import calculator from 'assets/svg/calculator.svg';
import { selectCompWording } from 'common/wording/selectors.wording';
import { useSelector } from 'react-redux';
import { getSimulationInfoByArraySelector } from 'features/home/selector.simulation';
import { LANG_STATE } from 'features/home/constants.simulation';

const useStyles = makeStyles((theme: GlobalTheme) => ({
  listStyle: {
    fontFamily: theme.fonts.medium,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '24%',
    height: 'auto',
    minHeight: '203px',
    backgroundColor: '#FFF',
    borderRadius: '35px',
    padding: '22px',
    marginBottom: '45px',
    '&:nth-child(5)': {
      height: 'auto',
    },
    '@media (max-width: 1024px)': {
      margin: '10px auto',
      width: '342px',
    },
    '@media (max-width: 768px)': {
      width: '98%',
      margin: '8px auto',
      minHeight: 'auto',
    },
  },
  contentStyle: {
    marginTop: '25px',
    color: theme.primary.title,
    fontFamily: theme.fonts.medium,
    '@media (max-width: 768px)': {
      marginTop: '10px',
      fontSize: '0.8125rem',
    },
  },
  contentStyleAr: {
    marginTop: '25px',
    color: theme.primary.title,
    fontFamily: theme.fonts.medium,
    fontWeight: 600,
    '@media (max-width: 768px)': {
      marginTop: '10px',
      fontSize: '0.8125rem',
    },
  },
  numberStyle: {
    color: theme.primary.main,
    fontFamily: theme.fonts.medium,
    fontSize: '2.5rem',
  },
  img: {
    width: '45px',
    height: '50px',
  },
  contentHeader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  firstSubContentStyle: {
    marginTop: '13px',
    fontFamily: theme.fonts.regular,
    fontSize: '0.8125rem',
  },
  secondSubContentStyle: {
    marginTop: '13px',
    fontFamily: theme.fonts.regular,
    fontSize: '0.6875rem',
  },
  contentBody: {
    textAlign: 'left',
    width: '100%',
  },
  styleEtoile: {
    fontSize: '0.9375rem',
  },
}));

interface ElementDetails {
  number: string;
  content: string;
  firstSubContentOne?: string;
  firstSubContentTwo?: string;
  secondSubContent?: string;
  icon: string;
  className?: any;
  starRequered?: boolean | undefined;
}

const StepElementHomePage = (props: ElementDetails) => {
  const {
    // tslint:disable-next-line:variable-name
    number,
    icon,
    content,
    firstSubContentOne,
    firstSubContentTwo,
    secondSubContent,
    starRequered,
    className,
  } = props;
  const classes = useStyles();
  const wordingAlt = useSelector(selectCompWording('alt'));
  const langSelected: any =
    useSelector(getSimulationInfoByArraySelector(['lang'])) || LANG_STATE.FR;
  return (
    <ListItem className={`${classes.listStyle} ${className}`}>
      <div className={classes.contentHeader}>
        <Typography variant='h5' className={`${classes.numberStyle}`}>
          {number}
        </Typography>
        {icon === 'contract' && (
          <img
            src={contract}
            alt={wordingAlt?.contract_home}
            className={classes.img}
          />
        )}
        {icon === 'sale' && (
          <img
            src={sale}
            alt={wordingAlt?.pourcentage_home}
            className={classes.img}
          />
        )}
        {icon === 'calculator' && (
          <img
            src={calculator}
            alt={wordingAlt?.calculate_home}
            className={classes.img}
          />
        )}
        {icon === 'files' && (
          <img
            src={files}
            alt={wordingAlt?.diroctory_home}
            className={classes.img}
          />
        )}
        {icon === 'personalInformation' && (
          <img
            src={personalInformation}
            alt={wordingAlt?.info_home}
            className={classes.img}
          />
        )}
      </div>
      <div className={classes.contentBody}>
        <Typography
          variant='h5'
          className={`${
            langSelected === LANG_STATE.FR
              ? classes.contentStyle
              : classes.contentStyleAr
          }`}
        >
          {content}
        </Typography>
        <Typography variant='h5' className={`${classes.firstSubContentStyle}`}>
          {firstSubContentOne}
          {starRequered && <b>*</b>}
          {firstSubContentTwo}
        </Typography>

        <Typography variant='h5' className={`${classes.secondSubContentStyle}`}>
          {starRequered && (
            <span className={`${classes.styleEtoile}`}>
              <b>*</b>
            </span>
          )}
          {secondSubContent}
        </Typography>
      </div>
    </ListItem>
  );
};
export default StepElementHomePage;
