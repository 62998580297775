// TODO : This is static liste for PRo
export const listActivityPro = [
  {
    value: 'commercant',
    label: 'Commerçant',
  },
  {
    value: 'liberale',
    label: 'Profession libérale',
  },
  {
    value: 'artisan',
    label: 'Artisan',
  },
  {
    value: 'gérant',
    label: 'Gérant associé',
  },
  {
    value: 'agriculteur',
    label: 'Agriculteur',
  },
];
// TODO : This is static liste for PRo
export const listOfJobNature = [
  {
    value: 'juridique',
    label: 'Profession libérale juridique',
  },
  {
    value: 'financiere',
    label: 'Profession libérale financière',
  },
  {
    value: 'BTP',
    label: 'Profession libérale BTP',
  },
  {
    value: 'culture',
    label: 'Profession libérale culture',
  },
  {
    value: 'service',
    label: 'Profession libérale service',
  },
];
// TODO : This is static liste for PRo
export const listIncoms = [
  {
    value: 'loyer',
    label: 'Loyer',
  },
  {
    value: 'primes',
    label: 'Primes',
  },
  {
    value: 'dividendes',
    label: 'Dividendes',
  },
  {
    value: 'rentes',
    label: 'Rentes',
  },
  {
    value: 'other',
    label: 'Autre',
  },
];
// TODO : This is static liste for PRo
export const seniorityByYear = [
  {
    label: '- 1 Ans',
    value: 'lessThanYear',
  },
  {
    label: '+ 1 Ans',
    value: 'moreThanYear',
  },
];
// TODO : This is static liste for PRo
export const seniorityByMonth = [
  {
    label: '- 6 mois',
    value: 'lessThanSixMonth',
  },
  {
    label: '+ 6 mois',
    value: 'moreThanSixMonth',
  },
];
export enum salarieSituationConfig {
  familialSituation = 'familialSituation',
  activityArea = 'activityArea',
  profession = 'profession',
  nationalities = 'nationalities',
  differe = 'differe',
  cities = 'cities',
  banks = 'banks',
  imputations = 'imputations',
  grades = 'grades',
  recheterCredits = 'recheterCredits',
}
export enum familialSituation {
  marie = '1',
  divorce = '2',
  celibataire = '3',
  veuf = '4',
}
export enum situationProFields {
  monthlyIncome = 'monthlyIncome',
  amountAdditionalIncome = 'amountAdditionalIncome',
  activityPro = 'activityPro',
  jobNature = 'jobNature',
  activitySeniority = 'activitySeniority',
  bankingSeniority = 'bankingSeniority',
  seniorityBankOfAfrica = 'seniorityBankOfAfrica',
  additionalIncome = 'additionalIncome',
  additionalIncomeType = 'additionalIncomeType',
  totalCreditAmount = 'totalCreditAmount',
}
export interface SituationProFields {
  [situationProFields.monthlyIncome]: number;
  [situationProFields.amountAdditionalIncome]: number;
  [situationProFields.activityPro]: string;
  [situationProFields.jobNature]: string;
  [situationProFields.activitySeniority]: string;
  [situationProFields.bankingSeniority]: string;
  [situationProFields.seniorityBankOfAfrica]: string;
  [situationProFields.additionalIncome]: boolean;
  [situationProFields.additionalIncomeType]: string;
  [situationProFields.totalCreditAmount]: string;
}
export enum situationSalarieFields {
  familialSituationList = 'familialSituationList',
  cityName = 'cityName',
  city = 'city',
  cinBmce = 'cinBmce',
  listRibBmce = 'listRibBmce',
  dateLastTransfer = 'dateLastTransfer',
  rib = 'rib',
  familialSituation = 'familialSituation',
  activeSpouse = 'activeSpouse',
  cityOfResidence = 'cityOfResidence',
  nationality = 'nationality',
  activityArea = 'activityArea',
  titulaire = 'titulaire',
  natureContrat = 'natureContrat',
  cddEndDate = 'cddEndDate',
  monthlyIncome = 'monthlyIncome',
  dateCurrentEmployer = 'dateCurrentEmployer',
  isDiffere = 'isDiffere',
  differe = 'differe',
  additionalIncome = 'additionalIncome',
  additionalIncomeNature = 'additionalIncomeNature',
  amountadditionalIncome = 'amountadditionalIncome',
  monthlyTotalCredits = 'monthlyTotalCredits',
  monthlyCreditImmovable = 'monthlyCreditImmovable',
  dateLastVirement = 'dateLastVirement',
  cinOrRib = 'cinOrRib',
  cin = 'cin',
  numberChilds = 'numberChilds',
  numberPersonWhitoutChilds = 'numberPersonWhitoutChilds',
  ribFirstContent = 'ribFirstContent',
  ribSecondContent = 'ribSecondContent',
  ribThirdContent = 'ribThirdContent',
  ribLastContent = 'ribLastContent',
  retirment = 'retirment',
  bankDebit = 'bankDebit',
  monthlyDateDebit = 'monthlyDateDebit',
  personInCharge = 'personInCharge',
  childInCharge = 'childInCharge',
  codeOtherBank = 'codeOtherBank',
  dateCreationOtherBankAccount = 'dateCreationOtherBankAccount',
  mouvementCredit = 'mouvementCredit',
  mouvementDebit = 'mouvementDebit',
  balanceEndOfMonth = 'balanceEndOfMonth',
  fonctionnaireCodeImputation = 'fonctionnaireCodeImputation',
  fonctionnaireMatricule = 'fonctionnaireMatricule',
  fonctionnaireGrade = 'fonctionnaireGrade',
  fonctionnairePrelevementSource = 'fonctionnairePrelevementSource',
  isBMCE = 'isBMCE',
  bmceDirect = 'bmceDirect',
  listRachats = 'listRachats',
  creditRachat = 'creditRachat',
  rachatMntReste = 'rachatMntReste',
  rachatMntEch = 'rachatMntEch',
  redirectViaBmceDirect = 'redirectViaBmceDirect',
}
export interface SituationSalarieFields {
  [situationSalarieFields.retirment]: boolean;
  [situationSalarieFields.bmceDirect]: boolean;
  [situationSalarieFields.familialSituation]: number;
  [situationSalarieFields.activeSpouse]: number;
  [situationSalarieFields.cityName]: string;
  [situationSalarieFields.city]: string;
  [situationSalarieFields.nationality]: string;
  [situationSalarieFields.activityArea]: string;
  [situationSalarieFields.titulaire]: boolean;
  [situationSalarieFields.natureContrat]: string;
  [situationSalarieFields.cddEndDate]: string;
  [situationSalarieFields.monthlyIncome]: number;
  [situationSalarieFields.amountadditionalIncome]: number;
  [situationSalarieFields.monthlyTotalCredits]: number;
  [situationSalarieFields.monthlyCreditImmovable]: number;
  [situationSalarieFields.numberPersonWhitoutChilds]: number;
  [situationSalarieFields.numberChilds]: number;
  [situationSalarieFields.dateCurrentEmployer]: string;
  [situationSalarieFields.dateCreationOtherBankAccount]: string;
  [situationSalarieFields.additionalIncome]: boolean;
  [situationSalarieFields.additionalIncomeNature]: string;
  [situationSalarieFields.dateLastTransfer]: string;
  [situationSalarieFields.cinOrRib]: string;
  [situationSalarieFields.cin]: string;
  [situationSalarieFields.cinBmce]: string;
  [situationSalarieFields.listRibBmce]: any;
  [situationSalarieFields.rib]: string;
  [situationSalarieFields.ribFirstContent]: string;
  [situationSalarieFields.ribSecondContent]: string;
  [situationSalarieFields.ribThirdContent]: string;
  [situationSalarieFields.ribLastContent]: string;
  [situationSalarieFields.isBMCE]: string;
}
export const listDayOfMonth = [
  {
    value: '1',
    label: '1',
  },
  {
    value: '2',
    label: '2',
  },
  {
    value: '3',
    label: '3',
  },
  {
    value: '4',
    label: '4',
  },
  {
    value: '5',
    label: '5',
  },
  {
    value: '6',
    label: '6',
  },
  {
    value: '7',
    label: '7',
  },
  {
    value: '8',
    label: '8',
  },
  {
    value: '9',
    label: '9',
  },
  {
    value: '10',
    label: '10',
  },
  {
    value: '11',
    label: '11',
  },
  {
    value: '12',
    label: '12',
  },
  {
    value: '13',
    label: '13',
  },
  {
    value: '14',
    label: '14',
  },
  {
    value: '15',
    label: '15',
  },
  {
    value: '16',
    label: '16',
  },
  {
    value: '17',
    label: '17',
  },
  {
    value: '18',
    label: '18',
  },
  {
    value: '19',
    label: '19',
  },
  {
    value: '20',
    label: '20',
  },
  {
    value: '21',
    label: '21',
  },
  {
    value: '22',
    label: '22',
  },
  {
    value: '23',
    label: '23',
  },
  {
    value: '24',
    label: '24',
  },
  {
    value: '25',
    label: '25',
  },
  {
    value: '26',
    label: '26',
  },
  {
    value: '27',
    label: '27',
  },
  {
    value: '28',
    label: '28',
  },
  {
    value: '29',
    label: '29',
  },
  {
    value: '30',
    label: '30',
  },
  {
    value: '31',
    label: '31',
  },
];
export enum SCORE_PROPOISITION {
  ACCORD_PLUS = 'ACCORD+',
  ACCORD = 'ACCORD',
  A_DEROGER = 'A DEROGER',
  REJET = 'REJET',
  EN_ATTENTE = 'En attente',
  NULL = '',
}

export enum ProffessionType {
  INDIVIDUAL = 'INDIVIDUAL',
  PRO = 'PRO',
}

export interface professionListInterface {
  id: number;
  label: string;
  labelar: string;
  type: ProffessionType;
  value: string;
}
