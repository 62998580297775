import React from 'react';
import { useSelector } from 'react-redux';
import { createStyles } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import { CustomInput } from './CustomInput';
import { GlobalTheme } from 'features/app/app.theme';
import FormLabel from '@material-ui/core/FormLabel';
import Tooltip from 'common/components/customFields/CustomTooltip';
import { getSimulationInfoByArraySelector } from 'features/home/selector.simulation';
import { LANG_STATE } from 'features/home/constants.simulation';

const usestyles = makeStyles((theme: GlobalTheme) =>
  createStyles({
    fieldStyle: (hasDefaultBorder) => ({
      width: '100%',
      color: theme.secondary.title,
      boxSizing: 'border-box',
      alignItems: 'left',
      padding: '2px 0px',
      '& fieldset': {
        border: 0,
        color: theme.secondary.title,
      },
    }),
    rootTextField: (hasDefaultBorder) => ({
      border: hasDefaultBorder ? `solid 0.063em ${theme.primary.gray}` : 0,
      borderRadius: hasDefaultBorder ? '21px' : 0,
      width: '100%',
      paddingLeft: '0',
      color: theme.secondary.title,
      '& .MuiOutlinedInput-inputAdornedStart': {
        textAlign: 'left',
        paddingLeft: '3px',
        paddingRight: '3px',
      },
      '& .MuiTypography-root': {
        fontSize: '0.875rem',
      },
      '& .MuiInputAdornment-positionStart': {
        '& .MuiTypography-root': {
          fontFamily: theme.fonts.bold,
          color: theme.secondary.title,
          zIndex: '99',
          marginLeft: '15px',
          '&::placeholder': {
            fontSize: '0.625rem',
          },
        },
      },
    }),
    globalTooltip: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    tooltip: {
      padding: '0',
      margin: 'auto',
      marginLeft: '10px',
    },
    formLabel: {
      fontSize: '0.9375rem',
      textAlign: 'left',
      fontFamily: theme.fonts.medium,
      color: theme.secondary.title,
      marginBottom: '7px',
    },
    formLabelTooltip: {
      fontSize: '0.9375rem',
      textAlign: 'left',
      fontFamily: theme.fonts.medium,
      color: theme.secondary.title,
    },
    inputAr: {
      '& div': {
        display: 'flex',
        flexDirection: 'row',
        direction: 'rtl',
      },
      '& input': {
        paddingRight: '15px',
      },
      '& .MuiInputAdornment-positionStart': {
        marginRight: '0px',
      },
    },
    bordergrey: {},
    border: {
      border: 0,
    },
    positionEnd: {
      '& p': {
        padding: '7px',
      },
    },
  }),
);

interface NumberFormatProps {
  suffix?: string;
  endElement?: string;
  name?: string;
  placeholder: string;
  format?: string;
  decimalSeparator?: string;
  disabled?: boolean;
  inputRef?: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
}

export function CustomFormat(props: NumberFormatProps) {
  const {
    inputRef,
    onChange,
    suffix,
    endElement,
    name,
    format,
    decimalSeparator,
    disabled,
    ...other
  } = props;
  return (
    <NumberFormat
      {...other}
      name={name}
      getInputRef={inputRef}
      onChange={onChange}
      thousandSeparator={' '}
      suffix={`${suffix}`}
      inputprops={{ maxLength: 10 }}
      format={format}
      decimalSeparator={decimalSeparator}
      disabled={disabled}
    />
  );
}

interface CurrencyInputCompProps {
  textFieldInputClassName?: string;
  name: string;
  placeholder: string;
  suffix: string;
  endElement?: string;
  startElement?: number | string;
  format?: string;
  decimalSeparator?: string;
  errorMessage?: string;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  value?: any;
  className?: any;
  error?: string | null;
  formLabel?: string;
  tooltip?: string;
  hasDefaultBorder?: boolean;
}

function CurrencyInputComp(props: CurrencyInputCompProps) {
  const {
    textFieldInputClassName,
    suffix,
    endElement,
    startElement,
    className,
    name,
    value,
    errorMessage,
    disabled,
    onChange = () => ({}),
    onBlur = () => ({}),
    placeholder,
    format,
    decimalSeparator,
    hasDefaultBorder = false,
    error = null,
    formLabel = '',
    tooltip = '',
    ...others
  } = props;
  const classes = usestyles(hasDefaultBorder);

  const handleSliderChange = (e: any) => {
    onChange({
      target: {
        value: e.target.value,
        name: e.target.name,
      },
    } as any);
  };

  const handleSliderBlur = (e: any) => {
    onBlur({
      target: {
        value: e.target.value,
        name: e.target.name,
      },
    } as any);
  };

  const langSelected: any =
    useSelector(getSimulationInfoByArraySelector(['lang'])) || LANG_STATE.FR;

  return (
    <div>
      <div className={classes.globalTooltip}>
        {formLabel && (
          <FormLabel
            className={tooltip ? classes.formLabelTooltip : classes.formLabel}
            component='legend'
          >
            {formLabel}
            {tooltip && <Tooltip content={tooltip} />}
          </FormLabel>
        )}
      </div>
      <CustomInput
        type='text'
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={handleSliderChange}
        onBlur={handleSliderBlur}
        error={error}
        disabled={disabled}
        InputProps={{
          name,
          classes: {
            root: error ? classes.border : classes.rootTextField,
            notchedOutline: value ? classes.bordergrey : '',
          },
          inputComponent: CustomFormat as any,
          inputProps: { suffix, name, format, decimalSeparator, disabled },
          endAdornment: (
            <InputAdornment position='end' className={classes.positionEnd}>
              {langSelected === LANG_STATE.FR
                ? endElement
                  ? endElement
                  : ''
                : startElement
                ? startElement
                : ''}
            </InputAdornment>
          ),
          startAdornment: (
            <InputAdornment position='start'>
              {langSelected === LANG_STATE.FR
                ? startElement
                  ? startElement
                  : ''
                : endElement
                ? endElement
                : ''}
            </InputAdornment>
          ),
        }}
        className={`${classes.fieldStyle} ${className} ${
          langSelected === LANG_STATE.AR && !startElement && classes.inputAr
        }
        }`}
        {...others}
      />
    </div>
  );
}

export default CurrencyInputComp;
