import { makeStyles } from '@material-ui/core/styles';
import { GlobalTheme } from 'features/app/app.theme';
import React from 'react';
import { ListItem, Typography } from '@material-ui/core';
import clock from 'assets/svg/clock.svg';
import confort from 'assets/svg/confort.svg';
import security from 'assets/svg/security.svg';
import transparence from 'assets/svg/transparence.svg';
import { selectCompWording } from 'common/wording/selectors.wording';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme: GlobalTheme) => ({
  listStyle: {
    color: theme.secondary.title,
    fontFamily: theme.fonts.medium,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'start',
    '@media (max-width: 1024px)': {
      margin: 'auto',
      width: '40%',
    },
    '@media (max-width: 450px)': {
      width: '100%',
    },
  },
  titleStyle: {
    marginTop: '25px',
    textAlign: 'center',
    fontFamily: theme.fonts.bold,
  },
  subTitleStyle: {
    marginTop: '15px',
    textAlign: 'center',
    fontFamily: theme.fonts.regular,
  },
  img: {
    width: '75px',
    height: '75px',
  },
}));

interface ElementDetails {
  title: string;
  subTitle: string;
  icon: string;
  className: any;
}

function AdvantageElement(props: ElementDetails) {
  const { title, icon, subTitle, className } = props;
  const classes = useStyles();
  const wordingAlt = useSelector(selectCompWording('alt'));

  return (
    <ListItem className={classes.listStyle}>
      {icon === 'clock' && (
        <img src={clock} alt={wordingAlt?.speed_home} className={classes.img} />
      )}
      {icon === 'confort' && (
        <img
          src={confort}
          alt={wordingAlt?.comfort_home}
          className={classes.img}
        />
      )}
      {icon === 'security' && (
        <img
          src={security}
          alt={wordingAlt?.security_home}
          className={classes.img}
        />
      )}
      {icon === 'transparence' && (
        <img
          src={transparence}
          alt={wordingAlt?.transparency_home}
          className={classes.img}
        />
      )}
      <Typography variant='h5' className={`${classes.titleStyle} ${className}`}>
        {title}
      </Typography>
      <Typography variant='h5' className={`${classes.subTitleStyle}`}>
        {subTitle}
      </Typography>
    </ListItem>
  );
}
export default AdvantageElement;
