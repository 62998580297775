import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Email from 'assets/images/Email.png';
import { Wording } from 'common/wording/reducer.wording';
import { getSimulationInfoByArraySelector } from 'features/home/selector.simulation';
import { LANG_STATE } from 'features/home/constants.simulation';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: 'auto',
    textAlign: 'center',
    margin: 'auto',
  },
  title: {
    textTransform: 'uppercase',
    margin: 10,
  },
  titleAr: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    margin: 10,
  },
  description: {
    textAlign: 'center',
  },
  iconeMessage: {
    height: '200px',
    textAlign: 'center',
    display: 'flex',
  },
  iconeMessagePng: {
    margin: 'auto',
    width: '80px',
  },
  waitMessage: {
    textAlign: 'center',
  },
}));

interface Props {
  wording: Wording;
}

function ConfirmationMail({ wording }: Props) {
  const classes = useStyles();
  const langSelected: any =
    useSelector(getSimulationInfoByArraySelector(['lang'])) || LANG_STATE.FR;
  return (
    <div className={classes.root}>
      <Typography
        variant='h3'
        className={
          langSelected === LANG_STATE.FR ? classes.title : classes.titleAr
        }
      >
        {wording?.confirmationMail?.title}
      </Typography>

      <Typography variant='h4' className={classes.description}>
        {wording?.confirmationMail?.description}
      </Typography>
      <div className={classes.iconeMessage}>
        <img
          className={classes.iconeMessagePng}
          src={`${Email}`}
          alt={wording?.confirmationMail?.altImage}
        />
      </div>

      <Typography variant='subtitle2' className={classes.waitMessage}>
        {wording?.confirmationMail?.waitMessage}
      </Typography>
    </div>
  );
}

export default ConfirmationMail;
