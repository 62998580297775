import { RachatsItem } from './reducer.situation';
import { apiGet, apiPost } from 'common/utils/request.util';
import {
  changeDateFormat,
  getTotalRibFormat,
} from 'common/utils/situation.util';
import { situationPreattSalarieConstraints } from 'common/utils/validateJs/constraints';
import {
  costumValidate,
  validateRib,
} from 'common/utils/validateJs/validateJs';
import { push } from 'connected-react-router';
import {
  put,
  select,
  takeEvery,
  takeLatest,
  call,
  delay,
} from 'redux-saga/effects';
import {
  GetConfigAction,
  getConfigSuccessAction,
  SituationAT,
  SaveOrConfirmeSituationAction,
  updateFieldAction,
  SubmitPreattSituationSalarieAction,
} from './actions.situation';
import { salarieSituationConfig } from './constants.situation';
import { getSituationInfoByArraySelector } from './selector.situation';
import { PropositionAT } from 'features/proposition/actions.proposition';
import { updateFieldUpAction } from '../upload/actions.upload';
import { extractError } from 'common/utils/auth.util';
import {
  setSessionStorage,
  getSessionStorage,
} from 'common/utils/sessionStorage.util';
import { isEmpty, has } from 'lodash';
import { AccountTypes, professionTypes } from 'features/auth/constants';
import { getValidationInfoByArraySelector } from 'features/validationBmceDirect/selector.validation';
import { E3 } from 'common/utils/validateJs/contants';
import { SimulationAT } from 'features/home/actions.simulation';
import { getRoute } from 'common/utils/app.util';
import { AuthAT } from 'features/auth/actions.auth';
import { getAuthInfoByArraySelector } from 'features/auth/selector.auth';
import {
  situationPreattFonctionnaireConstraints,
  situationPreattRetraiteConstraints,
  signUpConstraints,
} from './../../common/utils/validateJs/constraints';

import { submitSignUp } from 'features/auth/sagas.auth';
import { getSimulationInfoByArraySelector } from 'features/home/selector.simulation';
import { updateFieldByArrayAction } from './../home/actions.simulation';
import { LANG_STATE } from 'features/home/constants.simulation';
export function* submitPreattSituationSalarie(
  action: SubmitPreattSituationSalarieAction,
) {
  // mock
  let situationSalarieData = yield select(
    getSituationInfoByArraySelector(['situationSalarie', 'fields']),
  );
  const preAttributionBmceDirect = yield select(
    getValidationInfoByArraySelector(['preAttribution']),
  );
  const modifySimulationPreatt = yield select(
    getSimulationInfoByArraySelector(['simulation', 'modifySimulationPreAtt']),
  );
  try {
    // TODO : if the action is dispatched from modify-simulation do not exzcute signUp verification or check submitSignUp
    yield put({ type: SituationAT.updateLoader, value: true });
    // const userData = yield select(getAuthInfoByArraySelector(['signUp']));
    if (isEmpty(situationSalarieData.email)) {
      yield call(submitSignUp, {
        type: AuthAT.submitSignUp,
      });
      yield delay(5000);
      const errorsSignUp = yield select(
        getAuthInfoByArraySelector(['signUp', 'errors']),
      );
      if (!isEmpty(errorsSignUp)) {
        yield put({ type: SituationAT.updateLoader, value: false });
        return;
      }
    } else if (!modifySimulationPreatt) {
      const userData = yield select(getAuthInfoByArraySelector(['signUp']));
      const errorsUpdate = costumValidate(userData, signUpConstraints());
      if (errorsUpdate) {
        yield put({ type: AuthAT.submitSignUpError, errors: errorsUpdate });
        yield put({ type: SituationAT.updateLoader, value: false });
        yield put(
          updateFieldByArrayAction(['simulation', 'response', 'loader'], false),
        );

        return;
      }
      const simulationId = getSessionStorage('simulationId');
      userData.redirectViaBmceDirect = true;
      yield apiPost('/auth/update-user', { ...userData, simulationId });
      yield delay(2000);
    }
    const userProfile = yield getSessionStorage('userProfile');
    if (!isEmpty(userProfile?.product)) {
      yield put(
        updateFieldAction('situationSalarie', 'product', userProfile.product),
      );
    }
    if (!isEmpty(userProfile?.clientBOA)) {
      yield put(
        updateFieldAction(
          'situationSalarie',
          'clientBOA',
          userProfile.clientBOA,
        ),
      );
    }
    const { lastName, firstName, email, civility } = yield select(
      getAuthInfoByArraySelector(['signUp']),
    );
    yield put(updateFieldAction('situationSalarie', 'nom', lastName));
    yield put(updateFieldAction('situationSalarie', 'email', email));
    yield put(updateFieldAction('situationSalarie', 'prenom', firstName));
    yield put(updateFieldAction('situationSalarie', 'sex', civility));
    situationSalarieData = yield select(
      getSituationInfoByArraySelector(['situationSalarie', 'fields']),
    );

    // Is Redirect From BMCE DIRECT
    const fromBMCEDirect = yield select(
      getValidationInfoByArraySelector(['data']),
    );
    situationSalarieData = yield select(
      getSituationInfoByArraySelector(['situationSalarie', 'fields']),
    );

    if (isEmpty(fromBMCEDirect)) {
      situationSalarieData.redirectViaBmceDirect = false;
    } else {
      situationSalarieData.redirectViaBmceDirect = true;
    }

    /* validate form */
    let errors;

    if (situationSalarieData.type === AccountTypes.retraite) {
      errors = costumValidate(
        situationSalarieData,
        situationPreattRetraiteConstraints(
          // same constraints as preatt salarie ( optimization  )
          situationSalarieData.product,
        ),
      );
    } else if (situationSalarieData.type === AccountTypes.salarie) {
      errors = costumValidate(
        situationSalarieData,
        situationPreattSalarieConstraints(situationSalarieData.product),
      );
    } else if (situationSalarieData.type === AccountTypes.fonctionnaire) {
      errors = costumValidate(
        situationSalarieData,
        situationPreattFonctionnaireConstraints(situationSalarieData.product),
      );
    }

    if (
      situationSalarieData.cinOrRib === 'rib' &&
      situationSalarieData.isBMCE !== 'bmce'
    ) {
      errors = validateRib(
        situationSalarieData.ribSecondContent.trim(),
        situationSalarieData.ribThirdContent.trim(),
        situationSalarieData.ribLastContent.trim(),
      );
    } else {
      has(errors, 'ribSecondContent') && delete errors.ribSecondContent;
      has(errors, 'ribThirdContent') && delete errors.ribThirdContent;
      has(errors, 'ribLastContent') && delete errors.ribLastContent;
    }

    if (!isEmpty(errors)) {
      yield put({ type: SituationAT.submitSituationSalarieError, errors });
      yield put({ type: SituationAT.updateLoader, value: false });
      yield put(
        updateFieldByArrayAction(['simulation', 'response', 'loader'], false),
      );
      return;
    }

    setSessionStorage('situationSalarie', situationSalarieData);
    if (situationSalarieData.cinOrRib === 'rib') {
      situationSalarieData.cin = '';
    }
    if (situationSalarieData.cddEndDate !== '') {
      situationSalarieData.cddEndDate = changeDateFormat(
        'dd/MM/yyyy',
        situationSalarieData.cddEndDate,
      );
    }
    if (situationSalarieData.dateCurrentEmployer !== '') {
      situationSalarieData.dateCurrentEmployer = changeDateFormat(
        'MM/yyyy',
        situationSalarieData.dateCurrentEmployer,
      );
    }
    if (situationSalarieData.dateLastTransfer !== '') {
      situationSalarieData.dateLastTransfer = changeDateFormat(
        'dd/MM/yyyy',
        situationSalarieData.dateLastTransfer,
      );
    }
    if (situationSalarieData.dateLastVirement !== '') {
      situationSalarieData.dateLastVirement = changeDateFormat(
        'dd/MM/yyyy',
        situationSalarieData.dateLastVirement,
      );
    }
    if (situationSalarieData.cinOrRib === 'rib') {
      situationSalarieData.rib = getTotalRibFormat(
        situationSalarieData.ribSecondContent,
        situationSalarieData.ribThirdContent,
        situationSalarieData.ribLastContent,
      );
    }
    if (situationSalarieData.dateCreationOtherBankAccount !== '') {
      situationSalarieData.dateCreationOtherBankAccount = changeDateFormat(
        'MM/yyyy',
        situationSalarieData.dateCreationOtherBankAccount,
      );
    }
    /* get grade value */
    if (situationSalarieData.fonctionnaireGrade) {
      situationSalarieData.fonctionnaireGrade =
        situationSalarieData.fonctionnaireGrade.value;
    }
    /* get imputation value */
    if (situationSalarieData.fonctionnaireCodeImputation) {
      situationSalarieData.fonctionnaireCodeImputation =
        situationSalarieData.fonctionnaireCodeImputation.value;
    }

    /* get city value */
    if (situationSalarieData.city) {
      situationSalarieData.city = situationSalarieData.city.value;
    }
    // if (situationSalarieData.companyName !== COMPANY_NAME.other) {
    //   situationSalarieData.segClient = SegClientTypes.C;
    // } else {
    //   situationSalarieData.segClient = SegClientTypes.NC;
    // }

    setSessionStorage('type', situationSalarieData.type);
    setSessionStorage('product', situationSalarieData.product);
    setSessionStorage('clientBOA', situationSalarieData.clientBOA);

    yield put(updateFieldUpAction('upload', 'type', situationSalarieData.type));
    yield put(
      updateFieldUpAction('upload', 'product', situationSalarieData.product),
    );
    if (situationSalarieData.type === AccountTypes.retraite) {
      situationSalarieData.retirment = true;
      situationSalarieData.profession = professionTypes.retraite;
    }
    yield put({ type: SituationAT.submitSituationSalarieError, errors: {} });

    if (!isEmpty(situationSalarieData.listRachats)) {
      situationSalarieData.listRachats = situationSalarieData.listRachats
        .filter((item: any) => item.checked === true)
        .map((item: any) => ({
          V_idSimulation: item.idSimulation,
          V_NumDoss: item.numDossRachat,
        }));
    }

    situationSalarieData.preattribue = 'O';
    if (!situationSalarieData.additionalIncome) {
      situationSalarieData.amountadditionalIncome = '0';
      situationSalarieData.additionalIncomeNature = null;
    }
    const { data } = yield apiPost(
      '/instruction/simulate',
      situationSalarieData,
    );
    if (data.anomalieCode === E3) {
      yield put({
        type: SimulationAT.submitSimulationError,
        errors: {
          global: [data.anomalieCode],
        },
      });
      yield put(
        updateFieldByArrayAction(['simulation', 'response', 'loader'], false),
      );
      return;
    }
    setSessionStorage('proposition', data);
    data.isBMCE = situationSalarieData.isBMCE;
    yield put({ type: PropositionAT.updateProposition, data });

    // Get URL
    const _url = getRoute(
      situationSalarieData.type,
      situationSalarieData.product,
      situationSalarieData.clientBOA,
      '3',
      data.appreciation,
      preAttributionBmceDirect,
    );
    yield put(push(_url));
    yield put({ type: SituationAT.updateLoader, value: false });
    yield put(
      updateFieldByArrayAction(['simulation', 'modifySimulationPreAtt'], false),
    );
    yield put(updateFieldByArrayAction(['simulation', 'isEditInfo'], false));
    yield put(
      updateFieldByArrayAction(['simulation', 'response', 'loader'], false),
    );
  } catch (error) {
    yield put(
      updateFieldByArrayAction(['simulation', 'response', 'loader'], false),
    );
    yield put({ type: SituationAT.updateLoader, value: false });
    yield put(
      updateFieldAction('situationSalarie', 'checkValidateClient', true),
    );
    if (error.response && error.response.status === 400) {
      yield put({
        type: SituationAT.submitSituationError,
        errors: extractError(error),
      });
    }
  }
}

export function* saveOrConfirmeSituation(
  action: SaveOrConfirmeSituationAction,
) {
  const situationSalarieData = yield select(
    getSituationInfoByArraySelector(['situationSalarie', 'fields']),
  );

  try {
    yield put({ type: SituationAT.updateLoader, value: true });

    const situationSalarieDataLocal = yield select(
      getSituationInfoByArraySelector(['situationSalarie', 'fields']),
    );

    // Is Redirect From BMCE DIRECT
    const fromBMCEDirect = yield select(
      getValidationInfoByArraySelector(['data']),
    );

    if (isEmpty(fromBMCEDirect)) {
      situationSalarieData.redirectViaBmceDirect = false;
    } else {
      situationSalarieData.redirectViaBmceDirect = true;
    }

    setSessionStorage('situationSalarie', situationSalarieDataLocal);

    if (situationSalarieData.ribSecondContent != null) {
      situationSalarieData.cinOrRib = 'rib';
      situationSalarieData.cin = '';
    }
    if (situationSalarieData.cddEndDate !== '') {
      situationSalarieData.cddEndDate = changeDateFormat(
        'dd/MM/yyyy',
        situationSalarieData.cddEndDate,
      );
    }
    if (situationSalarieData.dateCurrentEmployer !== '') {
      situationSalarieData.dateCurrentEmployer = changeDateFormat(
        'MM/yyyy',
        situationSalarieData.dateCurrentEmployer,
      );
    }
    if (situationSalarieData.dateLastTransfer !== '') {
      situationSalarieData.dateLastTransfer = changeDateFormat(
        'dd/MM/yyyy',
        situationSalarieData.dateLastTransfer,
      );
    }
    if (situationSalarieData.dateLastVirement !== '') {
      situationSalarieData.dateLastVirement = changeDateFormat(
        'dd/MM/yyyy',
        situationSalarieData.dateLastVirement,
      );
    }
    if (situationSalarieData.ribSecondContent) {
      situationSalarieData.rib = getTotalRibFormat(
        situationSalarieData.ribSecondContent,
        situationSalarieData.ribThirdContent,
        situationSalarieData.ribLastContent,
      );
    }
    if (situationSalarieData.dateCreationOtherBankAccount !== '') {
      situationSalarieData.dateCreationOtherBankAccount = changeDateFormat(
        'MM/yyyy',
        situationSalarieData.dateCreationOtherBankAccount,
      );
    }

    /* get grade value */
    if (situationSalarieData.fonctionnaireGrade) {
      situationSalarieData.fonctionnaireGrade =
        situationSalarieData.fonctionnaireGrade.value;
    }
    /* get imputation value */
    if (situationSalarieData.fonctionnaireCodeImputation) {
      situationSalarieData.fonctionnaireCodeImputation =
        situationSalarieData.fonctionnaireCodeImputation.value;
    }

    /* get city value */
    if (situationSalarieData.city) {
      situationSalarieData.city = situationSalarieData.city.value;
    }

    // if (situationSalarieData.companyName !== COMPANY_NAME.other) {
    //   situationSalarieData.segClient = SegClientTypes.C;
    // } else {
    //   situationSalarieData.segClient = SegClientTypes.NC;
    // }

    setSessionStorage('type', situationSalarieData.type);
    setSessionStorage('product', situationSalarieData.product);
    setSessionStorage('clientBOA', situationSalarieData.clientBOA);

    yield put(updateFieldUpAction('upload', 'type', situationSalarieData.type));
    yield put(
      updateFieldUpAction('upload', 'product', situationSalarieData.product),
    );

    yield put({ type: SituationAT.submitSituationSalarieError, errors: {} });

    if (!isEmpty(situationSalarieData.listRachats)) {
      situationSalarieData.listRachats = situationSalarieData.listRachats
        .filter((item: any) => item.checked === true)
        .map((item: any) => ({
          V_idSimulation: item.idSimulation,
          V_NumDoss: item.numDossRachat,
        }));
    }

    action.name === 'save'
      ? yield apiPost('/instruction/save', situationSalarieData)
      : yield apiPost('/instruction/confirme', situationSalarieData);
    yield put({ type: SituationAT.updateLoader, value: false });
  } catch (error) {
    yield put({ type: SituationAT.updateLoader, value: false });
    if (error.response && error.response.status === 400) {
      yield put({
        type: SituationAT.submitSituationError,
        errors: extractError(error),
      });
    }
  }
}

export function* getConfig(action: GetConfigAction) {
  let lang = yield select(getSimulationInfoByArraySelector(['lang']));
  lang = lang === LANG_STATE.FR ? LANG_STATE.FR : LANG_STATE.AR_LABEL;
  try {
    let response = null;
    switch (action.nature) {
      case salarieSituationConfig.familialSituation:
        response = yield apiGet('/form-config/get-familial-situation');
        break;
      case salarieSituationConfig.activityArea:
        response = yield apiGet('/form-config/get-activity-area');
        break;
      case salarieSituationConfig.profession:
        response = yield apiGet('/form-config/get-profession?lang=' + lang);
        break;
      case salarieSituationConfig.nationalities:
        response = yield apiGet('/form-config/get-nationality');
        break;
      case salarieSituationConfig.cities:
        response = yield apiGet('/form-config/get-cities');
        break;
      case salarieSituationConfig.banks:
        response = yield apiGet('/form-config/get-banks');
        break;
      case salarieSituationConfig.imputations:
        response = yield apiGet('/form-config/get-imputations');
        break;
      case salarieSituationConfig.grades:
        response = yield apiGet('/form-config/get-grades');
        break;
    }
    return yield put(getConfigSuccessAction(action.nature, response.data));
  } catch (e) {
    console.error(e);
  }
}

export function* loanRepurchase() {
  try {
    const { data }: any = yield apiGet('/instruction/loan-repurchase-file', {
      params: { idSimulation: getSessionStorage('simulationId') },
    });
    if (data && !data[0]?.V_Anomalie) {
      const listRachats: RachatsItem[] = data.map(
        (item: RachatsItem): RachatsItem => ({ ...item, checked: true }),
      );

      yield put(
        updateFieldAction('situationSalarie', 'listRachats', listRachats),
      );
    } else {
      yield put(updateFieldAction('situationSalarie', 'listRachats', []));
    }
  } catch (error) {
    console.error(error);
  }
}

export function* getConfigSaga() {
  yield takeEvery(SituationAT.getConfig, getConfig);
}

export function* submitPreattSituationSalarieSaga() {
  yield takeLatest(
    SituationAT.submitPreattSituationSalarie,
    submitPreattSituationSalarie,
  );
}

export function* saveOrConfirmeSituationSaga() {
  yield takeLatest(
    SituationAT.saveOrConfirmeSituation,
    saveOrConfirmeSituation,
  );
}

export function* leonRepurchaseSaga() {
  yield takeLatest(SituationAT.loanRepurchase, loanRepurchase);
}

export const preattSituationSagas = [
  submitPreattSituationSalarieSaga,
  getConfigSaga,
  saveOrConfirmeSituationSaga,
  leonRepurchaseSaga,
];
