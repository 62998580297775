import { toJS } from 'common/utils/app.util';
import { createSelector } from 'reselect';
import { AppStore } from '../../core/root.model';
import { HOME_REDUCER } from './reducer.simulation';

const getSimulation = (state: AppStore) => state.get(HOME_REDUCER);

export const getSimulationErrorsSelector = createSelector(
  getSimulation,
  (simulation) => toJS(simulation.getIn(['simulation', 'errors'])),
);

export const getSimulationInfoByArraySelector = (selector: string[]) =>
  createSelector(getSimulation, (simulation) =>
    toJS(simulation.getIn(selector)),
  );
