import { fromJS } from 'immutable';
import { StoreOf } from '../../core/root.model';
import {
  SubmitPropositionAction,
  UpdatePropositionAction,
  UpdateFieldPropositionAction,
  InitPropositionATAction,
} from './actions.proposition';
import { PropositionAT } from 'features/proposition/actions.proposition';
import { getSessionStorage } from 'common/utils/sessionStorage.util';

const proposition = getSessionStorage('proposition');

export const initState: PropositionState = fromJS({
  proposition: {
    fields: proposition
      ? proposition
      : {
          creditAmount: '80',
          creditDuration: '80',
          monthlyPayment: '80',
          teg: '80',
          amountFeesAssurance: '80',
          amountFeesDemande: '80',
          debloquedAmount: '80',
        },
  },
});

export const resetState: PropositionState = fromJS({
  proposition: {
    fields: {
      creditAmount: '80',
      creditDuration: '80',
      monthlyPayment: '80',
      teg: '80',
      amountFeesAssurance: '80',
      amountFeesDemande: '80',
      debloquedAmount: '80',
    },
  },
});

export const PROPOSITION_REDUCER = 'PROPOSITION_REDUCER';

export interface PropositionStateModel {
  proposition: {};
}

export type PropositionState = StoreOf<PropositionStateModel>;

export type PropositionActions =
  | SubmitPropositionAction
  | UpdatePropositionAction
  | UpdateFieldPropositionAction
  | InitPropositionATAction;

export function PropositionReducer(
  state = initState,
  action: PropositionActions,
): PropositionState {
  switch (action.type) {
    case PropositionAT.updateProposition:
      return state.setIn(['proposition', 'fields'], fromJS(action.data));
    case PropositionAT.updateFieldProposition:
      return state.setIn(
        ['proposition', 'fields', action.name],
        fromJS(action.value),
      );
    case PropositionAT.initPropositionAT:
      return resetState;
    default:
      return state;
  }
}
