import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { selectCompWording } from 'common/wording/selectors.wording';
import { GlobalTheme } from 'features/app/app.theme';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import CreateProfileElement from './CreateProfileElement';
import { useHistory } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import Seo from 'common/components/seo/Seo';
import { getSimulationInfoByArraySelector } from 'features/home/selector.simulation';
import { LANG_STATE } from 'features/home/constants.simulation';
import { pushEvent, pushPageView } from 'features/auth/constants';

const useStyles = makeStyles((theme: GlobalTheme) => ({
  root: {
    display: 'flex',
    height: 'auto',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '80px 70px',
    margin: 'auto',
    '@media (max-width: 600px)': {
      padding: '5px',
    },
  },
  parag: {
    width: '75%',
    padding: '10px',
    '@media (max-width: 768px)': {
      width: '100%',
    },
  },
  paragTitle: {
    textAlign: 'center',
    color: theme.primary.title,
    textTransform: 'uppercase',
    fontSize: '1.75rem',
  },
  paragTitleAr: {
    textAlign: 'center',
    fontWeight: 'bold',
    color: theme.primary.title,
    textTransform: 'uppercase',
    fontSize: '1.75rem',
  },
  paragSubTitle: {
    textAlign: 'center',
    marginTop: '5px',
    maxWidth: 580,
  },
  firstElementColor: {
    color: theme.primary.title,
  },
  secondElementColor: {
    color: theme.secondary.main,
  },
  createProfileViaBmceDirect: {
    padding: '0',
    textAlign: 'left',
    marginBottom: '35px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  blocContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '27px',
    maxWidth: 610,
  },
}));

function CreateProfile() {
  const classes = useStyles();
  const langSelected: any =
    useSelector(getSimulationInfoByArraySelector(['lang'])) || LANG_STATE.FR;
  const wording = useSelector(selectCompWording('createProfile'));
  const history = useHistory();
  const createProfileByBmceDirect = () => {
    let eventObj = {
      event: 'click_cta',
      click_label: 'creer_mon_profil_via_mes_acces_bmce_direct',
      element_category: 'body',
    };
    pushEvent(eventObj);
    history.push('/create-bmce-profile');
  };
  const haveNotAccesBmce = () => {
    let eventObj = {
      event: 'click_cta',
      click_label: 'je_ne_dispose_pas_acces_bmce_direct',
      element_category: 'body',
    };
    pushEvent(eventObj);
    history.push('/sign-up');
  };

  useEffect(() => {
    pushPageView('step_0_create_profile');
  }, []);
  return (
    <div className={classes.root}>
      <Seo titleKey='title_5' keywordsKey='keywords_5' />
      <div className={classes.parag}>
        <Typography
          variant='h4'
          className={
            langSelected === LANG_STATE.FR
              ? classes.paragTitle
              : classes.paragTitleAr
          }
        >
          {wording?.title}
        </Typography>
        <Typography variant='h5' className={classes.paragSubTitle}>
          {wording?.subTitle}
        </Typography>
      </div>
      <div className={classes.blocContainer}>
        <Button
          className={classes.createProfileViaBmceDirect}
          type='button'
          onClick={createProfileByBmceDirect}
        >
          <CreateProfileElement
            title={wording?.createSpaceByBmceDirect}
            subTitle={''}
            className={classes.firstElementColor}
          />
        </Button>

        <Button
          className={classes.createProfileViaBmceDirect}
          type='button'
          onClick={haveNotAccesBmce}
        >
          <CreateProfileElement
            title={wording?.titleUser}
            subTitle={''}
            className={classes.secondElementColor}
          />
        </Button>
      </div>
    </div>
  );
}

export default CreateProfile;
