import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import information from 'assets/images/information.png';
import setting from 'assets/images/settings.png';
import edit from 'assets/svg/edit.svg';
import { SimulationDetailLine } from 'common/components/SimulationDetailLine';
import { Wording } from 'common/wording/reducer.wording';
import { selectCompWording } from 'common/wording/selectors.wording';
import { GlobalTheme } from 'features/app/app.theme';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getLocation } from 'core/root.selector';
import SalarieConv from './components/salarie/SalarieConv';
import SalarieNotConv from './components/salarie/SalarieNotConv';
import SalarieProspect from './components/salarie/SalarieProspect';
import FonctionnaireConv from './components/fonctionnaire/FonctionnaireConv';
import FonctionnaireNotConv from './components/fonctionnaire/FonctionnaireNotConv';
import FonctionnaireProspect from './components/fonctionnaire/FonctionnaireProspect';
import RetraiteConv from './components/retraite/RetraiteConv';
import RetraiteNotConv from './components/retraite/RetraiteNotConv';
import RetraiteProspect from './components/retraite/RetraiteProspect';
import { getRecapInfoByArraySelector } from './selector.recap';
import { useHistory } from 'react-router-dom';
import Seo from 'common/components/seo/Seo';
import { pushPageView } from 'features/auth/constants';
import { getValidationInfoByArraySelector } from 'features/validationBmceDirect/selector.validation';
import { SimulationAT } from 'features/home/actions.simulation';
import ProfessionnelProspectRecap from './components/professionnel/ProfessionnelProspect';
import ProfessionnelClientRecap from './components/professionnel/ProfessionnelClient';
import { RecapAT } from './actions.recap';

const useStyles = makeStyles((theme: GlobalTheme) => ({
  root: {
    display: 'flex',
    msOverflowStyle: 'none',
    flexDirection: 'column',
    alignItems: 'center',
  },
  titleWrapper: {
    width: '88%',
    padding: '10px',
    marginBottom: '20px',
  },
  title: {
    fontFamily: theme.fonts.bold,
    textTransform: 'uppercase',
    textAlign: 'left',
    '@media (max-width: 450px)': {
      fontSize: '1.3125rem',
    },
  },
  subTitle: {
    textAlign: 'left',
    width: '75%',
  },
  recapDetail: {
    width: '100%',
    borderRadius: '22px',
    backgroundColor: '#FFF',
    direction: 'ltr',
  },
  recapDetailHeader: {
    padding: '30px 30px 0 30px',
    display: 'flex',
    alignItems: 'flex-end',
    '@media (max-width: 450px)': {
      flexWrap: 'wrap',
      alignItems: 'center',
    },
  },
  headerTitle: {
    flex: 1,
    paddingLeft: 10,
    fontSize: '1rem',
    fontFamily: theme.fonts.bold,
    color: theme.primary.title,
    '@media (max-width: 450px)': {
      paddingLeft: 0,
      fontSize: '0.875rem',
    },
  },
  rightTitleIcon: {
    marginRight: '10px',
  },
  rightIcon: {
    marginRight: '10px',
  },
  buttonEdit: {
    backgroundColor: theme.primary.gray,
    color: theme.secondary.title,
    boxShadow: 'none',
    padding: '10px 16px',
    '&:hover': {
      backgroundColor: theme.primary.gray,
    },
    '@media (max-width: 450px)': {
      width: '167px',
      margin: '20px auto',
    },
  },
  recapDetailBody: {
    maxWidth: '80%',
    margin: 'auto',
    padding: '10px 0 10px 0',
  },
  gridContainer: {
    width: '95%',
    margin: 'auto',
    padding: '30px 0 30px 0',
  },
  textFieldCin: {
    width: '100%',
  },
  labelCin: {
    fontSize: '0.875rem',
    textAlign: 'left',
    fontFamily: theme.fonts.medium,
    color: theme.secondary.title,
    opacity: 1,
    marginBottom: '17px',
  },
  rootTextField: {
    color: theme.secondary.title,
    border: '0.063em solid',
    borderRadius: '20px',
    width: '100%',
  },
  buttonSubmitWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonSubmit: {
    borderRadius: '14px 0',
    fontSize: '0.6875rem',
    width: '200px',
  },
  subscriptionButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '30px',
    '@media (max-width: 600px)': {
      position: 'fixed',
      bottom: '2%',
      width: '94%',
      left: '3%',
      justifyContent: 'center',
    },
  },
  subscriptionButton: {
    minWidth: '200px',
    color: '#fff',
    backgroundColor: '#F93276',
    textTransform: 'capitalize',
    border: '0.063em solid #F93276',
    borderRadius: '20px 0',
    padding: '11px 53px',
    fontSize: '0.875rem',
    '&:hover': {
      backgroundColor: '#F93276',
    },

    '@media (max-width: 600px)': {
      flex: 1,
    },
  },
}));

const Recap = () => {
  const wording: Wording = useSelector(selectCompWording('recap'));
  const classes = useStyles();
  const dispatch = useDispatch();
  const { push } = useHistory();
  const location = useSelector(getLocation);

  const preAttributionBmceDirect: any = useSelector(
    getValidationInfoByArraySelector(['preAttribution']),
  );

  const propositionResponse: any = useSelector(
    getRecapInfoByArraySelector(['recap', 'fields']),
  );

  const isRachat = propositionResponse.rachat === 'O';

  /* push to upload documents */
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    dispatch({ type: RecapAT.submitRecap });
  };

  /* push to update information */
  const handleInformationChange = (): void => {
    if (preAttributionBmceDirect) {
      dispatch({ type: SimulationAT.modifyRecap });
    } else {
      push('/update-information');
    }
  };

  /* push to modify simulation */
  const modifySimulation = (): void => {
    push('/modify-simulation');
  };

  useEffect(() => {
    pushPageView('step_4_recapitulatif_de_la_demande');
  }, []);

  return (
    <form onSubmit={handleSubmit} className={classes.root}>
      <Seo titleKey='title_23' keywordsKey='keywords_23' />
      <div className={classes.titleWrapper}>
        <Typography variant='h2' className={classes.title}>
          {wording?.title}
        </Typography>
      </div>
      <div className={classes.recapDetail}>
        <div className={classes.recapDetailHeader}>
          <img src={information} alt='' className={classes.rightTitleIcon} />
          <Typography variant='h4' className={classes.headerTitle}>
            {wording?.titleInformation}
          </Typography>
          <Button
            variant='contained'
            color='primary'
            className={classes.buttonEdit}
            onClick={handleInformationChange}
          >
            <img src={edit} alt='' className={classes.rightIcon} />
            {wording?.buttonModify}
          </Button>
        </div>

        {/* ### Salarie ## */}
        {location === '/recap/salarie/conv' && <SalarieConv />}
        {location === '/recap/salarie/noConv' && <SalarieNotConv />}
        {location === '/recap/salarie/prospect' && <SalarieProspect />}

        {/* ### Fonctionnaire ## */}
        {location === '/recap/fonctionnaire/conv' && <FonctionnaireConv />}
        {location === '/recap/fonctionnaire/noConv' && <FonctionnaireNotConv />}
        {location === '/recap/fonctionnaire/prospect' && (
          <FonctionnaireProspect />
        )}

        {/* ### Retraité ## */}
        {location === '/recap/retraite/conv' && <RetraiteConv />}
        {location === '/recap/retraite/noConv' && <RetraiteNotConv />}
        {location === '/recap/retraite/prospect' && <RetraiteProspect />}

        {/* ### Professionnel ## */}
        {location === '/recap/professionnel/client' && (
          <ProfessionnelClientRecap />
        )}
        {location === '/recap/professionnel/prospect' && (
          <ProfessionnelProspectRecap />
        )}

        <div className={classes.recapDetailHeader}>
          <img src={setting} alt='' className={classes.rightTitleIcon} />
          <Typography variant='h4' className={classes.headerTitle}>
            {wording?.titleCredit}
          </Typography>
          <Button
            variant='contained'
            color='primary'
            className={classes.buttonEdit}
            onClick={modifySimulation}
          >
            <img src={edit} alt='' className={classes.rightIcon} />
            {wording?.buttonModify}
          </Button>
        </div>

        <div className={classes.recapDetailBody}>
          <SimulationDetailLine
            label={wording?.creditAmount}
            value={propositionResponse.creditAmount}
            unit={wording?.dhs}
          />
          {isRachat && (
            <>
              <SimulationDetailLine
                label={wording?.outstandingAmount}
                value={propositionResponse.rachatMntReste}
                unit={wording?.dhs}
              />
              <SimulationDetailLine
                label={wording?.amountToBeBlocked}
                value={propositionResponse.debloquedAmount}
                unit={wording?.dhs}
              />
            </>
          )}
          <SimulationDetailLine
            label={wording?.creditDuration}
            value={propositionResponse.creditDuration}
            unit={wording?.month}
          />
          <SimulationDetailLine
            label={wording?.echeanceCredit}
            value={propositionResponse.monthlyPayment}
            unit={wording?.dhs}
          />
        </div>
        <div className={classes.subscriptionButtonContainer}>
          <Button
            variant='contained'
            color='primary'
            className={classes.subscriptionButton}
            type='submit'
          >
            {wording?.submit}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default Recap;
