import React, { useEffect } from 'react';
import FormLabel from '@material-ui/core/FormLabel';
import Slider from '@material-ui/core/Slider';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import InputNumber from 'common/components/customFields/InputNumber';
import { GlobalTheme } from 'features/app/app.theme';
import { CLOSEST } from 'features/home/constants.simulation';
import { Box, Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: GlobalTheme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      '@media (max-width: 600px)': {
        flexDirection: 'column',
      },
    },
    label: {
      fontSize: '0.8rem',
      fontFamily: theme.fonts.regular,
      color: theme.secondary.title,
      textAlign: 'left',
      marginTop: '8px',
      '@media (max-width: 600px)': {
        fontSize: '0.875rem',
        margin: '10px 0',
      },
    },
    slider: (blue) => ({
      '@media (max-width: 600px)': {
        width: '100%',
        marginLeft: '0px',
        marginRight: '0px',
      },
    }),
    sliderBloc: (blue) => ({
      display: 'flex',
      flexDirection: 'column',
      width: 'calc(100% - 200px)',
      float: 'left',
      '& .MuiSlider-root': blue
        ? {
            color: `${theme.primary.title} !important`,
          }
        : undefined,
      '@media (max-width: 600px)': {
        width: '100%',
      },
    }),
    divInput: {
      textAlign: 'right',
      '& div[class^="makeStyles-root"]': {
        marginBottom: '30px',
      },
      '& .MuiInputAdornment-positionStart': {
        '@media (min-width: 600px) and (max-width: 768px)': {
          marginRight: '0',
          width: '5px',
        },
      },
      '& fieldset': {
        borderRadius: '15px',
        background: 'rgba(85, 101, 132, 0.07)',
        zIndex: 0,
        border: '1px solid #dedede !important',
      },
      '& .MuiOutlinedInput-root': {
        height: '35px!important',
      },
      '& .MuiOutlinedInput-inputAdornedStart': {
        height: '35px!important',
        zIndex: 1,
        background: 'transparent',
        padding: 0,
        '@media (min-width: 600px) and (max-width: 768px)': {
          textAlign: 'center',
        },
      },
      '& .MuiInputAdornment-positionEnd': {
        height: '35px!important',
        zIndex: 1,
      },

      '@media (max-width: 600px)': {
        width: '100%',
        margin: '0',
        '& div[class^="makeStyles-root"]': {
          margin: '0',
        },
      },
    },
    input: {
      maxWidth: '165px',
      '@media (max-width: 600px)': {
        margin: '15px 0',
        maxWidth: '100%',
      },
    },
    minMax: {
      fontSize: '0.75rem',
      color: 'gray',
    },
  }),
);

interface PropsCustomSlider {
  label: string;
  value?: number;
  name: string;
  endElement?: string;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string | null;
  min: number;
  max: number;
  step: number;
  blue?: boolean;
  disabled?: boolean;
  numFloat?: number;
  ncep?: boolean;
  type?: string;
}

export const CustomSlider = ({
  value = 0,
  handleChange = () => ({}),
  error = null,
  label = '',
  min = 0,
  max = 20000,
  step = 10,
  name = '',
  endElement = '',
  blue = false,
  disabled = false,
  numFloat = 2,
  ncep = false,
}: PropsCustomSlider) => {
  const classes = useStyles(blue);
  const marks = [
    {
      value: min,
      label: '',
    },
    {
      value: max,
      label: '',
    },
  ];

  const validateNewValue = (
    newStringValue: string,
    replaced: boolean,
  ): number => {
    let newValue: number = parseFloat(
      newStringValue.toString().replace(/\s/g, ''),
    );
    if (replaced) {
      if (newValue < min) {
        newValue = min;
      } else if (newValue > max) {
        newValue = max;
      }
    }
    return newValue;
  };

  const handleSliderChange = (
    e: any,
    sliderValue: number,
    sliderName: string,
  ) => {
    handleChange({
      target: {
        value: validateNewValue(sliderValue.toString(), false),
        name: sliderName,
      },
    } as any);
  };

  const customHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const result = validateNewValue(event.target.value, false);
    handleChange({
      target: {
        value: result,
        name: event.target.name,
      },
    } as any);
  };

  const customHandleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const result = validateNewValue(event.target.value, true);
    const closest = CLOSEST.reduce((a, b) => {
      return Math.abs(b - result) <= Math.abs(a - result) ? b : a;
    });
    handleChange({
      target: {
        value: ncep ? closest : result,
        name: event.target.name,
      },
    } as any);
  };

  const _value: any = isNaN(value)
    ? value.toFixed(numFloat)
    : parseFloat(value as any).toFixed(numFloat);

  useEffect(() => {
    let e: any = {
      target: {
        value: value,
        name: name,
      },
    };
    customHandleBlur(e);
    // eslint-disable-next-line
  }, [min, max, ncep]);

  return (
    <div className={classes.root}>
      <Grid item xs={12} sm={4} md={4}>
        <FormLabel className={classes.label} component='legend'>
          {label}
        </FormLabel>
      </Grid>
      <Grid item xs={12} sm={8} md={8}>
        <div className={classes.sliderBloc}>
          <Slider
            disabled={disabled}
            className={classes.slider}
            min={min}
            max={max}
            step={step}
            value={value}
            onChange={(e: any, newValue: any) => {
              handleSliderChange(e, newValue, name);
            }}
            aria-labelledby='continuous-slider'
            marks={marks}
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography className={classes.minMax}>
              {min.toLocaleString('fr-FR')}
            </Typography>
            <Typography className={classes.minMax}>
              {max.toLocaleString('fr-FR')}
            </Typography>
          </Box>
        </div>
        <div className={classes.divInput}>
          <InputNumber
            disabled={disabled}
            onChange={customHandleChange}
            onBlur={customHandleBlur}
            name={name}
            value={_value}
            placeholder=''
            suffix={''}
            endElement={endElement}
            error={error}
            className={classes.input}
          />
        </div>
      </Grid>
    </div>
  );
};
