export const PUBLIC_TOKEN = 'AutorisationPublic'; // should be the same in backend

export function checkIfJwtExist(): string | undefined {
  return getCookie(PUBLIC_TOKEN);
}

function getCookie(name: string): string | undefined {
  if (!document) {
    console.error(
      'document not defined, return undefined as cookie:',
      document,
    );
    return undefined;
  }
  const b = document.cookie.match(`(^|[^;]+)\\s*${name}\\s*=\\s*([^;]+)`);
  return b ? b.pop() : undefined;
}

export function extractError(error: any) {
  const { errors }: any = error.response.data;
  let globalErrors: any = null;
  if (Array.isArray(errors)) {
    errors.forEach((element) => {
      globalErrors = {
        ...globalErrors,
        [element.field]: element.errors,
      };
    });
  }

  return globalErrors;
}

export function getSizeGlobMB(files: any) {
  let sizeGlob = 0;
  // tslint:disable-next-line: prefer-for-of
  for (let index = 0; index < files.length; index++) {
    const element = files[index];
    sizeGlob += element.size;
  }
  return sizeGlob / 1024 / 1024;
}
