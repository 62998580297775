import Box from '@material-ui/core/Box';
import React from 'react';
import HightLightedBox from './HightLightedBox';
import salary from 'assets/svg/profile-salary.svg';
import fonctionnaire from 'assets/svg/profile-fonctionnaire.svg';
import professionnel from 'assets/svg/profile-professionnel.svg';
import retraite from 'assets/svg/profile-retraite.svg';
import BlocTitle from './BlocTitle';
import { Wording } from 'common/wording/reducer.wording';
import { useSelector } from 'react-redux';
import { selectCompWording } from 'common/wording/selectors.wording';

const Profiles = () => {
  const wording: Wording = useSelector(selectCompWording('contextual'));

  return (
    <Box>
      <BlocTitle title={wording?.title_1_profil_eligible} />
      <HightLightedBox
        icon={salary}
        description={wording?.description_1_profil_eligible}
      />
      <BlocTitle title={wording?.title_2_profil_eligible} />
      <HightLightedBox
        icon={fonctionnaire}
        description={wording?.description_2_profil_eligible}
      />
      <BlocTitle title={wording?.title_3_profil_eligible} />
      <HightLightedBox
        icon={professionnel}
        description={wording?.description_3_profil_eligible}
      />
      <BlocTitle title={wording?.title_4_profil_eligible} />
      <HightLightedBox
        icon={retraite}
        description={wording?.description_4_profil_eligible}
      />
    </Box>
  );
};

export default Profiles;
