import { makeStyles } from '@material-ui/core/styles';
import { Wording } from 'common/wording/reducer.wording';
import { selectWording } from 'common/wording/selectors.wording';
import { GlobalTheme } from 'features/app/app.theme';
import React from 'react';
import { useSelector } from 'react-redux';
import Pro from './components/Pro';

const useStyles = makeStyles((theme: GlobalTheme) => ({
  root: {
    display: 'flex',
    margin: 'auto',
    flexDirection: 'column',
    alignItems: 'center',
  },
  parag: {
    width: '88%',
    padding: '10px',
    marginBottom: '20px',
  },
  paragTitle: {
    textAlign: 'left',
    fontFamily: theme.fonts.medium,
    color: theme.primary.title,
    textTransform: 'uppercase',
    opacity: 1,
    letterSpacing: '0px',
    fontWeight: 500,
    fontSize: '1.75rem',
    margin: 'auto',
  },
  paragSubTitle: {
    color: theme.secondary.title,
    fontFamily: theme.fonts.regular,
    letterSpacing: '0px',
    fontSize: '0.875rem',
    width: '75%',
    textAlign: 'left',
  },
}));

function Situation() {
  const classes = useStyles();
  const wording: Wording = useSelector(selectWording);
  return (
    <div className={classes.root}>
      <div className={classes.parag}>
        <h2 className={classes.paragTitle}>{wording?.situation?.title}</h2>
        <p className={classes.paragSubTitle}>{wording?.situation?.subTitle}</p>
      </div>
      <Pro wording={wording} />
    </div>
  );
}

export default Situation;
