import { apiGet } from 'common/utils/request.util';
import { delay, select } from 'redux-saga/effects';
import { AuthAT, isAuthenticatedAction } from '../auth/actions.auth';
import { isAuthenticatedSelector } from '../../features/auth/selector.auth';

import { put, takeLatest } from 'redux-saga/effects';
export function* refreshToken(): any {

  yield delay(600000);
  const isAuthenticated = yield select(isAuthenticatedSelector);
  if(isAuthenticated){
    yield apiGet('/auth/refresh-token');
    yield put(isAuthenticatedAction(true));    
  }
    
}

export function* refreshTokenSaga() {
    yield takeLatest(AuthAT.isAuthenticated, refreshToken);
}

export const appSagas = [refreshTokenSaga];
