import { toJS } from 'common/utils/app.util';
import { createSelector } from 'reselect';
import { AppStore } from '../../core/root.model';
import { VALIDATION_REDUCER } from './reducer.validation';
import { ValidationBmceDirectAction } from './actions.validation';

const getRedirect = (state: AppStore) => state.get(VALIDATION_REDUCER);

export const getValidationInfoByArraySelector = (selector: string[]) =>
  createSelector(getRedirect, (validation) =>
    toJS<ValidationBmceDirectAction>(validation.getIn(selector)),
  );