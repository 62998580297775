export enum SimulationAT {
  initSimulationAT = 'SIMULATIONAT/INITSIMULATIONAT',
  setLang = 'SIMULATIONAT/SETLANG',
  updateField = 'SIMULATIONAT/UPDATEFIELD',
  updateSection = 'SIMULATIONAT/UPDATESECTION',
  refaireSimulation = 'SIMULATIONAT/REFAIRESIMULATION',
  submitSimulation = 'SIMULATIONAT/SUBMITSIMULATION',
  submitSimulationError = 'SIMULATIONAT/SUBMITSIMULATIONERROR',
  getConvention = 'SIMULATIONAT/GETCONVENTION',
  updateConvention = 'SIMULATIONAT/UPDATECONVENTION',
  getSimulation = 'SIMULATIONAT/GETSIMULATION',
  getSimulationSuccess = 'SIMULATIONAT/GETSIMULATIONSUCCESS',
  updateState = 'SIMULATIONAT/UPDATESTATE',
  submitOtp = 'SIMULATIONAT/SUBMITOTP',
  getSimulationResponse = 'SIMULATIONAT/GETSIMULATIONRESPONSE',
  submitSimulationCreaEspace = 'SIMULATIONAT/SUBMITSIMULATIONCREAESPACE',
  simulateWithLoader = 'SIMULATIONAT/SIMULATEWITHLOADER',
  resendOtp = 'SIMULATIONAT/RESENDOTP',
  modifySimulation = 'SIMULATIONAT/MODIFYSIMULATION',
  submitToProfil = 'SIMULATIONAT/SUBMITTOPROFIL',
  isOnline = 'HOMEAT/ISONLINE',
  preAttributionRoute = 'SIMULATIONAT/PREATTRIBUTIONROUTE',
  modifyRecap = 'SIMULATIONAT/MODIFYRECAP',
}
export interface InitSimulationATAction {
  type: SimulationAT.initSimulationAT;
}

export interface SetLangAction {
  type: SimulationAT.setLang;
  value: any;
}

export interface UpdateFieldByArrayAction {
  type: SimulationAT.updateField;
  array: string[];
  value: any;
}

export interface UpdateSectionAction {
  type: SimulationAT.updateSection;
  section: string;
  value: { [key: string]: string };
}

export interface RefaireSimulationAction {
  type: SimulationAT.refaireSimulation;
  paramToInit: any;
}
export interface SubmitSimulationAction {
  type: SimulationAT.submitSimulation;
  paramToInit: any;
}

export interface SubmitOtpAction {
  type: SimulationAT.submitOtp;
}

export interface ResendOtpAction {
  type: SimulationAT.resendOtp;
}

export interface UpdateConventionAction {
  type: SimulationAT.updateConvention;
  data: any;
}

export interface UpdateSimulationAction {
  type: SimulationAT.getSimulationSuccess;
  data: any;
}

export interface UpdateSimulationResponseAction {
  type: SimulationAT.getSimulationResponse;
  simulationResponse: any;
}

export interface SubmitSimulationCreaEspaceAction {
  type: SimulationAT.submitSimulationCreaEspace;
  simulationResponse: any;
}

export interface UpdateSimulationLoaderAction {
  type: SimulationAT.updateState;
  value: boolean;
}

export interface IsOnlineAction {
  type: SimulationAT.isOnline;
  value: boolean;
}

export const isOnlineAction = (value: boolean): IsOnlineAction => ({
  type: SimulationAT.isOnline,
  value,
});

// export const updateFieldAction = (
//   step: string,
//   name: string,
//   value: string | boolean | number | Date,
// ): UpdateFieldAction => ({
//   type: SimulationAT.updateField,
//   step,
//   name,
//   value,
// });

export const setLangAction = (value: any): SetLangAction => ({
  type: SimulationAT.setLang,
  value,
});

export const updateFieldByArrayAction = (
  array: string[],
  value: any,
): UpdateFieldByArrayAction => ({
  type: SimulationAT.updateField,
  array,
  value,
});

export const updateConventionAction = (data: any): UpdateConventionAction => ({
  type: SimulationAT.updateConvention,
  data,
});

export const updateSectionAction = (
  section: string,
  value: { [key: string]: string },
): UpdateSectionAction => ({
  type: SimulationAT.updateSection,
  section,
  value,
});

export interface ValidateJsError {
  [key: string]: string[];
}

export interface SubmitSimulationErrorsAction {
  type: SimulationAT.submitSimulationError;
  errors: ValidateJsError;
}

export interface ModifySimulationAction {
  type: SimulationAT.modifySimulation;
}

export interface SubmitToProfilAction {
  type: SimulationAT.submitToProfil;
}
