import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { CustomAutoComplete } from 'common/components/customFields/CustomAutoComplete';
import { CustomDatePicker } from 'common/components/customFields/CustomDatePicker';
import { CustomInput } from 'common/components/customFields/CustomInput';
import CustomizedSwitches from 'common/components/customFields/CustomizedSwitches';
import { CustomRadioGroup } from 'common/components/customFields/CustomRadioGroup';
import InputNumber from 'common/components/customFields/InputNumber';
import SelectDown from 'common/components/customFields/SelectDown';
import Seo from 'common/components/seo/Seo';
import { CheckAuthorization } from 'common/components/PrivateRoute';
import useDebounce from 'common/hooks/useDebounce';
import { selectCompWording } from 'common/wording/selectors.wording';
import { GlobalTheme } from 'features/app/app.theme';
import { AccountTypes, ProductTypes } from 'features/auth/constants';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getConfigAction, updateFieldAction } from '../../actions.situation';
import {
  salarieSituationConfig,
  situationSalarieFields,
  familialSituation,
} from '../../constants.situation';
import { getSituationInfoByArraySelector } from '../../selector.situation';
import { getSessionStorage } from 'common/utils/sessionStorage.util';
import Tooltip from 'common/components/customFields/CustomTooltip';
import { getSimulationInfoByArraySelector } from 'features/home/selector.simulation';
import { LANG_STATE } from 'features/home/constants.simulation';

const useStyles = makeStyles((theme: GlobalTheme) => ({
  labelRenseing: {
    fontSize: '0.9375rem',
    textAlign: 'left',
    fontFamily: theme.fonts.medium,
    color: theme.secondary.title,
    marginBottom: '17px',
  },
  labelRenseingTooltip: {
    fontSize: '0.9375rem',
    textAlign: 'left',
    fontFamily: theme.fonts.medium,
    color: theme.secondary.title,
  },
  groupFormBody: {
    padding: '30px',
  },
  textFieldRib: {
    width: '110px',
    marginRight: '20px',
    '& .MuiOutlinedInput-marginDense': {
      direction: 'ltr',
      '& .MuiInputAdornment-positionEnd': {
        marginRight: '5px',
        marginLeft: '5px',
      },
    },
    '& .MuiInputBase-input': {
      direction: 'initial',
      textAlign: 'center',
      fontFamily: theme.fonts.bold,
      fontSize: '0.875rem',
    },
    '@media (max-width: 414px)': {
      width: '45%',
      float: 'left',
    },
  },
  textFieldRibAr: {
    width: '110px',
    marginRight: '20px',
    '& .MuiOutlinedInput-marginDense': {
      direction: 'ltr',
      padding: '0px',
      '& .MuiInputAdornment-positionEnd': {
        marginRight: '5px',
        marginLeft: '5px',
        '& p': {
          fontWeight: 'bold',
        },
      },
    },
    '& .MuiInputBase-input': {
      direction: 'initial',
      textAlign: 'center',
      fontFamily: theme.fonts.bold,
      fontSize: '0.875rem',
    },
    '@media (max-width: 414px)': {
      width: '45%',
      float: 'left',
    },
  },
  thirdTextFieldRib: {
    width: '240px',
    '@media (max-width: 414px)': {
      width: '100%',
    },
  },
  lastTextFieldRib: {
    width: '90px',
    '@media (max-width: 414px)': {
      width: '40%',
    },
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center',
  },
  rootTextField: {
    color: theme.secondary.title,
    border: '0.063em solid',
    borderRadius: '20px',
  },
  globalTooltip: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  radioGroup: {
    justifyContent: 'start',
  },
  elementsRib: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    '@media (max-width: 768px)': {
      flexDirection: 'column',
    },
  },
  elementsRibAr: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'flex-start',
    '@media (max-width: 768px)': {
      flexDirection: 'column',
    },
  },
  autoComplBorder: {
    width: '100%',
    border: 'solid 1px',
    borderRadius: '40px',
    borderColor: theme.secondary.title,
    '& .MuiInputBase-root': {
      padding: '0px 15px',
    },
  },
  noBorder: {
    border: 0,
    '& .MuiInputBase-root': {
      padding: '0px 15px',
    },
  },
  error: {
    fontFamily: theme.fonts.regular,
    color: theme.secondary.main,
    fontSize: '0.75rem',
    fontWeight: 700,
  },
  whithoutMsgError: {
    '& .MuiFormHelperText-root.Mui-error': {
      display: 'none',
    },
  },
  displayDiv: {
    '@media (max-width: 540px)': {
      display: 'none',
    },
  },
  errorBorder: {
    '& .MuiInputBase-root': {
      borderColor: theme.secondary.main,
    },
  },
}));

interface Props {
  langSelected: string;
}

const FonctionnaireNotConv = (props: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const langSelected: any =
    useSelector(getSimulationInfoByArraySelector(['lang'])) || LANG_STATE.FR;
  const wordingSalarie = useSelector(selectCompWording('detailedForm'));
  const cinOrRibList = [
    {
      label: wordingSalarie?.cin,
      value: 'cin',
    },
    {
      label: wordingSalarie?.rib,
      value: 'rib',
    },
  ];
  const natureContratList = [
    {
      label: wordingSalarie?.cdi,
      value: 'cdi',
    },
    {
      label: wordingSalarie?.cdd,
      value: 'cdd',
    },
  ];
  const additionalIncomeNatureList = [
    {
      label: wordingSalarie?.foncierLoyer,
      value: '1',
    },
    {
      label: wordingSalarie?.placementsFinanciers,
      value: '2',
    },
    {
      label: wordingSalarie?.rente,
      value: '3',
    },
    {
      label: wordingSalarie?.pension,
      value: '4',
    },
    {
      label: wordingSalarie?.autresNature,
      value: '5',
    },
  ];

  const errorsWording = useSelector(selectCompWording('errors'));
  const situationSalarie: any = useSelector(
    getSituationInfoByArraySelector(['situationSalarie', 'fields']),
  );
  let situatSalarie: any = null;
  situatSalarie = getSessionStorage('situationSalarie');
  const errors: any = useSelector(
    getSituationInfoByArraySelector(['situationSalarie', 'errors']),
  );
  const [queryCityName] = React.useState<string | undefined>(undefined);
  const debouncedQueryCityName = useDebounce(500, queryCityName);
  const config: any = useSelector(
    getSituationInfoByArraySelector(['situationSalarie', 'config']),
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (situationSalarie.familialSituation !== familialSituation.marie) {
      dispatch(updateFieldAction('situationSalarie', 'activeSpouse', false));
    }
    dispatch(
      updateFieldAction(
        'situationSalarie',
        event.target.name,
        event.target.value,
      ),
    );
  };

  const handleChangeCity = (
    event: React.ChangeEvent<{}>,
    city: { value: string; label: string },
  ) => {
    dispatch(updateFieldAction('situationSalarie', 'city', city));
  };
  const handleChangeImputation = (
    event: React.ChangeEvent<{}>,
    imputation: { value: string; label: string },
  ) => {
    dispatch(
      updateFieldAction(
        'situationSalarie',
        'fonctionnaireCodeImputation',
        imputation,
      ),
    );
  };

  /*const handleChangeCityName = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setQueryCityName(event.target.value);
  };*/

  useEffect(() => {
    if (
      debouncedQueryCityName &&
      (debouncedQueryCityName as string).length > 2
    ) {
      dispatch(getConfigAction(salarieSituationConfig.cities, queryCityName));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, debouncedQueryCityName]);

  useEffect(() => {
    dispatch(getConfigAction(salarieSituationConfig.familialSituation));
    dispatch(getConfigAction(salarieSituationConfig.activityArea));
    dispatch(getConfigAction(salarieSituationConfig.nationalities));
    dispatch(getConfigAction(salarieSituationConfig.cities));
    dispatch(getConfigAction(salarieSituationConfig.imputations));
    dispatch(
      updateFieldAction('situationSalarie', 'type', AccountTypes.fonctionnaire),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CheckAuthorization
      roles={{
        typeClients: [AccountTypes.fonctionnaire],
        products: [ProductTypes.ppr],
      }}
    >
      <div
        className={classes.groupFormBody}
        style={
          props.langSelected === LANG_STATE.FR
            ? { direction: 'ltr' }
            : { direction: 'rtl' }
        }
      >
        <Seo titleKey='title_21' keywordsKey='keywords_21' />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <SelectDown
              formLabel={wordingSalarie?.familialSituation}
              list={config[salarieSituationConfig.familialSituation]}
              label={wordingSalarie?.familialSituationPlaceHolder}
              name={situationSalarieFields.familialSituation}
              value={situationSalarie.familialSituation}
              onChange={handleChange}
              error={
                errors[situationSalarieFields.familialSituation]
                  ? errorsWording[
                      errors[situationSalarieFields.familialSituation]
                    ]
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {situationSalarie.familialSituation === familialSituation.marie && (
              <CustomizedSwitches
                formLabel={wordingSalarie?.activeSpouse}
                tooltip={wordingSalarie?.infoActiveSpouse}
                labelPrefix={wordingSalarie?.no}
                labelSufix={wordingSalarie?.yes}
                checked={situationSalarie.activeSpouse}
                onChange={handleChange}
                name={situationSalarieFields.activeSpouse}
              />
            )}
          </Grid>

          <Grid item xs={12} sm={6}>
            <CustomAutoComplete
              onChangeValue={handleChangeCity}
              options={config[salarieSituationConfig.cities]}
              name={situationSalarieFields.city}
              value={situationSalarie.city}
              formLabel={wordingSalarie?.city}
              placeholder={wordingSalarie?.cityOfResidencePlaceHolder}
              loadingText={'chargement'}
              error={
                errors[situationSalarieFields.city]
                  ? errorsWording[errors[situationSalarieFields.city]]
                  : null
              }
              hasDefaultBorder={false}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectDown
              formLabel={wordingSalarie?.nationality}
              list={config[salarieSituationConfig.nationalities]}
              label={wordingSalarie?.nationalityPlaceHolder}
              name={situationSalarieFields.nationality}
              value={situationSalarie.nationality}
              onChange={handleChange}
              error={
                errors[situationSalarieFields.nationality]
                  ? errorsWording[errors[situationSalarieFields.nationality]]
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputNumber
              formLabel={wordingSalarie?.numberPersonWhitoutChilds}
              onChange={handleChange}
              name={situationSalarieFields.numberPersonWhitoutChilds}
              value={situationSalarie.numberPersonWhitoutChilds}
              placeholder={wordingSalarie?.numberPersonWhitoutChildsPlaceHolder}
              suffix={''}
              endElement={''}
              hasDefaultBorder={true}
              error={
                errors[situationSalarieFields.numberPersonWhitoutChilds]
                  ? errorsWording[
                      errors[situationSalarieFields.numberPersonWhitoutChilds]
                    ]
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputNumber
              formLabel={wordingSalarie?.numberChilds}
              onChange={handleChange}
              name={situationSalarieFields.numberChilds}
              value={situationSalarie.numberChilds}
              placeholder={wordingSalarie?.numberChildsPlaceHolder}
              suffix={''}
              endElement={''}
              hasDefaultBorder={true}
              error={
                errors[situationSalarieFields.numberChilds]
                  ? errorsWording[errors[situationSalarieFields.numberChilds]]
                  : null
              }
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <CustomizedSwitches
              formLabel={wordingSalarie?.titulaire}
              tooltip={wordingSalarie?.infoTitulaire}
              labelPrefix={wordingSalarie?.no}
              labelSufix={wordingSalarie?.yes}
              checked={situationSalarie.titulaire}
              onChange={handleChange}
              name={situationSalarieFields.titulaire}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <div className={classes.globalTooltip}>
              <FormLabel
                className={classes.labelRenseingTooltip}
                component='legend'
              >
                {wordingSalarie?.natureContrat}
                <Tooltip content={wordingSalarie?.infoNatureContrat} />
              </FormLabel>
            </div>
            <Grid item xs={12} sm={8} className={classes.gridItem}>
              <CustomRadioGroup
                className={classes.radioGroup}
                name={situationSalarieFields.natureContrat}
                onChange={handleChange}
                value={situationSalarie.natureContrat}
                listOfElements={natureContratList}
                error=''
              />
            </Grid>
          </Grid>

          {situationSalarie.natureContrat === 'cdd' && (
            <Grid item xs={12} sm={6}>
              <CustomDatePicker
                isCDD={true}
                formLabel={wordingSalarie?.cddEndDate}
                name={situationSalarieFields.cddEndDate}
                value={situationSalarie.cddEndDate}
                onChange={handleChange}
                format='dd/MM/yyyy'
                placeholder={wordingSalarie?.cddEndDatePlaceholder}
                hasDefaultBorder={true}
                error={
                  errors[situationSalarieFields.cddEndDate]
                    ? errorsWording[errors[situationSalarieFields.cddEndDate]]
                    : null
                }
              />
            </Grid>
          )}

          <Grid item xs={12} sm={6}>
            <CustomDatePicker
              isMinMax={true}
              formLabel={wordingSalarie?.dateCurrentEmployer}
              name={situationSalarieFields.dateCurrentEmployer}
              value={situationSalarie.dateCurrentEmployer}
              onChange={handleChange}
              format='MM/yyyy'
              views={['year', 'month']}
              placeholder={wordingSalarie?.dateCurrentEmployerPlaceholder}
              hasDefaultBorder={true}
              error={
                errors[situationSalarieFields.dateCurrentEmployer]
                  ? errorsWording[
                      errors[situationSalarieFields.dateCurrentEmployer]
                    ]
                  : null
              }
            />
          </Grid>
          {situationSalarie.natureContrat === 'cdi' && (
            <Grid item xs={12} sm={6} className={classes.displayDiv} />
          )}

          <Grid item xs={12} sm={6}>
            <CustomAutoComplete
              onChangeValue={handleChangeImputation}
              options={config[salarieSituationConfig.imputations]}
              name={situationSalarieFields.fonctionnaireCodeImputation}
              value={situationSalarie.fonctionnaireCodeImputation}
              formLabel={wordingSalarie?.fonctionnaireCodeImputation}
              placeholder={
                wordingSalarie?.fonctionnaireCodeImputationPlaceHolder
              }
              tooltip={wordingSalarie?.fonctionnaireCodeImputationInfo}
              loadingText={'chargement'}
              error={
                errors[situationSalarieFields.fonctionnaireCodeImputation]
                  ? errorsWording[
                      errors[situationSalarieFields.fonctionnaireCodeImputation]
                    ]
                  : null
              }
              hasDefaultBorder={false}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <div className={classes.globalTooltip}>
              <FormLabel
                className={classes.labelRenseingTooltip}
                component='legend'
              >
                {wordingSalarie?.fonctionnaireMatricule}
                <Tooltip content={wordingSalarie?.fonctionnaireMatriculeInfo} />
              </FormLabel>
            </div>
            <CustomInput
              hasDefaultBorder={true}
              value={situationSalarie.fonctionnaireMatricule}
              name={situationSalarieFields.fonctionnaireMatricule}
              onChange={handleChange}
              placeholder={wordingSalarie?.fonctionnaireMatriculePlaceHolder}
              error={
                errors[situationSalarieFields.fonctionnaireMatricule]
                  ? errorsWording[
                      errors[situationSalarieFields.fonctionnaireMatricule]
                    ]
                  : null
              }
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <InputNumber
              formLabel={wordingSalarie?.monthlyIncome}
              tooltip={wordingSalarie?.infoMonthlyIncome}
              onChange={handleChange}
              name={situationSalarieFields.monthlyIncome}
              value={situationSalarie.monthlyIncome}
              placeholder={wordingSalarie?.placeHolderMonthlyIncome}
              suffix={''}
              endElement={wordingSalarie?.dhs}
              decimalSeparator=','
              hasDefaultBorder={true}
              error={
                errors[situationSalarieFields.monthlyIncome]
                  ? errorsWording[errors[situationSalarieFields.monthlyIncome]]
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedSwitches
              formLabel={wordingSalarie?.additionalIncome}
              tooltip={wordingSalarie?.infoAdditionalIncome}
              labelPrefix={wordingSalarie?.no}
              labelSufix={wordingSalarie?.yes}
              checked={situationSalarie.additionalIncome}
              onChange={handleChange}
              name={situationSalarieFields.additionalIncome}
            />
          </Grid>
          {situationSalarie.additionalIncome === true && (
            <>
              <Grid item xs={12} sm={6}>
                <SelectDown
                  formLabel={wordingSalarie?.additionalIncomeNature}
                  list={additionalIncomeNatureList}
                  label={wordingSalarie?.additionalIncomeNaturePlaceHolder}
                  name={situationSalarieFields.additionalIncomeNature}
                  value={situationSalarie.additionalIncomeNature}
                  onChange={handleChange}
                  error={
                    errors[situationSalarieFields.additionalIncomeNature]
                      ? errorsWording[
                          errors[situationSalarieFields.additionalIncomeNature]
                        ]
                      : null
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputNumber
                  formLabel={wordingSalarie?.amountadditionalIncome}
                  onChange={handleChange}
                  name={situationSalarieFields.amountadditionalIncome}
                  value={situationSalarie.amountadditionalIncome}
                  placeholder={
                    wordingSalarie?.placeHolderAmountadditionalIncome
                  }
                  suffix={''}
                  endElement={wordingSalarie?.dhs}
                  decimalSeparator=','
                  hasDefaultBorder={true}
                  error={
                    errors[situationSalarieFields.amountadditionalIncome]
                      ? errorsWording[
                          errors[situationSalarieFields.amountadditionalIncome]
                        ]
                      : null
                  }
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={6}>
            <InputNumber
              formLabel={wordingSalarie?.bankDebit}
              tooltip={wordingSalarie?.infoMonthlyTotalCredits}
              onChange={handleChange}
              name={situationSalarieFields.bankDebit}
              value={situationSalarie.bankDebit}
              placeholder={wordingSalarie?.placeHolderMonthlyTotalCredits}
              suffix={''}
              endElement={wordingSalarie?.dhs}
              decimalSeparator=','
              hasDefaultBorder={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputNumber
              formLabel={wordingSalarie?.fonctionnairePrelevementSource}
              tooltip={wordingSalarie?.fonctionnairePrelevementSourceInfo}
              onChange={handleChange}
              name={situationSalarieFields.fonctionnairePrelevementSource}
              value={situationSalarie.fonctionnairePrelevementSource}
              placeholder={
                wordingSalarie?.fonctionnairePrelevementSourcePlaceHolder
              }
              suffix={''}
              endElement={wordingSalarie?.dhs}
              decimalSeparator=','
              hasDefaultBorder={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputNumber
              formLabel={wordingSalarie?.monthlyCreditImmovable}
              tooltip={wordingSalarie?.infoMonthlyCreditImmovable}
              onChange={handleChange}
              name={situationSalarieFields.monthlyCreditImmovable}
              value={situationSalarie.monthlyCreditImmovable}
              placeholder={wordingSalarie?.placeHolderMonthlyCreditImmovable}
              suffix={''}
              endElement={wordingSalarie?.dhs}
              decimalSeparator=','
              hasDefaultBorder={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomDatePicker
              isDefault={true}
              formLabel={wordingSalarie?.dateLastVirement}
              name={situationSalarieFields.dateLastVirement}
              value={situationSalarie.dateLastVirement}
              tooltip={wordingSalarie?.infoDateLastVirement}
              onChange={handleChange}
              format='dd/MM/yyyy'
              placeholder={wordingSalarie?.dateLastVirementPlaceholder}
              hasDefaultBorder={true}
              error={
                errors[situationSalarieFields.dateLastVirement]
                  ? errorsWording[
                      errors[situationSalarieFields.dateLastVirement]
                    ]
                  : null
              }
            />
          </Grid>
          {(situatSalarie && situatSalarie.listRibBmce) ||
          situationSalarie.listRibBmce ? (
            <>
              <Grid item xs={12} sm={6}>
                <FormLabel className={classes.labelRenseing} component='legend'>
                  {wordingSalarie?.cin}
                </FormLabel>
                <CustomInput
                  value={situationSalarie.cin}
                  name={situationSalarieFields.cin}
                  onChange={handleChange}
                  placeholder={wordingSalarie?.placeHolderCin}
                  hasDefaultBorder={true}
                  disabled={situationSalarie.cinBmce}
                  error={
                    errors[situationSalarieFields.cin]
                      ? errorsWording[errors[situationSalarieFields.cin]]
                      : null
                  }
                />
              </Grid>
              <Grid
                item
                sm={situatSalarie || situationSalarie.listRibBmce ? 6 : 12}
              >
                <div className={classes.globalTooltip}>
                  <FormLabel
                    className={classes.labelRenseingTooltip}
                    component='legend'
                  >
                    {errors.global ? (
                      <div className={classes.error}>
                        {wordingSalarie?.cinOrRibInfo}
                      </div>
                    ) : (
                      wordingSalarie?.rib
                    )}
                  </FormLabel>
                  <Tooltip content={wordingSalarie?.infoRib} />
                </div>
                {situatSalarie || situationSalarie.listRibBmce.length === 1 ? (
                  <CustomInput
                    value={
                      situationSalarie.rib
                        ? situationSalarie.rib
                        : situatSalarie.rib
                    }
                    name={situationSalarieFields.rib}
                    onChange={handleChange}
                    hasDefaultBorder={true}
                    disabled={true}
                  />
                ) : (
                  <SelectDown
                    list={
                      situationSalarie.listRibBmce
                        ? situationSalarie.listRibBmce
                        : situatSalarie.listRibBmce
                    }
                    label={
                      situationSalarie.listRibBmce
                        ? situationSalarie.listRibBmce[0].value
                        : situatSalarie.listRibBmce[0].value
                    }
                    name={situationSalarieFields.rib}
                    value={situationSalarie.rib}
                    onChange={handleChange}
                    error={
                      errors[situationSalarieFields.rib]
                        ? errorsWording[errors[situationSalarieFields.rib]]
                        : null
                    }
                  />
                )}
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} sm={6}>
                <FormLabel className={classes.labelRenseing} component='legend'>
                  {wordingSalarie?.cinOrRib}
                </FormLabel>
                <Grid item xs={12} sm={8} className={classes.gridItem}>
                  <CustomRadioGroup
                    className={classes.radioGroup}
                    name={situationSalarieFields.cinOrRib}
                    onChange={handleChange}
                    value={situationSalarie.cinOrRib}
                    listOfElements={cinOrRibList}
                    error=''
                  />
                </Grid>
              </Grid>
              {situationSalarie.cinOrRib === 'cin' && (
                <Grid item xs={12} sm={6}>
                  <FormLabel
                    className={classes.labelRenseing}
                    component='legend'
                  >
                    {wordingSalarie?.cin}
                  </FormLabel>
                  <CustomInput
                    value={situationSalarie.cin}
                    name={situationSalarieFields.cin}
                    onChange={handleChange}
                    placeholder={wordingSalarie?.placeHolderCin}
                    hasDefaultBorder={true}
                    disabled={situationSalarie.cinBmce}
                    error={
                      errors[situationSalarieFields.cin]
                        ? errorsWording[errors[situationSalarieFields.cin]]
                        : null
                    }
                  />
                </Grid>
              )}
              {/** RIB BEGIN */}
              {situationSalarie.cinOrRib === 'rib' && (
                <Grid item sm={situationSalarie.listRibBmce ? 6 : 12}>
                  <div className={classes.globalTooltip}>
                    <FormLabel
                      className={classes.labelRenseingTooltip}
                      component='legend'
                    >
                      {errors.global ? (
                        <div className={classes.error}>
                          {wordingSalarie?.cinOrRibInfo}
                        </div>
                      ) : (
                        wordingSalarie?.rib
                      )}
                    </FormLabel>
                    <Tooltip content={wordingSalarie?.infoRib} />
                  </div>
                  <div
                    className={
                      langSelected === LANG_STATE.FR
                        ? classes.elementsRib
                        : classes.elementsRibAr
                    }
                  >
                    <InputNumber
                      onChange={handleChange}
                      name={situationSalarieFields.ribFirstContent}
                      value={situationSalarie.ribFirstContent}
                      placeholder='011'
                      disabled={true}
                      format='###'
                      suffix={''}
                      endElement=''
                      hasDefaultBorder={true}
                      className={`${
                        langSelected === LANG_STATE.FR
                          ? classes.textFieldRib
                          : classes.textFieldRibAr
                      } ${classes.whithoutMsgError}`}
                      error={
                        errors[situationSalarieFields.ribFirstContent]
                          ? errorsWording[
                              errors[situationSalarieFields.ribFirstContent]
                            ]
                          : null
                      }
                    />
                    <InputNumber
                      onChange={handleChange}
                      name={situationSalarieFields.ribSecondContent}
                      value={situationSalarie.ribSecondContent}
                      placeholder='- - -'
                      format='###'
                      suffix={''}
                      endElement=''
                      hasDefaultBorder={true}
                      className={`${
                        langSelected === LANG_STATE.FR
                          ? classes.textFieldRib
                          : classes.textFieldRibAr
                      } ${classes.whithoutMsgError} `}
                      error={
                        errors[situationSalarieFields.ribSecondContent] ||
                        errors[situationSalarieFields.rib]
                      }
                    />
                    <InputNumber
                      onChange={handleChange}
                      name={situationSalarieFields.ribThirdContent}
                      value={situationSalarie.ribThirdContent}
                      placeholder='- -  - - -  - -  - - - - -'
                      format='## ### ## #####'
                      suffix={''}
                      endElement=''
                      hasDefaultBorder={true}
                      startElement={'0000'}
                      className={`${
                        langSelected === LANG_STATE.FR
                          ? classes.textFieldRib
                          : classes.textFieldRibAr
                      } ${classes.thirdTextFieldRib} ${
                        classes.whithoutMsgError
                      } `}
                      error={
                        errors[situationSalarieFields.ribThirdContent] ||
                        errors[situationSalarieFields.rib]
                      }
                    />
                    <InputNumber
                      onChange={handleChange}
                      name={situationSalarieFields.ribLastContent}
                      value={situationSalarie.ribLastContent}
                      placeholder='- -'
                      format='##'
                      suffix={''}
                      hasDefaultBorder={true}
                      endElement=''
                      className={`${
                        langSelected === LANG_STATE.FR
                          ? classes.textFieldRib
                          : classes.textFieldRibAr
                      } ${classes.lastTextFieldRib} ${
                        classes.whithoutMsgError
                      } `}
                      error={
                        errors[situationSalarieFields.ribLastContent] ||
                        errors[situationSalarieFields.rib]
                      }
                    />
                  </div>
                  <p className={classes.error}>
                    {errors[situationSalarieFields.ribFirstContent] ||
                    errors[situationSalarieFields.ribSecondContent] ||
                    errors[situationSalarieFields.ribThirdContent] ||
                    errors[situationSalarieFields.ribLastContent]
                      ? wordingSalarie?.requiredField
                      : errors[situationSalarieFields.rib]
                      ? wordingSalarie?.INVALID_RIB
                      : null}
                  </p>
                  {errors.global && (
                    <p className={classes.error}>
                      {wordingSalarie?.requiredField}
                    </p>
                  )}
                </Grid>
              )}
              {/** RIB END */}
            </>
          )}
        </Grid>
      </div>
    </CheckAuthorization>
  );
};

export default FonctionnaireNotConv;
