import { Button, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import BackgroundRightContainer from 'assets/images/BackgroundConfirmationMail.png';
import Background from 'assets/images/bgSignIn.png';
import logo from 'assets/images/logo.png';
import logoCreditDaba from 'assets/svg/logoCreditDaba.svg';
import { CustomInput } from 'common/components/customFields/CustomInput';
import { GlobalError } from 'common/components/customFields/GlobalError';
import Footer from 'common/components/Footer';
import { Wording } from 'common/wording/reducer.wording';
import { selectCompWording } from 'common/wording/selectors.wording';
import { GlobalTheme } from 'features/app/app.theme';
import { LANG_STATE } from 'features/home/constants.simulation';
import { getSimulationInfoByArraySelector } from 'features/home/selector.simulation';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AuthAT, updateFieldSignInAction } from '../actions.auth';
import { passOublieFields } from '../constants';
import { getAuthInfoByArraySelector } from '../selector.auth';

const useStyles = makeStyles((theme: GlobalTheme) =>
  createStyles({
    form: {
      margin: '18px 40px 41px',
      borderRadius: '22px',
      backgroundImage: `url(${BackgroundRightContainer})`,
    },
    formHeader: {
      padding: '60px 97px 10px',
      width: '420px',
      height: '76px',
      '@media (max-width: 450px)': {
        padding: '50px 15px 30px',
      },
    },
    formContent: {
      padding: '10px 40px 60px',
      '@media (max-width: 1024px)': {
        padding: '10px 38px',
      },
      '@media (max-width: 414px)': {
        padding: '30px 15px',
      },
    },
    title: {
      textTransform: 'uppercase',
      marginBottom: '30px',
      textAlign: 'center',
      fontWeight: 'bold',
    },
    subTitle: {
      fontSize: '1rem',
      textAlign: 'center',
      maxWidth: 400,
    },
    textfieldStyle: {
      minHeight: 60,
      marginBottom: '10px',
      '& .MuiOutlinedInput-inputMarginDense': {
        textAlign: 'left',
        '&::placeholder': {
          opacity: 1,
        },
      },
    },
    root: {
      minHeight: '100vh',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      backgroundImage: `url(${Background})`,
      backgroundSize: 'cover',
      '& .MuiButton-contained': {
        '&:hover': {
          boxShadow: 'unset',
          backgroundColor: 'unset',
        },
      },
    },
    logoBOA: {
      paddingLeft: 25,
      paddingTop: 12,
      display: 'block',
      width: '180px',
    },
    groupFormFooter: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    groupFormFooterAr: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
    radioGroup: {
      minWidth: '100%',
      justifyContent: 'space-around',
      '& .MuiTypography-root': {
        fontSize: '0.8125rem',
        color: theme.primary.title,
      },
      '@media (max-width: 414px)': {
        justifyContent: 'center',
      },
    },
    buttonSubmit: {
      borderRadius: '14px 0',
      textTransform: 'inherit',
      padding: '14px 50px',
      fontFamily: theme.fonts.regular,
      fontSize: '0.875rem',
    },
    logoCreditConso: {
      display: 'block',
      margin: '12px auto',
      width: '450px',
      maxWidth: '85%',
    },
    globalContainer: {
      maxWidth: 662,
      margin: 'auto',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
    },
    footer: {
      width: '100%',
      '@media (max-width: 1024px)': {
        textAlign: 'center',
      },
    },
    inputs: {
      padding: '0 40px',
      marginTop: '35px',
      '@media (max-width: 450px)': {
        padding: '0',
        marginTop: '5px',
      },
    },
    link: {
      padding: '0 26px',
      color: theme.secondary.title,
      fontSize: '0.9375rem',
      textDecoration: 'none',
    },
    logoButton: {
      backgroundColor: 'transparent',
      alignSelf: 'flex-start',
    },
    logoButtonAr: {
      backgroundColor: 'transparent',
      alignSelf: 'flex-start',
    },
  }),
);

function PasswordOublie() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const toHomePage = () => history.push('/credit-conso');
  const passwordOublie: any = useSelector(
    getAuthInfoByArraySelector(['passwordOublie']),
  );
  const errors: any = useSelector(
    getAuthInfoByArraySelector(['passwordOublie', 'errors']),
  );

  const passWording: Wording = useSelector(selectCompWording('passwordOublie'));
  const errorsWording: Wording = useSelector(selectCompWording('errors'));

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updateFieldSignInAction('passwordOublie', e.target.name, e.target.value),
    );
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch({ type: AuthAT.submitPasswordOublie });
  };

  const langSelected: any =
    useSelector(getSimulationInfoByArraySelector(['lang'])) || LANG_STATE.FR;
  return (
    <>
      <div className={classes.root}>
        <Button
          variant='contained'
          type='button'
          onClick={toHomePage}
          className={
            langSelected === LANG_STATE.FR
              ? classes.logoButton
              : classes.logoButtonAr
          }
        >
          <img className={classes.logoBOA} alt='logoBOA' src={logo} />
        </Button>
        <img
          className={classes.logoCreditConso}
          alt='logo-center'
          src={logoCreditDaba}
        />
        <div className={classes.globalContainer}>
          <form
            className={classes.form}
            noValidate
            autoComplete='off'
            onSubmit={handleSubmit}
          >
            <div className={classes.formHeader}>
              <Typography variant='h3' className={classes.title}>
                {passWording?.title}
              </Typography>
              <Typography variant='h3' className={classes.subTitle}>
                {passWording?.subtitle}
              </Typography>
            </div>
            {errors && errors.global && (
              <GlobalError
                globalError={errors.global}
                errorWording={errorsWording}
              />
            )}

            <div className={classes.formContent}>
              <div className={classes.inputs}>
                <CustomInput
                  value={passwordOublie.email}
                  type='text'
                  name={passOublieFields.email}
                  onChange={handleChange}
                  placeholder={passWording?.mailPlaceHolder}
                  className={classes.textfieldStyle}
                  error={
                    errors[passOublieFields.email]
                      ? errorsWording[errors[passOublieFields.email][0]]
                      : null
                  }
                />
              </div>
            </div>
            <div
              className={
                langSelected === LANG_STATE.FR
                  ? classes.groupFormFooter
                  : classes.groupFormFooterAr
              }
            >
              <Button
                type='submit'
                variant='contained'
                color='primary'
                className={classes.buttonSubmit}
              >
                {passWording?.btnEnvoyer}
              </Button>
            </div>
          </form>
        </div>
      </div>

      <div className={classes.footer}>
        <Footer />
      </div>
    </>
  );
}

export default PasswordOublie;
