import { apiPost } from 'common/utils/request.util';
import { push } from 'connected-react-router';
import { put, takeLatest, select } from 'redux-saga/effects';
import { PropositionAT, SubmitPropositionAction } from './actions.proposition';
import { RecapAT, updateElementRecap } from 'features/recap/actions.recap';
import { UpdateInformationAT } from 'features/updateInformation/actions.updateInformation';
import { setSessionStorage } from 'common/utils/sessionStorage.util';
import { AccountTypes, ProductTypes } from 'features/auth/constants';
import { getPropositionInfoByArraySelector } from './selector.proposition';

export function* submitProposition(action: SubmitPropositionAction) {
  try {
    const { data } = yield apiPost('/situation/recap', '');

    yield put({ type: RecapAT.updateRecap, data });
    const propositionRes = yield select(
      getPropositionInfoByArraySelector(['proposition', 'fields']),
    );
    yield put(updateElementRecap('recap', 'isBMCE', propositionRes.isBMCE));
    yield put({ type: UpdateInformationAT.updateInformation, data });
    setSessionStorage('recap', data);
    setSessionStorage('updateInformation', data);

    if (data.typeClient === AccountTypes.salarie) {
      if (data.product === ProductTypes.bconvcp && data.clientBOA) {
        yield put(push('/recap/salarie/conv'));
      } else if (data.product === ProductTypes.cp && data.clientBOA) {
        yield put(push('/recap/salarie/noConv'));
      } else {
        yield put(push('/recap/salarie/prospect'));
      }
    } else if (data.typeClient === AccountTypes.fonctionnaire) {
      if (data.product === ProductTypes.bconvppr && data.clientBOA) {
        yield put(push('/recap/fonctionnaire/conv'));
      } else if (data.product === ProductTypes.ppr && data.clientBOA) {
        yield put(push('/recap/fonctionnaire/noConv'));
      } else {
        yield put(push('/recap/fonctionnaire/prospect'));
      }
    } else if (data.typeClient === AccountTypes.retraite) {
      if (data.product === ProductTypes.bconvcp && data.clientBOA) {
        yield put(push('/recap/retraite/conv'));
      } else if (data.product === ProductTypes.cp && data.clientBOA) {
        yield put(push('/recap/retraite/noConv'));
      } else {
        yield put(push('/recap/retraite/prospect'));
      }
    } else if (data.typeClient === AccountTypes.professionnel) {
      if (data.product === ProductTypes.cp) {
        yield put(push('/recap/professionnel/client'));
      } else if (data.product === ProductTypes.cp_slf) {
        yield put(push('/recap/professionnel/prospect'));
      }
    }
  } catch (e) {
    console.error(e);
  }
}

export function* submitPropositionSaga() {
  yield takeLatest(PropositionAT.submitProposition, submitProposition);
}

export const propositionSagas = [submitPropositionSaga];
