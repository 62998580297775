// @ts-nocheck
import { connectRouter } from 'connected-react-router/immutable';
import { History } from 'history';
import { Reducer } from 'redux';
import { combineReducers } from 'redux-immutable';
import { AuthReducer, AUTH_REDUCER } from '../features/auth/reducer.auth';
import {
  uploadDocumentReducer,
  UPLOAD_REDUCER,
} from '../features/upload/reducer.upload';
import { AppModel } from './root.model';
import {
  wordingReducer,
  WORDINGS_REDUCER,
} from 'common/wording/reducer.wording';
import {
  SituationReducer,
  SITUATION_REDUCER,
} from '../features/situation/reducer.situation';
import {
  PropositionReducer,
  PROPOSITION_REDUCER,
} from '../features/proposition/reducer.proposition';
import {
  CongratulationReducer,
  CONGRATULATION_REDUCER,
} from '../features/congratulation/reducer.congratulation';
import { RecapReducer, RECAP_REDUCER } from '../features/recap/reducer.recap';
import {
  SimulationReducer,
  HOME_REDUCER,
} from '../features/home/reducer.simulation';
import {
  CaseTrackingReducer,
  CASETRACKING_REDUCER,
} from '../features/suivieDossier/reducer.caseTracking';
import {
  UpdateInformationReducer,
  UPDATEINFORMATION_REDUCER,
} from '../features/updateInformation/reducer.updateInformation';
import {
  validationReducer,
  VALIDATION_REDUCER,
} from '../features/validationBmceDirect/reducer.validation';

const createRootReducer: (history: History) => Reducer<AppModel> = (
  history: History,
) =>
  combineReducers<AppModel>({
    router: connectRouter(history),
    [AUTH_REDUCER]: AuthReducer,
    [UPLOAD_REDUCER]: uploadDocumentReducer,
    [SITUATION_REDUCER]: SituationReducer,
    [HOME_REDUCER]: SimulationReducer,
    [WORDINGS_REDUCER]: wordingReducer,
    [PROPOSITION_REDUCER]: PropositionReducer,
    [RECAP_REDUCER]: RecapReducer,
    [CONGRATULATION_REDUCER]: CongratulationReducer,
    [CASETRACKING_REDUCER]: CaseTrackingReducer,
    [UPDATEINFORMATION_REDUCER]: UpdateInformationReducer,
    [VALIDATION_REDUCER]: validationReducer,
  });

export default createRootReducer;
