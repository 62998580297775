import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { GlobalTheme } from 'features/app/app.theme';
import React from 'react';
import logoCreditDaba from 'assets/svg/logoCreditDaba-dark.svg';

const useStyles = makeStyles((theme: GlobalTheme) => ({
  logo: {
    display: 'block',
    maxWidth: '200px',
    marginBottom: '10px',
  },
  title: {
    fontWeight: 600,
    fontFamily: theme.fonts.bold,
    fontSize: '3.428rem',
    paddingRight: '20%',
    '@media (max-width: 980px)': {
      fontSize: '2.65rem',
      paddingRight: 0,
    },
  },
  paragraph: {
    color: theme.primary.title,
    fontSize: '1rem',
    fontWeight: 300,
    marginTop: 35,
    '@media (max-width: 980px)': {
      marginTop: 20,
    },
  },
  subTitle: {
    fontWeight: 500,
    fontFamily: theme.fonts.bold,
    fontSize: '2rem',
    '@media (max-width: 600px)': {
      fontSize: '1.5rem',
      marginBottom: '15px',
    },
  },
  image: {
    display: 'block',
    width: '100%',
    height: 'auto',
    margin: '0 auto',
    '@media (max-width: 600px)': {
      width: '80%',
      marginBottom: '20px',
    },
  },
  gridContainer: {
    '@media (max-width: 600px)': {
      flexDirection: 'column-reverse',
    },
  },
}));

interface IMainView {
  title: string;
  description: string;
  image: string;
  subTitle?: string;
}
const MainView = ({ title, description, image, subTitle }: IMainView) => {
  const classes = useStyles();

  return (
    <Box mb={{ xs: 4, sm: 8 }}>
      <Grid
        container
        justify='space-between'
        alignItems='center'
        spacing={4}
        className={classes.gridContainer}
      >
        <Grid item xs={12} sm={7}>
          <img src={logoCreditDaba} alt={title} className={classes.logo} />
          <Typography variant='h2' component='h1' className={classes.title}>
            <span dangerouslySetInnerHTML={{ __html: title }} />
          </Typography>
          {subTitle && (
            <Typography
              variant='h3'
              component='h3'
              className={classes.subTitle}
            >
              <span dangerouslySetInnerHTML={{ __html: subTitle }} />
            </Typography>
          )}
          <Typography className={classes.paragraph} variant='body1'>
            <span dangerouslySetInnerHTML={{ __html: description }} />
          </Typography>
        </Grid>
        <Grid item xs={12} sm={5}>
          <img className={classes.image} src={image} alt={title} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default MainView;
