import React from 'react';
import { useSelector } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { GlobalTheme } from 'features/app/app.theme';
import FormLabel from '@material-ui/core/FormLabel';
import Tooltip from 'common/components/customFields/CustomTooltip';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import frLocale from 'date-fns/locale/fr';
import arLocale from 'date-fns/locale/ar-SA';
import CalendarIcon from 'assets/svg/Calendar.svg';
import { getSimulationInfoByArraySelector } from 'features/home/selector.simulation';
import { LANG_STATE } from 'features/home/constants.simulation';

type Props = {
  hasDefaultBorder?: boolean;
  isSignUp?: boolean;
};

const useStyles = makeStyles<GlobalTheme, Props>((theme: GlobalTheme) =>
  createStyles({
    root: {
      width: '100%',
      marginBottom: '18px',
      '@media (max-width: 600px)': {
        marginBottom: '12px',
      },
    },
    employer: {
      width: '100%',
      border: 'solid 1px',
      borderRadius: '40px',
      borderColor: theme.secondary.title,
      '& .MuiInputBase-root': {
        padding: '0px 15px',
      },
    },
    globalTooltip: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    tooltip: {
      padding: '0',
      margin: 'auto',
      marginLeft: '10px',
    },
    formLabel: {
      fontSize: '0.865rem',
      textAlign: 'left',
      fontFamily: theme.fonts.medium,
      color: theme.secondary.title,
      marginBottom: '7px',
    },
    formLabelTooltip: {
      fontSize: '0.865rem',
      textAlign: 'left',
      fontFamily: theme.fonts.medium,
      color: theme.secondary.title,
    },
    datePicker: (props: Props) => ({
      '& .MuiInputBase-input:focus': {
        borderBottom: '0px !importent',
      },
      '& input.Mui-disabled': {
        backgroundColor: '#F5F6F9',
      },
      '& .MuiInputBase-root': props?.hasDefaultBorder
        ? {
            border: `solid 0.063em ${theme.primary.gray}`,
          }
        : props?.isSignUp
        ? {
            border: `solid 0.063em ${theme.primary.white}`,
          }
        : {},
      '& .MuiInput-root': props?.isSignUp
        ? {
            backgroundColor: theme.primary.white,
            '&:before': {
              content: 'none',
            },
            '&:after': {
              content: 'none',
            },
          }
        : {
            '&:before': {
              content: 'none',
            },
            '&:after': {
              content: 'none',
            },
          },
      '& .MuiInputBase-input': {
        height: '40px',
        padding: 0,
        marginLeft: '4%',
      },
      '& .MuiFormHelperText-root': {
        color: `${theme.secondary.main} !important`,
        paddingLeft: '17px',
      },
      '& .MuiPopover-paper': {
        marginTop: 0,
      },
    }),
    datePickerDialog: {
      '& .MuiTypography-body2': {
        fontSize: '0.9375rem',
      },
      '& .MuiPickersDay-daySelected': {
        backgroundColor: theme.secondary.main,
      },
      '& .MuiPickersToolbar-toolbar': {
        backgroundColor: theme.primary.main,
      },
      '& .MuiButton-textPrimary': {
        color: theme.primary.main,
      },
    },
    errorMessage: {
      '& .MuiInput-root': {
        borderColor: `${theme.secondary.main} !important`,
      },
      '& .MuiInputBase-root': {
        borderBottom: 0,
      },
    },
    bordergrey: {
      borderWidth: '0.063em !important',
      borderColor: '#556584B3 !important',
      border: 'solid !important',
      borderRadius: '40px',
    },
    stylePicker: {
      '& .MuiPickersToolbar-toolbar': {
        backgroundColor: `${theme.secondary.main} !important`,
      },
      '& .MuiPickersToolbarText-toolbarTxt': {
        color: `${theme.primary.white} !important`,
      },
      '& .MuiPickersToolbarText-toolbarBtnSelected': {
        color: `${theme.primary.white} !important`,
      },
      '& .MuiTypography-colorPrimary': {
        color: `${theme.primary.title} !important`,
      },
      '& .MuiTypography-body2': {
        fontSize: '0.875rem',
      },
      '& .MuiPaper-root': {
        border: `0.063em solid ${theme.primary.title}`,
        borderRadius: '22px',
      },
      '& .MuiTypography-caption': {
        color: theme.primary.title,
      },
      '& .MuiPickersSlideTransition-transitionContainer': {
        textTransform: 'uppercase',
        color: theme.primary.title,
        fontFamily: theme.fonts.medium,
      },
      '& .MuiSvgIcon-root': {
        color: theme.primary.title,
      },
      '& .MuiPickersDay-current': {
        backgroundColor: '#FFF',
      },
      '& .MuiPickersDay-daySelected:hover': {
        backgroundColor: theme.secondary.main,
        border: `0.063em solid ${theme.secondary.main}`,
        borderRadius: '12px',
      },
      '& .MuiPickersDay-daySelected': {
        backgroundColor: theme.secondary.main,
        border: `0.063em solid ${theme.secondary.main}`,
        borderRadius: '12px',
      },
    },
  }),
);

interface PropsCustomDatePicker {
  tooltip?: string;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  name: string;
  formLabel?: string;
  value: string;
  format: string;
  placeholder: string;
  views?: any;
  error?: string | null;
  isDefault?: boolean; // Default cas
  isMajor?: boolean; // +18 ans
  isCDD?: boolean; // Illimité 1900 a 2100
  isMinMax?: boolean; // Entre 1950 et Actuel +1 Keyboard Date Picker
  hasDefaultBorder?: boolean;
  isSignUp?: boolean;
  disabled?: boolean;
}

export const CustomDatePicker = ({
  onChange = () => ({}),
  onBlur = () => ({}),
  name = '',
  placeholder = '',
  formLabel = '',
  isDefault = false,
  isMajor = false,
  isCDD = false,
  isMinMax = false,
  tooltip = '',
  value = '',
  error = '',
  format = 'dd/MM/yyyy',
  views,
  hasDefaultBorder = false,
  isSignUp = false,
  disabled = false,
}: PropsCustomDatePicker) => {
  const langSelected: any =
    useSelector(getSimulationInfoByArraySelector(['lang'])) || LANG_STATE.FR;
  const classes = useStyles({ hasDefaultBorder, isSignUp });
  const today: Date = new Date();
  // =================== Debut default handle Change Function ===================
  const handleDefaultChange = (date: Date | null) => {
    onChange({
      target: {
        value: date !== null && date.getTime() > today.getTime() ? today : date,
        name,
      },
    } as any);
  };
  // =================== Fin default                        ===================
  // =================== Debut isMajor handle Change Function ===================
  const dateMajor: Date = new Date();
  const major: number = 18;
  dateMajor.setMonth(dateMajor.getMonth() - major * 12);
  const handleMajorChange = (date: Date | null) => {
    onChange({
      target: {
        value:
          date !== null && date.getTime() > dateMajor.getTime()
            ? dateMajor
            : date,
        name,
      },
    } as any);
  };
  // ===================    Fin isMajor                        ===================
  // ============== Debut isMinMax && isCDD handle Change Function ===============
  const dateMin: Date = new Date(1950, 1, 1);
  const dateMax: Date = new Date();
  dateMax.setMonth(today.getMonth() + 12);
  const handleMinMaxChange = (date: Date | null) => {
    onChange({
      target: {
        value: date,
        name,
      },
    } as any);
  };
  // =================== Fin isMinMax ===================
  return (
    <div className={classes.root}>
      <div className={classes.globalTooltip}>
        {formLabel && (
          <FormLabel
            className={tooltip ? classes.formLabelTooltip : classes.formLabel}
            component='legend'
          >
            {formLabel}
            {tooltip && <Tooltip content={tooltip} />}
          </FormLabel>
        )}
      </div>
      <MuiPickersUtilsProvider
        utils={DateFnsUtils}
        locale={langSelected === LANG_STATE.FR ? frLocale : arLocale}
      >
        <>
          {
            // isMajor Keyboard Date Picker +18 ans
            isMajor && (
              <KeyboardDatePicker
                className={`${classes.datePicker} ${
                  error ? classes.errorMessage : ''
                } ${value && !error ? classes.bordergrey : ''}`}
                autoOk
                variant='inline'
                views={views}
                disabled={disabled}
                format={format}
                id='date-picker-dialog'
                keyboardIcon={<img src={CalendarIcon} alt='Calendrier' />}
                name={name}
                value={value ? value : null}
                onChange={handleMajorChange}
                onBlur={onBlur}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                animateYearScrolling={true}
                disableFuture={true}
                maxDate={dateMajor}
                placeholder={placeholder}
                DialogProps={{ className: classes.datePickerDialog }}
                PopoverProps={{ className: classes.stylePicker }}
                cancelLabel={'Annuler'}
                okLabel={'Valider'}
                helperText={error}
              />
            )
          }
          {
            // isCDD Keyboard Date Picker
            isCDD && (
              <KeyboardDatePicker
                className={`${classes.datePicker} ${
                  error ? classes.errorMessage : ''
                } ${value && !error ? classes.bordergrey : ''}`}
                autoOk
                variant='inline'
                views={views}
                format={format}
                id='date-picker-dialog'
                keyboardIcon={<img src={CalendarIcon} alt='' />}
                name={name}
                value={value ? value : null}
                onChange={handleMinMaxChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                animateYearScrolling={true}
                placeholder={placeholder}
                DialogProps={{ className: classes.datePickerDialog }}
                PopoverProps={{ className: classes.stylePicker }}
                cancelLabel={'Annuler'}
                okLabel={'Valider'}
                helperText={error}
              />
            )
          }
          {
            // isMinMax entre 1950 et Actuel +1 Keyboard Date Picker
            isMinMax && (
              <KeyboardDatePicker
                className={`${classes.datePicker} ${
                  error ? classes.errorMessage : ''
                } ${value && !error ? classes.bordergrey : ''}`}
                autoOk
                variant='inline'
                views={views}
                format={format}
                id='date-picker-dialog'
                keyboardIcon={<img src={CalendarIcon} alt='' />}
                name={name}
                value={value ? value : null}
                onChange={handleMinMaxChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                minDate={dateMin}
                maxDate={dateMax}
                animateYearScrolling={true}
                placeholder={placeholder}
                DialogProps={{ className: classes.datePickerDialog }}
                PopoverProps={{ className: classes.stylePicker }}
                cancelLabel={'Annuler'}
                okLabel={'Valider'}
                helperText={error}
              />
            )
          }
          {isDefault && (
            <KeyboardDatePicker
              className={`${classes.datePicker} ${
                error ? classes.errorMessage : ''
              } ${value && !error ? classes.bordergrey : ''}`}
              autoOk
              variant='inline'
              views={views}
              format={format}
              id='date-picker-dialog'
              keyboardIcon={<img src={CalendarIcon} alt='' />}
              name={name}
              value={value ? value : null}
              onChange={handleDefaultChange}
              maxDate={today}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              animateYearScrolling={true}
              disableFuture={true}
              placeholder={placeholder}
              DialogProps={{ className: classes.datePickerDialog }}
              PopoverProps={{ className: classes.stylePicker }}
              cancelLabel={'Annuler'}
              okLabel={'Valider'}
              helperText={error}
            />
          )}
        </>
      </MuiPickersUtilsProvider>
    </div>
  );
};
