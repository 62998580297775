import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { GlobalTheme } from 'features/app/app.theme';
import { Typography } from '@material-ui/core';
import rightBlueFlesh from 'assets/svg/rightBlueFlesh.svg';
import { getSimulationInfoByArraySelector } from 'features/home/selector.simulation';
import { LANG_STATE } from 'features/home/constants.simulation';

const useStyles = makeStyles((theme: GlobalTheme) => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '80px',
    backgroundColor: '#FFF',
    borderRadius: '25px',
    margin: 'auto',
    padding: '40px',
    '@media (max-width: 768px)': {
      height: 'auto',
    },
  },
  rootAr: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '80px',
    backgroundColor: '#FFF',
    borderRadius: '25px',
    margin: 'auto',
    padding: '40px',
    flexDirection: 'row-reverse',
    '@media (max-width: 768px)': {
      height: 'auto',
    },
  },
  title: {
    fontFamily: theme.fonts.bold,
  },
  subTitle: {
    marginTop: '15px',
    lineHeight: 1,
    opacity: 0.8,
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    paddingRight: '40px',
  },
  rightBlueFlesh: {
    marginTop: '-2px',
  },
  rightImgBlueFlesh: {},
  rightImgBlueFleshAr: {
    transform: 'scaleX(-1)',
  },
}));

interface Props {
  title: string;
  subTitle: string;
  className?: any;
}

function CreateProfileElement(props: Props) {
  const { title, subTitle, className } = props;
  const langSelected: any =
    useSelector(getSimulationInfoByArraySelector(['lang'])) || LANG_STATE.FR;
  const classes = useStyles();
  return (
    <div
      className={langSelected === LANG_STATE.FR ? classes.root : classes.rootAr}
    >
      <div className={classes.titleWrapper}>
        <Typography
          variant='h4'
          className={`${classes.title} ${className}`}
          style={
            langSelected === LANG_STATE.FR
              ? { direction: 'ltr' }
              : { direction: 'rtl' }
          }
        >
          {title}
        </Typography>
        <Typography variant='h5' className={classes.subTitle}>
          {subTitle}
        </Typography>
      </div>
      <div className={classes.rightBlueFlesh}>
        <img
          src={rightBlueFlesh}
          alt=''
          className={
            langSelected === LANG_STATE.FR
              ? classes.rightImgBlueFlesh
              : classes.rightImgBlueFleshAr
          }
        />
      </div>
    </div>
  );
}

export default CreateProfileElement;
