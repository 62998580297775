import { StoreOf } from 'core/root.model';
import { fromJS } from 'immutable';
import {
  LoadWordingAction,
  LoadWordingErrorAction,
  LoadWordingSuccessAction,
  LoadWordingARSuccessAction,
  LoadWordingFRSuccessAction,
  SetLoadingAction,
  LOAD_WORDING,
  LOAD_WORDING_ERROR,
  LOAD_WORDING_SUCCESS,
  LOAD_WORDING_AR_SUCCESS,
  LOAD_WORDING_FR_SUCCESS,
  SET_LOADING
} from './actions.wording';

export enum WordingErrorType {
  CMS_CONNEXION_ERROR = 'CMS_CONNEXION_ERROR',
  WORDING_RUNTIME_ERROR = 'WORDING_RUNTIME_ERROR',
}

export interface Wording {
  [key: string]: any;
}

export class WordingError extends Error {
  constructor(public type: WordingErrorType) {
    super(type);
  }
}

export interface WordingModel {
  wording?: Wording;
  wordingfr?: Wording;
  wordingar?: Wording;
  loading?: boolean;
  error?: WordingError | false;
}

export const WORDINGS_REDUCER = 'WORDINGS_REDUCER';

export type WordingStore = StoreOf<WordingModel>;

const initialState: WordingStore = fromJS({} as WordingModel);

export function wordingReducer(
  state = initialState,
  action: LoadWordingAction | SetLoadingAction  | LoadWordingErrorAction | LoadWordingSuccessAction | LoadWordingARSuccessAction | LoadWordingFRSuccessAction,
): WordingStore {
  switch (action.type) {
    case LOAD_WORDING:
      return state.set('loading', true);
    case SET_LOADING:
      return state.set('loading', action.loading);
    case LOAD_WORDING_SUCCESS:
      return state
        .set('loading', false)
        .set('error', false)
        .set('wording', fromJS(action.wording));
    case LOAD_WORDING_FR_SUCCESS:
      return state
        .set('loading', false)
        .set('error', false)
        .set('wordingfr', fromJS(action.wording));
    case LOAD_WORDING_AR_SUCCESS:
      return state
        .set('loading', false)
        .set('error', false)
        .set('wordingar', fromJS(action.wording));
    case LOAD_WORDING_ERROR:
      return state.set('error', fromJS(action.error));
    default:
      return state;
  }
}
