import { apiPost } from 'common/utils/request.util';
import { push } from 'connected-react-router';
import { put, takeLatest } from 'redux-saga/effects';
import {
  CongratulationAT,
  SubmitCongratulationAction,
} from './actions.congratulation';
import { updateFieldCaseAction } from '../suivieDossier/actions.caseTracking';
import { setSessionStorage } from 'common/utils/sessionStorage.util';

export function* submitCongratulation(action: SubmitCongratulationAction) {
  try {
    const data = yield apiPost('/situation/suivi-dossier', '');
    yield put(
      updateFieldCaseAction('caseTracking', 'step', data.data.statusDemande),
    );
    setSessionStorage('statutDossier', data.data.statusDemande);
    yield put(push('/case-tracking'));
  } catch (e) {
    console.error(e);
  }
}

export function* submitCongratulationSaga() {
  yield takeLatest(CongratulationAT.submitCongratulation, submitCongratulation);
}

export const congratulationSagas = [submitCongratulationSaga];
