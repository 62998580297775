import { fromJS } from 'immutable';
import { StoreOf } from '../../core/root.model';
import {
  UpdateFieldAction,
  SubmitSituationErrorsAction,
  SubmitSituationSalarieErrorsAction,
  GetConfigActionSuccess,
  UpdateLoaderAction,
  UpdateStepAct,
  InitSituationATAction,
} from './actions.situation';
import {
  SituationAT,
  ValidateJsError,
} from 'features/situation/actions.situation';
import { getSessionStorage } from 'common/utils/sessionStorage.util';

const situationSalarie = getSessionStorage('situationSalarie');

export const initState: SituationState = fromJS({
  situationPro: {
    activityPro: 'commercant',
    bankingSeniority: 'lessThanYear',
    additionalIncomeType: false,
    additionalIncome: false,
    activitySeniority: 'lessThanYear',
    seniorityBankOfAfrica: 'lessThanSixMonth',
    errors: {},
  },
  situationSalarie: {
    fields: situationSalarie
      ? situationSalarie
      : {
          activeSpouse: true,
          titulaire: true,
          natureContrat: 'cdi',
          additionalIncome: false,
          retirment: false,
          cinOrRib: 'cin',
          cddEndDate: '',
          dateCurrentEmployer: '',
          dateLastTransfer: '',
          city: '',
          fonctionnaireCodeImputation: '',
          fonctionnaireGrade: '',
          dateLastVirement: '',
          bankDebit: '',
          dateCreationOtherBankAccount: '',
          numberChilds: '',
          numberPersonWhitoutChilds: '',
          monthlyTotalCredits: '',
          monthlyCreditImmovable: '',
          monthlyIncome: '',
          amountadditionalIncome: '',
        },
    config: {
      familialSituationList: [],
      activityAreaList: [],
      professionList: [],
      nationalityList: [],
      cityList: [],
      loader: false,
      checkValidateClient: false,
    },
    response: {},
    errors: {},
  },
});

export const resetState: SituationState = fromJS({
  situationPro: {
    activityPro: 'commercant',
    bankingSeniority: 'lessThanYear',
    additionalIncomeType: false,
    additionalIncome: false,
    activitySeniority: 'lessThanYear',
    seniorityBankOfAfrica: 'lessThanSixMonth',
    errors: {},
  },
  situationSalarie: {
    fields: {
      activeSpouse: true,
      titulaire: true,
      natureContrat: 'cdi',
      additionalIncome: false,
      retirment: false,
      cinOrRib: 'cin',
      cddEndDate: '',
      dateCurrentEmployer: '',
      dateLastTransfer: '',
      city: '',
      fonctionnaireCodeImputation: '',
      fonctionnaireGrade: '',
      dateLastVirement: '',
      bankDebit: '',
      dateCreationOtherBankAccount: '',
      numberChilds: '',
      numberPersonWhitoutChilds: '',
      monthlyTotalCredits: '',
      monthlyCreditImmovable: '',
      monthlyIncome: '',
      amountadditionalIncome: '',
      listRachats: [],
      rachatMntEch: '',
    },
    config: {
      familialSituationList: [],
      activityAreaList: [],
      professionList: [],
      nationalityList: [],
      cityList: [],
      loader: false,
      checkValidateClient: false,
    },
    response: {},
    errors: {},
  },
});

export const SITUATION_REDUCER = 'SITUATION_REDUCER';

export type RachatsItem = {
  mntECh: string;
  mntCRD: string;
  idSimulation: string;
  numDossRachat: string;
  checked: boolean;
};

export type AffectRachatItem = {
  idSimulation: string;
  numDossRachat: string;
};

export interface SituationStateModel {
  situationPro: {
    errors: ValidateJsError;
  };
  situationSalarie: {
    errors: ValidateJsError;
    listRachats: RachatsItem[];
  };
}

export type SituationState = StoreOf<SituationStateModel>;

export type SituationActions =
  | UpdateFieldAction
  | SubmitSituationErrorsAction
  | SubmitSituationSalarieErrorsAction
  | UpdateLoaderAction
  | GetConfigActionSuccess
  | UpdateStepAct
  | InitSituationATAction;

export function SituationReducer(
  state = initState,
  action: SituationActions,
): SituationState {
  switch (action.type) {
    case SituationAT.updateField:
      return state
        .setIn([action.step, 'fields', action.name], fromJS(action.value))
        .removeIn([action.step, 'errors', action.name]);
    case SituationAT.submitSituationError:
      return state.setIn(['situationSalarie', 'errors'], fromJS(action.errors));
    case SituationAT.getConfigSuccess:
      return state.setIn(
        ['situationSalarie', 'config', action.nature],
        fromJS(action.list),
      );
    case SituationAT.submitSituationSalarieError:
      return state.setIn(['situationSalarie', 'errors'], fromJS(action.errors));
    case SituationAT.updateLoader:
      return state.setIn(
        ['situationSalarie', 'config', 'loader'],
        fromJS(action.value),
      );
    case SituationAT.updateStep:
      return state.setIn([action.step, 'fields'], fromJS(action.value));
    case SituationAT.initSituationAT:
      return resetState;
    default:
      return state;
  }
}
