export enum CaseTrackingAT {
  initCaseTrackingAT = 'CASETRACKINGAT/INITCASETRACKINGAT',
  updateFieldCase = 'CASETRACKINGAT/UPDATEFIELDCASE',
}

export interface InitCaseTrackingATAction {
  type: CaseTrackingAT.initCaseTrackingAT;
}

export interface UpdateCaseAction {
  type: CaseTrackingAT.updateFieldCase;
  step: string;
  name: string;
  value: string;
}

export const updateFieldCaseAction = (
  step: string,
  name: string,
  value: string,
): UpdateCaseAction => ({
  type: CaseTrackingAT.updateFieldCase,
  step,
  name,
  value,
});
