import { fromJS } from 'immutable';
import { StoreOf } from '../../core/root.model';
import {
  SubmitDocumentsAction,
  SubmitDocumentErrorsAction,
  GetDocumentsAction,
  DeleteDocumentSuccessAction,
  UpdateDocumentInfoAction,
  UpdateDocumentsAction,
  UploadDocumentAT,
  UpdateFieldUpAction,
  InitUploadDocumentATAction,
} from './actions.upload';
import { getSessionStorage } from 'common/utils/sessionStorage.util';

export const UPLOAD_REDUCER = 'UPLOAD_REDUCER';
export interface UploadStateModel {
  upload: {
    documents: {};
    errors: {};
  };
}
export type UploadState = StoreOf<UploadStateModel>;

const type = getSessionStorage('typeclient');
const product = getSessionStorage('product');
const recap: any = getSessionStorage('recap');

export const initState: UploadState = fromJS({
  upload: {
    type: type ? type : '',
    product: product ? product : '',
    idSimulation: recap ? recap.idSimulation : '',
    documents: {},
    errors: {},
  },
});

export const resetState: UploadState = fromJS({
  upload: {
    type: '',
    product: '',
    idSimulation: '',
    documents: {},
    errors: {},
  },
});

export type UploadActions =
  | GetDocumentsAction
  | SubmitDocumentsAction
  | DeleteDocumentSuccessAction
  | UpdateDocumentInfoAction
  | UpdateDocumentsAction
  | SubmitDocumentErrorsAction
  | UpdateFieldUpAction
  | InitUploadDocumentATAction;

export function uploadDocumentReducer(
  state = initState,
  action: UploadActions,
): UploadState {
  switch (action.type) {
    case UploadDocumentAT.updateDocumentInfo:
      return state.setIn(
        ['upload', 'documents', fromJS(action.label), action.key],
        action.value,
      );
    case UploadDocumentAT.updateDocuments:
      return state.setIn(['upload', 'documents'], fromJS(action.value));
    case UploadDocumentAT.updateField:
      return state.setIn(['upload', action.name], fromJS(action.value));
    case UploadDocumentAT.deleteDocumentSuccess:
      return state.removeIn(['upload', 'documents', fromJS(action.label)]);
    case UploadDocumentAT.submitDocumentError:
      return state.setIn(['upload', 'errors'], fromJS(action.error));
    case UploadDocumentAT.initUploadDocumentAT:
      return resetState;
    default:
      return state;
  }
}
