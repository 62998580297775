import React, { useEffect } from 'react';
import ContextualLayout from './components/ContextualLayout';
import MainView from './components/MainView';
import rachatCreditImage from 'assets/svg/rachatCredit.svg';
import HowItWorks from './components/HowItWorks';
import BlocTitle from './components/BlocTitle';
import TextBloc from './components/TextBloc';
import WhyRachatCredit from './components/WhyRachatCredit';
import { Wording } from 'common/wording/reducer.wording';
import { useSelector } from 'react-redux';
import { selectCompWording } from 'common/wording/selectors.wording';
import Seo from 'common/components/seo/Seo';
import { pushPageView } from 'features/auth/constants';

function RachatCredit() {
  const wording: Wording = useSelector(selectCompWording('contextual'));

  useEffect(() => {
    pushPageView('rachat_de_credit', 'showcase');
  }, []);

  return (
    <ContextualLayout>
      <>
        <Seo
          keywordsKey='keywords_33'
          titleKey='title_33'
          descriptionKey='description_33'
        />
        <MainView
          title={wording?.title_rachat}
          description={wording?.description_rachat}
          image={rachatCreditImage}
        />
        <BlocTitle title={wording?.title_whatis_rachat} />
        <TextBloc text={wording?.description_whatis_rachat} />
        <WhyRachatCredit />
        <HowItWorks />
      </>
    </ContextualLayout>
  );
}

export default RachatCredit;
