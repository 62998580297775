import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { GlobalTheme } from 'features/app/app.theme';
import { ListItem, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { getSimulationInfoByArraySelector } from 'features/home/selector.simulation';
import { LANG_STATE } from 'features/home/constants.simulation';

const useStyles = makeStyles((theme: GlobalTheme) => ({
  listStyle: {
    zIndex: 1,
    fontFamily: theme.fonts.medium,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '29%',
    height: 'auto',
    minHeight: '140px',
    backgroundColor: '#FFF',
    borderRadius: '25px',
    padding: '34px 22px',
    marginBottom: '60px',
    '@media (max-width: 1024px)': {
      margin: '10px auto',
      width: '48%',
    },
    '@media (max-width: 768px)': {
      width: '100%',
      margin: '8px auto',
      minHeight: 'auto',
      padding: '18px',
    },
  },
  contentStyle: {
    color: theme.primary.title,
    fontFamily: theme.fonts.medium,
    margin: 'auto',
    '@media (max-width: 768px)': {
      fontSize: '0.8125rem',
    },
  },
  contentStyleAr: {
    marginTop: '25px',
    color: theme.primary.title,
    fontFamily: theme.fonts.medium,
    fontWeight: 600,
    '@media (max-width: 768px)': {
      marginTop: '10px',
      fontSize: '0.8125rem',
    },
  },
  numberStyle: {
    color: theme.primary.main,
    fontFamily: theme.fonts.medium,
    fontSize: '2.5rem',
  },
  img: {
    maxWidth: '45px',
    maxHeight: '50px',
    margin: '15px',
  },
  contentBody: {
    textAlign: 'left',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

interface ElementDetails {
  content: string;
  icon: string;
  className?: any;
}

const ParcoursElement = (props: ElementDetails) => {
  const { content, icon, className } = props;
  const classes = useStyles();
  const langSelected: any =
    useSelector(getSimulationInfoByArraySelector(['lang'])) || LANG_STATE.FR;
  return (
    <ListItem className={`${classes.listStyle} ${className}`}>
      <div className={classes.contentBody}>
        <Typography
          variant='h5'
          className={`${
            langSelected === LANG_STATE.FR
              ? classes.contentStyle
              : classes.contentStyleAr
          }`}
        >
          {content}
        </Typography>
        <img src={icon} alt={'icon'} className={classes.img} />
      </div>
    </ListItem>
  );
};
export default ParcoursElement;
