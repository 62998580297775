export const documents = {
  CIN: {
    numberOfFiles: 0,
    loader: false,
  },
  CP: {
    numberOfFiles: 0,
    loader: false,
  },
  RC: {
    numberOfFiles: 0,
    loader: false,
  },
  JPP: {
    numberOfFiles: 0,
    loader: false,
  },
  DRC: {
    numberOfFiles: 0,
    loader: false,
  },
  DJP: {
    numberOfFiles: 0,
    loader: false,
  },
  B_3DA: {
    numberOfFiles: 0,
    loader: false,
  },
  B_3DADC: {
    numberOfFiles: 0,
    loader: false,
  },
  ECRC: {
    numberOfFiles: 0,
    loader: false,
  },
};

export const ALLOWED_FILE_EXTENSIONS = ['jpg', 'jpeg', 'png', 'pdf'];
