import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GlobalTheme } from 'features/app/app.theme';
import React from 'react';
import { toUpper, trim } from 'lodash';
import { useSelector } from 'react-redux';
import { getSimulationInfoByArraySelector } from 'features/home/selector.simulation';
import { LANG_STATE } from 'features/home/constants.simulation';

const useStyles = makeStyles((theme: GlobalTheme) => ({
  root: {
    display: 'flex',
    marginTop: '20px',
    alignItems: 'center',
    width: '100%',
  },
  label: {
    width: '100%',
    maxWidth: 'max-content',
    fontFamily: theme.fonts.medium,
    textAlign: 'left',
    '@media (max-width: 450px)': {
      fontSize: '0.8125rem',
    },
  },
  separator: {
    display: 'block',
    height: '0.1px',
    border: '0',
    borderTop: '0.063em solid #cbd1dc !important',
    margin: '17px',
    width: '70%',
    padding: '0',
    flexGrow: 1,
    flex: '1 1 auto',
  },

  valueContainer: {
    width: '100%',
    maxWidth: 'max-content',
    '@media (max-width: 450px)': {
      fontSize: '0.8125rem',
      textAlign: 'right',
    },
  },
  valueContainerinLine: {
    width: '100%',
    maxWidth: 'max-content',
    color: 'rgb(249, 50, 118)',
    fontSize: '1.5rem',
    '@media (max-width: 450px)': {
      fontSize: '0.9375rem',
      textAlign: 'right',
    },
  },
  value: {
    fontWeight: 'bold',
    marginLeft: '5px',
    marginRight: '5px',
  },
  unit: {
    fontFamily: theme.fonts.medium,
    marginLeft: '0.1em',
    marginRight: '0.1em',
  },
}));

interface SimulationDetailLineProps {
  label: string;
  value: string | number;
  unit: string;
  show?: boolean;
  inline?: boolean;
}

export function SimulationDetailLine({
  label,
  value,
  unit = '',
  show = true,
  inline = false,
}: SimulationDetailLineProps) {
  const classes = useStyles();
  const langSelected: any =
    useSelector(getSimulationInfoByArraySelector(['lang'])) || LANG_STATE.FR;
  return (
    <div className={classes.root}>
      <Typography variant='h5' className={classes.label}>
        <div dangerouslySetInnerHTML={{ __html: label }} />
      </Typography>
      <div
        className={classes.separator}
        style={!show ? { borderTop: '0.063em solid #fff' } : {}}
      />

      <Typography
        variant='h5'
        className={
          !inline ? classes.valueContainer : classes.valueContainerinLine
        }
      >
        {langSelected === LANG_STATE.FR &&
          (toUpper(trim(unit)) === toUpper(trim('Dhs')) ||
          toUpper(trim(unit)) === toUpper(trim('Dhs/Mois')) ? (
            <label dir='ltr' className={classes.unit}>
              {value &&
                value.toString().replace(/(?=(?!\b)(\d{3})+\.?\b)/g, ' ')}
            </label>
          ) : (
            <label dir='ltr' className={classes.unit}>
              {value}
            </label>
          ))}
        {langSelected === LANG_STATE.AR &&
          (toUpper(trim(unit)) === toUpper(trim('درهم')) ||
          toUpper(trim(unit)) === toUpper(trim('درهم / شهر')) ? (
            <label dir='ltr' className={classes.unit}>
              {value &&
                value.toString().replace(/(?=(?!\b)(\d{3})+\.?\b)/g, ' ')}
            </label>
          ) : (
            <label dir='ltr' className={classes.unit}>
              {value}
            </label>
          ))}
        <label className={classes.unit}>{unit}</label>
      </Typography>
    </div>
  );
}
