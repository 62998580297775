export enum RecapAT {
  initRecapAT = 'RECAPAT/INITRECAPAT',
  submitRecap = 'RECAPAT/SUBMITRECAP',
  updateRecap = 'RECAPAT/UPDATERECAP',
  changeInformationRecap = 'RECAPAT/CHANGEINFORMATIONRECAP',
  updateRecapElement = 'updateRecapElement',
}

export interface InitRecapATAction {
  type: RecapAT.initRecapAT;
}

export interface SubmitRecapAction {
  type: RecapAT.submitRecap;
}

export interface UpdateRecapAction {
  type: RecapAT.updateRecap;
  data: any;
}

export interface ChangeInformationRecapAction {
  type: RecapAT.changeInformationRecap;
}

export interface UpdateRecapElement {
  type: RecapAT.updateRecapElement;
  step: string;
  name: string;
  value: any;
}

export const updateElementRecap = (
  step: string,
  name: string,
  value: any,
): UpdateRecapElement => ({
  type: RecapAT.updateRecapElement,
  step,
  name,
  value,
});
