import React, { useCallback, useEffect, useState } from 'react';
import { Button, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { CustomInput } from 'common/components/customFields/CustomInput';
import { Wording } from 'common/wording/reducer.wording';
import { GlobalTheme } from 'features/app/app.theme';
import { useDispatch, useSelector } from 'react-redux';
import { updateFieldSignInAction, AuthAT } from '../actions.auth';
import { getAuthInfoByArraySelector } from '../selector.auth';
import { signInFields } from '../constants';
import { GlobalError } from 'common/components/customFields/GlobalError';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import {
  getSessionStorage,
  removeSessionStorage,
} from 'common/utils/sessionStorage.util';
import {
  selectCompWording,
  selectWording,
} from 'common/wording/selectors.wording';
import Background from 'assets/images/bgSignIn.png';
import BackgroundSignMobile from 'assets/images/bgLogoPaged.png';
import logo from 'assets/images/logo.png';
import Footer from 'common/components/Footer';
import BackgroundRightContainer from 'assets/images/BackgroundConfirmationMail.png';
import { CustomRadioGroup } from 'common/components/customFields/CustomRadioGroup';
import { useHistory } from 'react-router-dom';
import logoCreditDaba from 'assets/svg/logoCreditDaba.svg';
import Spinner from 'common/components/Spinner';
import Seo from 'common/components/seo/Seo';
import { getSimulationInfoByArraySelector } from 'features/home/selector.simulation';
import { LANG_STATE } from 'features/home/constants.simulation';
import ReCaptcha from 'common/components/reCaptcha';

const useStyles = makeStyles((theme: GlobalTheme) =>
  createStyles({
    form: {
      margin: 'auto',
      borderRadius: '22px',
      backgroundImage: `url(${BackgroundRightContainer})`,
      '@media (min-width: 450px)': {
        width: '60vw',
        maxWidth: '600px',
        boxSizing: 'borderBox',
      },
    },
    formHeader: {
      padding: '10% 20% ',
      maxWidth: '100%',
      alignItems: 'center',
      height: '76px',
      fontSize: '1.55rem',
      justifyContent: 'center',
      '@media (max-width: 450px)': {
        padding: '60px 20px 10px',
      },
    },
    formContent: {
      padding: '10px 97px 60px',
      '@media (max-width: 1024px)': {
        padding: '30px 68px',
      },
      '@media (max-width: 450px)': {
        padding: '5px 30px 16px',
      },
    },
    title: {
      textTransform: 'uppercase',
      marginBottom: '30px',
      textAlign: 'center',
    },
    titleAr: {
      fontWeight: 'bold',
      textTransform: 'uppercase',
      marginBottom: '30px',
      textAlign: 'center',
    },
    titleChoise: {
      fontSize: '1.125rem',
      textAlign: 'center',
    },

    root: {
      minHeight: '100vh',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      backgroundImage: `url(${Background})`,
      backgroundSize: 'cover',
      '& .MuiButton-contained': {
        '&:hover': {
          boxShadow: 'unset',
          backgroundColor: 'unset',
        },
      },
      '@media (max-width: 450px)': {
        backgroundPosition: 'center',
        backgroundImage: `url(${BackgroundSignMobile})`,
        backgroundPositionX: '40%',
      },
    },
    logoBOA: {
      paddingLeft: 25,
      paddingTop: 12,
      display: 'block',
      width: '180px',
    },
    groupFormFooter: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    radioGroup: {
      minWidth: '100%',
      justifyContent: 'space-around',
      '& .MuiTypography-root': {
        fontSize: '0.8125rem',
        color: theme.primary.title,
      },
      '@media (max-width: 450px)': {
        justifyContent: 'left',
        marginLeft: '10px',
      },
    },
    buttonSubmit: {
      borderRadius: '14px 0',
      textTransform: 'inherit',
      padding: '14px 50px',
      fontFamily: theme.fonts.regular,
      fontSize: '0.875rem',
      backgroundColor: `${theme.primary.main} !important`,
      '& .MuiButton-containedPrimary': {
        '&:hover': {
          backgroundColor: `${theme.primary.main} !important`,
        },
      },
      '@media (max-width: 450px)': {
        padding: '14px 15px',
        fontSize: '0.75rem',
        width: '50%',
      },
    },
    buttonSubmitDisabled: {
      borderRadius: '14px 0',
      textTransform: 'inherit',
      padding: '14px 50px',
      fontFamily: theme.fonts.regular,
      fontSize: '0.875rem',
      backgroundColor: `${theme.primary.gray} !important`,
      '& .MuiButton-containedPrimary': {
        '&:hover': {
          backgroundColor: `${theme.primary.gray} !important`,
        },
      },
      '@media (max-width: 450px)': {
        padding: '14px 15px',
        fontSize: '0.75rem',
        width: '50%',
      },
    },
    link: {
      padding: '0 26px',
      color: theme.secondary.title,
      fontSize: '0.9375rem',
      textDecoration: 'none',
      '@media (max-width: 450px)': {
        padding: '0 10px',
        fontSize: '0.75rem',
      },
    },
    logoCreditConso: {
      display: 'block',
      margin: '40px auto 12px',
      width: '450px',
      maxWidth: '85%',
    },
    globalContainer: {
      maxWidth: 662,
      margin: 'auto',
      flexDirection: 'column',
      alignItems: 'center',
      '@media (max-width: 1024px)': {
        width: '75%',
        textAlign: 'center',
      },
      '@media (max-width: 768px)': {
        width: '85%',
      },
    },
    footer: {
      width: '100%',
      '@media (max-width: 1024px)': {
        textAlign: 'center',
      },
    },
    inputs: {
      marginTop: '35px',
      '@media (max-width: 450px)': {
        padding: '0',
        marginTop: '64px',
      },
    },
    msgPasswordOublieContainer: {
      display: 'flex',
      backgroundColor: '#DBE2ED',
      alignItems: 'center',
    },

    msgPasswordOublieIconContainer: {
      height: '75px',
    },

    msgPasswordOublieText: {
      textAlign: 'center',
      maxWidth: 420,
    },
    msgPasswordOublieTextContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    reCaptcha: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
    logoButton: {
      backgroundColor: 'transparent',
      alignSelf: 'flex-start',
    },
    logoButtonAr: {
      backgroundColor: 'transparent',
      alignSelf: 'flex-end',
    },
  }),
);

const SignInForm = (props: any) => {
  const signIn: any = useSelector(getAuthInfoByArraySelector(['signIn']));
  const signInWording: Wording = useSelector(selectCompWording('signIn'));
  const errorsWording: Wording = useSelector(selectCompWording('errors'));
  const wording: Wording = useSelector(selectWording);
  const langSelected: any =
    useSelector(getSimulationInfoByArraySelector(['lang'])) || LANG_STATE.FR;

  const listOfConnexionChoices = [
    {
      label: signInWording?.signInByBmceDirect,
      value: 'signInByBmceDirect',
    },
    {
      label: signInWording?.signInByEmail,
      value: 'signInByEmail',
    },
  ];

  // recaptcha
  const { executeRecaptcha } = useGoogleReCaptcha();
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return null;
    }

    const token = await executeRecaptcha();
    console.log('recaptcha token ', token);
    return token;
    // Do whatever you want with the token
  }, [executeRecaptcha]);

  // const [isHuman, setIsHuman] = useState('');
  const [seconds, setSeconds] = useState(0);
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const errors: any = useSelector(
    getAuthInfoByArraySelector(['signIn', 'errors']),
  );

  const toHomePage = () => history.push('/credit-conso');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateFieldSignInAction('signIn', e.target.name, e.target.value));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const token = await handleReCaptchaVerify();

    if (signIn.bmceDirect === 'signInByBmceDirect') {
      dispatch({ type: AuthAT.submitSignIn, reCAPTCHA: token });
    } else {
      dispatch({ type: AuthAT.submitGenerate, reCAPTCHA: token });
    }
  };

  useEffect(() => {
    const listener = (event: any) => {
      if (
        event.code === 'Enter' ||
        event.code === 13 ||
        event.code === '13' ||
        event.code === 'NumpadEnter' ||
        event.keyCode === 13 ||
        event.keyCode === '13'
      ) {
        // if (isHuman) handleSubmit(event);
        handleSubmit(event);
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const timer: any = getSessionStorage('timer') || 0;

  useEffect(() => {
    setSeconds(timer);
  }, [timer]);

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    }
    if (seconds === 0) {
      removeSessionStorage('timer');
    }
  }, [seconds]);

  return (
    <>
      <Seo titleKey='title_31' descriptionKey='description_31'>
        <meta name='robots' content='noindex, nofollow' />
      </Seo>
      <Spinner loader={signIn.loader} />
      <div className={classes.root}>
        <Button
          variant='contained'
          type='button'
          onClick={toHomePage}
          className={
            langSelected === LANG_STATE.FR
              ? classes.logoButton
              : classes.logoButtonAr
          }
        >
          <img
            className={classes.logoBOA}
            alt={wording?.alt.logo_BOA}
            src={logo}
          />
        </Button>
        <img
          className={classes.logoCreditConso}
          alt={wording?.alt.logo_CD}
          src={logoCreditDaba}
        />
        <div className={classes.globalContainer}>
          <form
            className={classes.form}
            noValidate
            autoComplete='off'
            onSubmit={handleSubmit}
          >
            <div className={classes.formHeader}>
              <Typography
                variant='h3'
                className={
                  langSelected === LANG_STATE.FR
                    ? classes.title
                    : classes.titleAr
                }
              >
                {signInWording?.signIn}
              </Typography>
              <Typography variant='h3' className={classes.titleChoise}>
                {signInWording?.choiseConnexion}
              </Typography>
            </div>

            {errors && errors.global && (
              <GlobalError
                globalError={errors.global}
                errorWording={errorsWording}
              />
            )}

            <div className={classes.formContent}>
              <CustomRadioGroup
                className={classes.radioGroup}
                name={signInFields.bmceDirect}
                onChange={handleChange}
                value={signIn.bmceDirect}
                listOfElements={listOfConnexionChoices}
                error=''
              />
              <div className={classes.inputs}>
                {signIn.bmceDirect === 'signInByBmceDirect' ? (
                  <>
                    <CustomInput
                      type='text'
                      value={signIn.login}
                      name={signInFields.login}
                      onKeyDown={(evt) =>
                        evt.keyCode === 69 && evt.preventDefault()
                      }
                      onChange={handleChange}
                      placeholder={signInWording?.secondIdentityBmceDirect}
                      formLabel={signInWording?.secondIdentityBmceDirect}
                      error={
                        errors[signInFields.login]
                          ? errorsWording[errors[signInFields.login]]
                          : null
                      }
                    />
                    <CustomInput
                      value={signIn.password}
                      type='password'
                      name={signInFields.password}
                      onChange={handleChange}
                      placeholder={signInWording?.pawd}
                      formLabel={signInWording?.pawd}
                      error={
                        errors[signInFields.password]
                          ? errorsWording[errors[signInFields.password]]
                          : null
                      }
                    />
                  </>
                ) : (
                  <CustomInput
                    value={signIn.email}
                    name={signInFields.email}
                    type='text'
                    onChange={handleChange}
                    formLabel={signInWording?.emailAdress}
                    placeholder={signInWording?.emailAdress}
                    error={
                      errors[signInFields.email]
                        ? errorsWording[errors[signInFields.email]]
                        : null
                    }
                  />
                )}
              </div>
              <div className={classes.reCaptcha}>
                <ReCaptcha />
              </div>
            </div>
            <div className={classes.groupFormFooter}>
              {signIn.bmceDirect === 'signInByEmail' ? (
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  className={
                    seconds === 0
                      ? classes.buttonSubmit
                      : classes.buttonSubmitDisabled
                  }
                  disabled={seconds > 0}
                >
                  {signInWording?.generateCodeAcces}{' '}
                  {seconds !== 0 && `(${seconds})`}
                </Button>
              ) : (
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  className={classes.buttonSubmit}
                  // disabled={isEmpty(isHuman)}
                >
                  {signInWording?.connexion}
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>

      <div className={classes.footer}>
        <Footer />
      </div>
    </>
  );
};

export default SignInForm;
