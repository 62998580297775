import { makeStyles } from '@material-ui/core/styles';
import { GlobalTheme } from 'features/app/app.theme';
import { Wording } from 'common/wording/reducer.wording';
import { useSelector } from 'react-redux';
import { selectWording } from 'common/wording/selectors.wording';
import React from 'react';
import firstNeed from 'assets/images/firstNeed.png';
import secondNeed from 'assets/images/secondNeed.png';
import thirdNeed from 'assets/images/thirdNeed.png';
import lastNeed from 'assets/images/lastNeed.png';
import { getSimulationInfoByArraySelector } from 'features/home/selector.simulation';
import { LANG_STATE } from 'features/home/constants.simulation';

const useStyles = makeStyles((theme: GlobalTheme) => ({
  listStyle: ({ props }: any) => ({
    backgroundImage: `url(${
      (props.img === 'firstNeed' && firstNeed) ||
      (props.img === 'secondNeed' && secondNeed) ||
      (props.img === 'thirdNeed' && thirdNeed) ||
      (props.img === 'lastNeed' && lastNeed)
    })`,
    backgroundSize: 'cover',
    flex: '0 0 auto',
    width: '304px',
    height: '360px',
    marginRight: '25px',
    borderRadius: '52px 32px',
  }),
  titleStyle: {
    textAlign: 'left',
    fontSize: '1.125rem',
  },
  subTitleStyle: {
    textAlign: 'left',
    fontFamily: theme.fonts.bold,
    color: theme.primary.title,
  },
  img: {
    width: '247px',
    padding: '15px',
    borderRadius: '45px',
    '@media (max-width: 1520px)': {
      width: '180px',
    },
    '@media (max-width: 1280px)': {
      width: '125px',
      padding: '10px',
    },
    '@media (max-width: 768px)': {
      width: '108px',
      padding: '6px',
    },
    '@media (max-width: 600px)': {
      width: '304px',
      height: '360px',
    },
  },
  imgAr: {
    width: '247px',
    padding: '15px',
    borderRadius: '45px',
    transform: 'scaleX(-1)',
    '@media (max-width: 1520px)': {
      width: '180px',
    },
    '@media (max-width: 1280px)': {
      width: '125px',
      padding: '10px',
    },
    '@media (max-width: 768px)': {
      width: '108px',
      padding: '6px',
    },
    '@media (max-width: 600px)': {
      width: '304px',
      height: '360px',
    },
  },
  bodyContent: {
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 600px)': {
      flexDirection: 'unset',
    },
  },
}));

interface ElementDetails {
  firstImg: string;
  secondImg: string;
}

const NeedImgRightElement = (props: ElementDetails) => {
  const { firstImg, secondImg } = props;
  const classes = useStyles({ props });
  const wording: Wording = useSelector(selectWording);
  const langSelected: any =
    useSelector(getSimulationInfoByArraySelector(['lang'])) || LANG_STATE.FR;

  return (
    <div className={classes.bodyContent}>
      {(firstImg === 'firstNeed' || secondImg === 'firstNeed') && (
        <img
          src={firstNeed}
          className={
            langSelected === LANG_STATE.FR ? classes.img : classes.imgAr
          }
          alt={wording?.alt.family_home}
        />
      )}
      {(firstImg === 'secondNeed' || secondImg === 'secondNeed') && (
        <img
          src={secondNeed}
          className={
            langSelected === LANG_STATE.FR ? classes.img : classes.imgAr
          }
          alt={wording?.alt.arrange_home}
        />
      )}
      {(firstImg === 'thirdNeed' || secondImg === 'thirdNeed') && (
        <img
          src={thirdNeed}
          className={
            langSelected === LANG_STATE.FR ? classes.img : classes.imgAr
          }
          alt={wording?.alt.holiday_home}
        />
      )}
      {(firstImg === 'lastNeed' || secondImg === 'lastNeed') && (
        <img
          src={lastNeed}
          className={
            langSelected === LANG_STATE.FR ? classes.img : classes.imgAr
          }
          alt={wording?.alt.bay_home}
        />
      )}
    </div>
  );
};
export default NeedImgRightElement;
