import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import CustomRadioUnchecked from 'assets/svg/custom-radio-unchecked.svg';
import CheckCircle from 'assets/svg/check-circle.svg';
import { GlobalTheme } from 'features/app/app.theme';
import React from 'react';
import { Wording } from 'common/wording/reducer.wording';
import { useSelector } from 'react-redux';
import { selectWording } from 'common/wording/selectors.wording';

const useStyles = makeStyles((theme: GlobalTheme) =>
  createStyles({
    radioGroupWrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      height: 'max-content',
      margin: '8px 0',
      '@media (max-width: 768px)': {
        flexWrap: 'wrap',
        margin: '0',
      },
    },
    formLabel: {
      color: theme.secondary.title,
    },
    root: {
      border: ' 1px solid #cfcfcf',
      borderRadius: '15px',
      height: '35px',
      marginRight: '0',
      marginLeft: '0',
      flexDirection: 'row-reverse',
      padding: '0 10px',
      textAlign: 'left',
      '@media (max-width: 768px)': {
        width: '100%',
        margin: '5px 0',
      },

      '& > span': {
        padding: '6px',
      },
    },
    label: {
      color: theme.secondary.title,
      fontFamily: theme.fonts.medium,
      width: '100%',
      fontSize: '0.75rem',
    },
    iconRadioButton: {
      width: '22px',
    },
    spacer: {
      columnGap: '10px',
    },
  }),
);

interface PropsCustomRadioGroup {
  label?: string;
  value?: string;
  name: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string | null;
  type?: string;
  placeholder?: string;
  row?: boolean;
  listOfElements: { value: any }[];
  wording?: any;
  className?: any;
}

export const CreditTypeSelect = ({
  value = '',
  onChange = () => ({}),
  error = null,
  label = '',
  name = '',
  listOfElements = [],
  wording,
  row = true,
  className,
  ...rest
}: PropsCustomRadioGroup) => {
  const classes = useStyles({ row });
  const selectorWording: Wording = useSelector(selectWording);
  return (
    <FormControl error={!!error}>
      <div className={classes.radioGroupWrapper}>
        <FormLabel className={classes.formLabel} component='legend'>
          {label}
        </FormLabel>
        <RadioGroup
          row
          aria-label='position'
          name={name}
          onChange={onChange}
          value={value}
          {...rest}
          classes={{ root: classes.spacer }}
        >
          {listOfElements.map((element: any) => (
            <FormControlLabel
              key={element.value}
              value={element.value}
              classes={{ root: classes.root, label: classes.label }}
              style={{
                border:
                  element.value === value
                    ? '2px solid #095098'
                    : '1px solid #cfcfcf',
              }}
              control={
                <Radio
                  size={'small'}
                  icon={
                    <img
                      className={classes.iconRadioButton}
                      src={CustomRadioUnchecked}
                      alt={selectorWording?.alt?.select}
                    />
                  }
                  checkedIcon={
                    <img
                      className={classes.iconRadioButton}
                      src={CheckCircle}
                      alt={selectorWording?.alt?.select}
                    />
                  }
                />
              }
              label={
                isEmpty(wording)
                  ? element.label
                  : element.value === 'N'
                  ? wording?.conso
                  : wording?.rachat
              }
            />
          ))}
        </RadioGroup>
      </div>

      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};
