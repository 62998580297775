import { makeStyles } from '@material-ui/core/styles';
import BackgroundRightContainer from 'assets/images/BackgroundConfirmationMail.png';
import { Wording } from 'common/wording/reducer.wording';
import React from 'react';
import Footer from '../Footer';
import LogoPage from './LogoPage';
import Logout from './Logout';
import { useSelector } from 'react-redux';
import { getAuthInfoByArraySelector } from 'features/auth/selector.auth';
import Spinner from 'common/components/Spinner';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundImage: `url(${BackgroundRightContainer})`,
    backgroundSize: 'cover',
  },
  globalContainer: {
    minHeight: '100vh',
    width: '100%',
    display: 'flex',
    '@media (max-width: 980px)': {
      flexDirection: 'column',
      textAlign: 'center',
    },
  },
  footer: {
    width: '100%',
    '@media (max-width: 1024px)': {
      width: '100%',
      textAlign: 'center',
    },
  },
  logout: {
    display: 'flex',
    justifyContent: 'flex-end',
    '@media (max-width: 980px)': {
      display: 'none',
    },
  },
  rightContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '56%',
    margin: '0 2%',
    '@media (max-width: 980px)': {
      width: '92%',
      margin: '0 auto',
    },
  },
}));

interface Props {
  wording: Wording;
  children: React.ReactNode;
}

function AppLayout({ wording, children }: Props) {
  const signIn: any = useSelector(getAuthInfoByArraySelector(['signIn']));
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Spinner loader={signIn.loader} />
      <div className={classes.globalContainer} style={{ direction: 'ltr' }}>
        <LogoPage wording={wording?.logoPage} />
        <div className={classes.rightContent}>
          <div className={classes.logout}>
            <Logout showIcon />
          </div>
          {children}
        </div>
      </div>
      <div className={classes.footer}>
        <Footer />
      </div>
    </div>
  );
}

export default AppLayout;
