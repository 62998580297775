import { createStyles, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GlobalTheme } from 'features/app/app.theme';
import React from 'react';
import { ValidateJsError } from '../../../features/auth/actions.auth';
import { Wording } from '../../wording/reducer.wording';
import errorUpload from 'assets/svg/errorUpload.svg';
import { Link } from 'react-router-dom';

const usestyles = makeStyles((theme: GlobalTheme) =>
  createStyles({
    label: {
      color: 'red',
      fontSize: '0.8125rem',
    },
    iconErrorUp: {
      width: '43px',
    },
    errorMessage: {
      padding: '5px 64px 5px 10px',
      textAlign: 'center',
      fontSize: '0.9375rem',
      margin: 'auto',
      width: 'max-content',
      color: theme.secondary.title,
      fontFamily: theme.fonts.regular,
    },
    error: {
      backgroundColor: '#F932761A',
      width: '100%',
      display: 'flex',
      marginTop: '20px',
      marginBottom: '20px',
    },
  }),
);

interface Props {
  globalError?: ValidateJsError | string[] | Error | undefined;
  errorWording?: Wording;
  linkContact?: string;
}

export function GlobalError(props: Props) {
  const classes = usestyles();
  const { globalError, errorWording, linkContact } = props;
  const errors: any = globalError;
  if (!errors || !(errors.length > 0) || !errorWording) {
    return null;
  }
  return (
    <div id='global-error' className={classes.error}>
      <img src={errorUpload} alt='' className={classes.iconErrorUp} />
      <Typography variant={'subtitle2'} className={classes.errorMessage}>
        {errorWording[errors[0]]}
        {linkContact && (
          <Link style={{ color: 'rgb(249, 50, 118)' }} to='/sign-in'>
            {linkContact}
          </Link>
        )}
      </Typography>
    </div>
  );
}
