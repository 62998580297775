import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { GlobalTheme } from 'features/app/app.theme';

const useStyles = makeStyles((theme: GlobalTheme) => ({
  root: {
    height: 'auto',
    padding: '20px 15px',
    display: 'flex',
    borderRadius: '18px',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '0 5px',
    '@media (max-width: 768px)': {
      width: '36%!important',
      height: 'auto',
    },
  },
  title: {
    fontSize: '0.77rem',
    fontFamily: theme.fonts.regular,
    '@media (max-width: 768px)': {
      fontSize: '0.75rem',
    },
  },
  subTitle: {
    fontSize: '0.6rem',
    '@media (max-width: 768px)': {
      fontSize: '0.6rem',
    },
  },
}));

interface PropsLoginHomePage {
  title: string;
  subTitle: string;
  width: string;
  className?: any;
}

function CercleElement(props: PropsLoginHomePage) {
  const classes = useStyles();
  const { className, title, subTitle, width } = props;
  return (
    <div
      className={`${classes.root} ${className?.classes?.backgroundCercle} ${className?.classes?.reverse}`}
      style={{ width: width }}
    >
      <Typography
        variant='h4'
        className={` ${classes.title} ${className?.classes?.textColorCercle}`}
      >
        {title}
      </Typography>
      <Typography
        variant='h5'
        className={` ${classes.subTitle} ${className?.classes?.textColorCercle}`}
      >
        {subTitle}
      </Typography>
    </div>
  );
}

export default CercleElement;
