import validate from 'validate.js';
import { REQUIRED, INVALID_DATE_FORMAT } from './contants';

export const validateRib = (
  ribSecondContent: any,
  ribThirdContent: any,
  ribLastContent: any,
) => {
  if (ribSecondContent?.length !== 3) {
    return { ribSecondContent: [REQUIRED] };
  }
  if (ribThirdContent?.length !== 15) {
    return { ribThirdContent: [REQUIRED] };
  }
  if (ribLastContent?.length !== 2) {
    return { ribLastContent: [REQUIRED] };
  }
  return null;
};

export const costumValidate = (
  data: any,
  constraint: any,
  options: any = null,
) => {
  const customOptions = {
    fullMessages: false,
    ...options,
  };
  const result = validate(data, constraint, customOptions);
  return result;
};

validate.validators.customPresence = (
  value: any,
  options: any,
  key: any,
  attributes: any,
) => {
  const keys = options.keys;
  if (attributes[keys[0]] === keys[1] && !value) {
    return [REQUIRED];
  }
  return null;
};

validate.validators.dateValidator = (value: string) => {
  if (value === 'Invalid Date') {
    return [INVALID_DATE_FORMAT];
  }
  return null;
};
