import { makeStyles } from '@material-ui/core/styles';
import { GlobalTheme } from 'features/app/app.theme';
import React from 'react';
import { Typography } from '@material-ui/core';
import firstNeed from 'assets/images/firstNeed.png';
import secondNeed from 'assets/images/secondNeed.png';
import thirdNeed from 'assets/images/thirdNeed.png';
import lastNeed from 'assets/images/lastNeed.png';
import check from 'assets/svg/check.svg';
import { selectCompWording } from 'common/wording/selectors.wording';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme: GlobalTheme) => ({
  listStyle: ({ props }: any) => ({
    backgroundImage: `url(${
      (props.img === 'firstNeed' && firstNeed) ||
      (props.img === 'secondNeed' && secondNeed) ||
      (props.img === 'thirdNeed' && thirdNeed) ||
      (props.img === 'lastNeed' && lastNeed)
    })`,
    backgroundSize: 'cover',
    flex: '0 0 auto',
    width: '304px',
    height: '360px',
    marginRight: '25px',
    borderRadius: '52px 32px',
  }),
  titleStyle: {
    textAlign: 'left',
    fontSize: '1.125rem',
  },
  subTitleStyle: {
    textAlign: 'left',
    fontFamily: theme.fonts.medium,
    fontSize: '0.9375rem',
    marginLeft: '23px',
  },
  img: {
    width: '23px',
    height: '13px',
  },
  bodyContent: {
    display: 'flex',
    marginBottom: '13px',
    alignItems: 'center',
  },
}));

interface ElementDetails {
  title: string;
}

function CheckElement(props: ElementDetails) {
  const { title } = props;
  const classes = useStyles({ props });
  const wordingAlt = useSelector(selectCompWording('alt'));

  return (
    <div className={classes.bodyContent}>
      <img src={check} alt={wordingAlt?.check_home} className={classes.img} />
      <Typography variant='h5' className={`${classes.subTitleStyle}`}>
        {title}
      </Typography>
    </div>
  );
}
export default CheckElement;
