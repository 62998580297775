import { put, delay, takeEvery } from 'redux-saga/effects';
import { map, assign, has } from 'lodash';
import {
  GetDocumentsAction,
  SubmitDocumentsAction,
  ValidateDocumentsAction,
  errorDocumentUpAction,
  updateDocumentInfoAction,
  updateDocumentsAction,
  UploadDocumentAT,
  DownloadDocumentsAction,
  DeleteDocumentsAction,
  deleteDocumentSuccessAction,
  getDocumentsAction,
} from './actions.upload';
import { apiPost } from 'common/utils/request.util';
import { push } from 'connected-react-router';
import {
  MAX_FILE_SIZE_ERROR,
  VERIFY_FILE_EXTENTIONS,
  FILE_MALICIOUS,
} from 'common/utils/validateJs/contants';
import { getSizeGlobMB } from 'common/utils/auth.util';
import { ALLOWED_FILE_EXTENSIONS } from './constant';
import { saveUserProfileAndRedirect } from 'features/validationBmceDirect/sagas.validation';
import { getSessionStorage } from 'common/utils/sessionStorage.util';

export function* downloadDocumentsSaga() {
  yield takeEvery(UploadDocumentAT.downloadDocuments, downloadDocuments);
}
export function* deleteDocumentsSaga() {
  yield takeEvery(UploadDocumentAT.deleteDocuments, deleteDocuments);
}
export function* getDocumentsSaga() {
  yield takeEvery(UploadDocumentAT.getDocuments, getDocuments);
}
export function* uploadDocumentsSaga() {
  yield takeEvery(UploadDocumentAT.submitDocument, uploadDocuments);
}
export function* validateDocumentsSaga() {
  yield takeEvery(
    UploadDocumentAT.validateUploadDocumentsStep,
    validateDocuments,
  );
}

export function* downloadDocuments(action: DownloadDocumentsAction) {
  try {
    const response = yield apiPost('/document/download', action.documents, {
      responseType: 'blob',
    });
    const blob = new Blob([response.data], { type: 'application/zip' });
    const downloadUrl = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = downloadUrl;
    const config = response.config.data;
    a.download = JSON.parse(config).nomPiece;
    document.body.appendChild(a);
    a.click();
  } catch (error) {
    console.error(error);
  }
}

export function* deleteDocuments(action: DeleteDocumentsAction) {
  const label = has(action.documents, 'label') ? action.documents.label : '';
  try {
    yield put(updateDocumentInfoAction(label, 'loader', true));
    yield delay(2000);
    yield apiPost('/document/delete-document', action.documents);
    yield put(deleteDocumentSuccessAction(label));
    yield put(getDocumentsAction(action.listDocIds));
    yield put(updateDocumentInfoAction(label, 'loader', false));
  } catch (error) {
    yield put(updateDocumentInfoAction(label, 'loader', false));
    console.error(error);
  }
}

export function* getDocuments(action: GetDocumentsAction) {
  try {
    const { data } = yield apiPost('/document/listDoc', '');
    const listDocIds: any = action.listDocIds;
    const documents: any = {};
    // La liste des pieces demandées pour (Salarié, Fonctionnaire ou Retraité)
    // TODO numberOfFiles n'existe pas dans le service salafin à voir
    map(listDocIds, (docs: any) => {
      assign(documents, {
        [docs.label]: {
          label: docs.label,
          identifiantPiece: docs.identifiantPiece,
          loader: false,
          numberOfFiles: '1',
        },
      });
    });
    // Jointure des pieces pour chaque liste demandée
    map(documents, (docs) => {
      map(data, (my) => {
        if (my.identifiantPiece === docs.identifiantPiece) {
          assign(docs, { ...my });
        }
      });
      // Suppresion des listes vide (ne contient aucun piece)
      !has(docs, 'numPiece') && delete documents[docs.label];
    });
    yield put(updateDocumentsAction(documents));
  } catch (error) {
    console.error(error);
  }
}

export function* uploadDocuments(action: SubmitDocumentsAction) {
  try {
    yield put(errorDocumentUpAction({}));
    const sizeGlob = getSizeGlobMB(action.files);
    // 5 Mega
    if (sizeGlob > 5) {
      yield put(errorDocumentUpAction(MAX_FILE_SIZE_ERROR));
      return;
    }
    const formData = new FormData();
    for (const file of action.files) {
      const reg = file.name.split('.').pop();
      if (!reg || !ALLOWED_FILE_EXTENSIONS.includes(reg.toLowerCase())) {
        yield put(errorDocumentUpAction(VERIFY_FILE_EXTENTIONS));
        return;
      }
      formData.append('files', file);
    }
    formData.append('identifiantPiece', action.identifiantPiece);

    yield put(updateDocumentInfoAction(action.label, 'loader', true));

    yield put(updateDocumentInfoAction(action.label, 'label', action.label));

    yield put(
      updateDocumentInfoAction(
        action.label,
        'numberOfFiles',
        action.files.length,
      ),
    );

    const { data } = yield apiPost(
      '/document/upload',
      formData,
      action.identifiantPiece,
    );
    console.log(data);
    // error lors d'upload de document
    if (data?.errorCode === '001') {
      yield put(errorDocumentUpAction(FILE_MALICIOUS));
      yield put(deleteDocumentSuccessAction(action.label));
      return;
    } else {
      yield put(
        updateDocumentInfoAction(
          action.label,
          'identifiantPiece',
          data.identifiantPiece,
        ),
      );

      yield put(
        updateDocumentInfoAction(
          action.label,
          'idSimulation',
          data.simulationId,
        ),
      );

      yield put(
        updateDocumentInfoAction(action.label, 'numPiece', data.numPiece),
      );
      yield put(
        updateDocumentInfoAction(action.label, 'nomPiece', data.nomPiece),
      );
    }

    yield delay(5000);
    yield put(updateDocumentInfoAction(action.label, 'loader', false));
  } catch (error) {
    yield put(updateDocumentInfoAction(action.label, 'loader', false));
    console.error(error);
  }
}

export function* validateDocuments(action: ValidateDocumentsAction) {
  try {
    const userProfile = getSessionStorage('userProfile');
    yield saveUserProfileAndRedirect({ ...userProfile, stepClient: '6' }); // step suivi
    yield put(push('/congra'));
  } catch (error) {
    console.error(error);
  }
}

export const uploadDocumentSagas = [
  getDocumentsSaga,
  uploadDocumentsSaga,
  deleteDocumentsSaga,
  validateDocumentsSaga,
  downloadDocumentsSaga,
];
