import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import couple from 'assets/images/couple.png';
import { Wording } from 'common/wording/reducer.wording';
import { selectWording } from 'common/wording/selectors.wording';
import { useSelector } from 'react-redux';
import { GlobalTheme } from 'features/app/app.theme';
import AdvantageElement from './AdvantageElement';
import Button from '@material-ui/core/Button';
import CustomTitle from 'common/components/customFields/CustomTitle';
import rightFlesh from 'assets/svg/rightFlesh.svg';
import Dialog from '@material-ui/core/Dialog';
import ReactPlayer from 'react-player';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { getSimulationInfoByArraySelector } from 'features/home/selector.simulation';
import { LANG_STATE } from 'features/home/constants.simulation';
import { pushEvent } from 'features/auth/constants';

const useStyles = makeStyles((theme: GlobalTheme) => ({
  root: {
    width: '100%',
    margin: '20px auto',
  },
  bodyContent: {
    width: '93%',
    height: 'auto',
    margin: 'auto',
    borderRadius: '30px',
    padding: '15px',
    backgroundColor: '#FFF',
    '@media (max-width: 1024px)': {
      width: '86%',
    },
  },
  elements: {
    display: 'flex',
    backgroundColor: '#EFF1F6',
    height: 'auto',
    margin: 'auto',
    borderRadius: '30px',
    paddingLeft: '30px',
    paddingTop: '30px',
    '@media (max-width: 1024px)': {
      flexWrap: 'wrap',
      height: 'auto',
      paddingLeft: '0',
    },
  },
  advantageElements: {
    display: 'flex',
    width: '65%',
    margin: '20px auto',
    '@media (max-width: 1024px)': {
      width: '100%',
      flexWrap: 'wrap',
      marginTop: '30px',
    },
  },
  green: {
    color: theme.primary.main,
  },
  blue: {
    color: theme.primary.title,
  },
  pink: {
    color: theme.secondary.main,
  },
  grey: {
    color: theme.secondary.title,
  },
  button: {
    width: '200px',
    textTransform: 'inherit',
    marginRight: '25px',
    padding: '12px',
    fontFamily: theme.fonts.regular,
    fontSize: '0.875rem',
    '@media (max-width: 1024px)': {
      margin: 'auto',
      padding: '12px',
      marginBottom: '10px',
    },
    '@media (max-width: 450px)': {
      padding: '12px',
    },
  },
  rightIcon: {
    marginLeft: '15px',
    height: '15px',
  },
  rightIconAr: {
    marginLeft: '15px',
    transform: 'scaleX(-1)',
    height: '15px',
  },
  dialog: {
    '& .MuiDialog-paper': {
      overflow: 'hidden',
    },
  },
  closeButton: {
    position: 'absolute',
    right: '5px',
    top: '5px',
    color: 'grey',
    cursor: 'pointer',
    zIndex: 99999,
  },
  img: {
    opacity: '.7',
    '@media (max-width: 1024px)': {
      margin: 'auto',
    },
    '@media (max-width: 450px)': {
      width: '210px',
    },
  },
  imgAr: {
    transform: 'scaleX(-1)',
    opacity: '.7',
    '@media (max-width: 1024px)': {
      margin: 'auto',
    },
    '@media (max-width: 450px)': {
      width: '210px',
    },
  },
  ContentImg: {
    display: 'flex',
    alignItems: 'flex-end',
    marginLeft: '30px',
    '@media (max-width: 1024px)': {
      order: 2,
      margin: 'auto !important',
      marginLeft: 'unset',
      marginBottom: '20px',
    },
  },
  contentButton: {
    display: 'flex',
    alignItems: 'center',
    margin: 'auto',
    '@media (max-width: 1024px)': {
      order: 3,
      width: '90%',
      transform: 'translate(0, -50%)',
    },
  },
}));

const Advantages = () => {
  const classes = useStyles();
  const wording: Wording = useSelector(selectWording);
  const [open, setOpen] = useState(false);
  const langSelected: any =
    useSelector(getSimulationInfoByArraySelector(['lang'])) || LANG_STATE.FR;

  const [videoUrl, setVideoUrl] = useState('');

  useEffect(() => {
    langSelected === LANG_STATE.FR
      ? setVideoUrl('video/credit_conso_demo.mp4')
      : setVideoUrl('../video/credit_conso_demo_ar.mp4');
  }, [langSelected]);

  const handleClickOpen = () => {
    setOpen(true);
    let eventObj = {
      event: 'click_cta',
      click_label: 'voir_la_demo',
      element_category: 'body',
    };
    pushEvent(eventObj);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CustomTitle
        title={wording?.homePage.advantageTitle}
        colorBorder={'blue'}
        positionBorder={''}
        positionTitle={''}
      />
      <div className={classes.bodyContent}>
        <div className={classes.elements}>
          <div className={classes.ContentImg}>
            <img
              src={couple}
              className={
                langSelected === LANG_STATE.FR ? classes.img : classes.imgAr
              }
              alt={wording?.alt.girl_boy_home}
            />
          </div>
          <div className={classes.advantageElements}>
            <AdvantageElement
              icon={'confort'}
              title={wording?.homePage.confort}
              subTitle={wording?.homePage.confortSubTitle}
              className={classes.green}
            />
            <AdvantageElement
              icon={'security'}
              title={wording?.homePage.security}
              subTitle={wording?.homePage.securitySubTitle}
              className={classes.blue}
            />
            <AdvantageElement
              icon={'clock'}
              title={wording?.homePage.rapidite}
              subTitle={wording?.homePage.rapiditeSubTitle}
              className={classes.pink}
            />
            <AdvantageElement
              icon={'transparence'}
              title={wording?.homePage.transparence}
              subTitle={wording?.homePage.transparenceSubTitle}
              className={classes.grey}
            />
          </div>
          <div className={classes.contentButton}>
            <Button
              type='button'
              className={classes.button}
              variant='contained'
              color='primary'
              onClick={handleClickOpen}
            >
              {wording?.homePage.seeDemo}
              <img
                src={rightFlesh}
                alt=''
                className={
                  langSelected === LANG_STATE.FR
                    ? classes.rightIcon
                    : classes.rightIconAr
                }
              />
            </Button>
          </div>

          <Dialog
            className={classes.dialog}
            open={open}
            onClose={handleClose}
            aria-labelledby='responsive-dialog-title'
          >
            <IconButton
              aria-label='close'
              className={classes.closeButton}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
            <ReactPlayer url={videoUrl} playing controls autoPlay />
          </Dialog>
        </div>
      </div>
    </div>
  );
};

export default Advantages;
