import React from 'react';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ValidationAT } from '../../validationBmceDirect/actions.validation';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Spinner from 'common/components/Spinner';
import { getValidationInfoByArraySelector } from 'features/validationBmceDirect/selector.validation';
import PopUpInfo from 'features/home/components/PopUpInfo';
import { isEmpty } from 'lodash';
import {
  TOKEN_BMCEDIRECT_EXPIRED,
  EXCEED_MAX_ATTEMPTS,
  COMPTE_MULTI_TITULAIRE,
  CIN_PLUSIEURS_TIERS,
  DATA_IN_NOT_VALIDE,
  CIN_RIB_NULLS,
  CLIENT_WITH_MULTI_CPT,
  ERROR_WAF,
  TOKEN_BMCEDIRECT_NOT_VALID,
  CIN_IS_NULL,
  DUPLICATE_EMAIL,
  DURATION_OR_AMOUNT_NULL,
} from 'common/utils/validateJs/contants';
import { setSessionStorage } from 'common/utils/sessionStorage.util';

interface ObjectToken {
  token: string;
}

const ValidationToken = (props: any) => {
  const errors: any = useSelector(getValidationInfoByArraySelector(['errors']));
  // const checkedClose: any = useSelector(
  //   getValidationInfoByArraySelector(['checkedClose']),
  // );
  const loader: any = useSelector(getValidationInfoByArraySelector(['loader']));
  const objectToken: ObjectToken = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  // Get query params simulation info
  const query = new URLSearchParams(props?.location?.search);
  const MontantCREDIT = query.get('MontantCREDIT');
  const duree = query.get('duree');
  const echeance = query.get('echeance');
  const statut = query.get('statut');
  setSessionStorage('MontantCREDIT', MontantCREDIT);
  setSessionStorage('duree', duree);
  setSessionStorage('echeance', echeance);
  setSessionStorage('statut', statut);
  useEffect(() => {
    if (window.location.href.includes('?')) {
      setSessionStorage('redirectionNormale', false);
    } else {
      setSessionStorage('redirectionNormale', true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps,
  }, []);
  const listErrors = [
    COMPTE_MULTI_TITULAIRE,
    CIN_PLUSIEURS_TIERS,
    DATA_IN_NOT_VALIDE,
    CIN_RIB_NULLS,
    CLIENT_WITH_MULTI_CPT,
    TOKEN_BMCEDIRECT_EXPIRED,
    EXCEED_MAX_ATTEMPTS,
    ERROR_WAF,
    TOKEN_BMCEDIRECT_NOT_VALID,
    CIN_IS_NULL,
    DUPLICATE_EMAIL,
    DURATION_OR_AMOUNT_NULL,
  ];
  useEffect(() => {
    dispatch({
      type: ValidationAT.VALIDATION_BMCEDIRECT,
      token: objectToken?.token,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps,
  }, []);
  const handleClose = () => {
    dispatch({
      type: ValidationAT.updateField,
      checkedClose: false,
    });
    history.push('/credit-conso');
  };
  if (listErrors.includes(errors)) {
    return (
      <>
        <PopUpInfo open={true} onClose={handleClose} error={errors} />
      </>
    );
  } else {
    return (
      <>
        <Spinner loader={loader} />
        {isEmpty(listErrors.includes(errors)) && loader === false && (
          <Redirect to='/credit-conso' />
        )}
      </>
    );
  }
};

export default ValidationToken;
