import React, { useEffect } from 'react';
import ContextualLayout from './components/ContextualLayout';
import MainView from './components/MainView';
import CreditConsoPicto from 'assets/svg/credit-conso-picto.svg';
import BlocTitle from './components/BlocTitle';
import TextBloc from './components/TextBloc';
import { Grid } from '@material-ui/core';
import AdvantageBloc from './components/AdvantageBloc';
import Parcours from './components/Parcours';
import zDH from 'assets/svg/zDH.svg';
import DH from 'assets/svg/DH.svg';
import HightLightedBox from './components/HightLightedBox';
import { Wording } from 'common/wording/reducer.wording';
import { useSelector } from 'react-redux';
import { selectCompWording } from 'common/wording/selectors.wording';
import Seo from 'common/components/seo/Seo';
import { pushPageView } from 'features/auth/constants';

function CreditConso() {
  const wording: Wording = useSelector(selectCompWording('contextual'));

  useEffect(() => {
    pushPageView('credit_a_la_consomation', 'showcase');
  }, []);

  return (
    <ContextualLayout>
      <>
        <Seo
          keywordsKey='keywords_32'
          titleKey='title_32'
          descriptionKey='description_32'
        />
        <MainView
          title={wording?.title_conso_page}
          subTitle={wording?.subtitle_conso_page}
          description={wording?.description_conso_page}
          image={CreditConsoPicto}
        />
        <BlocTitle title={wording?.title_whatis_conso_page} />
        <TextBloc text={wording?.description_whatis_conso_page} />
        <BlocTitle title={wording?.title_advantages_conso_page} />
        <Grid container spacing={2} style={{ marginTop: '20px' }}>
          <Grid item xs={12} sm={5}>
            <AdvantageBloc icon={zDH} text={wording?.advantage1_conso_page} />
          </Grid>
          <Grid item xs={12} sm={7}>
            <AdvantageBloc icon={DH} text={wording?.advantage2_conso_page} />
          </Grid>
        </Grid>
        <BlocTitle title={wording?.title_parcours_conso_page} />
        <Parcours />
        <BlocTitle title={wording?.title_assistance_conso_page} />
        <HightLightedBox>
          <>
            <div
              dangerouslySetInnerHTML={{
                __html: wording?.description_assistance1_conso_page,
              }}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: wording?.description_assistance2_conso_page,
              }}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: wording?.description_assistance3_conso_page,
              }}
            />
          </>
        </HightLightedBox>
      </>
    </ContextualLayout>
  );
}

export default CreditConso;
