export enum updateInformationFields {
  civility = 'civility',
  login = 'login',
  password = 'password',
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  emailConfirmation = 'emailConfirmation',
  conditionOfUse = 'conditionOfUse',
  promotionalOffers = 'promotionalOffers',
}
