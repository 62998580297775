import {
  // INVALID_PASSWORD,
  EMAIL_DOES_NOT_MATCH,
  REQUIRED,
  TOO_SHORT,
  INVALID_EMAIL,
  INVALID_PHONE_NUMBER,
  CONDITION_OF_USE_REQUIRED,
  TOO_SHORT_BMCE_DIRECT,
  REQUIRED_GSM,
  INVALID_OTP,
  // ERROR_OTP_SERVICE_INVALIDE_OTP_FORMAT
} from './contants';
import { ProductTypes } from 'features/auth/constants';

const phoneNumberPattern = /(\+212|0)([ \-_/]*)(\d[ \-_/]*){9}/;

export const signUpConstraints = (field?: string) => {
  // TODO validateJS constraint type
  const constraint: any = {
    civility: {
      presence: { allowEmpty: false, message: REQUIRED },
    },

    firstName: {
      presence: { allowEmpty: false, message: REQUIRED },
      length: { minimum: 2, message: TOO_SHORT },
    },

    lastName: {
      presence: { allowEmpty: false, message: REQUIRED },
      length: { minimum: 2, message: TOO_SHORT },
    },

    email: {
      presence: { allowEmpty: false, message: REQUIRED },
      email: { message: INVALID_EMAIL },
    },

    emailConfirmation: {
      presence: { allowEmpty: false, message: REQUIRED },
      equality: {
        attribute: 'email',
        message: EMAIL_DOES_NOT_MATCH,
      },
    },

    conditionOfUse: {
      presence: { allowEmpty: false, message: CONDITION_OF_USE_REQUIRED },
      inclusion: {
        within: [true],
        message: CONDITION_OF_USE_REQUIRED,
      },
    },
  };
  if (field) {
    return { [field]: constraint[field] };
  }
  return constraint;
};

export const updateUserConstraints = (field?: string) => {
  // TODO validateJS constraint type
  const constraint: any = {
    civility: {
      presence: { allowEmpty: false, message: REQUIRED },
    },

    firstName: {
      presence: { allowEmpty: false, message: REQUIRED },
      length: { minimum: 2, message: TOO_SHORT },
    },

    lastName: {
      presence: { allowEmpty: false, message: REQUIRED },
      length: { minimum: 2, message: TOO_SHORT },
    },

    email: {
      presence: { allowEmpty: false, message: REQUIRED },
      email: { message: INVALID_EMAIL },
    },

    conditionOfUse: {
      presence: { allowEmpty: false, message: CONDITION_OF_USE_REQUIRED },
      inclusion: {
        within: [true],
        message: CONDITION_OF_USE_REQUIRED,
      },
    },
  };
  if (field) {
    return { [field]: constraint[field] };
  }
  return constraint;
};

export const signUpBMCEDirectConstraints = (field?: string) => {
  const constraint: any = {
    login: {
      presence: { allowEmpty: false, message: REQUIRED },
      length: { minimum: 6, message: TOO_SHORT_BMCE_DIRECT },
    },

    password: {
      presence: { allowEmpty: false, message: REQUIRED },
      length: { minimum: 6, message: TOO_SHORT_BMCE_DIRECT },
    },
  };
  if (field) {
    return { [field]: constraint[field] };
  }
  return constraint;
};

export const signInConstraints = (bmceDirect?: boolean, field?: any) => {
  let constraint: any = null;
  if (bmceDirect) {
    constraint = {
      login: {
        presence: { allowEmpty: false, message: REQUIRED },
        length: { minimum: 6, message: TOO_SHORT_BMCE_DIRECT },
      },
      password: {
        presence: { allowEmpty: false, message: REQUIRED },
        length: { minimum: 6, message: TOO_SHORT_BMCE_DIRECT },
      },
    };
  } else {
    constraint = {
      email: {
        presence: { allowEmpty: false, message: REQUIRED },
        email: { message: INVALID_EMAIL },
      },
    };
  }
  if (field) {
    return { [field]: constraint[field] };
  }
  return constraint;
};

export const situationProConstraints = (field?: string) => {
  const constraint: any = {
    activityPro: {
      presence: { allowEmpty: false, message: REQUIRED },
    },
    jobNature: {
      customPresence: {
        keys: ['activityPro', 'liberale'],
      },
    },
    monthlyIncome: {
      presence: { allowEmpty: false, message: REQUIRED },
    },
    additionalIncomeType: {
      presence: { allowEmpty: false, message: REQUIRED },
    },
    amountAdditionalIncome: {
      customPresence: {
        keys: ['additionalIncome', true],
      },
    },
    totalCreditAmount: {
      presence: { allowEmpty: false, message: REQUIRED },
    },
  };
  if (field) {
    return { [field]: constraint[field] };
  }
  return constraint;
};

export const situationSalarieConstraints = (
  salarieType?: string,
  field?: string,
) => {
  let constraint: any;
  // TODO refactor this function
  switch (salarieType) {
    case ProductTypes.cp:
      constraint = {
        familialSituation: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        city: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        nationality: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        activityArea: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        cddEndDate: {
          customPresence: {
            keys: ['natureContrat', 'cdd'],
          },
        },
        dateCurrentEmployer: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        monthlyIncome: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        additionalIncomeNature: {
          customPresence: {
            keys: ['additionalIncome', true],
          },
        },
        amountadditionalIncome: {
          customPresence: {
            keys: ['additionalIncome', true],
          },
        },
        bankDebit: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        monthlyCreditImmovable: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        dateLastVirement: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        cin: {
          customPresence: {
            keys: ['cinOrRib', 'cin'],
          },
        },
        ribSecondContent: {
          customPresence: {
            keys: ['cinOrRib', 'rib'],
          },
        },
        ribThirdContent: {
          customPresence: {
            keys: ['cinOrRib', 'rib'],
          },
        },
        ribLastContent: {
          customPresence: {
            keys: ['cinOrRib', 'rib'],
          },
        },
      };
      break;
    case ProductTypes.bconvcp:
      constraint = {
        nationality: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        cddEndDate: {
          customPresence: {
            keys: ['natureContrat', 'cdd'],
          },
        },
        dateCurrentEmployer: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        dateLastVirement: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        monthlyDateDebit: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        monthlyIncome: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        additionalIncomeNature: {
          customPresence: {
            keys: ['additionalIncome', true],
          },
        },
        amountadditionalIncome: {
          customPresence: {
            keys: ['additionalIncome', true],
          },
        },
        cin: {
          customPresence: {
            keys: ['cinOrRib', 'cin'],
          },
        },
        ribSecondContent: {
          customPresence: {
            keys: ['cinOrRib', 'rib'],
          },
        },
        ribThirdContent: {
          customPresence: {
            keys: ['cinOrRib', 'rib'],
          },
        },
        ribLastContent: {
          customPresence: {
            keys: ['cinOrRib', 'rib'],
          },
        },
      };
      break;
    case ProductTypes.cp_slf:
      constraint = {
        familialSituation: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        city: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        nationality: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        activityArea: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        cddEndDate: {
          customPresence: {
            keys: ['natureContrat', 'cdd'],
          },
        },
        dateCurrentEmployer: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        monthlyIncome: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        additionalIncomeNature: {
          customPresence: {
            keys: ['additionalIncome', true],
          },
        },
        amountadditionalIncome: {
          customPresence: {
            keys: ['additionalIncome', true],
          },
        },
        codeOtherBank: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        dateCreationOtherBankAccount: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        bankDebit: {
          presence: { allowEmpty: true, message: REQUIRED },
        },
        monthlyCreditImmovable: {
          presence: { allowEmpty: true, message: REQUIRED },
        },
        mouvementCredit: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        mouvementDebit: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        balanceEndOfMonth: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        cin: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
      };
  }

  if (field) {
    return { [field]: constraint[field] };
  }
  return constraint;
};
export const situationPreattSalarieConstraints = (
  salarieType?: string,
  field?: string,
) => {
  let constraint: any;
  // TODO refactor this function
  switch (salarieType) {
    case ProductTypes.cp:
      constraint = {
        familialSituation: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        additionalIncomeNature: {
          customPresence: {
            keys: ['additionalIncome', true],
          },
        },
        amountadditionalIncome: {
          customPresence: {
            keys: ['additionalIncome', true],
          },
        },
        bankDebit: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        monthlyCreditImmovable: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        numberChilds: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        numberPersonWhitoutChilds: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
      };
      break;
    case ProductTypes.bconvcp:
      constraint = {
        bankDebit: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        monthlyDateDebit: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        additionalIncomeNature: {
          customPresence: {
            keys: ['additionalIncome', true],
          },
        },
        amountadditionalIncome: {
          customPresence: {
            keys: ['additionalIncome', true],
          },
        },
      };
      break;
    case ProductTypes.cp_slf:
      constraint = {
        bankDebit: {
          presence: { allowEmpty: true, message: REQUIRED },
        },
        monthlyDateDebit: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        additionalIncomeNature: {
          customPresence: {
            keys: ['additionalIncome', true],
          },
        },
        amountadditionalIncome: {
          customPresence: {
            keys: ['additionalIncome', true],
          },
        },
      };
  }

  if (field) {
    return { [field]: constraint[field] };
  }
  return constraint;
};

export const situationFonctionnaireConstraints = (
  salarieType?: string,
  field?: string,
) => {
  let constraint: any;
  // TODO refactor this function
  switch (salarieType) {
    case ProductTypes.cp:
      constraint = {
        familialSituation: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        city: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        nationality: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        cddEndDate: {
          customPresence: {
            keys: ['natureContrat', 'cdd'],
          },
        },
        dateCurrentEmployer: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        fonctionnaireCodeImputation: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        fonctionnaireMatricule: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        monthlyIncome: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        additionalIncomeNature: {
          customPresence: {
            keys: ['additionalIncome', true],
          },
        },
        amountadditionalIncome: {
          customPresence: {
            keys: ['additionalIncome', true],
          },
        },
        dateLastVirement: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        cin: {
          customPresence: {
            keys: ['cinOrRib', 'cin'],
          },
        },
        ribSecondContent: {
          customPresence: {
            keys: ['cinOrRib', 'rib'],
          },
        },
        ribThirdContent: {
          customPresence: {
            keys: ['cinOrRib', 'rib'],
          },
        },
        ribLastContent: {
          customPresence: {
            keys: ['cinOrRib', 'rib'],
          },
        },
      };
      break;
    case ProductTypes.bconvcp:
      constraint = {
        nationality: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        cddEndDate: {
          customPresence: {
            keys: ['natureContrat', 'cdd'],
          },
        },
        dateCurrentEmployer: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        fonctionnaireCodeImputation: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        fonctionnaireMatricule: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        retirment: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        monthlyIncome: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        additionalIncomeNature: {
          customPresence: {
            keys: ['additionalIncome', true],
          },
        },
        amountadditionalIncome: {
          customPresence: {
            keys: ['additionalIncome', true],
          },
        },
        dateLastVirement: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        cin: {
          customPresence: {
            keys: ['cinOrRib', 'cin'],
          },
        },
        ribSecondContent: {
          customPresence: {
            keys: ['cinOrRib', 'rib'],
          },
        },
        ribThirdContent: {
          customPresence: {
            keys: ['cinOrRib', 'rib'],
          },
        },
        ribLastContent: {
          customPresence: {
            keys: ['cinOrRib', 'rib'],
          },
        },
      };
      break;
    case ProductTypes.cp_slf:
      constraint = {
        familialSituation: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        city: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        nationality: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        cddEndDate: {
          customPresence: {
            keys: ['natureContrat', 'cdd'],
          },
        },
        dateCurrentEmployer: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        fonctionnaireCodeImputation: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        fonctionnaireMatricule: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        monthlyIncome: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        additionalIncomeNature: {
          customPresence: {
            keys: ['additionalIncome', true],
          },
        },
        amountadditionalIncome: {
          customPresence: {
            keys: ['additionalIncome', true],
          },
        },
        codeOtherBank: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        dateCreationOtherBankAccount: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        mouvementCredit: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        mouvementDebit: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        balanceEndOfMonth: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        cin: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
      };
      break;
    case ProductTypes.ppr:
      constraint = {
        familialSituation: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        city: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        nationality: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        cddEndDate: {
          customPresence: {
            keys: ['natureContrat', 'cdd'],
          },
        },
        dateCurrentEmployer: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        fonctionnaireCodeImputation: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        fonctionnaireMatricule: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        monthlyIncome: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        additionalIncomeNature: {
          customPresence: {
            keys: ['additionalIncome', true],
          },
        },
        amountadditionalIncome: {
          customPresence: {
            keys: ['additionalIncome', true],
          },
        },
        cin: {
          customPresence: {
            keys: ['cinOrRib', 'cin'],
          },
        },
        ribSecondContent: {
          customPresence: {
            keys: ['cinOrRib', 'rib'],
          },
        },
        ribThirdContent: {
          customPresence: {
            keys: ['cinOrRib', 'rib'],
          },
        },
        ribLastContent: {
          customPresence: {
            keys: ['cinOrRib', 'rib'],
          },
        },
      };
      break;
    case ProductTypes.bconvppr:
      constraint = {
        nationality: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        dateCurrentEmployer: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        fonctionnaireCodeImputation: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        fonctionnaireMatricule: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        monthlyIncome: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        additionalIncomeNature: {
          customPresence: {
            keys: ['additionalIncome', true],
          },
        },
        amountadditionalIncome: {
          customPresence: {
            keys: ['additionalIncome', true],
          },
        },
        cin: {
          customPresence: {
            keys: ['cinOrRib', 'cin'],
          },
        },
        ribSecondContent: {
          customPresence: {
            keys: ['cinOrRib', 'rib'],
          },
        },
        ribThirdContent: {
          customPresence: {
            keys: ['cinOrRib', 'rib'],
          },
        },
        ribLastContent: {
          customPresence: {
            keys: ['cinOrRib', 'rib'],
          },
        },
      };
      break;
  }

  if (field) {
    return { [field]: constraint[field] };
  }
  return constraint;
};

export const situationPreattFonctionnaireConstraints = (
  salarieType?: string,
  field?: string,
) => {
  let constraint: any;
  // TODO refactor this function
  switch (salarieType) {
    case ProductTypes.cp:
      constraint = {
        bankDebit: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        additionalIncomeNature: {
          customPresence: {
            keys: ['additionalIncome', true],
          },
        },
        amountadditionalIncome: {
          customPresence: {
            keys: ['additionalIncome', true],
          },
        },
      };
      break;
    case ProductTypes.ppr:
      constraint = {
        familialSituation: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        monthlyCreditImmovable: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        bankDebit: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        additionalIncomeNature: {
          customPresence: {
            keys: ['additionalIncome', true],
          },
        },
        amountadditionalIncome: {
          customPresence: {
            keys: ['additionalIncome', true],
          },
        },
        numberPersonWhitoutChilds: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        numberChilds: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
      };
      break;
    case ProductTypes.bconvcp:
      constraint = {
        additionalIncomeNature: {
          customPresence: {
            keys: ['additionalIncome', true],
          },
        },
        amountadditionalIncome: {
          customPresence: {
            keys: ['additionalIncome', true],
          },
        },
      };
      break;
    case ProductTypes.cp_slf:
      constraint = {
        bankDebit: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        additionalIncomeNature: {
          customPresence: {
            keys: ['additionalIncome', true],
          },
        },
        amountadditionalIncome: {
          customPresence: {
            keys: ['additionalIncome', true],
          },
        },
      };
      break;
    case ProductTypes.bconvppr:
      constraint = {
        bankDebit: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        additionalIncomeNature: {
          customPresence: {
            keys: ['additionalIncome', true],
          },
        },
        amountadditionalIncome: {
          customPresence: {
            keys: ['additionalIncome', true],
          },
        },
      };
  }

  if (field) {
    return { [field]: constraint[field] };
  }
  return constraint;
};

export const situationRetraiteConstraints = (
  salarieType?: string,
  field?: string,
) => {
  let constraint: any;
  // TODO refactor this function
  switch (salarieType) {
    case ProductTypes.cp:
      constraint = {
        familialSituation: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        city: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        nationality: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        monthlyIncome: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        additionalIncomeNature: {
          customPresence: {
            keys: ['additionalIncome', true],
          },
        },
        amountadditionalIncome: {
          customPresence: {
            keys: ['additionalIncome', true],
          },
        },
        bankDebit: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        monthlyCreditImmovable: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        dateLastVirement: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        cin: {
          customPresence: {
            keys: ['cinOrRib', 'cin'],
          },
        },
        ribSecondContent: {
          customPresence: {
            keys: ['cinOrRib', 'rib'],
          },
        },
        ribThirdContent: {
          customPresence: {
            keys: ['cinOrRib', 'rib'],
          },
        },
        ribLastContent: {
          customPresence: {
            keys: ['cinOrRib', 'rib'],
          },
        },
      };
      break;
    case ProductTypes.bconvcp:
      constraint = {
        nationality: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        dateLastVirement: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        monthlyDateDebit: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        monthlyIncome: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        additionalIncomeNature: {
          customPresence: {
            keys: ['additionalIncome', true],
          },
        },
        amountadditionalIncome: {
          customPresence: {
            keys: ['additionalIncome', true],
          },
        },
        cin: {
          customPresence: {
            keys: ['cinOrRib', 'cin'],
          },
        },
        ribSecondContent: {
          customPresence: {
            keys: ['cinOrRib', 'rib'],
          },
        },
        ribThirdContent: {
          customPresence: {
            keys: ['cinOrRib', 'rib'],
          },
        },
        ribLastContent: {
          customPresence: {
            keys: ['cinOrRib', 'rib'],
          },
        },
      };
      break;
    case ProductTypes.cp_slf:
      constraint = {
        familialSituation: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        city: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        nationality: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        monthlyIncome: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        additionalIncomeNature: {
          customPresence: {
            keys: ['additionalIncome', true],
          },
        },
        amountadditionalIncome: {
          customPresence: {
            keys: ['additionalIncome', true],
          },
        },
        codeOtherBank: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        dateCreationOtherBankAccount: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        bankDebit: {
          presence: { allowEmpty: true, message: REQUIRED },
        },
        monthlyCreditImmovable: {
          presence: { allowEmpty: true, message: REQUIRED },
        },
        mouvementCredit: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        mouvementDebit: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        balanceEndOfMonth: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        cin: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
      };
  }

  if (field) {
    return { [field]: constraint[field] };
  }
  return constraint;
};

/* ########### PROFESSIONNEL CONSTRAINT ############ */
export const situationProfessionnelConstraints = (
  salarieType?: string,
  field?: string,
) => {
  let constraint: any;
  // TODO refactor this function
  switch (salarieType) {
    case ProductTypes.cp:
      constraint = {
        familialSituation: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        city: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        nationality: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        activityArea: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        dateCurrentEmployer: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        monthlyIncome: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        additionalIncomeNature: {
          customPresence: {
            keys: ['additionalIncome', true],
          },
        },
        amountadditionalIncome: {
          customPresence: {
            keys: ['additionalIncome', true],
          },
        },
        bankDebit: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        monthlyCreditImmovable: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        dateLastVirement: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        cin: {
          customPresence: {
            keys: ['cinOrRib', 'cin'],
          },
        },
        ribSecondContent: {
          customPresence: {
            keys: ['cinOrRib', 'rib'],
          },
        },
        ribThirdContent: {
          customPresence: {
            keys: ['cinOrRib', 'rib'],
          },
        },
        ribLastContent: {
          customPresence: {
            keys: ['cinOrRib', 'rib'],
          },
        },
      };
      break;
    case ProductTypes.cp_slf:
      constraint = {
        familialSituation: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        city: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        nationality: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        activityArea: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        dateCurrentEmployer: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        monthlyIncome: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        additionalIncomeNature: {
          customPresence: {
            keys: ['additionalIncome', true],
          },
        },
        amountadditionalIncome: {
          customPresence: {
            keys: ['additionalIncome', true],
          },
        },
        codeOtherBank: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        dateCreationOtherBankAccount: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        bankDebit: {
          presence: { allowEmpty: true, message: REQUIRED },
        },
        monthlyCreditImmovable: {
          presence: { allowEmpty: true, message: REQUIRED },
        },
        mouvementCredit: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        mouvementDebit: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        balanceEndOfMonth: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        cin: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
      };
  }

  if (field) {
    return { [field]: constraint[field] };
  }
  return constraint;
};

export const situationPreattRetraiteConstraints = (
  salarieType?: string,
  field?: string,
) => {
  let constraint: any;
  // TODO refactor this function
  switch (salarieType) {
    case ProductTypes.cp:
      constraint = {
        familialSituation: {
          presence: { allowEmpty: false, message: REQUIRED },
        },

        additionalIncomeNature: {
          customPresence: {
            keys: ['additionalIncome', true],
          },
        },
        amountadditionalIncome: {
          customPresence: {
            keys: ['additionalIncome', true],
          },
        },
        bankDebit: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        monthlyCreditImmovable: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
      };
      break;
    case ProductTypes.bconvcp:
      constraint = {
        additionalIncomeNature: {
          customPresence: {
            keys: ['additionalIncome', true],
          },
        },
        amountadditionalIncome: {
          customPresence: {
            keys: ['additionalIncome', true],
          },
        },
        bankDebit: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
      };
      break;
    case ProductTypes.cp_slf:
      constraint = {
        familialSituation: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        city: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        nationality: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        monthlyIncome: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        additionalIncomeNature: {
          customPresence: {
            keys: ['additionalIncome', true],
          },
        },
        amountadditionalIncome: {
          customPresence: {
            keys: ['additionalIncome', true],
          },
        },
        codeOtherBank: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        dateCreationOtherBankAccount: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        bankDebit: {
          presence: { allowEmpty: true, message: REQUIRED },
        },
        monthlyCreditImmovable: {
          presence: { allowEmpty: true, message: REQUIRED },
        },
        mouvementCredit: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        mouvementDebit: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        balanceEndOfMonth: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
        cin: {
          presence: { allowEmpty: false, message: REQUIRED },
        },
      };
  }

  if (field) {
    return { [field]: constraint[field] };
  }
  return constraint;
};

export const simulationConstraints = (field?: string) => {
  const constraint: any = {
    birthday: {
      presence: { allowEmpty: false, message: REQUIRED },
    },
    companyName: {
      presence: { allowEmpty: false, message: REQUIRED },
    },
    nameOtherEmploye: {
      presence: { allowEmpty: false, message: REQUIRED },
    },
    phone: {
      presence: { allowEmpty: false, message: REQUIRED_GSM },
      length: { is: 10, message: INVALID_PHONE_NUMBER },
      format: {
        pattern: phoneNumberPattern,
        message: INVALID_PHONE_NUMBER,
      },
    },
  };
  if (field) {
    return { [field]: constraint[field] };
  }
  return constraint;
};

export const submitOtpConstraints = (field?: string) => {
  const constraint: any = {
    otp: {
      presence: { allowEmpty: false, message: REQUIRED },
      length: { is: 6, message: INVALID_OTP },
    },
  };
  if (field) {
    return { [field]: constraint[field] };
  }
  return constraint;
};

export const passwordOublieConstraints = (field?: string) => {
  const constraint: any = {
    email: {
      presence: { allowEmpty: false, message: REQUIRED },
      email: { message: INVALID_EMAIL },
    },
  };
  if (field) {
    return { [field]: constraint[field] };
  }
  return constraint;
};
