import { toJS } from 'common/utils/app.util';
import { createSelector } from 'reselect';
import { AppStore } from '../../core/root.model';
import { PROPOSITION_REDUCER } from './reducer.proposition';

const getProposition = (state: AppStore) => state.get(PROPOSITION_REDUCER);

export const getPropositionInfoByArraySelector = (selector: string[]) =>
  createSelector(getProposition, (proposition) =>
    toJS(proposition.getIn(selector)),
  );
