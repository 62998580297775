import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GlobalTheme } from 'features/app/app.theme';
import React from 'react';
import { toUpper, trim, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { getSimulationInfoByArraySelector } from 'features/home/selector.simulation';
import { LANG_STATE } from 'features/home/constants.simulation';

const useStyles = makeStyles((theme: GlobalTheme) => ({
  root: {
    display: 'flex',
    marginBottom: '40px',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  label: {
    width: '100%',
    maxWidth: 'max-content',
    fontFamily: theme.fonts.medium,
    textAlign: 'left',
    marginRight: '10px',
  },
  valueContainer: {
    width: '70%',
    maxWidth: 'max-content',
    '@media (max-width: 414px)': {
      display: 'flex',
    },
    '@media (max-width: 320px)': {
      flexDirection: 'column',
    },
  },
  value: {
    fontWeight: 'bold',
    marginRight: '5px',
  },
  unit: {
    fontFamily: theme.fonts.regular,
    marginLeft: '0.1em',
    marginRight: '0.1em',
  },
}));

interface RecapSimulationElementProps {
  label: string;
  value?: string | number | any;
  unit?: string;
  InputClasses?: any;
  asterisk?: boolean;
}

export function RecapSimulationElement({
  label,
  value = '',
  unit = '',
  asterisk = false,
  InputClasses = {},
}: RecapSimulationElementProps) {
  const classes = useStyles();
  const langSelected: any =
    useSelector(getSimulationInfoByArraySelector(['lang'])) || LANG_STATE.FR;

  return (
    <div className={classes.root}>
      <Typography
        variant='h5'
        className={`${classes.label} ${InputClasses.simulationElementColor}`}
      >
        <div dangerouslySetInnerHTML={{ __html: label }} />
      </Typography>
      <Typography
        variant='h5'
        className={`${classes.valueContainer} ${InputClasses.simulationElementColor} ${InputClasses.titleSize}`}
      >
        <label className={`${classes.value} ${InputClasses.simulationSizeVal}`}>
          {langSelected === LANG_STATE.FR &&
            (toUpper(trim(unit)) === toUpper(trim('Dhs')) ||
            (toUpper(trim(unit)) === toUpper(trim('Dhs/Mois')) && !asterisk) ? (
              <label dir='ltr' className={classes.unit}>
                {value.toString().replace(/(?=(?!\b)(\d{3})+\.?\b)/g, ' ')}
              </label>
            ) : toUpper(trim(unit)) === toUpper(trim('Dhs/Mois')) &&
              asterisk ? (
              <label dir='ltr' className={classes.unit}>
                {value.toString().replace(/(?=(?!\b)(\d{3})+\.?\b)/g, ' ')}*
              </label>
            ) : (
              <label dir='ltr' className={classes.unit}>
                {value}
              </label>
            ))}
          {langSelected === LANG_STATE.AR &&
            (toUpper(trim(unit)) === toUpper(trim('درهم')) ||
            toUpper(trim(unit)) === toUpper(trim('درهم / شهر')) ? (
              <label dir='ltr' className={classes.unit}>
                {value.toString().replace(/(?=(?!\b)(\d{3})+\.?\b)/g, ' ')}
              </label>
            ) : (
              <label dir='ltr' className={classes.unit}>
                {value}
              </label>
            ))}
        </label>
        {!isEmpty(unit) && <label className={classes.unit}>{unit}</label>}
      </Typography>
    </div>
  );
}
