import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import { CustomInput } from 'common/components/customFields/CustomInput';
import CustomizedSwitches from 'common/components/customFields/CustomizedSwitches';
import InputNumber from 'common/components/customFields/InputNumber';
import SelectDown from 'common/components/customFields/SelectDown';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateFieldAction, getConfigAction } from '../../actions.situation';
import { selectCompWording } from 'common/wording/selectors.wording';
import {
  situationSalarieFields,
  salarieSituationConfig,
  familialSituation,
} from '../../constants.situation';
import { getSituationInfoByArraySelector } from '../../selector.situation';
import { CustomAutoComplete } from 'common/components/customFields/CustomAutoComplete';
import { CustomDatePicker } from 'common/components/customFields/CustomDatePicker';
import { CheckAuthorization } from 'common/components/PrivateRoute';
import { AccountTypes, ProductTypes } from 'features/auth/constants';
import Seo from 'common/components/seo/Seo';
import useProfProspectStyles from './styles/useProfProspectStyles';

const ProfessionnelProspect = () => {
  const classes = useProfProspectStyles();
  const dispatch = useDispatch();
  const wordingSalarie = useSelector(selectCompWording('detailedForm'));
  const errorsWording = useSelector(selectCompWording('errors'));

  const additionalIncomeNatureList = [
    {
      label: wordingSalarie?.foncierLoyer,
      value: '1',
    },
    {
      label: wordingSalarie?.placementsFinanciers,
      value: '2',
    },
    {
      label: wordingSalarie?.rente,
      value: '3',
    },
    {
      label: wordingSalarie?.pension,
      value: '4',
    },
    {
      label: wordingSalarie?.autresNature,
      value: '5',
    },
  ];

  const situationSalarie: any = useSelector(
    getSituationInfoByArraySelector(['situationSalarie', 'fields']),
  );
  const errors: any = useSelector(
    getSituationInfoByArraySelector(['situationSalarie', 'errors']),
  );
  const config: any = useSelector(
    getSituationInfoByArraySelector(['situationSalarie', 'config']),
  );
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (situationSalarie.familialSituation !== familialSituation.marie) {
      dispatch(updateFieldAction('situationSalarie', 'activeSpouse', false));
    }
    dispatch(
      updateFieldAction(
        'situationSalarie',
        event.target.name,
        event.target.value,
      ),
    );
  };

  const handleChangeCity = (
    event: React.ChangeEvent<{}>,
    city: { value: string; label: string },
  ) => {
    dispatch(updateFieldAction('situationSalarie', 'city', city));
  };

  useEffect(() => {
    dispatch(getConfigAction(salarieSituationConfig.familialSituation));
    dispatch(getConfigAction(salarieSituationConfig.activityArea));
    dispatch(getConfigAction(salarieSituationConfig.nationalities));
    dispatch(getConfigAction(salarieSituationConfig.cities));
    dispatch(getConfigAction(salarieSituationConfig.banks));
    dispatch(
      updateFieldAction('situationSalarie', 'type', AccountTypes.professionnel),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CheckAuthorization
      roles={{
        typeClients: [AccountTypes.professionnel],
        products: [ProductTypes.cp_slf],
      }}
    >
      <div className={classes.groupFormBody}>
        <Seo titleKey='title_13' keywordsKey='keywords_13' />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <SelectDown
              formLabel={wordingSalarie?.familialSituation}
              list={config[salarieSituationConfig.familialSituation]}
              label={wordingSalarie?.familialSituationPlaceHolder}
              name={situationSalarieFields.familialSituation}
              value={situationSalarie.familialSituation}
              onChange={handleChange}
              error={
                errors[situationSalarieFields.familialSituation]
                  ? errorsWording[
                      errors[situationSalarieFields.familialSituation]
                    ]
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {situationSalarie.familialSituation === familialSituation.marie && (
              <CustomizedSwitches
                formLabel={wordingSalarie?.activeSpouse}
                tooltip={wordingSalarie?.infoActiveSpouse}
                labelPrefix={wordingSalarie?.no}
                labelSufix={wordingSalarie?.yes}
                checked={situationSalarie.activeSpouse}
                onChange={handleChange}
                name={situationSalarieFields.activeSpouse}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomAutoComplete
              onChangeValue={handleChangeCity}
              options={config[salarieSituationConfig.cities]}
              name={situationSalarieFields.city}
              value={situationSalarie.city}
              formLabel={wordingSalarie?.city}
              placeholder={wordingSalarie?.cityOfResidencePlaceHolder}
              loadingText={'chargement'}
              error={
                errors[situationSalarieFields.city]
                  ? errorsWording[errors[situationSalarieFields.city]]
                  : null
              }
              hasDefaultBorder={false}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectDown
              formLabel={wordingSalarie?.nationality}
              list={config[salarieSituationConfig.nationalities]}
              label={wordingSalarie?.nationalityPlaceHolder}
              name={situationSalarieFields.nationality}
              value={situationSalarie.nationality}
              onChange={handleChange}
              error={
                errors[situationSalarieFields.nationality]
                  ? errorsWording[errors[situationSalarieFields.nationality]]
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputNumber
              formLabel={wordingSalarie?.numberPersonWhitoutChilds}
              onChange={handleChange}
              name={situationSalarieFields.numberPersonWhitoutChilds}
              value={situationSalarie.numberPersonWhitoutChilds}
              placeholder={wordingSalarie?.numberPersonWhitoutChildsPlaceHolder}
              suffix={''}
              endElement={''}
              hasDefaultBorder={true}
              error={
                errors[situationSalarieFields.numberPersonWhitoutChilds]
                  ? errorsWording[
                      errors[situationSalarieFields.numberPersonWhitoutChilds]
                    ]
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputNumber
              formLabel={wordingSalarie?.numberChilds}
              onChange={handleChange}
              name={situationSalarieFields.numberChilds}
              value={situationSalarie.numberChilds}
              placeholder={wordingSalarie?.numberChildsPlaceHolder}
              suffix={''}
              endElement={''}
              hasDefaultBorder={true}
              error={
                errors[situationSalarieFields.numberChilds]
                  ? errorsWording[errors[situationSalarieFields.numberChilds]]
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectDown
              formLabel={wordingSalarie?.activityArea}
              list={config[salarieSituationConfig.activityArea]}
              label={wordingSalarie?.activityAreaPlaceHolder}
              name={situationSalarieFields.activityArea}
              value={situationSalarie.activityArea}
              onChange={handleChange}
              error={
                errors[situationSalarieFields.activityArea]
                  ? errorsWording[errors[situationSalarieFields.activityArea]]
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomDatePicker
              isMinMax={true}
              formLabel={wordingSalarie?.activityStartDate}
              name={situationSalarieFields.dateCurrentEmployer}
              value={situationSalarie.dateCurrentEmployer}
              tooltip={wordingSalarie?.infoActivityStartDate}
              onChange={handleChange}
              format='MM/yyyy'
              views={['year', 'month']}
              placeholder={wordingSalarie?.activityStartDatePlaceHolder}
              hasDefaultBorder={true}
              error={
                errors[situationSalarieFields.dateCurrentEmployer]
                  ? errorsWording[
                      errors[situationSalarieFields.dateCurrentEmployer]
                    ]
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedSwitches
              formLabel={wordingSalarie?.retirment}
              tooltip={wordingSalarie?.retraiteRetirmentInfo}
              labelPrefix={wordingSalarie?.no}
              labelSufix={wordingSalarie?.yes}
              checked={situationSalarie.retirment}
              onChange={handleChange}
              name={situationSalarieFields.retirment}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputNumber
              formLabel={wordingSalarie?.monthlyIncome}
              tooltip={wordingSalarie?.infoMonthlyIncome}
              onChange={handleChange}
              hasDefaultBorder={true}
              name={situationSalarieFields.monthlyIncome}
              value={situationSalarie.monthlyIncome}
              placeholder={wordingSalarie?.placeHolderMonthlyIncome}
              suffix={''}
              endElement={wordingSalarie?.dhs}
              decimalSeparator=','
              error={
                errors[situationSalarieFields.monthlyIncome]
                  ? errorsWording[errors[situationSalarieFields.monthlyIncome]]
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedSwitches
              formLabel={wordingSalarie?.additionalIncome}
              tooltip={wordingSalarie?.infoAdditionalIncome}
              labelPrefix={wordingSalarie?.no}
              labelSufix={wordingSalarie?.yes}
              checked={situationSalarie.additionalIncome}
              onChange={handleChange}
              name={situationSalarieFields.additionalIncome}
            />
          </Grid>
          <Grid item xs={12} sm={6} /> {/* empty row */}
          {situationSalarie.additionalIncome === true && (
            <>
              <Grid item xs={12} sm={6}>
                <SelectDown
                  formLabel={wordingSalarie?.additionalIncomeNature}
                  list={additionalIncomeNatureList}
                  label={wordingSalarie?.additionalIncomeNaturePlaceHolder}
                  name={situationSalarieFields.additionalIncomeNature}
                  value={situationSalarie.additionalIncomeNature}
                  onChange={handleChange}
                  error={
                    errors[situationSalarieFields.additionalIncomeNature]
                      ? errorsWording[
                          errors[situationSalarieFields.additionalIncomeNature]
                        ]
                      : null
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputNumber
                  formLabel={wordingSalarie?.amountadditionalIncome}
                  onChange={handleChange}
                  hasDefaultBorder={true}
                  name={situationSalarieFields.amountadditionalIncome}
                  value={situationSalarie.amountadditionalIncome}
                  placeholder={
                    wordingSalarie?.placeHolderAmountadditionalIncome
                  }
                  suffix={''}
                  endElement={wordingSalarie?.dhs}
                  decimalSeparator=','
                  error={
                    errors[situationSalarieFields.amountadditionalIncome]
                      ? errorsWording[
                          errors[situationSalarieFields.amountadditionalIncome]
                        ]
                      : null
                  }
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={6}>
            <SelectDown
              formLabel={wordingSalarie?.codeOtherBank}
              list={config[salarieSituationConfig.banks]}
              label={wordingSalarie?.codeOtherBankPlaceHolder}
              tooltip={wordingSalarie?.codeOtherBankInfo}
              name={situationSalarieFields.codeOtherBank}
              value={situationSalarie.codeOtherBank}
              onChange={handleChange}
              error={
                errors[situationSalarieFields.codeOtherBank]
                  ? errorsWording[errors[situationSalarieFields.codeOtherBank]]
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomDatePicker
              isDefault={true}
              formLabel={wordingSalarie?.dateCreationOtherBankAccount}
              name={situationSalarieFields.dateCreationOtherBankAccount}
              value={situationSalarie.dateCreationOtherBankAccount}
              tooltip={wordingSalarie?.dateCreationOtherBankAccountInfo}
              onChange={handleChange}
              format='MM/yyyy'
              views={['year', 'month']}
              placeholder={
                wordingSalarie?.dateCreationOtherBankAccountPlaceholder
              }
              hasDefaultBorder={true}
              error={
                errors[situationSalarieFields.dateCreationOtherBankAccount]
                  ? errorsWording[
                      errors[
                        situationSalarieFields.dateCreationOtherBankAccount
                      ]
                    ]
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputNumber
              hasDefaultBorder={true}
              formLabel={wordingSalarie?.bankDebit}
              tooltip={wordingSalarie?.infoMonthlyTotalCredits}
              onChange={handleChange}
              name={situationSalarieFields.bankDebit}
              value={situationSalarie.bankDebit}
              placeholder={wordingSalarie?.placeHolderMonthlyTotalCredits}
              suffix={''}
              endElement={wordingSalarie?.dhs}
              decimalSeparator=','
              error={
                errors[situationSalarieFields.bankDebit]
                  ? errorsWording[errors[situationSalarieFields.bankDebit]]
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputNumber
              formLabel={wordingSalarie?.monthlyCreditImmovable}
              tooltip={wordingSalarie?.infoMonthlyCreditImmovable}
              onChange={handleChange}
              name={situationSalarieFields.monthlyCreditImmovable}
              value={situationSalarie.monthlyCreditImmovable}
              placeholder={wordingSalarie?.placeHolderMonthlyCreditImmovable}
              suffix={''}
              hasDefaultBorder={true}
              endElement={wordingSalarie?.dhs}
              decimalSeparator=','
              error={
                errors[situationSalarieFields.monthlyCreditImmovable]
                  ? errorsWording[
                      errors[situationSalarieFields.monthlyCreditImmovable]
                    ]
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputNumber
              formLabel={wordingSalarie?.mouvementCredit}
              tooltip={wordingSalarie?.mouvementCreditInfo}
              onChange={handleChange}
              name={situationSalarieFields.mouvementCredit}
              value={situationSalarie.mouvementCredit}
              placeholder={wordingSalarie?.mouvementCreditPlaceHolder}
              suffix={''}
              hasDefaultBorder={true}
              endElement={wordingSalarie?.dhs}
              decimalSeparator=','
              error={
                errors[situationSalarieFields.mouvementCredit]
                  ? errorsWording[
                      errors[situationSalarieFields.mouvementCredit]
                    ]
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputNumber
              formLabel={wordingSalarie?.mouvementDebit}
              tooltip={wordingSalarie?.mouvementDebitInfo}
              onChange={handleChange}
              name={situationSalarieFields.mouvementDebit}
              value={situationSalarie.mouvementDebit}
              placeholder={wordingSalarie?.mouvementDebitPlaceHolder}
              suffix={''}
              hasDefaultBorder={true}
              endElement={wordingSalarie?.dhs}
              decimalSeparator=','
              error={
                errors[situationSalarieFields.mouvementDebit]
                  ? errorsWording[errors[situationSalarieFields.mouvementDebit]]
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputNumber
              formLabel={wordingSalarie?.balanceEndOfMonth}
              tooltip={wordingSalarie?.balanceEndOfMonthInfo}
              onChange={handleChange}
              name={situationSalarieFields.balanceEndOfMonth}
              value={situationSalarie.balanceEndOfMonth}
              placeholder={wordingSalarie?.balanceEndOfMonthPlaceHolder}
              suffix={''}
              hasDefaultBorder={true}
              endElement={wordingSalarie?.dhs}
              decimalSeparator=','
              error={
                errors[situationSalarieFields.balanceEndOfMonth]
                  ? errorsWording[
                      errors[situationSalarieFields.balanceEndOfMonth]
                    ]
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.displayDiv} />
          <Grid item xs={12} sm={6}>
            <FormLabel className={classes.labelRenseing} component='legend'>
              {wordingSalarie?.cin}
            </FormLabel>
            <CustomInput
              value={situationSalarie.cin}
              name={situationSalarieFields.cin}
              onChange={handleChange}
              hasDefaultBorder={true}
              placeholder={wordingSalarie?.placeHolderCin}
              error={
                errors[situationSalarieFields.cin]
                  ? errorsWording[errors[situationSalarieFields.cin]]
                  : null
              }
            />
          </Grid>
        </Grid>
      </div>
    </CheckAuthorization>
  );
};

export default ProfessionnelProspect;
