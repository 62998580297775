import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import logo from 'assets/images/logo.png';
import logoCreditDaba from 'assets/svg/logoCreditDaba-dark.svg';
import { Wording } from 'common/wording/reducer.wording';
import { GlobalTheme } from 'features/app/app.theme';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import CreditReminder from './CreditReminder';
import CustomStepper from './Stepper/CustomStepper';
import Logout from './Logout';
import useIsAuthenticated from 'common/hooks/useIsAuthenticate';

const useStyles = makeStyles((theme: GlobalTheme) =>
  createStyles({
    root: {
      width: '40%',
      backgroundColor: '#ffffff',
      '& .MuiButton-contained': {
        '&:hover': {
          boxShadow: 'unset',
          backgroundColor: 'unset',
        },
      },
      '@media (max-width: 980px)': {
        width: '100%',
        borderRadius: '0 0 20px 20px',
        marginBottom: '35px',
      },
    },

    profile: {
      display: 'none',
      '@media (max-width: 980px)': {
        display: 'flex',
        justifyContent: 'flex-end',
      },
    },
    logo: {
      paddingLeft: 25,
      paddingTop: 12,
      display: 'block',
      width: '180px',
      '@media (max-width: 1024px)': {
        margin: '20px auto',
      },
    },
    logoCenter: {
      display: 'block',
      margin: 'auto',
      width: '350px',
      maxWidth: '85%',
      marginTop: '100px',
      '@media (max-width: 980px)': {
        display: 'none',
      },
    },
    logoButton: {
      backgroundColor: 'transparent',
      '@media (max-width: 980px)': {
        display: 'none',
      },
    },
    logoButton2: {
      display: 'none',
      color: 'unset',
      backgroundColor: 'unset',
      '&.MuiButton-contained': {
        '&:hover,&:focus, &:active': {
          boxShadow: 'unset',
          backgroundColor: 'unset',
        },
      },
      '@media (max-width: 980px)': {
        display: 'block',
      },
    },
    logoBmce: {
      '@media (max-width: 980px)': {
        display: 'block',
        width: '178px',
      },
      '@media (max-width: 320px)': {
        width: '135px',
      },
    },
  }),
);
interface Props {
  wording: Wording;
}

function LogoPage({ wording }: Props) {
  const classes = useStyles();
  const history = useHistory();
  const toHomePage = () => history.push('/credit-conso');
  const isAuthenticated = useIsAuthenticated();

  return (
    <div className={classes.root}>
      <Box m={{ xs: 2, sm: 3, md: 4.5, lg: 6 }}>
        <div className={classes.profile}>
          <Logout showIcon />
        </div>
        <Button
          variant='contained'
          type='button'
          onClick={toHomePage}
          className={classes.logoButton}
        >
          <img className={classes.logo} alt='logo' src={logo} />
        </Button>
        {!isAuthenticated && (
          <Button
            variant='contained'
            type='button'
            onClick={toHomePage}
            className={classes.logoButton2}
          >
            <img className={classes.logoBmce} alt='logo' src={logo} />
          </Button>
        )}

        <img
          className={classes.logoCenter}
          alt='logo-center'
          src={logoCreditDaba}
        />
        <CreditReminder />
        <CustomStepper />
      </Box>
    </div>
  );
}

export default LogoPage;
