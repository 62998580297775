import { Button, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { CustomInput } from 'common/components/customFields/CustomInput';
import { GlobalTheme } from 'features/app/app.theme';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createProfileBMCEFields, pushPageView } from '../constants';
import { selectCompWording } from 'common/wording/selectors.wording';
import { updateFieldAuthAction, AuthAT } from '../actions.auth';
import { getAuthInfoByArraySelector } from '../selector.auth';
import ConfirmationMailBMCE from './ConfirmationMailBmce';
import Spinner from 'common/components/Spinner';
import { useHistory } from 'react-router-dom';
import {
  ERROR_BMCE_SERVICE,
  CLIENT_WITH_MULTI_CPT,
  COMPTE_MULTI_TITULAIRE,
} from 'common/utils/validateJs/contants';
import errorUpload from 'assets/svg/errorUpload.svg';
import Seo from 'common/components/seo/Seo';
import PopUpInfo from '../../home/components/PopUpInfo';
import { getSimulationInfoByArraySelector } from 'features/home/selector.simulation';
import { LANG_STATE } from 'features/home/constants.simulation';
import { toUpper } from 'lodash';
import { GlobalError } from 'common/components/customFields/GlobalError';

const useStyles = makeStyles((theme: GlobalTheme) =>
  createStyles({
    root: {
      // width: '50%',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20px 70px',
    },
    form: {
      width: '90%',
      maxWidth: 430,
    },
    title: {
      textTransform: 'uppercase',
    },
    titleAr: {
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
    subtitle: {
      marginTop: 10,
      color: theme.secondary.title,
    },
    subtitle2: {
      marginBottom: 30,
      fontFamily: theme.fonts.bold,
      color: theme.primary.title,
    },
    validate: {
      backgroundColor: theme.primary.main,
      fontFamily: theme.fonts.bold,
      borderRadius: '40px',
      fontSize: '0.875rem',
      color: '#FFFFFF',
      marginTop: '20px',
      '&:hover': {
        boxShadow:
          '0px 3px 0.063em -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 0.063em 5px 0px rgba(0,0,0,0.12)',
        backgroundColor: '#00b2bbe6',
      },
    },
    previous: {
      fontFamily: theme.fonts.bold,
      borderRadius: '40px',
      fontSize: '0.875rem',
      color: theme.secondary.title,
      marginTop: '20px',
      border: '0.063em solid #556584',
    },

    spinner: {
      position: 'absolute',
      zIndex: 1,
      width: '80px',
    },
    error: {
      backgroundColor: '#F932761A',
      width: '100%',
      display: 'flex',
      marginTop: '40px',
    },
    errorMessage: {
      fontSize: '0.9375rem',
      color: '#556584',
      fontFamily: theme.fonts.regular,
    },
    iconErrorUp: {
      width: '43px',
    },

    buttonContainer: {
      marginTop: '40px',
      '& > *': {
        width: '100%',
        display: 'flex',
      },
    },
  }),
);

function CreationProfileBMCE() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const langSelected: any =
    useSelector(getSimulationInfoByArraySelector(['lang'])) || LANG_STATE.FR;

  const signUpBMCEDirect: any = useSelector(
    getAuthInfoByArraySelector(['signUpBMCEDirect']),
  );
  const errors: any = useSelector(
    getAuthInfoByArraySelector(['signUpBMCEDirect', 'errors']),
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updateFieldAuthAction('signUpBMCEDirect', e.target.name, e.target.value),
    );
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch({ type: AuthAT.submitSignUpBMCEDirect });
  };

  const createProfileWording = useSelector(
    selectCompWording('createProfileBMCE'),
  );
  const errorsWording = useSelector(selectCompWording('errors'));

  const sendingEmail = useSelector(
    getAuthInfoByArraySelector(['signUpBMCEDirect', 'sendingEmail']),
  );

  const history = useHistory();
  const retunClick = () => history.push('/create-profile');
  const handleClose = () => {
    dispatch(
      updateFieldAuthAction('signUpBMCEDirect', 'checkValidateClient', false),
    );
  };
  const hasError =
    errors && errors.email && errors.email.includes(toUpper('duplicate_email'));

  useEffect(() => {
    pushPageView('step_1_creation_bmce');
  }, []);

  return (
    <>
      <Seo titleKey='title_7' keywordsKey='keywords_7' />
      <div className={classes.root}>
        <Spinner loader={signUpBMCEDirect.loader} />
        {errors.global &&
          (errors.global[0] === COMPTE_MULTI_TITULAIRE ||
            errors.global[0] === CLIENT_WITH_MULTI_CPT) && (
            <PopUpInfo
              open={signUpBMCEDirect.checkValidateClient}
              onClose={handleClose}
              error={errors.global[0]}
            />
          )}
        {!sendingEmail && (
          <>
            <Typography
              variant='h3'
              className={
                langSelected === LANG_STATE.FR ? classes.title : classes.titleAr
              }
            >
              {createProfileWording?.title}
            </Typography>
            {errors.global && errors.global[0] === ERROR_BMCE_SERVICE && (
              <div className={classes.error}>
                <img src={errorUpload} alt='' className={classes.iconErrorUp} />
                <p className={classes.errorMessage}>
                  {errorsWording[ERROR_BMCE_SERVICE]}
                </p>
              </div>
            )}
            <Typography variant='h5' className={classes.subtitle}>
              {createProfileWording?.subtitle}
            </Typography>
            <Typography variant='h5' className={classes.subtitle2}>
              {createProfileWording?.subtitle2}
            </Typography>
            {hasError && (
              <GlobalError
                globalError={errors.email}
                errorWording={errorsWording}
                linkContact={errorsWording.SIGNIN_LABEL_LINK}
              />
            )}
            <form
              className={classes.form}
              noValidate
              autoComplete='off'
              onSubmit={handleSubmit}
              style={
                langSelected === LANG_STATE.FR
                  ? { direction: 'ltr' }
                  : { direction: 'rtl' }
              }
            >
              <CustomInput
                type='text'
                value={signUpBMCEDirect[createProfileBMCEFields.idBMCEDirect]}
                name={createProfileBMCEFields.idBMCEDirect}
                onChange={handleChange}
                // to prevent user from writing "e", ina number fields
                onKeyDown={(evt) => evt.keyCode === 69 && evt.preventDefault()}
                formLabel={createProfileWording?.loginHolder}
                placeholder={createProfileWording?.loginHolder}
                error={
                  errors[createProfileBMCEFields.idBMCEDirect]
                    ? errorsWording[
                        errors[createProfileBMCEFields.idBMCEDirect][0]
                      ]
                    : null
                }
              />
              <CustomInput
                type='password'
                value={signUpBMCEDirect[createProfileBMCEFields.pwdBMCEDirect]}
                name={createProfileBMCEFields.pwdBMCEDirect}
                onChange={handleChange}
                formLabel={createProfileWording?.padHolder}
                placeholder={createProfileWording?.padHolder}
                error={
                  errors[createProfileBMCEFields.pwdBMCEDirect]
                    ? errorsWording[
                        errors[createProfileBMCEFields.pwdBMCEDirect][0]
                      ]
                    : null
                }
              />
              <div className={classes.buttonContainer}>
                <Button
                  className={classes.previous}
                  type='button'
                  onClick={retunClick}
                >
                  {createProfileWording?.btnPrevious}
                </Button>
                <Button type='submit' className={classes.validate}>
                  {createProfileWording?.btnValidat}
                </Button>
              </div>
            </form>
          </>
        )}
        {sendingEmail && <ConfirmationMailBMCE />}
      </div>
    </>
  );
}

export default CreationProfileBMCE;
