import React from 'react';
import Helmet from 'react-helmet';
import Homepage from 'assets/images/homepage.png';
import logo from 'assets/images/logo.png';
import { selectCompWording } from 'common/wording/selectors.wording';
import {
  url,
  social,
  type,
  structuredDataOrganization,
  legalName,
  local_fr,
  local_ar,
  home_url,
} from './config';
import { useSelector } from 'react-redux';
import { getSimulationInfoByArraySelector } from 'features/home/selector.simulation';
import { LANG_STATE } from 'features/home/constants.simulation';
import SeoDefaultLocalWording from './seoWording';

interface ISeo {
  titleKey?: string;
  descriptionKey?: string;
  keywordsKey?: string;
  cover?: string;
  location?: string;
}

/* #########    titleKey AND keywordsKey most be one of the keys in the wording file otherwase it will take default keys    #########*/

const Seo: React.FC<ISeo> = ({
  titleKey,
  descriptionKey,
  keywordsKey,
  cover,
  location = '',
  children,
}) => {
  const seoWording = useSelector(selectCompWording('seo')) || {};
  const wording = useSelector(selectCompWording('homePage'));
  const langSelected: any =
    useSelector(getSimulationInfoByArraySelector(['lang'])) || LANG_STATE.FR;

  const targetWording =
    langSelected === LANG_STATE.FR
      ? SeoDefaultLocalWording.FR
      : SeoDefaultLocalWording.AR;
  /* ##### GET TITLE ##### */
  const title = titleKey
    ? seoWording[titleKey] || targetWording[titleKey]
    : seoWording.defaultTitle;

  /* ##### GET KEYWORDS ##### */
  const keywords = keywordsKey
    ? seoWording[keywordsKey] || targetWording[keywordsKey]
    : seoWording.defaultKeywords;

  /* ##### GET DESCRIPTION ##### */
  const description = descriptionKey
    ? seoWording[descriptionKey] || targetWording[descriptionKey]
    : seoWording.defaultDescription;

  return (
    <Helmet>
      {/* The description that appears under the title of your website appears on search engines results */}
      <meta name='description' content={description} />
      <meta name='keywords' content={keywords} />
      <link href={logo} rel='icon' />
      {/* The thumbnail of website */}
      <meta
        name='image'
        content={cover ? `${url}${cover}` : `${url}${Homepage}`}
      />

      {/* Opengraph meta tags for Facebook & LinkedIn */}
      <meta
        property='og:local'
        content={langSelected === LANG_STATE.FR ? local_fr : local_ar}
      />
      <meta property='og:url' content={`${url}${home_url}`} />
      <meta property='og:site_name' content={legalName} />
      <meta property='og:type' content={type} />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      <meta
        property='og:image'
        content={cover ? `${url}${cover}` : `${url}${Homepage}`}
      />

      {/* You can get this id when you create an app id on Facebook of your Facebook page */}
      <meta
        property={`fb:${social.facebook.id}`}
        content={social.facebook.link}
      />

      {/* These tags work for Twitter & Slack, notice I've included more custom tags like reading time etc... */}
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:creator' content={social.twitter.creator} />
      <meta name='twitter:site' content={social.twitter.id} />
      <meta name='twitter:title' content={title} />
      <meta name='twitter:description' content={description} />
      <meta
        name='twitter:image:src'
        content={cover ? `${url}${cover}` : `${url}${Homepage}`}
      />

      {/* Structured data */}
      <script type='application/ld+json'>{structuredDataOrganization}</script>

      <title>{title}</title>

      {/* Default language and direction */}
      <html
        lang={
          langSelected === LANG_STATE.FR
            ? wording?.labelfr
            : LANG_STATE.AR_LABEL
        }
        dir={
          langSelected === LANG_STATE.FR
            ? wording?.directionfr
            : wording?.directionar
        }
      />

      {children && children}
    </Helmet>
  );
};

export default Seo;
