import { apiPost } from 'common/utils/request.util';
import { submitOtpConstraints } from 'common/utils/validateJs/constraints';
import { costumValidate } from 'common/utils/validateJs/validateJs';
import { delay, put, select, takeLatest } from 'redux-saga/effects';
import {
  SimulationAT,
  updateFieldByArrayAction,
  SubmitOtpAction,
  ResendOtpAction,
  ModifySimulationAction,
  SubmitToProfilAction,
  SubmitSimulationCreaEspaceAction,
} from './actions.simulation';
import {
  SIMULATION_STATE,
  COMPANY_NAME,
  TEL_VALID,
  CODE_CONVENTION,
} from 'features/home/constants.simulation';
import { getSimulationInfoByArraySelector } from './selector.simulation';
import { getValidationInfoByArraySelector } from 'features/validationBmceDirect/selector.validation';
import {
  getSessionStorage,
  setSessionStorage,
} from 'common/utils/sessionStorage.util';
import { extractError } from 'common/utils/auth.util';
import {
  ERROR_OTP_SERVICE_NBR_TENTATIVE_MAX,
  ERROR_VALID_TEL,
  //INVALID_DATE_FORMAT,
  REQUIRED_GSM,
  INVALID_PHONE_NUMBER,
  //REQUIRED,
  E3,
  E34,
  E35,
  E36,
  E37,
  E38,
  E39,
} from 'common/utils/validateJs/contants';
import {
  SituationAT,
  updateFieldAction,
} from 'features/situation/actions.situation';
import { push } from 'connected-react-router';
import { UpdateInformationAT } from 'features/updateInformation/actions.updateInformation';
import { find, assign, isEmpty, size, startsWith } from 'lodash';
//import moment from 'moment';
import { scrollToElement } from 'common/utils/app.util';
import {
  AccountTypes,
  ProductTypes,
  etapeClientEspacePersoACreer,
  pushEvent,
} from 'features/auth/constants';
import { saveUserProfileAndRedirect } from 'features/auth/sagas.auth';

export function* submitSimulation(action: any) {
  try {
    let simulation = yield select(
      getSimulationInfoByArraySelector(['simulation', 'fields']),
    );
    // let selectedConvention = yield select(
    //   getSimulationInfoByArraySelector(['simulation', 'selectedConvention']),
    // );
    let fromBMCEDirect = yield select(
      getValidationInfoByArraySelector(['data']),
    );
    // From BMCE DIRECT
    if (!isEmpty(fromBMCEDirect)) {
      simulation.fromBmceDirect = true;
      simulation.redirectViaBmceDirect = true;
      simulation.cin = fromBMCEDirect.cin;
      // Rib Simulation BMCE DIRECT
      simulation.cmptBq = fromBMCEDirect.rib;
    } else {
      simulation.redirectViaBmceDirect = false;
      simulation.fromBmceDirect = false;
    }

    /** empty RACHAT if user selected No **/
    if (simulation.rachat === 'N') {
      simulation.rachatMntEch = '';
      simulation.rachatMntReste = '';
    }

    /** remove RACHAT field if clientType is 'professionnel' **/
    if (simulation.typeClient === AccountTypes.professionnel) {
      delete simulation.rachat;
      delete simulation.rachatMntReste;
      delete simulation.rachatMntEch; // to be verified
    }

    yield put({
      type: SimulationAT.submitSimulationError,
      errors: {},
    });

    const errors = {};

    // if (!simulation.birthday) {
    //   assign(errors, { birthday: [INVALID_DATE_FORMAT] });
    // }
    // const birthday = new Date(simulation.birthday);
    // simulation.birthday = birthday;
    // if (!moment(simulation.birthday, 'dd/MM/yyyy').isValid()) {
    //   assign(errors, { birthday: [INVALID_DATE_FORMAT] });
    // }

    // if (
    //   simulation.codeConvention === CODE_CONVENTION.X8 ||
    //   simulation.codeConvention === CODE_CONVENTION.X9
    // ) {
    //   simulation.companyName = COMPANY_NAME.other;
    // }

    // if (isEmpty(simulation.companyName)) {
    //   assign(errors, { companyName: [REQUIRED] });
    // }
    // if (
    //   isEmpty(simulation.nameOtherEmploye) &&
    //   isEmpty(simulation.companyName)
    // ) {
    //   assign(errors, { nameOtherEmploye: [REQUIRED] });
    // }
    if (size(simulation.phone) !== 10) {
      assign(errors, { phone: [INVALID_PHONE_NUMBER] });
    }
    if (
      !startsWith(simulation.phone, TEL_VALID.TEL_A) &&
      !startsWith(simulation.phone, TEL_VALID.TEL_B)
    ) {
      assign(errors, { phone: [INVALID_PHONE_NUMBER] });
    }
    if (isEmpty(simulation.phone)) {
      assign(errors, { phone: [REQUIRED_GSM] });
    }
    if (!isEmpty(errors)) {
      yield put({ type: SimulationAT.submitSimulationError, errors });
      return;
    }
    /* Type Client
     *  Salarie : 1 et 2
     *  Fonctionnaire :  2 et 3 (2 redirect vers salarie)
     *  Retrité : 4
     * */
    // if (
    //   simulation.typeClient === AccountTypes.fonctionnaire &&
    //   selectedConvention.type === '2'
    //   ) {
    //   simulation.typeClient = AccountTypes.salarie;
    //   yield put(
    //     updateFieldByArrayAction(
    //       ['simulation', 'fields', 'typeClient'],
    //       AccountTypes.salarie,
    //     ),
    //   );
    // }
    setSessionStorage('typeclient', simulation.typeClient);
    setSessionStorage('clientBOA', simulation.clientBOA);
    // End
    simulation = updateSimulationInput(simulation, action.paramToInit);
    yield put(
      updateFieldByArrayAction(
        ['simulation', 'response', 'product'],
        simulation.product,
      ),
    );

    yield put(
      updateFieldAction('situationSalarie', 'clientBOA', simulation.clientBOA),
    );
    yield put(
      updateFieldAction(
        'situationSalarie',
        'openAccountBOA',
        simulation.openAccountBOA,
      ),
    );

    yield put(
      updateFieldByArrayAction(['simulation', 'response', 'loader'], true),
    );

    // if (simulation.companyName !== COMPANY_NAME.other) {
    //   simulation.segClient = SegClientTypes.C;
    // } else {
    //   simulation.segClient = SegClientTypes.NC;
    // }
    const preAttributionBmceDirect: any = yield select(
      getValidationInfoByArraySelector(['preAttribution']),
    );
    if (preAttributionBmceDirect) {
      simulation.preattribue = 'O';
    } else {
      simulation.preattribue = 'N';
    }
    // SEND Simulation
    simulation.cin = getSessionStorage('cin');
    const { data } = yield apiPost('/simulation/simulate', simulation);
    yield put({ type: SimulationAT.getSimulationSuccess, data });
    setSessionStorage('simulationId', data.idSimulation);
    setSessionStorage('identifiant_otp', data.otpId);
    // if (!isEmpty(fromBMCEDirect)) {
    //   setSessionStorage('birthday', simulation.birthday);
    // }
    setSessionStorage('rachat', simulation.rachat);

    yield put(
      updateFieldByArrayAction(['simulation', 'response', 'loader'], false),
    );

    if (
      // simulation.companyName !== COMPANY_NAME.other &&
      // simulation.companyName !== '' &&
      data.anomalieCode === ERROR_VALID_TEL
    ) {
      yield put({
        type: SimulationAT.updateState,
        value: SIMULATION_STATE.confirmationOtp,
      });
    } else if (data.anomalieCode !== E3) {
      if (action.type !== 'SIMULATIONAT/REFAIRESIMULATION') {
        yield put({
          type: SimulationAT.updateState,
          value: SIMULATION_STATE.recapSimulation,
        });
      } else {
        yield put({
          type: SimulationAT.updateState,
          value: SIMULATION_STATE.remakeSimulation,
        });
      }
    } else {
      yield put({
        type: SimulationAT.submitSimulationError,
        errors: {
          global: [data.anomalieCode],
        },
      });
      yield put({
        type: SimulationAT.updateState,
        value: SIMULATION_STATE.remakeSimulation,
      });
      scrollToElement('global-error');
      return;
    }
  } catch (error) {
    yield put(
      updateFieldByArrayAction(['simulation', 'response', 'loader'], false),
    );
    if (error.response && error.response.status === 400) {
      yield put({
        type: SimulationAT.submitSimulationError,
        errors: extractError(error),
      });
      yield put(
        updateFieldByArrayAction(
          ['simulation', 'fields', 'checkValidateSimulation'],
          true,
        ),
      );
      const errors = extractError(error);
      if (
        errors.global[0] === ERROR_OTP_SERVICE_NBR_TENTATIVE_MAX ||
        errors.global[0] === ERROR_VALID_TEL
      ) {
        yield put({
          type: SimulationAT.updateState,
          value: SIMULATION_STATE.confirmationOtp,
        });
      }
      if (
        errors.global[0] === E34 ||
        errors.global[0] === E35 ||
        errors.global[0] === E36 ||
        errors.global[0] === E37 ||
        errors.global[0] === E38 ||
        errors.global[0] === E39
      ) {
        yield put({
          type: SimulationAT.submitSimulationError,
          errors: {
            global: [errors.global[0]],
          },
        });
        yield put({
          type: SimulationAT.updateState,
          value: SIMULATION_STATE.remakeSimulation,
        });
        scrollToElement('global-error');
        return;
      }
    }
  }
}

export function* submitSimulationCreaEspace(
  action: SubmitSimulationCreaEspaceAction,
) {
  const { simulationResponse } = action;
  if (!isEmpty(simulationResponse)) {
    // Begin Track Simulation by salafin
    const tracker = {
      idSimulation: simulationResponse.idSimulation,
      etapeClient: etapeClientEspacePersoACreer,
    };
    yield apiPost('/simulation/encreaEspace', tracker);
    // End Track
  }
}

export function* modifySimulation(action: ModifySimulationAction) {
  try {
    const preAttributionBmceDirect = yield select(
      getValidationInfoByArraySelector(['preAttribution']),
    );
    const simulation = yield select(
      getSimulationInfoByArraySelector(['simulation', 'fields']),
    );
    yield put(
      updateFieldAction(
        'situationSalarie',
        'creditAmount',
        simulation?.creditAmount,
      ),
    );

    yield put(
      updateFieldAction(
        'situationSalarie',
        'creditDuration',
        simulation?.creditDuration,
      ),
    );
    if (preAttributionBmceDirect) {
      yield put(
        updateFieldByArrayAction(
          ['simulation', 'modifySimulationPreAtt'],
          true,
        ),
      );
      yield put(
        updateFieldByArrayAction(['simulation', 'response', 'loader'], true),
      );
    }

    preAttributionBmceDirect
      ? yield put({ type: SituationAT.submitPreattSituationSalarie })
      : yield put({ type: SituationAT.submitSituationSalarie });
  } catch (error) {
    console.error(error);
  }
}

export function* submitToProfil(action: SubmitToProfilAction) {
  try {
    yield put(
      updateFieldByArrayAction(['simulation', 'response', 'loader'], true),
    );
    const simulation = yield select(
      getSimulationInfoByArraySelector(['simulation', 'fields']),
    );
    yield put(
      updateFieldAction(
        'situationSalarie',
        'creditAmount',
        simulation?.creditAmount,
      ),
    );

    yield put(
      updateFieldAction(
        'situationSalarie',
        'creditDuration',
        simulation?.creditDuration,
      ),
    );

    const { data } = yield apiPost('/situation/recap', '');
    yield put({ type: UpdateInformationAT.updateInformation, data });
    setSessionStorage('updateInformation', data);
    yield put(
      updateFieldByArrayAction(['simulation', 'response', 'loader'], false),
    );
    yield put(push('/update-information'));
  } catch (error) {
    yield put(
      updateFieldByArrayAction(['simulation', 'response', 'loader'], false),
    );
    console.error(error);
  }
}

export function* submitOtp(action: SubmitOtpAction) {
  try {
    const simulation = yield select(
      getSimulationInfoByArraySelector(['simulation', 'fields']),
    );
    const errors = costumValidate(simulation, submitOtpConstraints());
    if (errors) {
      yield put({ type: SimulationAT.submitSimulationError, errors });
      return;
    }
    const idSimulation = getSessionStorage('simulationId');
    const otpId = getSessionStorage('identifiant_otp');
    const otp = simulation.otp;
    const { data } = yield apiPost('/simulation/validPhone', {
      idSimulation,
      otpId,
      otp,
      phone: simulation.phone,
    });
    yield put({ type: SimulationAT.getSimulationSuccess, data });
    yield put({
      type: SimulationAT.updateState,
      value: SIMULATION_STATE.recapSimulation,
    });
    pushEvent({ event: 'form_submit', form_type: 'OTP_validation' });
  } catch (error) {
    if (error.response && error.response.status === 400) {
      yield put({
        type: SimulationAT.submitSimulationError,
        errors: extractError(error),
      });
      yield put(
        updateFieldByArrayAction(['simulation', 'response', 'loader'], false),
      );
      const errors = extractError(error);
      if (errors.global[0] === E3) {
        yield put({ type: SimulationAT.submitSimulationError, errors });
        yield put({
          type: SimulationAT.updateState,
          value: SIMULATION_STATE.remakeSimulation,
        });
      }
    }
  }
}

export function* resendOtp(action: ResendOtpAction) {
  try {
    const simulation = yield select(
      getSimulationInfoByArraySelector(['simulation', 'fields']),
    );
    const { data } = yield apiPost('/auth/send_sms', {
      phone: simulation.phone,
    });
    setSessionStorage('identifiant_otp', data.otpId);
  } catch (error) {
    if (error.response && error.response.status === 400) {
      yield put({
        type: SimulationAT.submitSimulationError,
        errors: extractError(error),
      });
    }
  }
}

export function* getConvention(action: any) {
  try {
    // const typeClient =
    //   action.typeClient === AccountTypes.salarie
    //     ? ['1', '2']
    //     : action.typeClient === AccountTypes.fonctionnaire
    //     ? ['2', '3']
    //     : ['4'];

    const type = yield select(
      getSimulationInfoByArraySelector(['simulation', 'fields', 'typeClient']),
    );
    const openAccountBOA = yield select(
      getSimulationInfoByArraySelector([
        'simulation',
        'fields',
        'openAccountBOA',
      ]),
    );
    const clientBOA = yield select(
      getSimulationInfoByArraySelector(['simulation', 'fields', 'clientBOA']),
    );

    const typeClient =
      type === AccountTypes.salarie || type === AccountTypes.professionnel
        ? ['1', '2']
        : type === AccountTypes.fonctionnaire
        ? ['2', '3']
        : ['4'];

    const { data } = yield apiPost('/form-config/find-conventions', {
      value: action.value,
      type: action.value === COMPANY_NAME.other ? ['4'] : typeClient,
    });
    // ADD OTHERS
    const others = {
      codeConvention: 'X8',
      companyName: COMPANY_NAME.other,
      companyNameAR: COMPANY_NAME.otherAr,
      idConvention: 0,
      maxAmount: 200000,
      maxDuration: 84,
      maxMonthlyPayment: 0,
      minAmount: 10000,
      minDuration: 12,
      minMonthlyPayment: 0,
      parentConvention: '',
      type: '4',
    };

    data.unshift(others);

    //

    let selectedConvention = {};

    if (!openAccountBOA && !clientBOA) {
      selectedConvention = find(data, ['codeConvention', CODE_CONVENTION.X9]);
    } else {
      selectedConvention = find(data, ['codeConvention', CODE_CONVENTION.X8]);
    }

    if (action.value === COMPANY_NAME.other && selectedConvention) {
      yield put({
        type: SimulationAT.updateConvention,
        data: [selectedConvention],
      });
      yield put(
        updateFieldByArrayAction(
          ['simulation', 'convention'],
          [selectedConvention],
        ),
      );
      yield put(
        updateFieldByArrayAction(
          ['simulation', 'selectedConvention'],
          selectedConvention,
        ),
      );
      return;
    }

    yield put({ type: SimulationAT.updateConvention, data });
  } catch (e) {
    console.error(e);
  }
}

export function updateSimulationInput(simulation: any, paramToInit: string) {
  const newSimulation = { ...simulation };
  delete newSimulation.convention;
  delete newSimulation.errors;
  delete newSimulation[paramToInit];
  // BEGIN GESTION PRODUCT
  /* ####### PROSPECT IS OR WILL BE CLIENT OF BOA) #####*/
  if (newSimulation.clientBOA || newSimulation.openAccountBOA) {
    if (
      // client Type is SALARIE OR RETRAITE
      newSimulation.typeClient === AccountTypes.salarie ||
      newSimulation.typeClient === AccountTypes.retraite
    ) {
      newSimulation.product = ProductTypes.bconvcp;
    } else if (
      // client Type is PROFESSIONNEL
      newSimulation.typeClient === AccountTypes.professionnel
    ) {
      newSimulation.product = ProductTypes.cp;
    }
    // client Type is FONCIONNAIRE
    else {
      newSimulation.product = ProductTypes.bconvppr;
    }
  }
  // PROSPECT IS NOT OR WILL NOT BE CLIENT OF BOA
  else {
    newSimulation.product = ProductTypes.cp_slf;
  }
  // END GESTION PRODUCT
  // if (newSimulation.birthday !== '') {
  //   newSimulation.birthday = newSimulation?.birthday?.toLocaleDateString(
  //     'fr-FR',
  //   );
  //}
  return newSimulation;
}

export function* getSimulation(action: any) {
  try {
    let simulation: any = yield select(
      getSimulationInfoByArraySelector(['simulation', 'fields']),
    );
    simulation = updateSimulationInput(simulation, action.paramToInit);
    yield put(
      updateFieldByArrayAction(
        ['simulation', 'response', 'product'],
        simulation.product,
      ),
    );

    const { data } = yield apiPost('/simulation/simulate', simulation);

    yield put({ type: SimulationAT.getSimulationSuccess, data });
    setSessionStorage('simulationId', data.idSimulation);
  } catch (e) {
    console.error(e);
    yield put(
      updateFieldByArrayAction(['simulation', 'response', 'loader'], false),
    );
  }
}

export function* simulateWithLoader(action: any) {
  try {
    let simulation: any = yield select(
      getSimulationInfoByArraySelector(['simulation', 'fields']),
    );
    yield put(
      updateFieldByArrayAction(['simulation', 'response', 'loader'], true),
    );
    yield delay(3000);
    yield put(
      updateFieldByArrayAction(['simulation', 'response', 'loader'], false),
    );
    simulation = updateSimulationInput(simulation, action.paramToInit);
    yield put(
      updateFieldByArrayAction(
        ['simulation', 'response', 'product'],
        simulation.product,
      ),
    );

    const { data } = yield apiPost('/simulation/simulate', simulation);

    yield put({ type: SimulationAT.getSimulationSuccess, data });
    setSessionStorage('simulationId', data.idSimulation);
  } catch (e) {
    console.error(e);
    yield put(
      updateFieldByArrayAction(['simulation', 'response', 'loader'], false),
    );
  }
}
export function* modifyRecap() {
  try {
    const user = getSessionStorage('userProfile');
    const preAttributionBmceDirect = yield select(
      getValidationInfoByArraySelector(['preAttribution']),
    );
    if (isEmpty(user.typeClient)) {
      user.typeClient = getSessionStorage('typeclient');
    }
    const userProfile = {
      clientBOA: user.clientBOA,
      typeClient: user.typeClient,
      product: user.product,
      stepClient: '2',
      preAttribution: preAttributionBmceDirect,
    };
    yield saveUserProfileAndRedirect(userProfile);
  } catch (error) {
    console.info(error);
  }
}

export function* preAttributionRoute() {
  try {
    const typeClient = yield select(
      getSimulationInfoByArraySelector(['simulation', 'fields', 'typeClient']),
    );
    const product = yield select(
      getSimulationInfoByArraySelector(['simulation', 'response', 'product']),
    );

    const clientBOA = yield select(
      getSimulationInfoByArraySelector(['simulation', 'fields', 'clientBOA']),
    );
    const userProfile = {
      clientBOA,
      typeClient:
        typeClient === AccountTypes.salarie ? AccountTypes.salarie : typeClient,
      product,
      stepClient: '2',
    };
    yield saveUserProfileAndRedirect(userProfile);
  } catch (error) {
    console.error(error);
  }
}

export function* submitSimulationSaga() {
  yield takeLatest(SimulationAT.submitSimulation, submitSimulation);
}

export function* refaireSimulationSaga() {
  yield takeLatest(SimulationAT.refaireSimulation, submitSimulation);
}

export function* submitSimulationCreaEspaceSaga() {
  yield takeLatest(
    SimulationAT.submitSimulationCreaEspace,
    submitSimulationCreaEspace,
  );
}

export function* modifySimulationSaga() {
  yield takeLatest(SimulationAT.modifySimulation, modifySimulation);
}

export function* submitToProfilSaga() {
  yield takeLatest(SimulationAT.submitToProfil, submitToProfil);
}

export function* getConventionSaga() {
  yield takeLatest(SimulationAT.getConvention, getConvention);
}

export function* getSimulationSaga() {
  yield takeLatest(SimulationAT.getSimulation, getSimulation);
}

export function* submitOtpSaga() {
  yield takeLatest(SimulationAT.submitOtp, submitOtp);
}

export function* simulateWithLoaderSaga() {
  yield takeLatest(SimulationAT.simulateWithLoader, simulateWithLoader);
}

export function* resendOtpSaga() {
  yield takeLatest(SimulationAT.resendOtp, resendOtp);
}
export function* preAttributionRouteSage() {
  yield takeLatest(SimulationAT.preAttributionRoute, preAttributionRoute);
}
export function* modifyRecapSaga() {
  yield takeLatest(SimulationAT.modifyRecap, modifyRecap);
}

export const simulationSagas = [
  submitSimulationSaga,
  refaireSimulationSaga,
  getConventionSaga,
  getSimulationSaga,
  submitOtpSaga,
  simulateWithLoaderSaga,
  resendOtpSaga,
  modifySimulationSaga,
  submitToProfilSaga,
  submitSimulationCreaEspaceSaga,
  preAttributionRouteSage,
  modifyRecapSaga,
  // preAttribution redirect after preAttributionRoute Saga (dispatched in recap)
];
