import { makeStyles } from '@material-ui/core/styles';
import { Wording } from 'common/wording/reducer.wording';
import React from 'react';
import { selectWording } from 'common/wording/selectors.wording';
import { useSelector } from 'react-redux';
import { GlobalTheme } from 'features/app/app.theme';
import CustomTitle from 'common/components/customFields/CustomTitle';
import Open from 'assets/svg/Open.svg';
import Close from 'assets/svg/Close.svg';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { Link } from '@material-ui/core';
import { getSimulationInfoByArraySelector } from 'features/home/selector.simulation';
import { LANG_STATE } from 'features/home/constants.simulation';
import { pushEvent } from 'features/auth/constants';

const useStyles = makeStyles((theme: GlobalTheme) => ({
  root: {
    width: '100%',
    marginTop: '30px',
  },
  bodyContent: {
    width: '75%',
    margin: 'auto',
    backgroundColor: '#FFF',
    '& .MuiAccordion-root': {
      padding: '10px 75px',
      position: 'unset',
      boxShadow: 'unset',
      '@media (max-width: 768px)': {
        padding: '2px 10px',
      },
    },
    '& .MuiAccordion-root:first-child': {
      paddingTop: '33px',
      '@media (max-width: 768px)': {
        paddingTop: '15px',
      },
    },
    '& .MuiAccordion-root:last-child': {
      paddingBottom: '33px',
      '@media (max-width: 768px)': {
        paddingTop: '15px',
      },
    },
    borderRadius: '30px',
    '& .MuiAccordion-rounded:first-child': {
      borderTopLeftRadius: '30px !important',
      borderTopRightRadius: '30px  !important',
    },
    '& .MuiAccordion-rounded:last-child': {
      borderBottomLeftRadius: '30px !important',
      borderBottomRightRadius: '30px  !important',
    },
    '@media (max-width: 768px)': {
      width: '90%',
    },
  },
  heading: {
    fontSize: '1.125rem',
    color: theme.primary.title,
    fontFamily: theme.fonts.medium,
    '@media (max-width: 768px)': {
      fontSize: '0.95rem',
    },
  },
  headingAr: {
    fontSize: '1.125rem',
    fontWeight: 600,
    color: theme.primary.title,
    fontFamily: theme.fonts.medium,
    '@media (max-width: 768px)': {
      fontSize: '0.95rem',
    },
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column',
  },
  contentDetails: {
    fontSize: '0.9375rem',
    color: theme.primary.title,
    fontFamily: theme.fonts.regular,
    marginBottom: '4px',
    '@media (max-width: 768px)': {
      fontSize: '0.8rem',
    },
  },
  someText: {
    fontSize: '0.8125rem',
  },
  bold: {
    fontFamily: theme.fonts.bold,
  },
  pink: {
    color: theme.secondary.main,
  },
  separator: {
    backgroundColor: '#cbd1dc',
    height: '1px',
    width: '88%',
    margin: 'auto',
  },
  link: {
    textDecoration: 'underline',
    color: theme.secondary.title,
  },
  colordesc: {
    color: theme.primary.main,
  },
  grey: {
    color: theme.secondary.title,
  },
  underline: {
    textDecoration: 'underline',
    margin: '10px 0',
  },
}));

const FAQ = () => {
  const classes = useStyles();
  const langSelected: any =
    useSelector(getSimulationInfoByArraySelector(['lang'])) || LANG_STATE.FR;
  const wording: Wording = useSelector(selectWording);
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean,
  ) => {
    setExpanded(isExpanded ? panel : false);
    let eventObj = {
      event: 'click_faq',
      click_label: wording?.FAQ.QstOne,
      element_category: 'body',
    };
    switch (panel) {
      case 'panel1':
        eventObj.click_label = wording?.FAQ.QstOne;
        break;
      case 'panel2':
        eventObj.click_label = wording?.FAQ.QstTwo;
        break;
      case 'panel3':
        eventObj.click_label = wording?.FAQ.QstThree;
        break;
      case 'panel4':
        eventObj.click_label = wording?.FAQ.QstFour;
        break;
      case 'panel5':
        eventObj.click_label = wording?.FAQ.QstFive;
        break;
      case 'panel6':
        eventObj.click_label = wording?.FAQ.QstSix;
        break;
      case 'panel7':
        eventObj.click_label = wording?.FAQ.QstSeven;
        break;
    }
    pushEvent(eventObj);
  };
  return (
    <div className={classes.root}>
      <CustomTitle
        title={wording?.FAQ.title}
        colorBorder={'blue'}
        positionBorder={''}
        positionTitle={''}
      />
      <div className={classes.bodyContent}>
        <Accordion
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
        >
          <AccordionSummary
            expandIcon={
              expanded === 'panel1' ? (
                <img src={Close} alt={wording?.alt.moins_home} />
              ) : (
                <img src={Open} alt={wording?.alt.plus_home} />
              )
            }
            aria-controls='panel1a-content'
            id='panel1a-header'
          >
            <Typography
              className={
                langSelected === LANG_STATE.FR
                  ? classes.heading
                  : classes.headingAr
              }
            >
              {wording?.FAQ.QstOne}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <Typography className={classes.contentDetails}>
              {wording?.FAQ.ContentOneOfQstOne}
            </Typography>
            <Typography className={classes.contentDetails}>
              {wording?.FAQ.ContentTwoOfQstOne}
            </Typography>
            <Typography className={classes.contentDetails}>
              {wording?.FAQ.ContentFourOfQstOne}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <div className={classes.separator} />
        <Accordion
          expanded={expanded === 'panel2'}
          onChange={handleChange('panel2')}
        >
          <AccordionSummary
            expandIcon={
              expanded === 'panel2' ? (
                <img src={Close} alt={wording?.alt.moins_home} />
              ) : (
                <img src={Open} alt={wording?.alt.plus_home} />
              )
            }
            aria-controls='panel2a-content'
            id='panel2a-header'
          >
            <Typography
              className={
                langSelected === LANG_STATE.FR
                  ? classes.heading
                  : classes.headingAr
              }
            >
              {wording?.FAQ.QstTwo}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <Typography className={classes.contentDetails}>
              {wording?.FAQ.ContentOneOfQstTwo}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <div className={classes.separator} />
        <Accordion
          expanded={expanded === 'panel3'}
          onChange={handleChange('panel3')}
        >
          <AccordionSummary
            expandIcon={
              expanded === 'panel3' ? (
                <img src={Close} alt={wording?.alt.moins_home} />
              ) : (
                <img src={Open} alt={wording?.alt.plus_home} />
              )
            }
            aria-controls='panel3a-content'
            id='panel3a-header'
          >
            <Typography
              className={
                langSelected === LANG_STATE.FR
                  ? classes.heading
                  : classes.headingAr
              }
            >
              {wording?.FAQ.QstThree}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <Typography className={classes.contentDetails}>
              <span className={`${classes.bold} ${classes.pink}`}>
                {wording?.FAQ.stepOneOfQstThree}
              </span>
              {''}
              {wording?.FAQ.contentStepOneOfQstThree}
            </Typography>
            <Typography className={classes.contentDetails}>
              <span className={`${classes.bold} ${classes.pink}`}>
                {wording?.FAQ.stepTwoOfQstThree}
              </span>
              {''}
              {wording?.FAQ.contentStepTwoOfQstThree}
            </Typography>
            <Typography className={classes.contentDetails}>
              {wording?.FAQ.contentTwoStepTwoOfQstThree}
            </Typography>
            <Typography className={classes.contentDetails}>
              {wording?.FAQ.contentThreeStepTwoOfQstThree}
            </Typography>
            <Typography className={classes.contentDetails}>
              <span className={`${classes.bold} ${classes.pink}`}>
                {wording?.FAQ.stepThreeOfQstThree}
              </span>
              {''}
              {wording?.FAQ.contentStepThreeOfQstThree}
            </Typography>
            <Typography className={classes.contentDetails}>
              <span className={`${classes.bold} ${classes.pink}`}>
                {wording?.FAQ.stepFourOfQstThree}
              </span>
              {''}
              {wording?.FAQ.contentStepFourOfQstThree}
            </Typography>
            <Typography className={classes.contentDetails}>
              {wording?.FAQ.contentOneStepFourOfQstThree}
            </Typography>
            <Typography className={classes.contentDetails}>
              <span className={`${classes.bold} ${classes.pink}`}>
                {wording?.FAQ.stepFiveOfQstThree}
              </span>
              {''}
              {wording?.FAQ.contentOneStepFiveOfQstThree}
            </Typography>
            <Typography className={classes.contentDetails}>
              {wording?.FAQ.contentTwoStepFiveOfQstThree}
            </Typography>
            <Typography
              className={`${classes.contentDetails} ${classes.someText} ${classes.pink}`}
            >
              {wording?.FAQ.noteQstThree}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <div className={classes.separator} />
        <Accordion
          expanded={expanded === 'panel4'}
          onChange={handleChange('panel4')}
        >
          <AccordionSummary
            expandIcon={
              expanded === 'panel4' ? (
                <img src={Close} alt={wording?.alt.moins_home} />
              ) : (
                <img src={Open} alt={wording?.alt.plus_home} />
              )
            }
            aria-controls='panel4a-content'
            id='panel4a-header'
          >
            <Typography
              className={
                langSelected === LANG_STATE.FR
                  ? classes.heading
                  : classes.headingAr
              }
            >
              {wording?.FAQ.QstFour}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <Typography className={classes.contentDetails}>
              {wording?.FAQ.contentOneOfQstFour}
            </Typography>
            <Typography className={classes.contentDetails}>
              {wording?.FAQ.contentTwoOfQstFour}{' '}
              <span>
                <Link
                  target='_blank'
                  rel='noopener noreferrer'
                  href={wording?.FAQ.contentHrefThreeOfQstFour}
                  className={classes.link}
                >
                  {wording?.FAQ.contentThreeOfQstFour}
                </Link>
              </span>{' '}
            </Typography>
            <Typography className={classes.contentDetails}>
              {wording?.FAQ.contentFiveOfQstFour}
            </Typography>
            <Typography className={classes.contentDetails}>
              {wording?.FAQ.contentSixOfQstFour}{' '}
              <span className={classes.colordesc}>
                {wording?.FAQ.contentSevenOfQstFour}
              </span>{' '}
              {wording?.FAQ.contentEightOfQstFour}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <div className={classes.separator} />
        <Accordion
          expanded={expanded === 'panel5'}
          onChange={handleChange('panel5')}
        >
          <AccordionSummary
            expandIcon={
              expanded === 'panel5' ? (
                <img src={Close} alt={wording?.alt.moins_home} />
              ) : (
                <img src={Open} alt={wording?.alt.plus_home} />
              )
            }
            aria-controls='panel5a-content'
            id='panel5a-header'
          >
            <div
              className={
                langSelected === LANG_STATE.FR
                  ? classes.heading
                  : classes.headingAr
              }
              dangerouslySetInnerHTML={{
                __html: wording?.FAQ.QstFive,
              }}
            />
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <Typography
              className={`${classes.contentDetails} ${classes.underline}`}
            >
              {wording?.FAQ.titleOneOfQstFive}
            </Typography>
            <Typography className={classes.contentDetails}>
              {wording?.FAQ.contentOneStepOneOfQstFive}
            </Typography>
            <Typography className={classes.contentDetails}>
              {wording?.FAQ.contentTwoStepOneOfQstFive}
            </Typography>
            <Typography className={classes.contentDetails}>
              {wording?.FAQ.contentThreeStepOneOfQstFive}
            </Typography>
            <Typography className={classes.contentDetails}>
              {wording?.FAQ.contentFourStepOneOfQstFive}
            </Typography>
            <Typography className={classes.contentDetails}>
              {wording?.FAQ.contentFiveStepOneOfQstFive}
            </Typography>
            <Typography className={classes.contentDetails}>
              {wording?.FAQ.contentSixStepOneOfQstFive}{' '}
              <span>{wording?.FAQ.contentSevenStepOneOfQstFive}</span>
            </Typography>
            <Typography
              className={`${classes.contentDetails} ${classes.underline}`}
            >
              {wording?.FAQ.titleTwoOfQstFive}
            </Typography>
            <Typography className={classes.contentDetails}>
              {wording?.FAQ.contentOneStepTwoOfQstFive}
            </Typography>
            <Typography className={classes.contentDetails}>
              {wording?.FAQ.contentTwoStepTwoOfQstFive}
            </Typography>
            <Typography className={classes.contentDetails}>
              {wording?.FAQ.contentThreeStepTwoOfQstFive}
            </Typography>
            <Typography className={classes.contentDetails}>
              {wording?.FAQ.contentFourStepTwoOfQstFive}{' '}
              <span>{wording?.FAQ.contentFiveStepTwoOfQstFive}</span>
            </Typography>
            <Typography
              className={`${classes.contentDetails} ${classes.underline}`}
            >
              {wording?.FAQ.titleThreeOfQstFive}
            </Typography>
            <Typography className={classes.contentDetails}>
              {wording?.FAQ.contentOneStepThreeOfQstFive}
            </Typography>
            <Typography className={classes.contentDetails}>
              {wording?.FAQ.contentTwoStepThreeOfQstFive}
            </Typography>
            <Typography className={classes.contentDetails}>
              {wording?.FAQ.contentThreeStepThreeOfQstFive}{' '}
              <span>{wording?.FAQ.contentFourStepThreeOfQstFive}</span>
            </Typography>
            <Typography className={classes.contentDetails}>
              {wording?.FAQ.contentFiveStepThreeOfQstFive}
            </Typography>
            <Typography
              className={`${classes.contentDetails} ${classes.someText} ${classes.pink}`}
            >
              {wording?.FAQ.someTextStepThreeOfQstFive}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <div className={classes.separator} />
        <Accordion
          expanded={expanded === 'panel6'}
          onChange={handleChange('panel6')}
        >
          <AccordionSummary
            expandIcon={
              expanded === 'panel6' ? (
                <img src={Close} alt={wording?.alt.moins_home} />
              ) : (
                <img src={Open} alt={wording?.alt.plus_home} />
              )
            }
            aria-controls='panel6a-content'
            id='panel6a-header'
          >
            <Typography
              className={
                langSelected === LANG_STATE.FR
                  ? classes.heading
                  : classes.headingAr
              }
            >
              {wording?.FAQ.QstSix}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <Typography className={classes.contentDetails}>
              {wording?.FAQ.contentQstSix}
            </Typography>
            <Typography className={classes.contentDetails}>
              {wording?.FAQ.secondcontentQstSix}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <div className={classes.separator} />
        <Accordion
          expanded={expanded === 'panel7'}
          onChange={handleChange('panel7')}
        >
          <AccordionSummary
            expandIcon={
              expanded === 'panel7' ? (
                <img src={Close} alt={wording?.alt.moins_home} />
              ) : (
                <img src={Open} alt={wording?.alt.plus_home} />
              )
            }
            aria-controls='panel7a-content'
            id='panel7a-header'
          >
            <Typography
              className={
                langSelected === LANG_STATE.FR
                  ? classes.heading
                  : classes.headingAr
              }
            >
              {wording?.FAQ.QstSeven}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <Typography className={classes.contentDetails}>
              {wording?.FAQ.contentQstSeven}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default FAQ;
