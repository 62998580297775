import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { GlobalTheme } from 'features/app/app.theme';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CustomInput } from 'common/components/customFields/CustomInput';
import FormLabel from '@material-ui/core/FormLabel';
import Tooltip from 'common/components/customFields/CustomTooltip';

const useStyles = makeStyles((theme: GlobalTheme) =>
  createStyles({
    root: {
      width: '100%',
      borderRadius: '40px',
      borderColor: theme.secondary.title,
      '& .MuiInputBase-root': {
        padding: '2px 15px',
        border: 'solid 1px',
        borderRadius: '40px',
        borderColor: theme.primary.gray,
      },
    },
    globalTooltip: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    tooltip: {
      padding: '0',
      margin: 'auto',
      marginLeft: '10px',
    },
    formLabel: {
      fontSize: '0.9375rem',
      textAlign: 'left',
      fontFamily: theme.fonts.medium,
      color: theme.secondary.title,
      marginBottom: '7px',
    },
    formLabelTooltip: {
      fontSize: '0.9375rem',
      textAlign: 'left',
      fontFamily: theme.fonts.medium,
      color: theme.secondary.title,
    },
    noBorder: {
      border: 0,
      '& .MuiInputBase-root': {
        padding: '0px 15px',
      },
    },
  }),
);

interface ListDown {
  label: string;
  value: string;
}

interface PropsCustomAutoComplete {
  label?: string;
  tooltip?: string;
  options: ListDown[];
  className?: any;
  onChange?: (e: any) => void;
  onChangeValue?: (e: React.ChangeEvent<{}>, value: any) => void;
  onChangeName?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  name: string;
  formLabel?: string;
  value?: string | object;
  placeholder: string;
  suffix?: string;
  endElement?: string;
  // valueAutoComplete?: string;
  loadingText?: string;
  error?: any;
  hasDefaultBorder?: boolean;
}

export const CustomAutoComplete = ({
  onChangeValue = () => ({}),
  // onChangeName = () => ({}),
  // onBlur = () => ({}),
  options = [],
  name = '',
  formLabel = '',
  tooltip,
  value,
  placeholder = '',
  loadingText = '',
  error = null,
  hasDefaultBorder = false,
}: PropsCustomAutoComplete) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  return (
    <div>
      <div className={classes.globalTooltip}>
        {formLabel && (
          <FormLabel
            className={tooltip ? classes.formLabelTooltip : classes.formLabel}
            component='legend'
          >
            {formLabel}
            {tooltip && <Tooltip content={tooltip}></Tooltip>}
          </FormLabel>
        )}
      </div>
      <Autocomplete
        freeSolo={true}
        className={classes.root}
        onChange={onChangeValue}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        loadingText={loadingText}
        getOptionLabel={(option: any) => (option.label ? option.label : '')} // (value ? value : '')
        options={options}
        loading={open}
        value={value}
        renderInput={(params: any) => (
          <CustomInput
            {...params}
            hasDefaultBorder={hasDefaultBorder}
            // onChange={onChangeName}
            name={name}
            // value={value}
            placeholder={placeholder}
            error={error}
          />
        )}
      />
    </div>
  );
};
