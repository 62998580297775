import { authSagas } from '../features/auth/sagas.auth';
import { appSagas } from '../features/app/app.sagas';
import { wordingSagas } from 'common/wording/saga.wording';
import { situationSagas } from '../features/situation/sagas.situation';
import { preattSituationSagas } from '../features/situation/sagas.preattributionSituation';
import { propositionSagas } from '../features/proposition/sagas.proposition';
import { recapSagas } from '../features/recap/sagas.recap';
import { simulationSagas } from '../features/home/sagas.simulation';
import { uploadDocumentSagas } from '../features/upload/sagas.upload';
import { congratulationSagas } from '../features/congratulation/sagas.congratulation';
import { updateInformationSagas } from '../features/updateInformation/sagas.updateInformation';
import { validateTokenSagas } from '../features/validationBmceDirect/sagas.validation';

export const rootSagas = [
  ...authSagas,
  ...situationSagas,
  ...uploadDocumentSagas,
  ...appSagas,
  ...wordingSagas,
  ...simulationSagas,
  ...propositionSagas,
  ...recapSagas,
  ...congratulationSagas,
  ...updateInformationSagas,
  ...validateTokenSagas,
  ...preattSituationSagas,
];
