export function changeDateFormat(format: string, date: Date) {
  const options = { year: 'numeric', month: 'numeric' };
  switch (format) {
    case 'dd/MM/yyyy':
      return String(new Date(date).toLocaleDateString('fr-FR'));
    case 'MM/yyyy':
      return String(new Date(date).toLocaleDateString('fr-FR', options));
    default:
      return date;
  }
}

export function getTotalRibFormat(
  firstElemRib: string,
  secondElemRib: string,
  lastElemRib: string,
) {
  const rib =
    '011' +
    String(firstElemRib) +
    '0000' +
    String(secondElemRib) +
    String(lastElemRib);
  return rib.split(' ').join('');
}
