import { StepIconProps } from '@material-ui/core/StepIcon';
import { makeStyles } from '@material-ui/core/styles';
import { Check } from '@material-ui/icons';
import theme from 'features/app/app.theme';
import React from 'react';

const useQontoStepIconStyles = makeStyles({
  root: {
    color: theme.primary.gray,
    display: 'flex',
    height: 32,
    width: 32,
    alignItems: 'center',
    justifyContent: 'center',
    border: `2px solid ${theme.primary.gray}`,
    borderRadius: '50%',
  },
  active: {
    borderColor: theme.secondary.main,
    color: theme.secondary.main,
  },
  completed: {
    backgroundColor: theme.secondary.main,
    borderColor: theme.secondary.main,
    color: theme.secondary.main,
  },
  circle: {
    width: 12,
    height: 12,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  iconCompleted: {
    color: theme.primary.white,
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props: StepIconProps) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={`${classes.root} ${
        completed ? classes.completed : active ? classes.active : ''
      }`}
    >
      {completed ? (
        <Check className={classes.iconCompleted} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

export default QontoStepIcon;
