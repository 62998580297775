export enum PropositionAT {
  initPropositionAT = 'PROPOSITIONAT/INITPROPOSITIONAT',
  submitProposition = 'PROPOSITIONAT/SUBMITPROPOSITION',
  updateProposition = 'PROPOSITIONAT/UPDATEPROPOSITION',
  updateFieldProposition = 'PROPOSITIONAT/UPDATEFIELDPROPOSITION',
}

export interface InitPropositionATAction {
  type: PropositionAT.initPropositionAT;
}

export interface SubmitPropositionAction {
  type: PropositionAT.submitProposition;
}

export interface UpdatePropositionAction {
  type: PropositionAT.updateProposition;
  data: any;
}

export interface UpdateFieldPropositionAction {
  type: PropositionAT.updateFieldProposition;
  name: string;
  value: any;
}

export const updatePropositionAction = (
  name: string,
  value: any,
): UpdateFieldPropositionAction => ({
  type: PropositionAT.updateFieldProposition,
  name,
  value,
});
