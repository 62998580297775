import { toJS } from 'common/utils/app.util';
import { createSelector } from 'reselect';
import { AppStore } from '../../core/root.model';
import { CASETRACKING_REDUCER } from './reducer.caseTracking';

const getCaseTracking = (state: AppStore) => state.get(CASETRACKING_REDUCER);

export const getCaseTrackingErrorsSelector = createSelector(
  getCaseTracking,
  (caseTracking) => toJS(caseTracking.getIn(['caseTracking', 'errors'])),
);

export const getCaseTrackingInfoByArraySelector = (selector: string[]) =>
  createSelector(getCaseTracking, (caseTracking) =>
    toJS(caseTracking.getIn(selector)),
  );
