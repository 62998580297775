import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import CheckedRadioButton from 'assets/svg/ActiveRadioButton.svg';
import UncheckedRadioButton from 'assets/svg/NonActiveRadioButton.svg';
import { GlobalTheme } from 'features/app/app.theme';
import React from 'react';
import { AccountTypes } from 'features/auth/constants';
import { Wording } from 'common/wording/reducer.wording';
import { useSelector } from 'react-redux';
import { selectWording } from 'common/wording/selectors.wording';

const useStyles = makeStyles((theme: GlobalTheme) =>
  createStyles({
    formControl: {
      width: '100%',
    },
    radioGroupWrapper: ({ row }: { row: boolean }) => ({
      display: 'flex',
      flexDirection: row ? 'row' : 'column',
      alignItems: 'center',
      height: 'max-content',
      '@media (max-width: 768px)': {
        flexWrap: 'wrap',
      },
    }),
    radioGroup: {
      minWidth: '110%',
      display: 'flex',
      flexWrap: 'nowrap',
      '@media (max-width: 768px)': {
        flexWrap: 'wrap',
      },
      justifyContent: 'space-evenly',
    },
    formLabel: {
      color: theme.secondary.title,
    },
    radioButtonCheck: {
      '&, & + $label': {
        color: theme.primary.title,
        fontFamily: theme.fonts.medium,
      },
    },
    label: {
      color: theme.secondary.title,
      fontFamily: theme.fonts.medium,
      width: '100%',
      fontSize: '0.875rem',
    },
    iconRadioButton: {
      width: '20px',
    },
  }),
);

interface PropsCustomRadioGroup {
  label?: string;
  value?: string;
  name: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string | null;
  type?: string;
  placeholder?: string;
  row?: boolean;
  listOfElements: { value: any }[];
  wording?: any;
  className?: any;
}

export const CustomRadioGroup = ({
  value = '',
  onChange = () => ({}),
  error = null,
  label = '',
  name = '',
  listOfElements = [],
  wording,
  row = true,
  className,
  ...rest
}: PropsCustomRadioGroup) => {
  const classes = useStyles({ row });
  const selectorWording: Wording = useSelector(selectWording);
  return (
    <FormControl error={!!error} className={classes.formControl}>
      <div className={classes.radioGroupWrapper}>
        <FormLabel className={classes.formLabel} component='legend'>
          {label}
        </FormLabel>
        <RadioGroup
          className={`${classes.radioGroup} ${className}`}
          row
          aria-label='position'
          name={name}
          onChange={onChange}
          value={value}
          {...rest}
        >
          {listOfElements.map((element: any) => (
            <FormControlLabel
              key={element.value}
              value={element.value}
              classes={{ label: classes.label }}
              control={
                <Radio
                  icon={
                    <img
                      className={classes.iconRadioButton}
                      src={UncheckedRadioButton}
                      alt={selectorWording?.alt?.select}
                    />
                  }
                  checkedIcon={
                    <img
                      className={classes.iconRadioButton}
                      src={CheckedRadioButton}
                      alt={selectorWording?.alt?.select}
                    />
                  }
                  classes={{ checked: classes.radioButtonCheck }}
                />
              }
              label={
                isEmpty(wording)
                  ? element.label
                  : element.value === AccountTypes.salarie
                  ? wording?.salarie
                  : element.value === AccountTypes.fonctionnaire
                  ? wording?.fonctionnaire
                  : element.value === AccountTypes.retraite
                  ? wording?.retraite
                  : element.value === AccountTypes.professionnel
                  ? wording?.professionnel
                  : element.value === 'N'
                  ? wording?.conso
                  : element.value === 'O'
                  ? wording?.rachat
                  : element.value === true
                  ? wording?.yes
                  : wording?.no
              }
            />
          ))}
        </RadioGroup>
      </div>

      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};
