import { createStyles } from '@material-ui/core';
import { isEmpty } from 'lodash';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { GlobalTheme } from 'features/app/app.theme';
import React from 'react';
import FormLabel from '@material-ui/core/FormLabel';
import Tooltip from 'common/components/customFields/CustomTooltip';
import { selectCompWording } from 'common/wording/selectors.wording';
import { useSelector } from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox';
import { LANG_STATE } from 'features/home/constants.simulation';
import { getSimulationInfoByArraySelector } from 'features/home/selector.simulation';
import { RachatFieldElement } from './RachatFieldElement';
import CheckedFilled from 'assets/svg/checked.svg';
import Unchecked from 'assets/svg/unchecked.svg';
import { getSituationInfoByArraySelector } from 'features/situation/selector.situation';

const useStyles = makeStyles((theme: GlobalTheme) =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    globalTooltip: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formLabel: {
      fontSize: '0.9375rem',
      textAlign: 'left',
      fontFamily: theme.fonts.medium,
      color: theme.secondary.title,
      marginBottom: '17px',
    },
    formLabelTooltip: {
      fontSize: '0.9375rem',
      textAlign: 'left',
      fontFamily: theme.fonts.medium,
      color: theme.secondary.title,
    },
    paper: {
      borderStyle: 'groove',
      borderColor: '#b2cde8',
      borderWidth: 2,
      borderRadius: 25,
    },
    gridItem: {
      width: '50%',
      '@media (max-width: 768px)': {
        width: 'auto',
      },
    },
    gridItemTwo: {
      padding: '20px',
    },
    labelTitle: {
      fontFamily: 'roboto-medium',
      color: theme.secondary.title,
      marginBottom: -2,
    },
    label: {
      fontSize: '0.875rem',
      marginBottom: -2,
    },
    value: {
      fontWeight: 'bold',
      marginRight: '2px !important',
      fontSize: '1.0625rem',
      color: theme.primary.title,
    },
    unit: {
      fontFamily: theme.fonts.regular,
      whiteSpace: 'nowrap',
    },
    unitFont: {
      fontWeight: 'bold',
      fontSize: '1.125rem !important',
    },
    lowBlock: {
      marginTop: 4,
    },
    red: {
      color: '#F93276',
      fontSize: '0.875rem !important',
    },
    nonred: {
      fontSize: '0.875rem !important',
    },
    without: {
      margin: '0px',
    },
    highBlock: {
      marginTop: 4,
      display: 'flex',
    },
    highLeftBlock: {
      flexGrow: 1,
      textAlign: 'left',
      margin: 'auto',
    },
    highRightBlock: {
      flexGrow: 0,
    },
    checkBoxIcon: {
      width: '22px',
    },
    gridParent: {
      '@media (max-width: 768px)': {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    typographyH5: {
      fontSize: '1rem',
      '@media (max-width: 1280px)': {
        fontSize: '0.9375rem',
      },
      '@media (max-width: 1024px)': {
        fontSize: '0.9375rem',
      },
      '@media (max-width: 768px)': {
        fontSize: '1.0625rem',
      },
    },
  }),
);

export interface ListGrid {
  idSimulation?: string;
  numDossRachat?: string;
  mntCRD?: string;
  mntECh?: string;
  nominal?: string;
  dateFin?: string;
  checked?: boolean;
}

interface PropsSelectGrid {
  name?: string;
  onChange?: (e: any) => void;
  error?: string | null;
  tooltip?: string;
}
export default function SelectGrid({
  name = '',
  onChange = () => ({}),
  error = null,
  tooltip = '',
}: PropsSelectGrid) {
  const classes = useStyles();
  const wording = useSelector(selectCompWording('detailedForm'));
  const langSelected: any =
    useSelector(getSimulationInfoByArraySelector(['lang'])) || LANG_STATE.FR;
  const situationSalarie: any = useSelector(
    getSituationInfoByArraySelector(['situationSalarie', 'fields']),
  );
  const list: ListGrid[] = situationSalarie.listRachats;
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const listchanged = list?.map((element?: ListGrid) => ({
      ...element,
      checked:
        element?.numDossRachat === event.target.value
          ? event.target.checked
          : element?.checked,
    }));
    const evt = {
      target: {
        name: name,
        value: listchanged,
      },
    };
    onChange(evt);
  };
  return (
    <div>
      <div className={classes.globalTooltip}>
        {wording?.creditRacheter && (
          <FormLabel
            className={tooltip ? classes.formLabelTooltip : classes.formLabel}
            component='legend'
          >
            {wording?.creditRacheter}
            {tooltip && <Tooltip content={tooltip} />}
          </FormLabel>
        )}
      </div>
      <div className={classes.container}>
        <Grid item className={classes.gridParent} container spacing={6}>
          {!isEmpty(list) &&
            list.map((element?: ListGrid) => (
              <Grid item className={classes.gridItem}>
                <div className={classes.paper}>
                  <Grid item className={classes.gridItemTwo}>
                    <div className={classes.highBlock}>
                      <div className={classes.highLeftBlock}>
                        <Typography
                          variant='h5'
                          className={classes.typographyH5}
                        >
                          <label
                            className={`${classes.labelTitle} ${classes.without}`}
                          >
                            {wording?.mntEcheanceTitle}
                          </label>
                          <br />
                          <label
                            className={`${classes.value} ${
                              classes.labelTitle
                            } ${
                              element?.checked ? classes.red : classes.without
                            }`}
                          >
                            {langSelected === LANG_STATE.FR && (
                              <label
                                dir='ltr'
                                className={`${classes.unit} ${classes.unitFont}`}
                              >
                                {element?.mntECh?.replace(
                                  /(?=(?!\b)(\d{3})+\.?\b)/g,
                                  ' ',
                                )}
                              </label>
                            )}
                            {langSelected === LANG_STATE.AR && (
                              <label
                                dir='ltr'
                                className={`${classes.unit} ${classes.unitFont}`}
                              >
                                {element?.mntECh?.replace(
                                  /(?=(?!\b)(\d{3})+\.?\b)/g,
                                  ' ',
                                )}
                              </label>
                            )}
                          </label>

                          <label
                            className={`${classes.unit} ${
                              element?.checked ? classes.red : classes.nonred
                            }`}
                          >
                            {wording?.dhs}
                          </label>
                        </Typography>
                      </div>
                      <div className={classes.highRightBlock}>
                        <Checkbox
                          icon={
                            <img
                              src={Unchecked}
                              alt=''
                              className={classes.checkBoxIcon}
                            />
                          }
                          checkedIcon={
                            <img
                              src={CheckedFilled}
                              alt=''
                              className={classes.checkBoxIcon}
                            />
                          }
                          name={'checked'}
                          value={element?.numDossRachat}
                          checked={element?.checked}
                          onChange={handleChange}
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      </div>
                    </div>
                    <div className={classes.lowBlock}>
                      <RachatFieldElement
                        label={wording?.creditRachat}
                        value={element?.nominal}
                        unit={wording?.dhs}
                      />
                      <RachatFieldElement
                        label={wording?.mntRestant}
                        value={element?.mntCRD}
                        unit={wording?.dhs}
                      />
                      <RachatFieldElement
                        label={wording?.dateFinCredit}
                        value={element?.dateFin}
                      />
                    </div>
                  </Grid>
                </div>
              </Grid>
            ))}
        </Grid>
      </div>
    </div>
  );
}
