import StepConnector from '@material-ui/core/StepConnector';
import { withStyles } from '@material-ui/core/styles';
import theme from 'features/app/app.theme';

const QontoConnector = withStyles({
  active: {
    '& $line': {
      borderColor: theme.secondary.main,
    },
  },
  completed: {
    '& $line': {
      borderColor: theme.secondary.main,
    },
  },
  line: {
    borderColor: theme.primary.gray,
    borderLeftWidth: 2,
    borderRadius: 1,
  },
  root: {
    padding: '5px 0 5px',
    marginLeft: '17px',
  },
})(StepConnector);

export default QontoConnector;
